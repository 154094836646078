
import * as firebase from 'firebase/app'
import 'firebase/analytics';

export function useGoogleAnalyticsHook() {

  const analytics = firebase.analytics();

  /*
  @platerocm

  events[category][action][event] : [label]

  */

  const events: any = {
    'questions': {
      'new': {
        'new_question_draft': 'Draft',
        'new_question_open': 'New question open',
        'new_comment': 'Comment',
        'new_first_comment': 'First Comment',
      },
      'state_change': {
        'question_draft_moves_to_open': 'Draft to open',
        'question_ready_for_review': 'Ready for review',
        'answer_selected': 'Answer selected',
      },
      'archived': {
        'archived_from_draft': 'From draft',
        // 'archived_from_open': 'From open',
        'archived_from_answer_selected': 'From answer selected',
        'archived_from_ready_for_review': 'From ready for review'
      },
      'unarchived': {
        'unarchived': 'Restore question'
      }
    },
    'discussions': {
      'edit': {
        'archive': 'Archive discussion',
        'restore': 'Restore discussion',
      },
    },
    'reports': {
      'new': {
        'new_report': 'Report without editors',
        'new_report_with_editors': 'Report with editors',
      },
      'edit': {
        'report_edit': 'From table',
        'report_edit_order_questions': 'From report edit',
        'report_edit_questions': 'From report edit',
        'report_add_viewer': 'From report edit',
        'report_add_team': 'From report edit',
        'report_add_editor': 'From report edit',
        'report_remove_viewer': 'From report edit',
        'report_remove_team': 'From report edit',
        'report_remove_editor': 'From report edit',
      },
      'view': {
        'report_view': 'From table',
        'report_export': 'Export',
      },
      'delete': {
        'report_delete': 'From table'
      }
    },
    'general': {
      'view': {
        'hw_init_app': 'Start HW'
      }
    }
  }

  const newEventGA = (category: string, action: string, event: string) => {

    let payload = {
      event_category: category,
      event_action: action,
      event_label: ''
    }

    const host = window.location.hostname;
    if (host !== 'localhost') {
      if (events[category][action][event]) {
        payload.event_label = events[category][action][event];
        analytics.logEvent(event, payload);
      } else {
        console.error(`This GA Event not exist in HW : ${event}`, payload);
      }
    }
  }

  return {
    functions: {
      newEventGA
    }
  }
}