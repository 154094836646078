/* Core | Components | Hooks | Helpers | Third-party */

import React, { useState, useEffect, useCallback } from 'react';
import { SuspenseWithPerf, useUser } from 'reactfire'
import Header from './Header'

import GridCenter from 'pages/App/components/GridCenter'
import SelectorSubjects from 'pages/App/components/Dropdowns/SelectorSubjects'
import SelectorTeams from 'pages/App/components/Dropdowns/SelectorTeams'
import SelectorUsers from 'pages/App/components/Dropdowns/SelectorUsers'
import { Loading } from 'pages/App/components/Loading';
import { useDropzone } from 'react-dropzone';
import * as firebase from 'firebase/app'
import 'firebase/storage';
import 'firebase/firestore';

import { Form, Input, Icon, Divider, Radio, Button, Dimmer, Loader, Segment, Label } from 'semantic-ui-react'
import { useProjectHook } from 'services/hooks/useProjectHook';
import TextareaPack from 'pages/App/components/TextareaPack';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { useContextOrganisations } from 'services/hooks/useContextOrganisations';
import { DropZoneHW, ThumbDropZone } from './styles';
import { CenterAlign } from '../Table/styles';
import { orgId, projectId, subjectId, teamId, userId } from 'typings/generic';
import { projectType } from 'typings/projects';

interface Props {
  path: string;
  oid: typeof orgId;
  projId: typeof projectId;
}

function EditDiscussionComponent({ path, oid, projId }: Props) {

  const { uid }: any = useUser();
  const storage: any = firebase.storage();

  // UI ===>
  const [loading, setLoading] = useState<boolean>(true)
  const [disabledForm, setDisabledForm] = useState<boolean>(false)

  /* Custom Hooks */
  const { functions: { projectAccessFromContextAdd } } = useContextOrganisations();
  const { functions: { projectById, newProject, editProject } } = useProjectHook(oid);

  // Form ===>
  const [projectData] = useState<projectType | null>(projectById(projId))

  const [projectName, setProjectName] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');
  const [projectPrivacy, setProjectPrivacy] = useState<'private' | 'public'>('private');
  const [adminProj, setAdminProj] = useState<Array<typeof userId>>([]);
  const [teamsProj, setTeamsForQ] = useState<Array<typeof teamId>>([]);
  const [usersProj, setUsersProj] = useState<Array<typeof userId>>([]);
  const [subjectsProj, setSubjectsProj] = useState<Array<typeof subjectId>>([]);
  // <=== Form

  // Cover Image ===>
  const [coverImage, setCoverImage] = useState<Array<any>>([]);
  const [invalidCoverImage, setInvalidCoverImage] = useState<boolean>();
  const [coverImageCurrent, setCurrentCoverImage] = useState<string>('');
  const [coverImageFile, setCoverImageFile] = useState<Array<Blob>>();

  const onDrop = useCallback((acceptedFiles: any) => {
    setCoverImageFile(acceptedFiles);

    setCoverImage(
      acceptedFiles.map((file: any) => {
        if (file.size / 1000 > 500) {
          setInvalidCoverImage(true)
        } else {
          setInvalidCoverImage(false)
        }

        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      })
    );
  }, []);

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    coverImage.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [coverImage]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: onDrop,
  });

  const Thumbs = coverImage.map((file: any) => (
    <ThumbDropZone
      key={file.name || ''}
      alt={file.name || ''}
      src={file.preview}
    />
  ));
  // <=== Cover Image

  useEffect(() => {
    if (projId) {
      if (projectData?.createdBy !== uid) {
        setDisabledForm(true);
        setLoading(false);
      }

      if (projectData) {
        setProjectName(projectData.name)
        setProjectDescription(projectData.description)
        setProjectPrivacy(projectData.visibility)
        setAdminProj(projectData.editors)
        setTeamsForQ(projectData.teams)
        setUsersProj(projectData.users)
        setSubjectsProj(projectData.subjects)

        if (projectData.coverImage) {
          const pathImageReference = storage.ref(projectData.coverImage);
          pathImageReference.getDownloadURL().then((url: string) => {
            setCurrentCoverImage(url);
          });
        }

        setLoading(false);
      }
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePrivacy = (e: any, { value }: any) => setProjectPrivacy(value)

  const handleDescription = (e: any) => setProjectDescription(e.target.value)

  const handleUploadImage = ({ file, refStorage, storage, invalidCoverImage }: any) => {

    return new Promise((resolve, reject) => {
      if (invalidCoverImage === true || !file) {
        resolve(true);
      } else {

        const refFireStorage = storage.ref(refStorage);

        refFireStorage.put(file).then((snapshot: any) => {
          if (snapshot.state === 'success') {
            snapshot.ref.getDownloadURL().then((downloadURL: string) => {
              const pathImage = snapshot.metadata.fullPath;
              resolve({ pathImage, downloadURL });
            }).catch((err: any) => {
              reject(err)
            })
          }
        }).catch((err: any) => {
          reject(err)
        })
      }
    })
  }


  const handleSaveChanges = () => {
    setLoading(true)

    const payload: any = {
      name: projectName,
      questionsQty: 0,
      description: projectDescription,
      editors: adminProj,
      users: usersProj,
      teams: teamsProj,
      subjects: subjectsProj,
      visibility: projectPrivacy,
      latestActivity: firebase.firestore.FieldValue.serverTimestamp(),
    }

    const refStorage = `/organisations/${oid}/projects/${projId}/coverImage`;
    const file = coverImageFile ? coverImageFile[0] : null;

    handleUploadImage({ file, refStorage, storage, invalidCoverImage })
      .then(async ({ pathImage }: any) => {
        if (pathImage) payload.coverImage = pathImage;

        if (!projId) {
          payload.questionsQty = 0;

          newProject(payload).then((data: any) => {
            projectAccessFromContextAdd(oid, [data.id], true)
            toast.success(`Discussion '${payload.name}' created correctly, we are configuring some things`)
            toast.info(`Please wait a few seconds`)
            navigate(`/app/org/${oid}/discussions`)
          }).catch(() => {
            setLoading(false)
          })
        } else {
          editProject(payload, projId).then(() => {
            toast.success(`Discussion '${payload.name}' edited correctly`)
            navigate('../../discussions')
          }).catch(() => {
            setLoading(false)
          })
        }

      }).catch((err: any) => {
        console.log(err)
      })
  }

  return (
    <div style={{ paddingTop: '0.4em' }}>
      <Header projId={projId} oid={oid} projectName={projectName} />
      <Divider />
      <GridCenter>
        {
          disabledForm &&
          <Segment inverted tertiary>
            Only the author can edit the discussion, please contact the author
          </Segment>
        }
      </GridCenter>
      <GridCenter>
        {
          loading &&
          <Dimmer active={loading} inverted>
            <Loader size='medium'>Loading Discussion</Loader>
          </Dimmer>
        }
        <Dimmer active={disabledForm} inverted></Dimmer>
        {
          !loading &&
          <Form style={{ marginTop: '1em' }}>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>Name</label>
                <Input labelPosition='left' type='text' placeholder=''>
                  <input
                    name="question"
                    type="text"
                    autoComplete="off"
                    placeholder="A concise name for the discussion"
                    value={projectName}
                    onChange={((event: any) => setProjectName(event.target.value))}
                  />
                </Input>
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field>
                <TextareaPack
                  label="Purpose"
                  placeholder="The purpose of this discussion"
                  row={3}
                  name='description'
                  value={projectDescription}
                  setValue={handleDescription}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group style={{ marginTop: '-2em' }}>
              <Form.Field width={16}>
                <label>
                  Admins
                  <Icon name='pencil alternate' style={{ marginLeft: '0.5em' }} />
                </label>
                {!loading &&
                  <SelectorUsers
                    oid={oid}
                    setResult={setAdminProj}
                    initial={adminProj}
                  />
                }
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <label>
                  Teams
                  <Icon name='users' style={{ marginLeft: '0.5em' }} />
                </label>
                {!loading &&
                  <SelectorTeams
                    oid={oid}
                    setResult={setTeamsForQ}
                    initial={teamsProj}
                  />
                }
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <label>
                  Individuals
                  <Icon name='user' style={{ marginLeft: '0.5em' }} />
                </label>
                {!loading &&
                  <SelectorUsers
                    oid={oid}
                    initial={usersProj}
                    setResult={setUsersProj}
                  />
                }
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <label>
                  Subjects
                  <Icon name='tag' style={{ marginLeft: '0.5em' }} />
                </label>
                {!loading &&
                  <SelectorSubjects
                    oid={oid}
                    initial={subjectsProj}
                    setResult={setSubjectsProj}
                  />
                }
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <label> Cover Image </label>
                <DropZoneHW
                  haveFile={coverImage.length > 0}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <p>Drop the file here... </p> :
                      <p>Drag and drop or click here to select a cover image for this discussion {projectName && `'${projectName}'`}</p>
                  }
                  {coverImageCurrent && coverImage.length < 1 &&
                    <>
                      <ThumbDropZone
                        alt={'current cover image'}
                        src={coverImageCurrent}
                      />
                      <p> Current cover image </p>
                    </>
                  }
                  {Thumbs}
                </DropZoneHW>
                {invalidCoverImage &&
                  <CenterAlign style={{ marginBottom: '1em' }}>
                    <Label color='red'>
                      Please provide a smaller image file. Maximum size 500KB
                    </Label>
                  </CenterAlign>
                }
                <CenterAlign>
                  {
                    coverImage.length > 0 &&
                    <Button
                      icon='trash'
                      circular
                      onClick={() => {
                        setCoverImage([])
                        setInvalidCoverImage(false)
                      }}
                    />
                  }
                </CenterAlign>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <label> Visibility </label>
                <p>
                  Who should be able to see that this discussion is taking place?
                </p>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <Radio
                  label='Invited Only'
                  checked={projectPrivacy === 'private'}
                  onChange={handleChangePrivacy}
                  value='private'
                />
                <Radio
                  style={{ marginLeft: '2em' }}
                  label='Organisation'
                  checked={projectPrivacy === 'public'}
                  onChange={handleChangePrivacy}
                  value='public'
                />
              </Form.Field>
            </Form.Group>
          </Form>
        }
      </GridCenter >
      <Divider />
      <GridCenter>
        <div style={{ float: 'right' }}>
          <Button color='grey' onClick={() => navigate(`/app/org/${oid}/discussions`)}> Cancel </Button>
          <Button primary disabled={projectName === '' || disabledForm} onClick={handleSaveChanges}> Save </Button>
        </div>
      </GridCenter>
    </div >
  );
}

function EditDiscussion(props: any) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId={`howwee-organisation-reports-${props.oid}`} >
      <EditDiscussionComponent {...props} />
    </ SuspenseWithPerf>
  )
}

export default EditDiscussion;