import React from 'react';
import BadgeOtherSubjects from './BadgeOtherSubjects';
import BadgeSubject from './BadgeSubject'

interface Props {
  subjects: Array<string>;
  oid: string;
  maxItems?: number;
}

function BadgeMultipleSubject({ subjects, oid, maxItems = 3 }: Props) {
  return (
    <>
      {
        subjects.map((subjectId: string, index: number) => {
          if (index < maxItems) {
            return <BadgeSubject key={index} subjectId={subjectId} oid={oid} />
          }
          return null;
        })
      }
      {
        subjects.length > maxItems &&
        <BadgeOtherSubjects oid={oid} subjects={subjects} maxItems={maxItems} />
      }
    </>
  )
}

export default BadgeMultipleSubject;