import React, { useState, useEffect } from 'react'
import { Table, Card, Header, Segment, Dropdown, Dimmer, Loader } from 'semantic-ui-react'
import { formatDate } from 'helpers/mini'
import { SuspenseWithPerf, useFirestoreCollectionData } from 'reactfire'
import { Loading } from 'pages/App/components/Loading';
import StatusInvititation from 'pages/App/components/Label/StatusInvitation'
import useFunctionsHook from 'services/hooks/useFunctionsHook';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';

interface Props {
  showInvites: boolean;
  oid: string;
}

function InvitationsComponent({ showInvites, oid }: Props) {

  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { collections: { HWFS } } = useFirestoreHook();
  const { functions: { runCloudFunction } } = useFunctionsHook('COREHW-cancelInvitation');

  const invitationsRef = HWFS.organisations_access_invitations(oid).orderBy('createdAt', 'desc');
  const invitations = useFirestoreCollectionData(invitationsRef, { idField: 'id' })

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancelInvite = (id: string) => {
    setDisabled(true);
    runCloudFunction({ invitationId: id, oid: oid }).then(() => {
      setDisabled(false);
    });
  }

  if (!showInvites) return <></>

  const TableInvitations = () => {
    return (
      <Table unstackable singleLine basic='very' collapsing style={{ margin: '20px', width: '100%', marginBottom: '50px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            invitations.length > 0 &&
            invitations.map((invite: any) => {
              return (
                <Table.Row key={invite.id}>
                  <Table.Cell>
                    <Header as='h4' image>
                      <Header.Content>
                        {invite.email}
                        <Header.Subheader>Email</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      invite.createdAt &&
                      formatDate(invite.createdAt)
                    }
                  </Table.Cell>
                  <Table.Cell>
                    <StatusInvititation code={invite.status} />
                  </Table.Cell>
                  <Table.Cell>
                    {invite.role}
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      icon='ellipsis vertical'
                      style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
                    >
                      <Dropdown.Menu>
                        {invite.status === 203 &&
                          <Dropdown.Item text='Cancel Invite' onClick={() => { cancelInvite(invite.id) }} />
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>)
  }


  return (
    <Card.Group itemsPerRow={1} style={{ marginTop: '15px', display: 'none' }} >
      <Card style={{ paddingTop: '10px' }}>
        <Header as='h3' attached='top'>
          Invitation history
        </Header>
        <Segment attached>
          <Dimmer active={disabled} inverted>
            <Loader size='medium'>Loading Invitations</Loader>
          </Dimmer>
          {!loading &&
            <TableInvitations />
          }
          {loading &&
            <Loading />
          }
        </Segment>
      </Card>
    </Card.Group>
  );
}

function Invitations(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Loading />}
      traceId={`howwee-invitations-${props.oid || ''}`}
    >
      <InvitationsComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default Invitations;