import React, { useContext, useEffect, useState } from 'react';
import 'firebase/auth';
import { navigate } from '@reach/router';
import { Button, Dropdown, Grid, Icon, Image, Menu } from "semantic-ui-react"
import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'
import HowWeeContext from 'services/contexts/ContextHowWee';
import noCompanyLogo from 'assets/nocompany.jpg'


function DropdownOrg() {

  const HWCtt: any = useContext(HowWeeContext);
  const searchCtt: any = useContext(SearchGlobalContext);
  const [openOID, setOpenOID]: any = useState(null);
  const [openOrg, setOpenOrg]: any = useState(null);

  useEffect(() => {
    if (HWCtt.data.HW_DEFAULT.organisation) {
      if (HWCtt.data.HW_DEFAULT.organisation.id !== openOID) {
        setOpenOrg(HWCtt.data.HW_DEFAULT.organisation);
        setOpenOID(HWCtt.data.HW_DEFAULT.organisation.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HWCtt.data.HW_DEFAULT.organisation]);

  return (
    <Dropdown
      trigger={
        openOrg?.IMAGE_URL ?
          <Image avatar src={openOrg?.IMAGE_URL} style={{ marginRight: '0em', }} />
          :
          <Button
            icon='users'
          />
      }
      pointing='top right'
      icon={false}
      style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
    >
      <Dropdown.Menu>
        {
          HWCtt.data.HW_ORG_listDetailed.map((org: any) => {
            return (
              <Menu.Item
                as='a'
                key={org.id}
                style={{ textAlign: 'center' }}
                onClick={() => {
                  searchCtt.setSearchData((draft: any) => {
                    draft.searchResultsQuestionsOpen = draft.searchResultsQuestionsOpen = [];
                  });
                  navigate('/app/org/' + org.id + '/questions')
                }}
              >
                <Grid>
                  <Grid.Column>
                    <img src={org.IMAGE_URL || noCompanyLogo} alt="company" style={{ borderRadius: '6px', maxHeight: '20px' }} />
                  </Grid.Column>
                  <Grid.Column>
                    {org.name}
                  </Grid.Column>
                </Grid>
              </Menu.Item>
            )
          })
        }
        {
          HWCtt.data.HW_ORG_listDetailed.length === 0 &&
          <Menu.Item
            as='a'
            style={{ textAlign: 'center' }}
            onClick={() => {
              navigate('/secure/new/organisation')
            }}
          >
            <Grid>
              <Grid.Column>
                <Icon name='plus' />Create your first Organisation
              </Grid.Column>
            </Grid>
          </Menu.Item>
        }
        {
          openOID &&
          <>
            <Dropdown.Divider />
            <Menu.Item
              as='a'
              style={{ textAlign: 'center' }}
              onClick={() => {
                searchCtt.setSearchData((draft: any) => {
                  draft.searchResultsQuestionsOpen = draft.searchResultsQuestionsOpen = [];
                });
                navigate('/')
              }}
            >
              <Grid>
                <Grid.Column>
                  Exit organisation
            </Grid.Column>
              </Grid>
            </Menu.Item>
          </>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownOrg;