import React from 'react';
import { Router } from '@reach/router'
import PersonalHome from './Personal'
import OrganisationHome from './Organisation'
// import SwitchOrganisation from './Menu/Switch'

interface SettingsProps {
  path: string;
}

function Settings(props: SettingsProps) {

  return (
    <>
      {/* <SwitchOrganisation /> */}
      <Router>
        <PersonalHome path={'personal'} />
        <OrganisationHome path={'organisation/:id'} />
      </Router>
    </>
  );
}

export default Settings;