import React, { useState, useEffect, useContext } from 'react';
import { Draft } from "immer";
import { Header, Grid, Input, Icon, Button, Table, Image, Dropdown, Label } from 'semantic-ui-react'
import DropdownColour from '../../DropdownColour';
import UserSelect from 'pages/App/components/UserSelect'
import HowWeeContext from 'services/contexts/ContextHowWee'

interface Props<S = any, A = any> {
  immerForm: Draft<S>;
  setImmerForm: A;
  oid: string;
  currentUsers: Array<any>;
}

function FormEditTeam(props: Props) {

  const HWCtt: any = useContext(HowWeeContext);
  const { setImmerForm, immerForm, currentUsers, oid } = props;

  const [teamColor, setTeamColor] = useState('green');
  const [usersDeleted, setUsersDeleted]: any = useState([]);
  const [users, setUsers] = useState([]);
  const [showUserSelect, setShowUserSelect] = useState(false);

  useEffect(() => {
    if (immerForm.color) setTeamColor(immerForm.color);
  }, [immerForm.color])

  useEffect(() => {
    let tmpCurrent: Array<any> = [];
    if (currentUsers) {
      tmpCurrent = currentUsers.filter((uid: string) => !usersDeleted.includes(uid) && !users.includes(uid));
    } else {
      tmpCurrent = [];
    }

    setImmerForm((draft: any) => {
      draft.color = teamColor;
      draft.users = [...tmpCurrent, ...users];
    });

    // console.info("===== STATUS =====");
    // console.info("current", currentUsers)
    // console.info("new", users);
    // console.info("deleted", usersDeleted)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamColor, users, usersDeleted]);

  const handleChange = (e, data) => {
    const { value, name } = data;
    setImmerForm((draft: any) => {
      draft[name] = value;
    })
  }

  return (
    <Grid columns='equal'>
      <Grid.Column width={16}>
        <Header style={{ marginBottom: '8px' }}>Team Name</Header>
        <Input autoComplete="off" name="name" defaultValue={immerForm.name} onChange={handleChange} placeholder={'A name to recognise your team'} style={{ width: '100%' }} />
      </Grid.Column>
      <Grid.Column width={16}>
        <Header style={{ marginBottom: '8px' }}>Description</Header>
        <Input autoComplete="off" name="description" defaultValue={immerForm.description} onChange={handleChange} placeholder={'A short description of what the team does'} style={{ width: '100%' }} />
      </Grid.Column>
      <Grid.Column width={16}>
        <Header style={{ marginBottom: '8px' }}>Colour</Header>
        <DropdownColour
          color={teamColor}
          setColor={setTeamColor}
        />
      </Grid.Column>
      <Grid.Column width={16}>
        <Header style={{ marginBottom: '14px' }}>Team Members</Header>
        {!showUserSelect &&
          <Button icon primary labelPosition='left' onClick={() => { setShowUserSelect(!showUserSelect) }}>
            <Icon name='user' />
            Add Member
          </Button>
        }
        {showUserSelect &&
          <>
            <Button icon='x' onClick={() => { setShowUserSelect(!showUserSelect) }} />
            <div style={{ width: 'calc(100% - 45px)', float: 'right' }} >
              <UserSelect
                users={users}
                setUsers={setUsers}
                currentUsers={currentUsers}
                usersDeleted={usersDeleted}
                usersAvailables={HWCtt.data.HW_ORG_listById[oid].users}
              />
            </div>
          </>
        }
        {
          currentUsers &&
          <>
            <Table unstackable singleLine basic='very' collapsing style={{ margin: '20px', width: '100%', marginBottom: '50px' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Current Members</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  currentUsers.map((user: any) => {
                    let userInfo = HWCtt.data.HW_ORG_listById[oid].users.filter((usr: any) => usr.id === user)[0];
                    let isDeleted = usersDeleted.includes(user);

                    return (
                      <Table.Row disabled={isDeleted}>
                        <Table.Cell>
                          <div>
                            <Image src={userInfo.photoURL} avatar />
                            <span>
                              {userInfo.displayName}</span>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {isDeleted &&
                            <Label
                              content="Removed"
                              as='a'
                            />
                          }
                        </Table.Cell>
                        <Table.Cell>
                          <Dropdown
                            icon='ellipsis vertical'
                            style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                text='Remove'
                                onClick={() => { setUsersDeleted([user, ...usersDeleted]) }}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>)
                  })
                }
              </Table.Body>
            </Table>
          </>
        }
      </Grid.Column>
    </Grid >
  );
}

export default FormEditTeam;