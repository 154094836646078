import { countries } from 'countries-list'

export const countriesSelect = () => {
  const res: Array<any> = Object.keys(countries).map((key) => {
    return {
      key: key, value: key, text: countries[key].name
    }
  });

  return res;
}