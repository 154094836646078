import React from 'react';
import GridCenter from 'pages/App/components/GridCenter'
import { Divider } from 'semantic-ui-react';
import HistoryContentUser from './Content/HistoryContentUser'
import { useUser } from 'reactfire'
import { TitleQuestion, PrefixTitleQuestion } from './styles'
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';

export function HistoryUser({ oid, isModal }: any) {

  const { uid }: any = useUser()
  const { functions: { usersById, orgData } } = useOrganisationHook(oid);

  return (
    <>
      <GridCenter fullWidth={isModal}>
        <PrefixTitleQuestion> {orgData.name || 'Organisation'}</PrefixTitleQuestion>
        <TitleQuestion> {usersById(uid).displayName} </TitleQuestion>
        <Divider />
        <HistoryContentUser uid={uid} oid={oid} />
      </GridCenter>
    </>
  );
}

