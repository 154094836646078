import React, { useRef } from 'react';
import { Image, Loader, Button } from 'semantic-ui-react'
import * as firebase from 'firebase/app'
import 'firebase/storage';
import styled from 'styled-components'

interface ProfilePictureProps {
  profilePic: string;
  setProfilePicPreview: any;
  profilePicLoading: boolean;
  setFile: any;
  refStorage: string;
  disabled?: boolean;
}

const ImageShadow = styled(Image)`
  -webkit-box-shadow: 0px 0px 50px -20px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 50px -20px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 50px -20px rgba(0,0,0,0.75);
  border-radius: 20px !important;
`

function ProfilePicture(props: ProfilePictureProps) {

  const {
    profilePic,
    setProfilePicPreview,
    profilePicLoading,
    setFile,
    disabled
  } = props;

  const orgProfilePicture = useRef();

  const handleChangeImage = (ev: any) => {

    setFile(ev.target.files[0]);
    setProfilePicPreview(URL.createObjectURL(ev.target.files[0]))
  }

  return (
    <>
      {profilePicLoading &&
        <Loader active inline />
      }
      {
        !profilePicLoading &&
        <>
          <ImageShadow
            src={profilePic || 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
            size='tiny'
            rounded
          />
          { !disabled &&
            <>
              <Button
                htmlFor="filePicker"
                circular
                icon='photo'
                style={{ position: 'absolute', marginTop: '-24px', marginLeft: '55px' }}
                onClick={() => orgProfilePicture.current.click()}
              />
              <input
                ref={orgProfilePicture}
                type="file"
                style={{ visibility: "hidden" }}
                onChange={handleChangeImage}
              />
            </>
          }
        </>
      }
    </>
  );
}

export const handleUploadImage = ({ file, refStorage }: any) => {

  const storage = firebase.storage();
  const refProfile = storage.ref(refStorage);

  return new Promise((resolve, reject) => {

    if (!file || !refStorage) resolve(true)

    refProfile.put(file).then((snapshot) => {
      if (snapshot.state === 'success') {
        snapshot.ref.getDownloadURL().then(function (downloadURL) {
          const pathImage = snapshot.metadata.fullPath;
          resolve({ pathImage, downloadURL });
        }).catch((error) => {

        });
      }
    });
  });
}

export default ProfilePicture;