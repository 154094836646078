import React from 'react';
import { Modal } from 'semantic-ui-react'
import EditQuestion from 'pages/App/Modules/Org/Questions/Forms/EditQuestion'

interface EditQuestionProps {
  setOpen: any;
  open: boolean;
  oid: string;
  questions: Array<any>; // questions in the column
  projId: string | null;
}

function EditQuestionModal(props: EditQuestionProps) {

  const { setOpen, open, oid, questions, projId } = props;

  const closeModal = () => {
    setOpen(false);
  }

  return (
    <Modal
      closeOnDimmerClick={false}
      onClose={() => {
        closeModal()
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size={'small'}
      closeIcon
    >
      <Modal.Header>New Question</Modal.Header>
      <Modal.Content>
        <EditQuestion path='' projId={projId || null} questions={questions} setOpenModal={setOpen} oid={oid} isModal />
      </Modal.Content>
    </Modal>
  );
}

export default EditQuestionModal;