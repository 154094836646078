import React from 'react';
import { Label, Popup, Image } from 'semantic-ui-react';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'

function BadgeOtherUsers({ users, maxItems, oid }: any) {

  const { functions: { usersById, photoUserByID } } = useOrganisationHook(oid);

  return (
    <div style={{ marginRight: '-10px', display: 'inline' }}>
      <Popup
        basic
        content={<>
          {
            users.map((uid: string, index: number) => {
              return (<p key={index}>
                <Image bordered src={photoUserByID(uid)} avatar />
                {usersById(uid).displayName}
              </p>);
            })
          }
        </>}
        trigger={
          <Label circular color='teal'>
            +{users.length - maxItems}
          </Label>
        }
      />
    </div>
  );
}

export default BadgeOtherUsers;