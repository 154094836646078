import { useUser, useFirestoreCollectionData } from 'reactfire'
import { useFirestoreHook } from './useFirestoreHook'
import { useGoogleAnalyticsHook } from './useGoogleAnalyticsHook';

export function useHistoryHook(oid: string) {

  const itemTypes = ['questions', 'reports', 'discussions'];
  const { uid }: any = useUser();

  const { collections: { HWFS } } = useFirestoreHook();

  const organisation = HWFS.organisations.doc(oid);
  const usersRef = organisation.collection('users');
  const historyPersonal = organisation.collection('users').doc(uid).collection('history')

  const usersDocs = useFirestoreCollectionData(usersRef, { idField: 'id' });

  const { functions: { newEventGA } } = useGoogleAnalyticsHook();
  const { functions: { addDoc } } = useFirestoreHook();

  const historyByQuestion = (questionId: string, projId: string) => {
    return organisation.collection('projects').doc(projId).collection('questions').doc(questionId).collection('history')
  }

  const historyByReport = (projId: string, reportId: string) => {
    return organisation.collection('projects').doc(projId).collection('reports').doc(reportId).collection('history')
  }

  const historyByUser = (uid: string) => {
    return organisation.collection('users').doc(uid).collection('history').orderBy("createdAt", "desc")
  }

  const historyByDiscussion = (projId: string) => {
    let res: any = [];

    return new Promise((resolve) => {
      usersDocs.map((async (user: any, index: number) => {
        if (user.id) {
          let hist: Array<any> = await historyByUser(user.id).get();

          hist.forEach((doc) => {
            if (doc.exists) {
              let data = doc.data();
              if (data.projId === projId) {
                res = [...res, data];
              }
            }
          })
        }
        if (usersDocs.length === index + 1) {
          resolve(res);
        }
      }))
    })
  }

  const historyOrg = () => {
    let res: any = [];

    return new Promise((resolve) => {
      usersDocs.map((async (user: any, index: number) => {
        if (user.id) {
          let hist: Array<any> = await historyByUser(user.id).get();

          hist.forEach((doc) => {
            if (doc.exists) {
              res = [...res, doc.data()];
            }
          })
        }
        if (usersDocs.length === index + 1) {
          resolve(res);
        }
      }))
    })
  }

  const newHistoryRecord = (projId: string, itemType: string, itemId: string, actionType: string, extra: any) => {

    if (!itemTypes.includes(itemType) && !projId) {
      console.error('NHR Error')
      return null
    }

    const payload = {
      itemType,
      itemId,
      actionType,
      extra,
      user: uid,
      projId: projId
    }

    let collect = organisation
      .collection('projects').doc(projId)
      .collection(itemType).doc(itemId)
      .collection('history');

    if (itemType === 'discussions') {
      collect = organisation
        .collection('projects').doc(projId)
        .collection('history');
    }

    addDoc(collect, payload).then((res: any) => {
      addDoc(historyPersonal, payload, { id: res.id })
    })
  }

  // =========================

  const nhr_questions_draft_to_open = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'state_change', 'question_draft_moves_to_open');
    newHistoryRecord(projId, 'questions', itemId, 'question_draft_moves_to_open', extra)
  }

  const nhr_questions_ready_for_review = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'state_change', 'question_ready_for_review');
    newHistoryRecord(projId, 'questions', itemId, 'question_ready_for_review', extra);
  }

  const nhr_questions_unarchived = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'unarchived', 'unarchived');
    newHistoryRecord(projId, 'questions', itemId, 'question_unarchived', extra)
  }

  const nhr_questions_archived_from_draft = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'archived', 'archived_from_draft');
    newHistoryRecord(projId, 'questions', itemId, 'question_archived_from_draft', extra);
  }

  const nhr_questions_archived_from_answerSelected = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'archived', 'archived_from_answer_selected');
    newHistoryRecord(projId, 'questions', itemId, 'question_archived_from_answer_selected', extra);
  }

  const nhr_questions_archived_from_readyForReview = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'archived', 'archived_from_ready_for_review');
    newHistoryRecord(projId, 'questions', itemId, 'question_archived_from_ready_for_review', extra);
  }

  const nhr_questions_new = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'new', 'new_question_draft');

    newHistoryRecord(projId, 'questions', itemId, 'question_new', extra)
  }

  const nhr_questions_open = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'new', 'new_question_open');

    newHistoryRecord(projId, 'questions', itemId, 'question_open', extra)
  }

  const nhr_questions_answerSelected = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'state_change', 'answer_selected');

    newHistoryRecord(projId, 'questions', itemId, 'question_answer_selected', extra)
  }

  const nhr_questions_first_comment = (itemType: string, itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'new', 'new_first_comment');

    newHistoryRecord(projId, itemType, itemId, 'question_first_comment', extra)
  }

  const nhr_questions_new_comment = (itemType: string, itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('questions', 'new', 'new_comment');

    newHistoryRecord(projId, itemType, itemId, 'question_new_comment', extra)
  }

  const nhr_report_new = (itemId: string, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'new', 'new_report');

    newHistoryRecord(projId, 'reports', itemId, 'report_new', {})
  }

  const nhr_report_newWithEditors = (itemId: string, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'new', 'new_report_with_editors');

    newHistoryRecord(projId, 'reports', itemId, 'report_newWithEditors', {})
  }

  const nhr_report_edit = (itemId: string, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_edit');

    newHistoryRecord(projId, 'reports', itemId, 'report_edit', {})
  }

  const nhr_report_editQuestionsOrder = (itemId: string, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_edit_order_questions');

    newHistoryRecord(projId, 'reports', itemId, 'report_editQuestionsOrder', {})
  }

  const nhr_report_editQuestions = (itemId: string, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_edit_questions');

    newHistoryRecord(projId, 'reports', itemId, 'report_editQuestions', {})
  }

  const nhr_report_view = (itemId: string, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_view');

    newHistoryRecord(projId, 'reports', itemId, 'report_view', {})
  }

  const nhr_report_delete = (itemId: string, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'delete', 'report_delete');

    newHistoryRecord(projId, 'reports', itemId, 'report_delete', {})
  }

  const nhr_report_export = (itemId: string, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'view', 'report_export');

    newHistoryRecord(projId, 'reports', itemId, 'report_export', {})
  }

  const nhr_report_add_viewer = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_add_viewer');

    newHistoryRecord(projId, 'reports', itemId, 'report_add_viewer', extra)
  }

  const nhr_report_add_editor = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_add_editor');

    newHistoryRecord(projId, 'reports', itemId, 'report_add_editor', extra)
  }

  const nhr_report_add_teams = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_add_team');

    newHistoryRecord(projId, 'reports', itemId, 'report_add_teams', extra)
  }

  const nhr_report_remove_viewer = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_remove_viewer');

    newHistoryRecord(projId, 'reports', itemId, 'report_remove_viewer', extra)
  }

  const nhr_report_remove_editor = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_remove_editor');

    newHistoryRecord(projId, 'reports', itemId, 'report_remove_editor', extra)
  }

  const nhr_report_remove_teams = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('reports', 'edit', 'report_remove_team');

    newHistoryRecord(projId, 'reports', itemId, 'report_remove_teams', extra)
  }

  const nhr_discussion_archive = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('discussions', 'edit', 'archive');
    newHistoryRecord(projId, 'discussions', itemId, 'discussion_archive', extra)
  }

  const nhr_discussion_restored = (itemId: string, extra: any = {}, projId: string) => {
    /* G Analytics */
    newEventGA('discussions', 'edit', 'restore');
    newHistoryRecord(projId, 'discussions', itemId, 'discussion_restored', extra)
  }

  return {
    collections: {
      historyPersonal,
      historyByQuestion,
      historyByUser,
      historyByReport,
      historyByDiscussion,
      historyOrg
    },
    functions: {
      newHistoryRecord,

      nhr_discussion_archive,
      nhr_discussion_restored,

      nhr_report_new,
      nhr_report_newWithEditors,
      nhr_report_edit,
      nhr_report_editQuestionsOrder,
      nhr_report_editQuestions,
      nhr_report_view,
      nhr_report_delete,
      nhr_report_export,
      nhr_report_add_viewer,
      nhr_report_add_editor,
      nhr_report_add_teams,
      nhr_report_remove_viewer,
      nhr_report_remove_editor,
      nhr_report_remove_teams,

      nhr_questions_new,
      nhr_questions_open,
      nhr_questions_answerSelected,
      nhr_questions_first_comment,
      nhr_questions_new_comment,
      nhr_questions_archived_from_draft,
      nhr_questions_archived_from_answerSelected,
      nhr_questions_archived_from_readyForReview,
      nhr_questions_draft_to_open,
      nhr_questions_ready_for_review,
      nhr_questions_unarchived,
    }
  }
}