import { useContext, useState } from 'react'
import { useFirestoreCollectionData, useFirestoreDoc, useUser } from 'reactfire'
import noCompanyLogo from 'assets/nocompany.jpg'
import HowWeeContext from 'services/contexts/ContextHowWee'
import { useFirestoreHook } from './useFirestoreHook';

export function useOrganisationHook(oid: string) {

  const { collections: { HWFS } } = useFirestoreHook();
  const HWCtt: any = useContext(HowWeeContext);
  const { uid }: any = useUser();

  const baseOrg = HWFS.organisations.doc(oid);
  const baseOrgDoc = useFirestoreDoc(baseOrg, { idField: 'id' });

  const teamsRef = HWFS.teams(oid);
  const teamsDocs = useFirestoreCollectionData(teamsRef, { idField: 'id' });

  const usersRef = HWFS.users(oid);
  const usersDocs = useFirestoreCollectionData(usersRef, { idField: 'id' });

  const subjectsRef = HWFS.subjects(oid);
  const subjectsDocs = useFirestoreCollectionData(subjectsRef, { idField: 'id' });

  const [orgData] = useState(baseOrgDoc.data())

  const myTeams = () => {
    const teamsID: Array<string> = [];
    teamsDocs.map((team: any) => {
      if (team.users.includes(uid)) {
        teamsID.push(team.id)
      }
      return null;
    })
    return teamsID;
  }

  const teamById = (teamId: string) => {
    let response: any;
    teamsDocs.map((team: any) => {
      if (team.id === teamId) {
        response = team;
      }
      return true;
    });

    return response;
  }

  const isPartOfSomeTeam = (teamsArray: Array<any>, uid: string) => {
    let result = false;
    if (teamsArray.length > 0) {
      teamsArray.map((teamId) => {
        const team = teamById(teamId);
        if (team && team.users && team.users.includes(uid)) {
          result = true;
        }
        return null
      })
    }

    return result;
  }

  const photoUserByID = (uid: string) => {
    const res = usersById(uid);
    if (res.photoURL) {
      return res.photoURL
    } else {
      return 'http://www.gravatar.com/avatar/?d=identicon'
    }
  }

  const photoOrgOpen = () => {
    let result = null;
    HWCtt.data.HW_ORG_listDetailed.map((org: any) => {
      if (org.id === oid) {
        result = org.IMAGE_URL || noCompanyLogo
      }

      return null
    })
    return result;
  }

  const usersById = (uid: string) => {
    let response: any;
    usersDocs.map((usr: any) => {
      if (usr.id === uid) {
        response = usr;
      }
      return true;
    });

    if (response) return response;

    return {
      displayName: '???',
      id: null,
      photoUrl: 'http://www.gravatar.com/avatar/?d=identicon'
    }
  }

  const userDisplayNameById = (uid: string) => {
    let res = usersById(uid);
    if (res?.displayName) return res.displayName;
    return 'Error User';
  }

  const myUser = () => {
    return usersById(uid);
  }

  const subjectById = (subjectId: string) => {
    let res: string = '';
    subjectsDocs.map((subject: any) => {
      if (subjectId === subject.id) {
        res = subject.text || subject.name;
      }
      return null;
    })

    return res;
  }

  const getMyRole = () => {
    if (HWCtt.data?.HW_ORG_myAccessGuest?.includes(oid)) return 'guest'
    if (HWCtt.data?.HW_ORG_myAccessMember?.includes(oid)) return 'member'
    if (HWCtt.data?.HW_ORG_myAccessAdmin?.includes(oid)) return 'admin'
    return null;
  }

  const setOrganisationRepresent = (orgData: any) => {
    if (orgData && orgData.id && orgData.name && !myUser().representsOrg) {
      let payload: any = { ...orgData, status: 0 }
      return HWFS.users(oid).doc(uid).update({ representsOrg: payload })
    }
  }

  const userRepresentOrg = (userId: string) => {
    const user = usersById(userId);
    if (user?.representsOrg?.name) return user?.representsOrg?.name
    return ''
  }

  return {
    data: {
      teamsDocs,
      usersDocs,
      orgData,
      subjectsDocs,
    },
    functions: {
      orgData,
      myTeams,
      myUser,
      getMyRole,
      setOrganisationRepresent,
      teamById,
      isPartOfSomeTeam,
      usersById,
      userDisplayNameById,
      userRepresentOrg,
      photoUserByID,
      photoOrgOpen,
      subjectById
    }
  }
}