import React from 'react';
import { Menu, Dropdown, } from "semantic-ui-react"
import { MenuItemHW } from '../styles'
import { navigate, } from '@reach/router'

function MenuHWMin({ oid }: any) {

  const MenuContent = ({ mobile = false }) =>
    <>
      <MenuItemHW
        position='left'
      >
        <Dropdown
          floating={!mobile}
          style={{ color: '#38B1F9', fontWeight: '500' }}
          text={mobile ? 'Organisation' : 'Organisation'}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => navigate('/secure/new/organisation')}
            >
              New Organisation
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </MenuItemHW>
    </>

  return (
    <>
      <Menu className="computer widescreen large screen only" style={{ backgroundColor: "#F8F9FA", border: 'none', boxShadow: 'none' }}>
        <MenuContent />
      </Menu>

      <Menu.Item className="mobile tablet only">
        <Dropdown
          pointing='top left'
          icon={'th'}
          button
          style={{ paddingRight: '1em' }}
        >
          <Dropdown.Menu style={{ minWidth: '135px' }}>
            <MenuContent mobile />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  );
}

export default MenuHWMin;