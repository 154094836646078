import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Dimmer, Loader } from 'semantic-ui-react'
import FormEditTeam from './Form'
import { SuccessModal } from 'pages/App/components/Success'
import { useImmer } from 'use-immer';
import HowWeeContext from 'services/contexts/ContextHowWee'

interface EditTeamProps {
  setOpen: any;
  open: boolean;
  orgId: string;
  setData: any;
  data: any;
}

const initial = {
  name: '',
  description: '',
  color: '',
  users: []
}

function EditTeam(props: EditTeamProps) {

  const { setOpen, open, orgId, data, setData } = props;
  const [immerForm, setImmerForm] = useImmer(initial);
  const [title, setTitle] = useState('');

  const HWCtt: any = useContext(HowWeeContext);

  useEffect(() => {
    if (data) {
      if (data.name) setTitle(': ' + data.name);
      setImmerForm((draft: any) => draft = data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  const [disabledForm, setDisabledForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setData({});
    resetComponent();
  }

  const resetComponent = () => {
    setDisabledForm(true)
    setLoading(false)
    setSuccess(false)
  }

  useEffect(() => {
    if (immerForm.name === '') {
      setDisabledForm(true)
    } else {
      setDisabledForm(false)
    }
  }, [immerForm.name]);

  const saveChanges = () => {
    setLoading(true)
    if (data.id) {
      HWCtt.data.HW_FUNCTIONS.update.team(orgId, data.id, { ...immerForm }).then(() => {
        resetComponent();
        setSuccess(true);
      })
    } else {
      HWCtt.data.HW_FUNCTIONS.create.team(orgId, { ...immerForm }).then(() => {
        resetComponent();
        setSuccess(true);
      })
    }
  }

  return (
    <Modal
      closeOnDimmerClick={false}
      onClose={() => {
        closeModal()
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size={'tiny'}
    >
      <Modal.Header>Team {title}</Modal.Header>
      <Modal.Content>
        <Dimmer active={loading} inverted>
          <Loader size='medium'>Saving Team</Loader>
        </Dimmer>
        <Modal.Description>
          {success &&
            <SuccessModal
              description={data.id ? "Team edited successfully" : "Team created successfully"}
            />
          }

          {!success &&
            <FormEditTeam
              oid={orgId}
              immerForm={immerForm}
              setImmerForm={setImmerForm}
              currentUsers={data.users}
            />
          }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => closeModal()}>
          {success ? 'Close' : 'Cancel'}
        </Button>
        {!success &&
          <Button
            disabled={disabledForm}
            content={data.id ? 'Update' : 'Save Changes'}
            onClick={saveChanges}
            primary
          />
        }
      </Modal.Actions>
    </Modal>
  );
}

export default EditTeam;