import styled from 'styled-components'

export const TextUnderline = styled.span`
  text-decoration: underline;
  color: grey;
`
export const TextGrey = styled.span`
  color: grey;
`

export const LinkTextUnderline = styled.span`
  cursor: pointer;
`