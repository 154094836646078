import React from 'react';
import { Message } from 'semantic-ui-react'
import GridCenter from 'pages/App/components/GridCenter';
import { SuspenseWithPerf, useFirestoreDocData, useFirestoreCollectionData } from 'reactfire'
import { Loading } from 'pages/App/components/Loading';
import QuestionData from './QuestionData';
import QuestionComments from './QuestionComments';
import QuestionNewCommentEditorjs from './QuestionNewCommentEditorjs';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';
import Header from './Header'
import { useProjectHook } from 'services/hooks/useProjectHook';

function QuestionInfoComponent({ questionId, projId, fullWidth = false, oid }: any) {

  const { collections: { HWFS } } = useFirestoreHook();
  const questionRef = HWFS.questions(oid, projId).doc(questionId);
  const commentsRef = HWFS.questionComments(oid, projId, questionId).orderBy('createdAt', 'asc');

  const question: any = useFirestoreDocData(questionRef, { idField: 'id' });
  const comments: any = useFirestoreCollectionData(commentsRef, { idField: 'id' });

  const { functions: { userHaveSomeAccessInProject } } = useProjectHook(oid);

  return (
    <>
      <Header oid={oid} projId={projId} question={question} />
      <GridCenter fullWidth={fullWidth} style={{ marginTop: '2em' }}>
        {userHaveSomeAccessInProject(projId) ?
          <>
            <QuestionData question={question} oid={oid} fullWidth={fullWidth} />
            <QuestionComments comments={comments} question={question} oid={oid} />
            {question.status !== 410 &&
              <QuestionNewCommentEditorjs comments={comments} question={question} oid={oid} />
            }
          </>
          :
          <Message style={{ marginTop: '2em' }}>
            <Message.Header>
              Sorry, yo do not have permission to the details of this Question
            </Message.Header>
            <Message.Content>
              Here information that can help you:
            </Message.Content>
            <Message.List>
              <Message.Item>Contact the owner of this Question</Message.Item>
              <Message.Item><p style={{ cursor: 'pointer' }}>Report a issue</p></Message.Item>
            </Message.List>
          </Message>
        }
      </GridCenter>
    </>
  );
}

function QuestionInfo(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Loading />}
      traceId={`howwee-question-${props.questionId || ''}`}
    >
      <QuestionInfoComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default QuestionInfo;