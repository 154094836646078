import React, { useState, useEffect } from 'react';
import { Button, Modal, Dimmer, Loader } from 'semantic-ui-react'
import FormInvitePeople from './Form'
import { SuccessModal } from 'pages/App/components/Success'
import useFunctionsHook from 'services/hooks/useFunctionsHook'

interface InvitePeopleProps {
  setOpen: any;
  open: boolean;
  orgId: string;
}

function InvitePeople(props: InvitePeopleProps) {

  const { setOpen, open, orgId } = props;

  const [emailInvite, setEmailInvite] = useState([]);
  const [disabledForm, setDisabledForm] = useState(true);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [typeUser, setTypeUser] = useState('member');

  const { functions: { runCloudFunction } } = useFunctionsHook('COREHW-createInvitation');

  const closeModal = () => {
    setOpen(false);
    resetComponent();
  }

  const resetComponent = () => {
    setEmailInvite([])
    setDisabledForm(true)
    setLoading(false)
    setSuccess(false)
    setTypeUser('member')
  }

  useEffect(() => {
    if (emailInvite.length < 1) {
      setDisabledForm(true)
    } else {
      setDisabledForm(false)
    }
  }, [emailInvite]);

  const handleSendInvites = () => {

    setLoading(true);
    runCloudFunction({
      invitedTeam: [],
      invitedRole: typeUser,
      invitedEmails: emailInvite,
      oid: orgId,
    }).then((res: any) => {
      if (res && res.data.success && res.data.success === true) {
        setLoading(false);
        setSuccess(true);
      } else {
        setLoading(false);
        alert("We have issues with this request");
      }
    }).catch((err: any) => {
      setLoading(false);
      console.warning(err);
    })
  }

  return (
    <Modal
      closeOnDimmerClick={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size={'tiny'}
    >
      <Modal.Header>Invite people to your organisation</Modal.Header>
      <Modal.Content>
        <Dimmer active={loading} inverted>
          <Loader size='medium'>
            Sending invitations <br /> <br />
            This may take a few seconds
          </Loader>
        </Dimmer>
        <Modal.Description>
          {success &&
            <SuccessModal
              description="The invitations were sent correctly"
            />
          }

          {!success &&
            <FormInvitePeople
              emailInvite={emailInvite}
              setEmailInvite={setEmailInvite}
              typeUser={typeUser}
              setTypeUser={setTypeUser}
            />
          }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => closeModal()}>
          {success ? 'Close' : 'Cancel'}
        </Button>
        {!success &&
          <Button
            disabled={disabledForm}
            content="Send Invites"
            labelPosition='right'
            icon='mail'
            onClick={() => handleSendInvites()}
            positive
          />
        }
      </Modal.Actions>
    </Modal>
  );
}

export default InvitePeople;