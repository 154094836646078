import React from 'react'
import { Dropdown, Button, Grid, Divider } from 'semantic-ui-react'
import {
  NotifxxInvitedOrg,
  NotifxxInvitedDiscussion,
  NotifxxNewCommentQuestionAsked,
  NotifxxNewQuestionInProject
} from './Items'

function NotificationsHWContent({ oid, notifs, notifsGeneral, clearAllNotifications, modalMode = false }: any) {

  const NotificationSwitch = (props: any) => {
    if (props.type) {
      switch (props.type) {
        case 'newQuestionInProject':
          return (<NotifxxNewQuestionInProject oid={oid}  {...props} />)
        case 'newCommentQuestionAsked':
          return (<NotifxxNewCommentQuestionAsked oid={oid} {...props} />)
        case 'invitedDiscussion':
          return (<NotifxxInvitedDiscussion oid={oid} {...props} />)
        case 'invitedOrganisation':
          return (<NotifxxInvitedOrg oid={oid} {...props} />)
        default:
          return (<></>)
      }
    } else {
      return (<></>)
    }
  }

  const HeaderNotification = () => {
    return (
      <Dropdown.Item>
        <Grid columns={2}>
          <Grid.Column>
            <p style={{ marginTop: '0.2em', fontSize: '15px', fontWeight: 500 }}>
              Notifications
              {(
                (notifs.length && notifs.length > 0) ||
                (notifsGeneral.length.length && notifsGeneral.length.length > 0)
              ) &&
                ` (${notifs.length + notifsGeneral.length})`}
            </p>
          </Grid.Column>
          <Grid.Column>
            <Button
              color='teal'
              size='mini'
              style={{ float: 'right' }}
              onClick={clearAllNotifications}
              disabled={notifs.length < 1}
            >
              Clear All
            </Button>
          </Grid.Column>
        </Grid>
      </Dropdown.Item>
    )
  }

  const titleStlyeBox = () => {
    const base = { width: '100%', paddingLeft: '1em', marginTop: '0.5em' }
    if (modalMode) base.paddingLeft = '0em';
    return base;
  }
  const titleStyle = { color: '#6C757D', fontSize: '14px', margin: '0px', fontWeight: 500 }


  const NotifsGeneral = () => {
    return (
      <>
        {
          notifsGeneral.length > 0 &&
          <>
            <div style={titleStlyeBox()}>
              <p style={titleStyle}>
                Global
              </p>
            </div>
            {
              modalMode &&
              <Divider />
            }
          </>
        }
        {
          notifsGeneral.map((notif: any, index: number) => {
            return (
              <NotificationSwitch key={index} isGeneral={true} {...notif} />
            )
          })
        }
      </>
    )
  }

  const NotifsOrganisation = () => {
    return (
      <>
        {
          notifsGeneral.length > 0 &&
          notifs.length > 0 &&
          <>
            <Dropdown.Divider />
            <div style={titleStlyeBox()}>
              <p style={titleStyle}>
                Organisation
            </p>
            </div>
            {
              modalMode &&
              <Divider />
            }
          </>
        }
        {
          notifs.map((notif: any, index: number) => {
            return (
              <NotificationSwitch key={index} {...notif} />
            )
          })
        }
      </>
    )
  }

  const CaughtUp = () => {
    return (
      <>
        {
          (
            (notifs.length === 0 || !notifs) &&
            (notifsGeneral.length === 0 || !notifsGeneral)
          )
          &&
          <Dropdown.Item>
            <p> You are all caught up! </p>
          </Dropdown.Item>
        }
      </>
    )
  }

  return (
    <>
      <HeaderNotification />
      <NotifsGeneral />
      <NotifsOrganisation />
      <CaughtUp />
    </>
  );
}


export default NotificationsHWContent;