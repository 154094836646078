import React from 'react'
import { Grid } from 'semantic-ui-react'

function GridCenter({ children, fullWidth, bigger, marginTop = '0em' }: any) {

  const getSizeComputer = (space: string) => {
    if (space === 'laterals') {
      if (bigger) {
        return 1
      } else {
        return 3
      }
    } else {
      if (fullWidth) {
        return 16
      } else {
        if (bigger) {
          return 14
        } else {
          return 10
        }
      }
    }
  }

  return (
    <div style={{ marginTop: marginTop }}>
      <Grid container columns='equal'>
        {!fullWidth &&
          <Grid.Column mobile={16} tablet={1} computer={getSizeComputer('laterals')}></Grid.Column>
        }
        <Grid.Column mobile={16} tablet={fullWidth ? 16 : 14} computer={getSizeComputer('center')}>
          {children}
        </Grid.Column>
        {!fullWidth &&
          <Grid.Column mobile={16} tablet={1} computer={getSizeComputer('laterals')}></Grid.Column>
        }
      </Grid>
    </div>
  );
}

export default GridCenter;