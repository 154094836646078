import React from 'react';
import { Grid, Breadcrumb } from 'semantic-ui-react';
import { navigate } from '@reach/router';
import { onlyUniqueArray } from 'helpers/mini'
import { BadgeMultipleUser } from 'pages/App/components/Badges'

function Header({ oid, project }: any) {

  const InsightsHeader = () => <Grid columns={1}>
    <Grid.Column>
      <div style={{ paddingTop: '0.3em' }}>
        <Breadcrumb size='small' style={{ marginLeft: '1em' }}>
          <Breadcrumb.Section link onClick={() => navigate(`/app/org/${oid}/discussions`)}>
            Discussions
      </Breadcrumb.Section>
          <Breadcrumb.Divider />
          {project &&
            <>
              <Breadcrumb.Section link onClick={() => navigate(`/app/org/${oid}/discussions/${projId}`)}>
                {project?.name || ''}
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
            </>
          }
          <Breadcrumb.Section style={{ color: 'grey' }}>
            Insights
        </Breadcrumb.Section>
        </Breadcrumb>
      </div>
    </Grid.Column>
    <Grid.Column style={{ paddingTop: '0px' }} width={8}>
      <div style={{ paddingLeft: '28px' }}>
        <p style={{ fontSize: '20px', display: 'block', fontWeight: 500, marginBottom: '0.4em' }}>
          {project?.name || ''}
          {!project && 'Global Insights'}
        </p>
        <span style={{ color: '#6F7A80' }}>
          {project?.description || ''}
          {!project && 'Statistics calculated based on all open discussions with access'}
        </span>
      </div>
    </Grid.Column>
    <Grid.Column style={{ paddingTop: '0px' }} width={16}>
      <div style={{ paddingLeft: '28px' }}>
        {project &&
          <b style={{ marginBottom: '0.5em', display: 'block' }}>Participants</b>
        }
        {project &&
          <BadgeMultipleUser
            maxItems={9}
            oid={oid}
            users={
              onlyUniqueArray(
                [
                  ...project?.editors || null,
                  ...project?.users || null,
                  project.createdBy || null
                ])
            }
          />
        }
      </div>
    </Grid.Column>
  </Grid>

  return (<InsightsHeader />)
}

export default Header;