import React, { useEffect } from 'react';
import SideMenu from './Layout/SideMenu'
import Container from './Layout/Container'
import { Router } from "@reach/router"
import { DummyRow } from 'pages/App/components/DummyRow';
import Org from './Modules/Org';
import { useLocation, navigate } from '@reach/router';
import { localSt, LOCAL_ST } from 'helpers/local-session-storage'
import WelcomeMainBox from './Modules/Welcome/MainBox';

interface PrimaryProps {
  path: string,
  default: boolean,
}

function Primary({ path }: PrimaryProps) {

  const location = useLocation();

  useEffect(() => {
    const routeParts = location.pathname.split('/')
    if (routeParts[0] === '' && routeParts[1] === 'app' && routeParts[2] === 'org') {
      const oidDefault = localSt.obtainNow(LOCAL_ST.defaultOrganisation);
      if (!routeParts[3] && oidDefault) {
        navigate("/app/org/" + oidDefault);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SideMenu>
      <Container>
        <Router style={{ height: '100%' }}>
          <WelcomeMainBox path={'/'} />
          <Org path={'/app/org/:oid/*'} oid='' />
          <DummyRow default />
        </Router>
      </Container>
    </SideMenu>
  );
}

export default Primary;