import React from 'react';
import BadgeOtherUsers from './BadgeOtherUsers';
import BadgeUser from './BadgeUser'

interface Props {
  users: Array<string>;
  oid: string;
  maxItems?: number;
}

function BadgeMultipleUser({ users, oid, maxItems = 3 }: Props) {

  return (
    <>
      {
        users.map((uid: string, index: number) => {
          if (index < maxItems) {
            return (
              <BadgeUser key={index} uid={uid} oid={oid} />
            )
          }
          return null
        })
      }
      {
        users.length > maxItems &&
        <BadgeOtherUsers oid={oid} users={users} maxItems={maxItems} />
      }
    </>
  )
}

export default BadgeMultipleUser;