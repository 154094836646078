import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react'
import { SuspenseWithPerf } from 'reactfire'
import Access from './Access'
import Invitations from './Invitations'

function OrganisationElement({ path }: any) {

  const [showInvites, setShowInvites] = useState(false);

  return (
    <Grid columns='equal'>
      <Grid.Column width={16}>
        <Access
          setShowInvites={setShowInvites}
          showInvites={showInvites}
        />
        <Invitations
          showInvites={showInvites}
        />
      </Grid.Column>
    </Grid>
  );
}

function Organisations(props: any) {

  return (
    <SuspenseWithPerf fallback={<p>Cargando datos...</p>} traceId='secure_settings_personal_organisation'>
      <OrganisationElement {...props} />
    </SuspenseWithPerf>
  )

}

export default Organisations;