import { navigate } from '@reach/router';
import { BadgeUser } from 'pages/App/components/Badges';
import React, { useContext, useEffect, useState } from 'react';
import { useUser } from 'reactfire'
import { Button, Dimmer, Divider, Dropdown, Grid, Label, Loader, Modal, Segment, Card, Icon } from 'semantic-ui-react';
import { LogoOrganisation } from './styles'
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'
import noCompanyLogo from 'assets/nocompany.jpg'
import HowWeeContext from 'services/contexts/ContextHowWee';
import { toast } from 'react-toastify';


function ProfileInOrg({ oid }: any) {

  const HWCtt: any = useContext(HowWeeContext);
  const { uid, ...userData }: any = useUser();
  const disableMsg = 'Contact support@how-wee.com to change';
  const disableGuest = 'This feature is only available for guest users'

  const {
    data: { orgData },
    functions: { photoOrgOpen, photoUserByID, setOrganisationRepresent, getMyRole, myUser }
  } = useOrganisationHook(oid);

  const [modalConfirm, setModalConfirm]: any = useState(false);
  const [loading, setLoading]: any = useState(false);
  const [disabled, setDisabled]: any = useState(false);
  const [disabledMessage, setDisabledMessage]: any = useState(disableMsg)
  const [orgOptions, setOrgOptions]: any = useState([]); // [{org}, {org}]
  const [orgSelected, setOrgSelected]: any = useState(myUser()?.representsOrg?.id); //oid

  useEffect(() => {
    const tmp: any = [{
      key: 'independent',
      text: 'Independent',
      value: 'independent',
      image: { avatar: true, src: photoUserByID(uid) },
    }];

    HWCtt.data.HW_ORG_listDetailed.map((org: any) => {
      if (org.id !== oid) {
        if (
          HWCtt.data.HW_ORG_myAccessAdmin.includes(org.id) ||
          HWCtt.data.HW_ORG_myAccessMember.includes(org.id)) {
          tmp.push({
            key: org.id,
            text: org.name,
            value: org.id,
            image: { avatar: true, src: org.IMAGE_URL || noCompanyLogo },
          })
        }
      }

      return false;
    })

    setOrgOptions(tmp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HWCtt.data.HW_ORG_listDetailed]);

  useEffect(() => {
    if (getMyRole() === 'guest') {
      if (myUser().representsOrg) {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
      setDisabledMessage(disableGuest)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, { value }: any) => {
    setOrgSelected(value);
  }

  const orgFullData = (orgId: string, all: boolean = false) => {

    if (orgId === 'independent') {
      if (all) {
        return { name: 'Independent', id: 'independent' }
      } else {
        return 'Independent'
      }
    }

    let name: string = ''
    let orgAll: any = {}

    HWCtt.data.HW_ORG_listDetailed.map((org: any) => {
      if (org.id === orgId) {
        name = org.name;
        orgAll = org;
      }
      return null;
    })

    if (all) return orgAll;
    return name;
  }

  const ExampleBadge = () => (
    <>
      <BadgeUser uid={uid} oid={oid} />
      <span style={{ marginLeft: '1em' }}>{userData.displayName}</span>
      <p style={{ marginLeft: '3em', color: 'grey', marginTop: '-0.5em', fontSize: '12px' }}>
        {orgSelected === 'independent' ?
          'Independent' :
          orgFullData(orgSelected)}
      </p>
    </>
  );

  const defineOrg = () => {
    setLoading(true);
    setModalConfirm(false)

    setOrganisationRepresent(orgFullData(orgSelected, true)).then((res: any) => {
      setLoading(false)
      setDisabled(true);
      setDisabledMessage(disableMsg);

      toast.success('Organisation defined correctly')
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
    })
  }

  return (
    <>

      <Dimmer active={loading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>

      {disabled && orgSelected &&
        <Grid centered columns={2}>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Icon name='check circle outline' style={{ float: 'right' }} />
                <ExampleBadge />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      }

      <Dimmer.Dimmable as={Segment} dimmed={disabled}>
        <Grid columns={1}>
          <Grid.Column>
            <LogoOrganisation
              src={photoOrgOpen() || noCompanyLogo}
              alt={`${orgData?.name} organisation logo`}
            />
            <p>
              You are being invited to join <b>{orgData?.name}</b> as a guest. Please select the organisation you will represent as a guest within {orgData?.name}.
            </p>
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              selection
              fluid
              placeholder='Organisation name'
              onChange={handleChange}
              options={orgOptions}
              style={{ width: '100%' }}
            >
            </Dropdown>
            <p style={{ fontSize: '12px', marginTop: '1em' }}>
              Select an organisation that you are a member or admin of which you will represent
              when participating in discussions in this new organisation
              </p>
            <Label color='brown' style={{ float: 'right', marginBottom: '0px' }}>
              This action cannot be reversed
              </Label>
          </Grid.Column>
          <Grid.Column>
            <Divider style={{ marginTop: '0px' }} />
          </Grid.Column>
        </Grid>
        <Grid columns={2} style={{ marginTop: '-15px' }}>
          <Grid.Column>
            <ExampleBadge />
          </Grid.Column>
          <Grid.Column>
            <Button primary onClick={() => setModalConfirm(true)} style={{ float: 'right' }}>
              Save Changes
                </Button>
            <Button onClick={() => navigate('/')} style={{ float: 'right' }}>
              Cancel
              </Button>
          </Grid.Column>
        </Grid>

        <Dimmer active={disabled}>
          <p>{disabledMessage}</p>
        </Dimmer>
      </Dimmer.Dimmable>

      <Modal
        closeOnDimmerClick={false}
        size='mini'
        open={modalConfirm}
        onClose={() => setModalConfirm(false)}
      >
        <Modal.Header>Confirm</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want represent <b>{orgFullData(orgSelected)}</b> ?</p>
          <p>This is what it will look like when you participate in the <b>{orgData?.name}</b> organisation::</p>
          <ExampleBadge />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalConfirm(false)}>
            No
          </Button>
          <Button positive onClick={defineOrg}>
            Yes, confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default ProfileInOrg;