import React, { useContext, useEffect, useState } from 'react'
import { useUser } from 'reactfire'
import firebase from '@firebase/app'
import 'firebase/auth'
import { navigate } from '@reach/router';
import { Dropdown, Image, Grid } from "semantic-ui-react"
import { IconAlert } from './styles'
import { useModalHook } from 'services/hooks/useModalHook'
import HowWeeContext from 'services/contexts/ContextHowWee'
import { ModalContextInfo } from 'pages/App/components/Modals/ContextInfo'
import { useAccessHook } from 'services/hooks/useAccessHook'

function DropdownUser() {

  const HWCtt: any = useContext(HowWeeContext);
  const user: any = useUser();

  const { functions: { haveOpenInvitations } } = useAccessHook()
  const { functions: { openModal } } = useModalHook()

  const [openInvit, setOpenInvit] = useState(false);

  const handleSignout = () => {
    firebase.auth().signOut().then(function () {
      // Sign-out successful.
    }).catch((err: any) => {
      // An error happened.
    });
  }

  useEffect(() => {
    haveOpenInvitations().then((res: any) => {
      if (res && res.length > 0) setOpenInvit(true)
    }).catch((err: any) => {
      console.error(err)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown
      trigger={
        <Image
          avatar
          src={user.photoURL}
          style={{
            marginRight: '0em',
            borderColor: 'red',
            borderWidth: openInvit ? '3px' : '0px',
            borderStyle: 'solid',
          }}
        />
      }
      pointing='top right'
      icon={false}
      style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
    >
      <Dropdown.Menu>
        <Dropdown.Item style={{ marginBottom: '-8px' }} disabled>
          <p style={{ marginTop: '0px', color: 'grey', fontSize: '14px', textAlign: 'center' }}>
            Account
          </p>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column style={{ minWidth: '55px' }}>
                <Image avatar src={user.photoURL} style={{ marginTop: '20%' }} />
              </Grid.Column>
              <Grid.Column>
                <p style={{ marginBottom: '0px' }}>{user.displayName}</p>
                <p style={{ marginTop: '0px', color: 'grey', fontSize: '12px' }}>{user.email}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => navigate('/secure/settings/personal?tab=profile')}
          text='Global Profile'
        />
        <Dropdown.Item
          onClick={() => navigate('/secure/settings/personal?tab=profileInOrg')}
        >
          {
            HWCtt?.data?.HW_DEFAULT?.organisation?.name ?
              `Profile in ${HWCtt.data.HW_DEFAULT.organisation.name}` :
              'Profile in Organisation'
          }
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => navigate('/secure/settings/personal?tab=organisation')}>
          Organisation Membership
          {openInvit && <IconAlert>●</IconAlert>}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => navigate('/secure/settings/personal?tab=settings')}
          text='Settings' />
        <Dropdown.Divider />
        {
          window.location.hostname === 'localhost' &&
          <ModalContextInfo>
            <Dropdown.Item text='Dev Mode' />
          </ModalContextInfo>
        }
        <Dropdown.Item
          onClick={() => openModal('historyUser', {})}
          text='My activity' />
        <Dropdown.Divider />
        <Dropdown.Item text='Log Out' onClick={handleSignout} />
      </Dropdown.Menu>
    </Dropdown >
  );
}

export default DropdownUser;