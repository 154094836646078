import React from 'react';
import { Message, Image, Grid } from 'semantic-ui-react';
import IllustZeroResults from 'assets/illustrations/watermelon-pack-illustration-19.svg'
import GridCenter from 'pages/App/components/GridCenter';

function index({ title, description, reportIssue = true }: any) {
  return (
    <GridCenter>
      <Grid columns={1} centered style={{ marginTop: '2em' }}>
        <Grid.Column>
          <Image src={IllustZeroResults} style={{ margin: '0 auto' }} size='small' />
        </Grid.Column>
        <Grid.Column>
          <Message style={{ marginTop: '2em', margin: '1em' }}>
            <Message.Header>
              {title ?
                title :
                'Ops! Page not found'
              }
            </Message.Header>
            <Message.Content>
              <br />
              {
                description ?
                  description :
                  <>
                    The page you are looking for cannot be found. <br />
                    If you think it is a mistake, please let us know
                </>
              }
            </Message.Content>
            <Message.List>
              {
                reportIssue ?
                  <Message.Item><p style={{ cursor: 'pointer' }}>Report a issue</p></Message.Item>
                  :
                  <></>
              }
            </Message.List>
          </Message>
        </Grid.Column>
      </Grid>
    </GridCenter>
  );
}

export default index;