/* eslint-disable @typescript-eslint/no-unused-vars */
import noCompanyLogo from 'assets/nocompany.jpg'
import { useHelperHook } from './useHelperHook';
import { useFirestoreHook } from './useFirestoreHook';

export function useOrganisationsHook() {

  const { collections: { HWFS }, data: { SDK } } = useFirestoreHook();

  const organisationRef = HWFS.organisations;
  const organisation_publicRef = HWFS.organisations_public;
  const { functions: { orgLogoByPathId } } = useHelperHook('')


  const organisationBase = (oid: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await organisationRef.doc(oid).get();
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  const organisationBasePublic = (oid: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await organisation_publicRef.doc(oid).get();
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err)
      }
    });
  }

  const sortOrganisations = (a: any, b: any) => {
    if (!a && !b) return null;

    const nameA = a?.name?.toUpperCase();
    const nameB = b?.name?.toUpperCase();
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  const organisationByIds = async (orgsId: Array<string>) => {
    return new Promise((resolve, reject) => {
      const list: Array<any> = [];
      orgsId.map((orgId: string, index: number) => {
        organisation_publicRef.doc(orgId).get().then((doc: any) => {
          const docData = doc.data();

          list.push({ id: doc.id, ...docData });
          if (orgsId.length - 1 === index) {
            resolve(
              list.sort(sortOrganisations)
            )
          }
        }).catch((err: any) => {
          reject(err);
        })

        return null;
      })
    })
  }

  // Use this function only from Context
  const addImageByIds = (organisations: Array<string>, noCache?: boolean) => {
    return new Promise((resolve, reject) => {
      let result: Array<any> = [];

      try {
        organisations.map(async (org: any) => {
          let ORG = { ...org };
          await orgLogoByPathId(ORG.imageProfile, ORG.id, noCache).then((url: any) => {
            if (url === 'org_without_profile') url = noCompanyLogo
            ORG.IMAGE_URL = url || noCompanyLogo;
            result.push(ORG);
          }).catch((err: any) => {
            console.error(err);
          })

          if (organisations.length === result.length) {
            resolve(result.sort(sortOrganisations));
          }
        });
      } catch (error: any) {
        reject(error)
      }

    })
  }

  return {
    ref: {
      organisationRef,
      organisation_publicRef
    },
    data: {
      organisationBase,
      organisationBasePublic,
    },
    functions: {
      organisationByIds,
      addImageByIds
    }
  }
}