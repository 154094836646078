import React from 'react';
import { Form, Input, Grid, Checkbox, Icon, Label } from 'semantic-ui-react'

interface InputPackProps {
  publicToggle?: boolean,
  description?: string,
  label?: string,
  placeHolder?: string,
  icon?: string,
  disabled?: boolean,
  value?: string,
  setValue?: any,
  name?: string,
  publicField?: boolean,
  setPublicField?: any,
  mandatory?: boolean,
  typeInput?: string,
}

function InputPack(props: InputPackProps) {

  const {
    publicToggle,
    description,
    label,
    placeHolder,
    icon,
    disabled,
    value,
    setValue,
    name,
    publicField,
    setPublicField,
    mandatory
  } = props;

  let { typeInput } = props;

  if (!props.typeInput) typeInput = 'text';

  return (
    <>
      <Form style={{ width: '100%', marginBottom: '35px' }}>
        <Form.Field>
          <label>{label}</label>
          <Input
            onChange={setValue}
            name={name}
            value={value}
            disabled={disabled}
            type={typeInput}
            iconPosition={icon ? 'left' : undefined}
            placeholder={placeHolder}
          >
            {icon && <Icon name={icon} />}
            <input />
          </Input>
          {mandatory && value === '' &&
            <Label basic color='red' pointing>
              Please enter a valid {label}
            </Label>
          }
          <p style={{ marginTop: '10px' }}> {description}</p>
          {publicToggle &&

            <Grid>
              <Grid.Column style={{ minWidth: '60px' }}>
                <Checkbox
                  disabled={disabled}
                  toggle name={name}
                  onChange={setPublicField}
                  checked={publicField}
                />
              </Grid.Column>
              <Grid.Column style={{ minWidth: '180px' }}>
                <span style={{ marginLeft: '5px' }}>Appear in public search</span>
              </Grid.Column>
            </Grid>
          }
        </Form.Field>
      </Form>
    </>
  );
}

export default InputPack;