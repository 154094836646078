import React from 'react'
import IllustZeroResults from 'assets/illustrations/watermelon-pack-illustration-19.svg'
import { Image } from 'semantic-ui-react'
import GridCenter from '../GridCenter';

function NoResults({ title, text }: any) {
  return (
    <GridCenter>
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        <Image src={IllustZeroResults} size='small' />
      </div>

      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        <div>
          <h2 style={{ marginBottom: '0px' }}>{title || 'There are no results'}</h2>
          <h4 style={{ marginTop: '5px' }}>{text || 'Try to change your search terms'}</h4>
        </div>
      </div>
    </GridCenter>
  );
}

export default NoResults;