import React from 'react';
import { Form } from 'semantic-ui-react';

interface TextareaPackProps {
  label: string;
  placeholder: string;
  row?: number;
  name: string;
  value?: string;
  setValue: any;
  disabled?: boolean;
}

function TextareaPack(props: TextareaPackProps) {

  return (
    <Form>
      <Form.Field style={{ marginBottom: '35px' }}>
        <label>{props.label}</label>
        <textarea
          disabled={props.disabled}
          placeholder={props.placeholder}
          rows={props.rows || 3}
          name={props.name}
          onChange={props.setValue}
        >
          {props.value}
        </textarea>
      </Form.Field>
    </Form>
  );
}

export default TextareaPack;