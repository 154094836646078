import React, { useEffect, useState } from 'react'
import { useFirestoreCollectionData, SuspenseWithPerf } from 'reactfire'
import { Icon, Dropdown, Modal, Header, Button } from 'semantic-ui-react'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook'
import NotificationsHWContent from './Content'
import useFunctionsHook from 'services/hooks/useFunctionsHook'
import { DropdownNotifications } from './styles'
import { useContextOrganisations } from 'services/hooks/useContextOrganisations'
import { useNotificationsHook } from 'services/hooks/useNotificationsHook'

function NotificationsHWComponent({ oid }: any) {

  const { functions: { runCloudFunction } } = useFunctionsHook('NOTIF-clearUserNotifications');
  const { functions: { projectAccessFromContextAdd } } = useContextOrganisations();
  const { functions: { markCapturedFirstTime } } = useNotificationsHook(oid);

  const [loading, setLoading]: any = useState(false);
  const [openModal, setOpenModal]: any = useState(false);

  const { collections: { HWFS } } = useFirestoreHook()

  const notifRef = HWFS.userNotification(oid).where('readed', '==', false);
  const notifications = useFirestoreCollectionData(notifRef, { idField: 'id' });

  const notifGeneralRef = HWFS.users_notifications.where('readed', '==', false);
  const notificationsGeneral = useFirestoreCollectionData(notifGeneralRef, { idField: 'id' });

  const [notifs, setNotif]: any = useState([])
  const [notifsGeneral, setNotifGeneral]: any = useState([])

  useEffect(() => {

    notifications.map((noti: any) => {
      if (noti?.type === 'invitedDiscussion') {
        // console.xx('[Notifications] Adding new tmp access', noti.project)
        if (noti.captured !== true) {
          projectAccessFromContextAdd(oid, [noti.project], false)
          markCapturedFirstTime(noti.id);
        }
      }
      return null
    });

    setNotif(notifications
      .sort((a: any, b: any) =>
        (a.createdAt.seconds < b.createdAt.seconds) ? 1 :
          ((b.createdAt.seconds < a.createdAt.seconds) ? -1 : 0))
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    setNotifGeneral(notificationsGeneral.sort((a: any, b: any) =>
      (a.createdAt.seconds < b.createdAt.seconds) ? 1 :
        ((b.createdAt.seconds < a.createdAt.seconds) ? -1 : 0))
    )
  }, [notificationsGeneral])


  const clearAllNotifications = () => {
    setNotif([]);
    setLoading(true)
    runCloudFunction({ oid: oid }).then(() => {
      setLoading(false)
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
    })
  }

  const NotificationIcon = () => {
    return (
      <Icon.Group>
        {
          loading ?
            <Icon loading name='spinner' /> :
            <Icon name='bell' />
        }
        {(notifs.length > 0 || notifsGeneral.length > 0) && !loading &&
          <Icon corner name='circle' color='red' />
        }
      </Icon.Group>

    )
  }

  const FullContent = ({ modalMode = false }: any) => <NotificationsHWContent
    notifs={notifs}
    oid={oid}
    modalMode={modalMode}
    notifsGeneral={notifsGeneral}
    loading={loading}
    setLoading={setLoading}
    clearAllNotifications={clearAllNotifications}
  />

  return (
    <>
      <div
        className="computer large screen only"
        style={{ marginRight: '1em', marginLeft: '1em', display: 'inline' }}
      >

        <Dropdown
          loading={loading}
          trigger={<NotificationIcon />}
          icon={false}
          floating
          direction='left'
          className=''
        >
          <DropdownNotifications
            className='left'
          >
            <FullContent />
          </DropdownNotifications>
        </Dropdown>

      </div >

      <div
        style={{ marginRight: '1em', marginLeft: '1em', display: 'inline' }}
        className="mobile tablet only"
        onClick={() => { setOpenModal(!openModal) }}
      >
        <NotificationIcon />
      </div>

      <Modal
        closeOnDimmerClick={false}
        closeIcon
        onClose={() => setOpenModal(false)}
        open={openModal}
        size='small'
        dimmer='blurring'
      >
        <Header icon>
          <Icon name='bell' />
          Notifications
        </Header>
        <Modal.Content>
          <FullContent modalMode={true} />
        </Modal.Content>
        <Modal.Actions>
          <Button color='grey' onClick={() => setOpenModal(false)}>
            <Icon name='remove' /> Close
        </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}


function NotificationsHW(props: any) {

  if (!props.oid) {
    return (<></>)
  }

  return (
    <SuspenseWithPerf
      fallback={
        <div
          style={{ marginRight: '1em', marginLeft: '1em', display: 'inline' }}>
          <Dropdown
            trigger={
              <Icon loading name='spinner' />
            }
            icon={false}
            floating
            loading
            direction='left'
          ></Dropdown>
        </div>
      }
      traceId={`howwee-notific-${props.oid}`}
    >
      <NotificationsHWComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default NotificationsHW;