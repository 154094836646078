import React from 'react';
import GridCenter from 'pages/App/components/GridCenter'
import { useModalHook } from 'services/hooks/useModalHook'
import { Divider } from 'semantic-ui-react';
import { TitleQuestion } from './styles'
import HistoryContentReport from './Content/HistoryContentReport';

export function HistoryReport({ oid, isModal }: any) {

  const { functions: { getModalData } } = useModalHook()
  const { report } = getModalData()

  return (
    <>
      <GridCenter fullWidth={isModal}>
        <TitleQuestion> {report.name}</TitleQuestion>
        <Divider />
        <HistoryContentReport reportId={report.id} projId={report.projId} oid={oid} />
      </GridCenter>
    </>
  );
}