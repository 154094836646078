import React from 'react';
import { Popup, Image } from 'semantic-ui-react';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'

interface Props {
  uid: string;
  oid: string;
}

function BadgeUser({ uid, oid }: Props) {

  const { functions: { photoUserByID, usersById } } = useOrganisationHook(oid);
  const creator = usersById(uid);

  return (
    <div style={{ marginRight: '-10px', display: 'inline' }}>
      <Popup
        basic
        content={<>
          {creator.displayName}
          {creator.representsOrg?.name &&
            ` (${creator.representsOrg?.name})`
          }
        </>}
        trigger={<Image bordered src={photoUserByID(uid)} avatar />}
      />
    </div>
  )
}

export default BadgeUser;