import styled from 'styled-components'

export const CenterAlign = styled.div`
  text-align: center;
`

export const RightAlign = styled.div`
  text-align: right;
`

export const LeftAlign = styled.div`
  text-align: left;
`