import React from 'react';
import { navigate } from '@reach/router';
import { Icon, Popup, Table, Dropdown } from 'semantic-ui-react'
import { BadgeUser, BadgeMultipleUser, BadgeMultipleSubject, BadgeMultipleTeam } from 'pages/App/components/Badges'
import { useTable, useSortBy } from 'react-table'
import { CenterAlign } from './styles'
import moment from 'moment';
import { useProjectHook } from 'services/hooks/useProjectHook';
import { useModalHook } from 'services/hooks/useModalHook';
import { orgId, projectId } from 'typings/generic';
import { projectArrayType, projectType } from 'typings/projects';

interface Props {
  oid: typeof orgId;
  loading: boolean;
  discussions: projectArrayType;
  openDiscussion: (discussion: projectType, projId: typeof projectId) => void;
  validateProjectAccess: (discussion: projectType, projId: typeof projectId) => void;
}

function DiscussionsTable({ oid, loading, validateProjectAccess, openDiscussion, discussions }: Props) {

  const { functions: { userIsAdmin, restoreProject } } = useProjectHook(oid);
  const { functions: { openModal } } = useModalHook()


  const columns = React.useMemo(
    () => [
      {
        Header: 'Visibility',
        accessor: 'visibility',
        onClick: () => { },
        Cell: (props: any) => {

          switch (props.cell.value) {
            case 'private': return (
              <CenterAlign>
                <Icon name='lock' />
              </CenterAlign>
            );

            case 'public': return (
              <CenterAlign>
                <Icon name='bullhorn' />
              </CenterAlign>
            );

            default:
              return (
                <CenterAlign>
                  <Icon name='cut' />
                </CenterAlign>
              );
          }
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        onClick: () => { },
        Cell: (props: any) => {
          return (
            <div onClick={props.column.onClick}>
              { props.cell.value}
            </div>
          );
        },
      },
      {
        Header: 'Author',
        accessor: 'createdBy',
        Cell: (props: any) => {
          const uid = props.cell.value;
          return (
            <div onClick={props.column.onClick}>
              <BadgeUser uid={uid} oid={oid} />
            </div>
          );
        },
      },
      {
        Header: 'Owners',
        accessor: 'editors',
        Cell: (props: any) => {
          const editors = props.cell.value;
          return (
            <div onClick={props.column.onClick}>
              <BadgeMultipleUser users={editors} oid={oid} />
            </div>
          );
        },
      },
      {
        Header: 'Subjects',
        accessor: 'subjects',
        Cell: (props: any) => {
          const subjects = props.cell.value;
          return (
            <div onClick={props.column.onClick}>
              <BadgeMultipleSubject subjects={subjects} oid={oid} />
            </div>
          );
        },
      },
      {
        Header: 'Participating teams',
        accessor: 'teams',
        Cell: (props: any) => {
          const teams = props.cell.value;
          return (
            <div onClick={props.column.onClick}>
              <BadgeMultipleTeam teams={teams} oid={oid} />
            </div>
          );
        },
      },
      {
        Header: 'Individuals',
        accessor: 'users',
        Cell: (props: any) => {
          const users = props.cell.value;
          return (
            <div onClick={props.column.onClick}>
              <BadgeMultipleUser users={users} oid={oid} />
            </div>
          );
        },
      },
      {
        Header: 'Last Activity',
        accessor: 'dateSeconds',
        Cell: (props: any) => {
          const date = props.cell.value;
          return (
            <div onClick={props.column.onClick}>
              <Popup
                basic
                content={date ? moment.utc(date * 1000).format("DD MMM YYYY") : ''}
                trigger={
                  <span>
                    {date ?
                      <> {moment.utc(date * 1000).fromNow()} </> :
                      ''
                    }
                  </span>
                }
              />
            </div>
          );
        },
      },
      {
        Header: 'Favourite',
        accessor: 'favourite',
        Cell: (props: any) => {
          if (validateProjectAccess(props.row.original, props.row.original.id)) {
            return (<CenterAlign>
              <Icon name='flag checkered' />
            </CenterAlign>)
          } else {
            return (
              <CenterAlign>
                <Icon name='star outline' />
              </CenterAlign>
            );
          }
        },
      },
      {
        Header: '',
        id: 'options',
        Cell: (props: any) => {
          const id = props.row.original.id;
          return (
            <CenterAlign>
              <Dropdown
                icon='ellipsis vertical'
                pointing='right'
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text='Edit'
                    onClick={() => navigate(`discussions/${id}/edit`)}
                  />

                  {userIsAdmin(id) &&
                    props.row.original.status === 410 &&
                    <Dropdown.Item
                      text='Restore'
                      onClick={() => {
                        restoreProject(props.row.original.id)
                      }}
                    />
                  }

                  {userIsAdmin(id) &&
                    props.row.original.status !== 410 &&
                    <Dropdown.Item
                      text='Archive'
                      onClick={() => {
                        openModal(
                          'archiveDiscussion',
                          { discussion: props.row.original, id: id }
                        )
                      }}
                    />
                  }

                </Dropdown.Menu>
              </Dropdown>
            </CenterAlign>
          );
        },
      },
      {
        Header: '',
        id: 'margin_last',
        Cell: (props: any) => {
          return (
            <CenterAlign>
            </CenterAlign>
          );
        },
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const initialStateTable: any = {
    columns: columns,
    data: discussions,
    initialState: {
      sortBy: [
        {
          id: 'dateSeconds',
          desc: true
        }
      ]
    }
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(initialStateTable, useSortBy)

  return (

    <Table selectable basic='very' {...getTableProps()}>
      <Table.Header>
        {headerGroups.map(headerGroup => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            <Table.HeaderCell>
              {/* For loading column */}
            </Table.HeaderCell>
            {headerGroup.headers.map((column: any, index: number) => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <Table.HeaderCell key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span style={{ marginLeft: '5px' }}>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <Icon name='arrow up' />
                      : <Icon name='arrow down' />
                    : ''}
                </span>
              </Table.HeaderCell>
            ))}
          </Table.Row>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {rows.map(
          (row: any, i: number) => {
            prepareRow(row);
            return (
              <Table.Row
                disabled={validateProjectAccess(row.original, row.original.id)}
                key={i}
                style={{ cursor: 'pointer' }}
                onClick={() => openDiscussion(row.original, row.original.id)}
                {...row.getRowProps()}
              >
                <Table.Cell>
                  {validateProjectAccess(row.original, row.original.id) &&
                    <Icon name='spinner' loading />
                  }
                </Table.Cell>
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <Table.Cell key={index} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
              </Table.Row >
            )
          }
        )}
      </Table.Body>
    </Table>

  );
}

export default DiscussionsTable;