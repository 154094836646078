import { useState } from 'react';
import { useFunctions } from 'reactfire';

type fnType =
  'helloWorld' |
  'COREHW-createInvitation' |
  'COREHW-acceptInvitation' |
  'COREHW-refuseInvitation' |
  'COREHW-cancelInvitation' |
  'COREHW-createOrganisation' |
  'COREHW-removeUser' |
  'NOTIF-clearUserNotifications';

const useFunctionsHook = (type: fnType) => {

  const [loading, setLoading] = useState(false);

  const functions = useFunctions()
  const cloudFunction = functions.httpsCallable(type);

  const runCloudFunction = async (payload: any) => {
    setLoading(true)

    return cloudFunction(payload).then((res: any) => {
      setLoading(false);
      return res;
    }).catch((err) => {
      console.error(err);
    })
  }

  return {
    functions: {
      runCloudFunction,
    },
    data: {
      loading
    }
  }
}

export default useFunctionsHook;