import styled from 'styled-components'

export const TitleQuestion = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 18 (0.9);
  color: #212529;
  margin-bottom: 0px;
`

export const PrefixTitleQuestion = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #6C757D;
  margin-top: 0px;
`

export const LinkCustom = styled.span`
  cursor: pointer;
  color: #007BFF;
  font-weight: 600;
`

export const HistoryItem = styled.p`
`