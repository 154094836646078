import React, { useState } from 'react'
import { Table, Card, Header, Segment, Dropdown, Dimmer, Loader } from 'semantic-ui-react'
import { formatDate } from 'helpers/mini'
import { Loading } from 'pages/App/components/Loading';
import StatusInvititation from 'pages/App/components/Label/StatusInvitation'
import useFunctionsHook from 'services/hooks/useFunctionsHook';
import { useUser, useFirestoreCollectionData, SuspenseWithPerf, } from 'reactfire';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';

interface Props {
  showInvites: boolean;
}

function Invitations(props: any) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId={'settings-personal-invitations'}>
      <InvitationsElement {...props} />
    </SuspenseWithPerf>)
}

function InvitationsElement({ showInvites }: Props) {

  const { email }: any = useUser();
  const { collections: { HWFS } } = useFirestoreHook();

  const invitationsRef = HWFS.nousers_invitations(email.toLowerCase()).orderBy('createdAt', 'desc');
  const invitations = useFirestoreCollectionData(invitationsRef, { idField: 'id' })

  const [disabled, setDisabled] = useState(false);
  const { functions: { runCloudFunction } } = useFunctionsHook('COREHW-refuseInvitation');


  const deleteInvite = (id: string) => {
    setDisabled(true);
    runCloudFunction({ invitationId: id }).then(() => {
      setDisabled(false);
    });
  }

  if (!showInvites) return <></>

  const TableInvitations = () => {
    return (
      <Table unstackable singleLine basic='very' collapsing style={{ margin: '20px', width: '100%', marginBottom: '50px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Organisation</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            invitations.length > 0 &&
            invitations.map((invite: any) => {

              if (!invite.name) return <Table.Row></Table.Row>
              return (
                <Table.Row key={invite.id}>
                  <Table.Cell>
                    <Header as='h4' image>
                      <Header.Content>
                        {invite.name}
                        <Header.Subheader>Organisation</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      invite.createdAt &&
                      formatDate(invite.createdAt)
                    }
                  </Table.Cell>
                  <Table.Cell>
                    <StatusInvititation code={invite.status} />
                  </Table.Cell>
                  <Table.Cell>
                    {invite.role.charAt(0).toUpperCase() + invite.role.slice(1)}
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      icon='ellipsis vertical'
                      style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
                    >
                      <Dropdown.Menu>
                        {invite.status === 203 &&
                          <Dropdown.Item text='Delete' onClick={() => { deleteInvite(invite.id) }} />
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>)
  }

  return (
    <Card.Group itemsPerRow={1} style={{ marginTop: '15px' }} >
      <Card style={{ paddingTop: '10px' }}>
        <Header as='h3' attached='top'>
          All your invitations
        </Header>
        <Segment attached>
          <Dimmer active={disabled} inverted>
            <Loader size='medium'>Update in progress</Loader>
          </Dimmer>
          <TableInvitations />
        </Segment>
      </Card>
    </Card.Group>
  );
}

export default Invitations;