import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { Breadcrumb } from 'semantic-ui-react';
import { useProjectHook } from 'services/hooks/useProjectHook';

function Header({ oid, projId }: any) {

  const { functions: { projectById } } = useProjectHook(oid)
  const [project, setProject] = useState({ name: '', description: '' })

  useEffect(() => {
    if (projId) {
      setProject(projectById(projId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ paddingTop: '0.3em', paddingBottom: '2em' }}>
      <Breadcrumb size='small' style={{ marginLeft: '1em' }}>
        <Breadcrumb.Section link onClick={() => navigate(`/app/org/${oid}/discussions`)}>
          Discussions
            </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section onClick={() => {
          projId ?
            navigate(`/app/org/${oid}/discussions/${projId}`) :
            navigate(`/app/org/${oid}/questions`)
        }} link>
          {projId ?
            project?.name :
            'All Discussions'
          }
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section style={{ color: 'grey' }}>
          Archived Questions
        </Breadcrumb.Section>
      </Breadcrumb>
    </div>
  );
}

export default Header;