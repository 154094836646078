import React, { useContext } from 'react'
import { Button, Modal, Tab } from 'semantic-ui-react'
import HowWeeContext from 'services/contexts/ContextHowWee'
import UIContext from 'services/contexts/ContextUI';
import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";

export function ModalContextInfo(props: any) {
  const [open, setOpen] = React.useState(false)
  const HWCtt: any = useContext(HowWeeContext);
  const uiCtt: any = useContext(UIContext);
  const searchCtt: any = useContext(SearchGlobalContext);


  const panes = [
    {
      menuItem: 'Context How-Wee ', render: () => <Tab.Pane>
        <AceEditor
          style={{ width: '100%' }}
          mode="json"
          fontSize={14}
          theme="monokai"
          value={JSON.stringify(HWCtt, null, 2)}
          name="ace-how-wee-context"
        />
      </Tab.Pane>
    },
    {
      menuItem: 'Context UI', render: () => <Tab.Pane>
        <AceEditor
          style={{ width: '100%' }}
          mode="json"
          fontSize={14}
          theme="monokai"
          value={JSON.stringify(uiCtt, null, 2)}
          name="ace-ui-context"
        />
      </Tab.Pane>
    },
    {
      menuItem: 'Context Search', render: () => <Tab.Pane>
        <AceEditor
          style={{ width: '100%' }}
          mode="json"
          fontSize={14}
          theme="monokai"
          value={JSON.stringify(searchCtt, null, 2)}
          name="ace-search-context"
        />
      </Tab.Pane>
    },
  ]

  return (
    <Modal
      closeOnDimmerClick={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size={'large'}
      trigger={props.children}
    >
      <Modal.Header>React Context [Dev Mode]</Modal.Header>
      <Modal.Content>
        <Tab
          menu={{ fluid: true, vertical: true }}
          menuPosition='right'
          panes={panes}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
