import styled from 'styled-components'

export const LogoOrganisation = styled.img`
  border-radius: 6px;
  max-height: 35px;
  margin-right: 0.5em;
  border: 1px;
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.15) !important;
  -moz-box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.15) !important;
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.15) !important;
`