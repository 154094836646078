import styled from 'styled-components'

export const Title = styled.div`
  font-weight: 500;
  margin-left: 5%;
  margin-top: 8px;
`

export const ContainerCards = styled.div`
  height: calc(100% - 50px);
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;
  overflow-y: auto;
  padding-top: 5px;
  flex-direction: column;
  display: flex;
`

export const ListBoard = styled.div`
  ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
  }
 overflow-y: scroll;
 -webkit-overflow-scrolling: touch;
 background-color: #F8F9FA;
 height: calc(100% - 49px);;
 min-width: 340px;
 width: 340px;
 max-width: 340px;
 display: inline-block;
 margin-left: 10px;
 margin-right: 10px;
 margin-top: 20px;
 margin-bottom: 20px;
 -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
`