import React, { useContext, useEffect, useState } from 'react';
import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'
import Draggable from 'react-draggable'
import { Header } from './Header'
import { Dimmer, Divider, Loader } from 'semantic-ui-react';
import { QuestionFloated } from './styles'
import Fields from './Fields'
import Results from './Results'
import { SuspenseWithPerf, useFirestoreCollectionData } from 'reactfire'
import { useImmer } from 'use-immer';
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';
import { Loading } from 'pages/App/components/Loading';

export function SearchGlobalComponent({ oid, projId, independent, onClickQuestion, avoidQuestions, onlyAnswerSelected }: any) {

  const { functions: { allQuestionsInOrg } } = useQuestionsHook(oid);

  const searchCtt: any = useContext(SearchGlobalContext);
  const [initialSearch, setInitialSearch] = useState('')
  const [loading, setLoading] = useState(true)

  const { collections: { HWFS } } = useFirestoreHook();

  let questionsRef: any;

  if (projId) {
    questionsRef = HWFS.questions(oid, projId)
  } else {
    questionsRef = HWFS.questionsOld(oid);
  }

  const questionsRefDoc = useFirestoreCollectionData(questionsRef, { idField: 'id' });
  const [questionsDocs, setQuestionDocs] = useImmer({ data: [] });

  useEffect(() => {
    setLoading(true);
    if (projId) {
      setQuestionDocs((draft: any) => {
        draft.data = questionsRefDoc;
      })
      setLoading(false);
    }

    if (!projId) {
      allQuestionsInOrg().then((res: any) => {
        setQuestionDocs((draft: any) => {
          draft.data = res;
        });
        setLoading(false);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsRefDoc])

  useEffect(() => {
    if (searchCtt.searchData.searchTerm !== initialSearch) {
      setInitialSearch(searchCtt.searchData.searchTerm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCtt.searchData.searchTerm]);

  const closeWindow = () => {
    searchCtt.setSearchData((draft: any) => {
      draft.searchData.isOpen = false;
      draft.searchData.searchTerm = '';
    })
  }

  const SearchCore = () => {
    let columnsResults = independent ? 3 : 1;
    return (
      <>
        <Dimmer active={loading} inverted>
          <Loader inverted>Loading Questions</Loader>
        </Dimmer>
        <Fields
          independent={independent}
          searchCtt={searchCtt}
          oid={oid}
          initialSearch={initialSearch}
          onlyAnswerSelected={onlyAnswerSelected}
        />
        <Divider />
        <Results
          onClickQuestion={onClickQuestion}
          avoidQuestions={avoidQuestions}
          independent={independent}
          columns={columnsResults}
          projId={projId}
          questionsDocs={questionsDocs.data}
          searchCtt={searchCtt}
          oid={oid}
          onlyAnswerSelected={onlyAnswerSelected}
        />
      </>
    )
  }

  return (
    <div>
      {/* Search Window */}
      {searchCtt.searchData.isOpen && !independent && oid &&
        <Draggable defaultPosition={{ x: window.screen.width * 0.22, y: 20 }}>
          <QuestionFloated>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'scroll', overflowX: 'hidden' }}>
              <Header closeWindow={closeWindow} />
              <div style={{ whiteSpace: 'pre-wrap', width: '100%', marginTop: '45px', }} >
                <SearchCore />
              </div>
            </div>
          </QuestionFloated>
        </Draggable>
      }

      {independent && oid &&
        <>
          <SearchCore />
        </>
      }
    </div>
  );
}

export function SearchGlobal(props: any) {
  return (
    <SuspenseWithPerf
      fallback={
        <Draggable defaultPosition={{ x: window.screen.width * 0.22, y: 20 }}>
          <QuestionFloated>
            <Loading />
          </QuestionFloated>
        </Draggable>
      }
      traceId={`howwee-history-${props.oid || ''}}-search-global`}
    >
      <SearchGlobalComponent {...props} />
    </SuspenseWithPerf>
  )
}