import styled from 'styled-components'

interface DropZoneHWProps {
  haveFile: boolean;
}

export const DropZoneHW: any = styled.div<DropZoneHWProps>`
  min-width: 50%;
  width: auto;
  min-height: 50px;
  border-radius: 2em;
  margin: 1em;
  margin-bottom: ${props => props.haveFile && "-1em"};
  border: ${props => !props.haveFile && "thin dashed"};
  background-color: ${props => !props.haveFile && "#E8E8E8"};
  padding: 2em;
  text-align: center;
`

export const ThumbDropZone = styled.img`
  height: auto;
  max-height: 200px;
  border-radius: 1em;
  -webkit-box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.75) !important;
  box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.75) !important;
`