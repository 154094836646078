import React from 'react';
import { Loading } from 'pages/App/components/Loading';
import { useFirestoreCollectionData, SuspenseWithPerf } from 'reactfire';
import { Grid } from 'semantic-ui-react';
import { useHistoryHook } from 'services/hooks/useHistoryHook'
import HistoryItems from '../Items'

function HistoryContentReportCore({ reportId, oid, projId }: any) {

  const { collections: { historyByReport } } = useHistoryHook(oid);

  const historyData = useFirestoreCollectionData(historyByReport(projId, reportId))

  return (
    <>
      <Grid.Row>
        <HistoryItems oid={oid} historyData={historyData} />
      </Grid.Row>
    </>
  );
}

function HistoryContentReport(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Loading />}
      traceId={`howwee-history-${props.oid || ''}-${props.reportId || ''}`}
    >
      <HistoryContentReportCore {...props} />
    </SuspenseWithPerf>
  )
}

export default HistoryContentReport;