import { localSt, LOCAL_ST } from 'helpers/local-session-storage'
import { useStorage } from 'reactfire'
import { useFirestoreHook } from './useFirestoreHook';

export function useHelperHook(id: string) {

  const { collections: { HWFS } } = useFirestoreHook();
  const storage = useStorage();

  const orgLogoByPathId = (path: string, oid: string, noCache?: boolean) => {
    return new Promise((resolve, reject) => {
      try {
        if (!path) resolve('org_without_profile')
        if (path == null || path === undefined) {
          resolve('org_without_profile')
        } else {
          if (!noCache) {
            let current = localSt.obtainNow(LOCAL_ST.organisationsLogos);
            if (current[oid] === path) resolve(current[oid])
          }

          const pathImageReference = storage.ref(path);
          pathImageReference.getDownloadURL().then(function (url) {
            localSt.mergeNow(LOCAL_ST.organisationsLogos, { [oid]: url });
            resolve(url);
          }).catch((err: any) => {
            console.error(err);
            resolve('org_without_profile')
          })
        }
      } catch (err) {
        console.error(err);
        reject(err)
      }
    });
  }

  const orgLogoById = (oid: string) => {
    return new Promise((resolve, reject) => {
      let current = localSt.obtainNow(LOCAL_ST.organisationsLogos);
      HWFS.organisations_public.doc(oid).get().then((res: any) => {
        const organisation = res.data();
        if (organisation.imageProfile) {

          if (current[oid] === organisation.imageProfile) resolve(current[oid])

          const pathImageReference = storage.ref(organisation.imageProfile);
          pathImageReference.getDownloadURL().then(function (url) {
            localSt.mergeNow(LOCAL_ST.organisationsLogos, { [oid]: url });
            resolve(url);
          });
        } else {
          resolve('org_without_profile')
        }
      })
    })
  }

  return {
    functions: {
      orgLogoById,
      orgLogoByPathId
    }
  }
}