import { useFirestore, useUser } from 'reactfire'
import * as firebase from 'firebase/app'
import 'firebase/firestore';

export function useFirestoreHook() {

  // Keep this like only instance of Firestore in the entire App
  const firestore = useFirestore();
  const { uid }: any = useUser();

  const SDK: any = {
    documentId() {
      return firebase.firestore.FieldPath.documentId()
    },
    arrayUnion(value: any) {
      return firebase.firestore.FieldValue.arrayUnion(value)
    },
    arrayRemove(value: any) {
      return firebase.firestore.FieldValue.arrayRemove(value)
    }
  }

  const HWFS: any = { // HWFS = How Wee FireStore 
    'users_access': firestore.collection('users_access'),
    'users_notifications': firestore.collection('users_notifications').doc(uid).collection('notifications'),
    'users_public': firestore.collection('users_public'),
    'users_settings': firestore.collection('users_settings'),
    'organisations': firestore.collection('organisations'),
    'organisations_public': firestore.collection('organisations_public'),
    'nousers': firestore.collection('nousers'),
    'nousers_invitations'(email: string) {
      return this.nousers.doc(email).collection('invitations')
    },
    'organisations_access': firestore.collection('organisations_access'),
    'organisations_access_invitations'(oid: string) {
      return this.organisations_access.doc(oid).collection('invitations')
    },
    'projects_access_user'(oid: string) {
      return this.organisations.doc(oid).collection('projects_access').doc(uid);
    },
    'projects_access'(oid: string) {
      return this.organisations.doc(oid).collection('projects_access');
    },
    'projects'(oid: string) {
      return this.organisations.doc(oid).collection('projects');
    },
    'subjects'(oid: string) {
      return this.organisations.doc(oid).collection('subjects');
    },
    'teams'(oid: string) {
      return this.organisations.doc(oid).collection('teams');
    },
    'users'(oid: string) {
      return this.organisations.doc(oid).collection('users');
    },
    'userNotification'(oid: string) {
      return this.organisations.doc(oid).collection('users').doc(uid).collection('notifications');
    },
    'reportsOld'(oid: string) { // Empty collection always
      return this.organisations.doc(oid).collection('reports');
    },
    'reports'(oid: string, projId: string) {
      return this.projects(oid).doc(projId).collection('reports');
    },
    'questionsOld'(oid: string) { // Empty collection always
      return this.organisations.doc(oid).collection('questions');
    },
    'questions'(oid: string, projId: string) {
      return this.projects(oid).doc(projId).collection('questions');
    },
    'questionComments'(oid: string, projId: string, questionId: string) {
      return this.questions(oid, projId).doc(questionId).collection('comments')
    }
  }

  const FSorganisations = firestore.collection('organisations');
  const FSorganisations_public = firestore.collection('organisations_public');
  const FSorganisations_access = firestore.collection('organisations_access');

  const organisation_id = (oid: string) => {
    return FSorganisations.doc(oid);
  }

  const addDoc = (path: any, dataX: any, meta?: any) => {

    let data = dataX;

    data.createdBy = uid;
    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    if (!dataX.status) {
      data.status = 200;
    }

    return new Promise((resolve, reject) => {

      if (meta && meta.id) {
        path.doc(meta.id).set(data).then((res: any) => {
          resolve(res)
        }).catch((err: any) => {
          reject(err);
        })
      } else {
        path.add(data).then((res: any) => {
          resolve(res)
        }).catch((err: any) => {
          reject(err);
        })
      }

    })
  }

  const getDoc = (path: any) => {
    return new Promise((resolve, reject) => {
      path.get().then((res: any) => {
        resolve(res.data());
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  const softDeleteDoc = (path: any) => {
    return new Promise((resolve, reject) => {
      path.update({ status: 404 }).then((res: any) => {
        resolve(res)
      }).catch((err: any) => {
        reject(err);
      })
    })
  }

  const updateDoc = (path: any, dataX: any, meta?: any) => {
    let data = dataX;

    data.modificatedBy = uid;
    data.modificatedAt = firebase.firestore.FieldValue.serverTimestamp()
    data.status = 200;

    return new Promise((resolve, reject) => {

      path.update(data).then((res: any) => {
        resolve(res)
      }).catch((err: any) => {
        reject(err);
      })

    })
  }

  const deleteDoc = (path: any) => {
    return new Promise((resolve, reject) => {
      path.delete().then((res: any) => {
        resolve(res)
      }).catch((err: any) => {
        reject(err);
      })
    })
  }


  const getSubCollection = (oid: string, subcollection: string) => {
    return new Promise((resolve, reject) => {
      FSorganisations.doc(oid).collection(subcollection).get().then((snapshot: any) => {
        const tempResult: Array<any> = [];
        snapshot.forEach((doc: any) => {
          let data = doc.data();
          let temp = { id: doc.id, ...data };

          if (data.status && data.status !== 404) {
            tempResult.push(temp);
          } else if (!data.status) {
            tempResult.push(temp);
          }

        });
        resolve(tempResult);
      });
    })
  }

  return {
    data: {
      SDK
    },
    collections: {
      HWFS,
      organisation_id,
      FSorganisations,
      FSorganisations_public,
      FSorganisations_access
    },
    functions: {
      addDoc,
      getDoc,
      softDeleteDoc,
      deleteDoc,
      updateDoc,

      getSubCollection
    }
  }
}