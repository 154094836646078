import { useUser } from 'reactfire'
import { useFirestoreHook } from './useFirestoreHook';

export function useAccessHook() {

  const { uid, email }: any = useUser();
  const { collections: { HWFS } } = useFirestoreHook();

  const users_access = HWFS.users_access.doc(uid);
  const organisations_public = HWFS.organisations_public;
  const organisations_access = HWFS.organisations_access;

  const nouser_invitations = HWFS.nousers;

  const invitationsByEmail = () => {
    return nouser_invitations.doc(email.toLowerCase()).collection('invitations').get();
  }

  const invitationsByOrg = (oid: string) => {
    return organisations_access.doc(oid).collection('invitations').get();
  }

  const haveOpenInvitations = () => {

    return new Promise((resolve: any) => {
      listAccess().then((res: any) => {
        let result: Array<string> = [];
        const { invitations, organisationsWorking } = res;

        Object.entries(invitations).map((data: any) => {
          const orgId = data[0];
          const invits = data[1];

          if (
            invits.length > 0 &&
            !organisationsWorking.includes(orgId)
          ) {
            result.push(orgId)
          }

          return null;
        })

        resolve(result);
      })
    })
  }

  const listAccess = () => {
    return new Promise((resolve, reject) => {
      users_access.get().then(async (res) => {
        const data: any = res.data() || {};
        const dataOriginal: any = res.data() || {};

        const organisationWithInvitation: any = {}

        await invitationsByEmail().then((snapshot: any) => {
          snapshot.forEach((doc: any) => {
            const invitation = { id: doc.id, ...doc.data() };

            if (!data.organisation) data.organisation = [];

            if (!organisationWithInvitation[invitation.organisation]) organisationWithInvitation[invitation.organisation] = []

            if (invitation.status === 203) {
              organisationWithInvitation[invitation.organisation].push(invitation);
              data.organisation.push(invitation.organisation);
            }
          });
        });

        if (!data || !data.organisation || data.organisation.length === 0) {
          // Prevent invalid query in organisations_public
          // organisationsWorking represent organisations with an accepted invitation
          resolve({
            organisations: [],
            organisationsWorking: [],
            invitations: organisationWithInvitation,
            user_access: data
          });
        } else {

          const list: Array<any> = [];
          data.organisation.map(async (oidX: string, index: number) => {
            await organisations_public.doc(oidX).get().then((res: any) => {

              if (res.exists) {
                list.push({ id: res.id, ...res.data() });
              }

              if (index + 1 === data.organisation.length) {
                resolve({
                  organisations: list,
                  organisationsWorking: dataOriginal.organisation || [],
                  invitations: organisationWithInvitation,
                  user_access: data
                });
              }

            }).catch((err: any) => {
              reject(err);
            })
          })
        }
      })
    })
  }

  return {
    functions: {
      listAccess,
      invitationsByOrg,
      invitationsByEmail,
      haveOpenInvitations
    }
  }
}