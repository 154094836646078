import { useUser } from 'reactfire'
import { useFirestoreHook } from './useFirestoreHook'
import { useHistoryHook } from './useHistoryHook'

export function useCommentsHook(oid: string, projId: string) {

  const { uid }: any = useUser();

  const { data: { SDK }, collections: { HWFS } } = useFirestoreHook()

  const questions = HWFS.questions(oid, projId);

  const { functions: { nhr_questions_new_comment, nhr_questions_first_comment } } = useHistoryHook(oid);
  const { functions: { addDoc } } = useFirestoreHook();

  const newComment = (data: any, questionId: string, commentsLength: number) => {
    const path = questions.doc(questionId).collection('comments');

    return addDoc(path, data).then((res: any) => {
      if (res.id) {
        nhr_questions_new_comment(
          'questions',
          questionId,
          { commentId: res.id },
          projId)
        if (commentsLength === 0) nhr_questions_first_comment('questions', questionId, { commentId: res.id }, projId)
      }
    });
  }

  const likeCommentQuestion = (commentId: string, questionId: string) => {
    questions
      .doc(questionId)
      .collection('comments')
      .doc(commentId)
      .set({
        likes: SDK.arrayUnion(uid),
        dislikes: SDK.arrayRemove(uid)
      }, { merge: true });
  }

  const cancelLikeCommentQuestion = (commentId: string, questionId: string) => {
    questions
      .doc(questionId)
      .collection('comments')
      .doc(commentId)
      .set({
        likes: SDK.arrayRemove(uid),
      }, { merge: true });
  }

  const dislikeCommentQuestion = (commentId: string, questionId: string) => {
    questions
      .doc(questionId)
      .collection('comments')
      .doc(commentId)
      .set({
        dislikes: SDK.arrayUnion(uid),
        likes: SDK.arrayRemove(uid)
      }, { merge: true });
  }

  const cancelDislikeCommentQuestion = (commentId: string, questionId: string) => {
    questions
      .doc(questionId)
      .collection('comments')
      .doc(commentId)
      .set({
        dislikes: SDK.arrayRemove(uid)
      }, { merge: true });

  }

  return {
    collections: {
    },
    functions: {
      newComment,
      likeCommentQuestion,
      cancelLikeCommentQuestion,
      dislikeCommentQuestion,
      cancelDislikeCommentQuestion
    }
  }
}