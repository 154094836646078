import styled from 'styled-components'
import { Menu } from 'semantic-ui-react'

export const TopMenuStyled = styled.div`
  border-radius: "0px";
  padding-top: '0px';
  padding-bottom: '0px';
  margin-bottom: '0px';
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  z-index: 100;
  width: 100%;
  position: absolute;
`

export const MenuItemHW = styled(Menu.Item)`
  height: 80%;
  border-radius:  ${props => props.active ? "0em !important" : ""};
  border-bottom: ${props => props.active ? "2px solid #38B1F9 !important" : ""};
  background: none !important;
  > p {
    font-weight: ${props => props.active ? 500 : 400};
    color: ${props => props.active ? "#38B1F9" : ""};
  }
`