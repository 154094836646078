import React, { useState, useEffect, useContext } from 'react';
import { Tab, Grid } from 'semantic-ui-react';
import { useOrganisationsHook } from 'services/hooks/useOrganisationsHook'
import Profile from './Profile'
import Members from './Members';
import { Loading } from 'pages/App/components/Loading'
import Teams from './Teams';
import HowWeeContext from 'services/contexts/ContextHowWee'
import queryString from 'query-string'
import { useLocation } from '@reach/router'

function OrganisationHome(props: any) {

  const { id: oid } = props;

  const HWCtt: any = useContext(HowWeeContext);
  const { data: { organisationBase, organisationBasePublic } } = useOrganisationsHook();

  const location = useLocation();
  const getParams = queryString.parse(location.search);

  const [defaultTab, setDefaultTab]: any = useState(getParams.tab || 0)
  const [org, setOrg] = useState(null);
  const [orgPublic, setOrgPublic] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleTabChange = (e: void, { activeIndex }: any) => setDefaultTab(activeIndex);

  useEffect(() => {
    let tmp = queryString.parse(location.search);
    setDefaultTab(tmp?.tab);
  }, [location]);

  useEffect(() => {

    const initComponent = async () => {
      HWCtt.data.HW_FUNCTIONS.loadCoreOrg(oid).then(() => {
        organisationBase(oid).then((res: any) => {
          organisationBasePublic(oid).then((res2: any) => {
            setOrg(res.data());
            setOrgPublic(res2.data());
            setLoading(false);
          }).catch((err) => {
            console.error(err);
          })
        }).catch((err) => {
          console.error(err);
        })
      }).catch((err) => {
        console.error(err);
      })
    }

    initComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const panes = [
    {
      menuItem: { key: 'users', content: 'Profile' },
      render: () => <Tab.Pane>
        <Profile
          organisation={org}
          organisationPublic={orgPublic}
          orgId={oid}
        />
      </Tab.Pane>,
    },
    {
      menuItem: { key: 'members', content: 'Members' },
      render: () => <Tab.Pane>
        <Members
          organisation={org}
          orgId={oid}
        />
      </Tab.Pane>,
    },
    {
      menuItem: { key: 'teams', content: 'Teams' },
      render: () => <Tab.Pane>
        <Teams
          organisation={org}
          orgId={oid}
        />
      </Tab.Pane>,
    },
  ]


  return (
    <>
      <Grid container columns='equal'>
        <Grid.Column mobile={16} tablet={1} computer={3}></Grid.Column>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <h2>Organisation Account</h2>
          {loading || (org === null) || (orgPublic === null) ?
            <Loading /> :
            <Tab
              panes={panes}
              onTabChange={handleTabChange}
              activeIndex={defaultTab || 0}
              renderActiveOnly
            />
          }
        </Grid.Column>
        <Grid.Column mobile={16} tablet={1} computer={3}></Grid.Column>
      </Grid>
    </>
  );
}

export default OrganisationHome;