import React from 'react';
import { Label } from 'semantic-ui-react';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'

interface Props {
  oid: string;
  subjectId: string;
}

function BadgeSubect({ oid, subjectId }: Props) {

  const { functions: { subjectById } } = useOrganisationHook(oid);

  return (
    <Label
      color='grey'
      size='tiny'
      style={{ margin: '2px' }}
    >
      #{subjectById(subjectId)}
    </Label>
  )
}

export default BadgeSubect;