import React, { useState, useEffect, useContext } from 'react';
import Profile from './Profile'
import Organisations from './Organisations'
import { Tab, Grid, Icon } from 'semantic-ui-react';
import HowWeeContext from 'services/contexts/ContextHowWee'
import { useLocation } from "@reach/router"
import queryString from 'query-string'
import ProfileInOrg from 'pages/App/Modules/Settings/Personal/ProfileInOrg'
import GridCenter from 'pages/App/components/GridCenter';


interface PersonalHomeProps {
  path: string;
}

function PersonalHome(props: PersonalHomeProps) {

  const location = useLocation();
  const search = queryString.parse(location.search);
  const HWCtt: any = useContext(HowWeeContext);
  const [activeTab, setActiveTab] = useState<any>(search.tab || 'profile');

  useEffect(() => {
    setActiveTab(queryString.parse(location.search).tab || 'profile')
  }, [location]);

  const getIndexByString = (type: any, invert: boolean) => {
    if (invert) {
      switch (type) {
        case 0: return 'profile'
        case 1: return 'profileInOrg'
        case 2: return 'organisation'
        case 3: return 'settings'
      }
    }
    switch (type) {
      case 'profile': return 0
      case 'profileInOrg': return 1
      case 'organisation': return 2
      case 'settings': return 3
    }
  }

  const panes = [
    {
      menuItem: { key: 'users', content: 'Global Profile' },
      render: () => <Tab.Pane style={{ border: '0px' }}><Profile /></Tab.Pane>,
    },
    {
      menuItem: {
        key: 'profileInOrg',
        content: HWCtt?.data?.HW_DEFAULT?.organisation?.name ?
          `Profile in ${HWCtt.data.HW_DEFAULT.organisation.name}` :
          'Profile in Organisation'
      },
      render: () => <Tab.Pane style={{ border: '0px' }}>
        {HWCtt.data?.HW_DEFAULT?.organisation?.id ?
          <ProfileInOrg oid={HWCtt.data.HW_DEFAULT.organisation.id} /> :
          <div style={{ marginTop: '2em' }}>
            <GridCenter>
              <center>
                <Icon name='users' size='huge' />
              </center>
              <p>
                To see this content you need to have an open organisation, please select from the top menu
              </p>
            </GridCenter>
          </div>
        }
      </Tab.Pane>,
    },
    {
      menuItem: { key: 'organisation', content: 'Organisations' },
      default: true,
      render: () => <Tab.Pane style={{ border: '0px' }}><Organisations /></Tab.Pane>,
    },
    {
      menuItem: { key: 'settings', content: 'Settings' },
      render: () => <Tab.Pane style={{ border: '0px' }}></Tab.Pane>,
    },
  ]

  return (
    <>
      <Grid container columns='equal'>
        <Grid.Column mobile={16} tablet={1} computer={3}></Grid.Column>
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <h2>Personal Account</h2>
          <Tab
            panes={panes}
            onTabChange={(e, data) => {
              const tab = getIndexByString(data.activeIndex, true);
              setActiveTab(tab);
              window.scrollTo(0, 0)
            }}
            activeIndex={getIndexByString(activeTab, false)}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={1} computer={3}></Grid.Column>
      </Grid>
    </>
  );
}

export default PersonalHome;