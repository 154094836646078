import { navigate } from '@reach/router';
import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { useModalHook } from 'services/hooks/useModalHook'
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';

function ArchiveQuestion({ oid }: any) {

  const { functions: { closeModal, getModalData } } = useModalHook()
  const { functions: { confirmArchiveQuestion } } = useQuestionsHook(oid);
  const { question, id, redirect } = getModalData()

  return (
    <div>
      <div style={{ marginBottom: '3em' }}>
        <p>Are you sure you want to archive this Question?</p>
        <span> "{question.question}" </span>
      </div>
      <Grid centered>
        <Button
          color='blue'
          onClick={closeModal}
        >
          No
          </Button>
        <Button
          color='red'
          onClick={() => {
            confirmArchiveQuestion(id, question);
            closeModal()
            if (redirect) navigate(`/app/org/${oid}/discussions/${question.projId}`)
          }
          }
        >
          Yes, archive
          </Button>
      </Grid>
    </div>
  );
}

export default ArchiveQuestion;