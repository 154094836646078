import React from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'

export function ModalConfirm(props: any) {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      closeOnDimmerClick={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size={'mini'}
      trigger={props.children}
    >
      <Modal.Header>{props.header || 'Confirm Action'}</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Header>{props.title || 'Are you sure you want to continue?'}</Header>
          <p style={{ width: '280px' }}>
            {props.description || 'Please select if you want to continue or cancel'}
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            props.callback()
          }}
          primary={props.primary}
          secondary={props.secondary}
        >
          {props.confirmText || 'Yes'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
