import React from 'react';
import { Card, Header } from 'semantic-ui-react';

export const CardChart = ({ children, title, height = '160px' }: any) =>
  <Card fluid>
    <Card.Content>
      <Card.Header>{title} </Card.Header>
    </Card.Content>
    <Card.Content style={{ height: height, marginBottom: '2em' }}>
      {children}
    </Card.Content>
  </Card>;

export const CardChartDescription = ({ children }: any) =>
  <div style={{ height: '40px' }}>
    {children}
  </div>

export const AvgTitle = ({ children }: any) =>
  <Header
    as='h1'
    style={{
      textAlign: 'center',
      marginTop: '1.5em',
    }}>
    {children}
  </Header>

export const AvgSubtitle = ({ title, subtitle }: any) =>
  <>
    <h4
      style={{
        textAlign: 'center',
        marginTop: '1.5em',
        marginBottom: '0.5em',
        color: '#0098FA',
      }}>
      {title}
    </h4>
    <p style={{ textAlign: 'center' }}>
      {subtitle}
    </p>
  </>
