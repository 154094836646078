import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useModalHook } from 'services/hooks/useModalHook';
import { useProjectHook } from 'services/hooks/useProjectHook';
import { navigate } from '@reach/router';
import { BadgeUser } from '../../Badges';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import { SuspenseWithPerf } from 'reactfire';
import { Loading } from '../../Loading';

function ProjectWithoutAccessCore({ oid }: any) {

  const { data: { modalData }, functions: { closeModal } } = useModalHook();
  const { functions: { userHaveSomeAccessInProject } } = useProjectHook(oid);
  const { functions: { usersById } } = useOrganisationHook(oid);
  const { functions: { validateAccessHelper }, data: { accessLive } } = useProjectHook(oid);

  const [haveInvitation, setHaveInvitation]: any = useState(false);
  const [haveAccess, setHaveAccess]: any = useState(false);
  const [projectOwner, setProjectOwner]: any = useState(null);

  useEffect(() => {
    setHaveAccess(
      validateAccessHelper(modalData.projectId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessLive]);

  useEffect(() => {
    if (haveInvitation === false) {
      setHaveInvitation(userHaveSomeAccessInProject(modalData.projectId))
    }

    if (projectOwner === null) {
      if (modalData?.projectData?.createdBy) setProjectOwner(modalData.projectData.createdBy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      { haveInvitation &&
        <>
          <div style={{ textAlign: 'center' }}>
            <Icon name='check' color='green' size='huge' />
          </div>
          <h3>
            {
              !haveAccess ?
                "This could take a few seconds or minutes" :
                "Your access it's ready"
            }
          </h3>
          <p>
            The owner of this discussion successfully added you to join, but we are still synchronizing the permissions, If this process does not appear to end please try refreshing your browser.
          </p>
          <div style={{ textAlign: 'center' }}>
            {haveAccess ?
              <Button color='green'><Icon name='thumbs up' /> Permissions ready </Button> :
              <Button color='yellow'><Icon name='spinner' loading /> Syncing permissions </Button>
            }
            <Button
              primary
              disabled={!haveAccess}
              onClick={() =>
                navigate(`/app/org/${oid}/discussions/${modalData.projectId}`)
                && closeModal()}
            >
              <Icon name='arrow circle up' /> Open Discussion
            </Button>
          </div>
        </>
      }
      {
        !haveInvitation &&
        <>
          <div style={{ textAlign: 'center' }}>
            <Icon name='lock' color='black' size='huge' />
          </div>
          <h3> You don't have access to this discussion</h3>
          <p>
            This discussion is publicly visible within the organisation. If you wish to be involved in the discussion please contact the owner.
          </p>
          { projectOwner &&
            <div style={{ textAlign: 'center' }}>
              <Button>
                <BadgeUser uid={projectOwner} oid={oid} />
                &nbsp; &nbsp; {usersById(projectOwner)?.displayName}
              </Button>
            </div>
          }
        </>
      }
    </div >
  );
}

export function ProjectWithoutAccess(props: any) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId={`howwee-project-without-access-${props.oid}`} >
      <ProjectWithoutAccessCore {...props} />
    </SuspenseWithPerf>
  )
}