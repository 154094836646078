import React from 'react';
import GridCenter from 'pages/App/components/GridCenter'
import { Divider } from 'semantic-ui-react';
import HistoryContentDiscussion from './Content/HistoryContentDiscussion'
import { TitleQuestion, PrefixTitleQuestion } from './styles'
import { useModalHook } from 'services/hooks/useModalHook'

export function HistoryDiscussion({ oid, isModal }: any) {

  const { functions: { getModalData } } = useModalHook()
  const { project } = getModalData()

  return (
    <>
      <GridCenter fullWidth={isModal}>
        <PrefixTitleQuestion> {project?.name || 'Discussion'}</PrefixTitleQuestion>
        <TitleQuestion> All the activity in this Discussion</TitleQuestion>
        <Divider />
        <HistoryContentDiscussion projId={project.id} oid={oid} />
      </GridCenter>
    </>
  );
}

