import GridCenter from 'pages/App/components/GridCenter';
import React from 'react';
import { BadgeUser, BadgeMultipleSubject, } from 'pages/App/components/Badges'
import { Grid, Card, Icon, Dimmer, Loader, Dropdown, } from 'semantic-ui-react';
import { ProjectCard, ProjectFoot, BackgroundImage, GradientBackground, ProjectVisibility, ProjectSettings } from './styles';
import { useImmer } from 'use-immer';
import { navigate } from '@reach/router';
import { projectArrayType, projectType } from 'typings/projects';
import { orgId, projectId } from 'typings/generic';

interface Props {
  oid: typeof orgId;
  loading: boolean;
  discussions: projectArrayType;
  openDiscussion: (discussion: projectType, projId: typeof projectId) => void;
  validateProjectAccess: (discussion: projectType, projId: typeof projectId) => void;
}

function DiscussionsGrid({ oid, loading, openDiscussion, discussions, validateProjectAccess }: Props) {

  const [hoverCard, setHoverCard]: any = useImmer({});

  return (
    <>
      <GridCenter fullWidth>
        <Grid style={{ margin: '0em 0.5em' }}>
          <Grid.Row style={{ marginLeft: '6.25%' }}>
            {discussions.map((discussion: any, key: any) => {
              return (
                <Grid.Column key={key} mobile={16} tablet={8} computer={5}>
                  <ProjectCard
                    onMouseEnter={() =>
                      setHoverCard((draft: any) => {
                        draft[discussion.id] = true;
                      })
                    }
                    onMouseLeave={() =>
                      setHoverCard((draft: any) => {
                        draft[discussion.id] = false;
                      })
                    }
                    onClick={() => {
                      if (!validateProjectAccess(discussion, discussion.id)) {
                        openDiscussion(discussion, discussion.id)
                      }
                    }
                    }>
                    {
                      validateProjectAccess(discussion, discussion.id) &&
                      <Dimmer active>
                        <Loader inverted content='Loading Access' />
                      </Dimmer>
                    }
                    <Card.Content style={{ padding: '0em' }}>

                      {
                        discussion.coverImageURL &&
                        <BackgroundImage
                          src={discussion.coverImageURL}
                          alt='background project'
                        />
                      }

                      <ProjectVisibility>
                        {
                          discussion.visibility === 'private' &&
                          <Icon name='lock' />
                        }
                        {
                          discussion.visibility === 'public' &&
                          <Icon name='bullhorn' />
                        }
                      </ProjectVisibility>
                      {
                        hoverCard[discussion.id] &&
                        <ProjectSettings>
                          <Dropdown
                            icon='ellipsis vertical'
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                text='Edit'
                                onClick={() => navigate(`discussions/${discussion.id}/edit`)}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </ProjectSettings>
                      }

                      {
                        !discussion.coverImageURL &&
                        <GradientBackground />
                      }

                      <ProjectFoot>
                        <div style={{ marginBottom: '1em' }}>
                          <div style={{ marginRight: '1em', display: 'inline' }}>
                            <BadgeUser uid={discussion.createdBy} oid={oid} />
                          </div>
                          <span>{discussion.name}</span>
                        </div>
                        <BadgeMultipleSubject subjects={discussion.subjects} oid={oid} />
                      </ProjectFoot>
                    </Card.Content>
                  </ProjectCard>
                </Grid.Column>
              )
            })}
          </Grid.Row>
        </Grid>
      </GridCenter>
    </>
  );
}

export default DiscussionsGrid;