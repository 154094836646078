import React from 'react';
import { Icon } from 'semantic-ui-react';
import { CloseButton } from './styles'

export function Header({ closeWindow }: any) {
  return (
    <div style={{ zIndex: 10, position: 'fixed', width: '100%', marginBottom: '20px', height: 'auto', paddingRight: '30px' }}>
      <div style={{ float: 'right' }}>
        <CloseButton
          onClick={closeWindow}
        >
          <Icon
            name="close"
            style={{ margin: '0 auto' }}
          />
        </CloseButton>
      </div>
    </div>
  );
}