import { useUser } from 'reactfire'
import { useFirestoreHook } from './useFirestoreHook'
import { toast } from 'react-toastify';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import { LexoRank } from 'lexorank';
import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'
import { useHistoryHook } from 'services/hooks/useHistoryHook'
import { useProjectHook } from 'services/hooks/useProjectHook';
import { useModalHook } from 'services/hooks/useModalHook'
import { useContext } from 'react';
import * as firebase from 'firebase/app'
import 'firebase/firestore';

export function useQuestionsHook(oid: string) {

  const { uid }: any = useUser();
  const { data: { projectsDocs } } = useProjectHook(oid)
  const { functions: { openModal } } = useModalHook()
  const { data: { SDK }, collections: { HWFS }, functions: { addDoc } } = useFirestoreHook();
  const searchCtt: any = useContext(SearchGlobalContext);

  const { functions: {
    nhr_questions_answerSelected,
    nhr_questions_new,
    nhr_questions_open,
    nhr_questions_archived_from_draft,
    nhr_questions_archived_from_answerSelected,
    nhr_questions_archived_from_readyForReview,
    nhr_questions_draft_to_open,
    nhr_questions_ready_for_review,
    nhr_questions_unarchived, } } = useHistoryHook(oid);
  const { functions: { isPartOfSomeTeam } } = useOrganisationHook(oid)


  const statusGeneral = {
    'draft': 201, // 1# Not Public, only for owner
    'open': 204, // 2# Open and with/without comments or answered
    'answered': 200, // 3# "Ready for Review", with 1 or more comments
    'selected': 203,  // 4#  1 comment selected like answer
    'archived': 410, // Other5# Archived, available for users
    'deleted': 404 // Other6# Deleted Definitive
  }

  const statusStringObj = {
    201: 'Draft',
    204: 'Open',
    200: 'Ready for Review',
    203: 'Answer Selected',
    410: 'Archived',
    404: 'Deleted'
  }

  const statusStringHandler = {
    get: function (target: any, name: any) {
      return target.hasOwnProperty(name) ? target[name] : '¡Error!';
    }
  }

  const statusString = new Proxy(statusStringObj, statusStringHandler);


  const createQuestion = (projId: string, data: any) => {
    return addDoc(HWFS.questions(oid, projId), data).then((res: any) => {
      if (data.status === 201) {
        nhr_questions_new(res.id, {}, projId)
      } else if (data.status === 204) {
        nhr_questions_open(res.id, {}, projId)
      }
    });
  }

  const defineAnswer = (questionId: string, comment: any, projId: string) => {
    toast.success('Comment selected as answer correctly')
    nhr_questions_answerSelected(questionId, { ownerAnswer: comment.createdBy, commentId: comment.id }, projId);

    return HWFS.questions(oid, projId).doc(questionId).update(
      {
        answer: { ...comment },
        status: 203,
        createdAtAnswer: firebase.firestore.FieldValue.serverTimestamp(),
      })
  }

  const removeAnswer = (questionId: string, projId: string) => {
    toast.info('Comment removed as answer')
    return HWFS.questions(oid, projId).doc(questionId).update({ answer: {}, status: 200 })
  }

  const updateQuestion = (projId: string, id: string, data: any, prevData: any = null) => {


    // NHR ===>
    if (data.status && prevData && prevData.status) {
      // Draft to Open
      if (prevData.status === 201 && prevData.status === 204) {
        nhr_questions_draft_to_open(id, {}, projId)
      }
    }

    if (data.status === 200) {
      nhr_questions_ready_for_review(id, {}, projId)
    }
    // <=== NHR

    return HWFS.questions(oid, projId).doc(id).update({ ...data }).then((res: any) => {

    }).catch((err: any) => {
      console.error(err)
    })
  }

  const archiveQuestion = (id: string, question: any, redirect: boolean = false) => {
    openModal('archiveQuestion', { question: question, id: id, redirect: redirect });
  }

  const confirmArchiveQuestion = (id: string, question: any) => {
    if (question.createdBy !== uid) return null;

    toast.info('Question archived correctly');

    switch (question.status) {
      case 201: nhr_questions_archived_from_draft(id, {}, question.projId); break
      case 203: nhr_questions_archived_from_answerSelected(id, {}, question.projId); break
      case 200: nhr_questions_archived_from_readyForReview(id, {}, question.projId); break
    }

    return HWFS.questions(oid, question.projId).doc(id).update({ status: statusGeneral.archived }).then((res: any) => {
    }).catch((err: any) => {
      console.error(err)
    })
  }

  const unarchiveQuestion = (id: string, question: any) => {
    if (question.createdBy !== uid) return null;

    nhr_questions_unarchived(id, {}, question.projId);
    toast.info('Question restored correctly');

    return HWFS.questions(oid, question.projId).doc(id).update({ status: statusGeneral.open }).then((res: any) => {
    }).catch((err: any) => {
      console.error(err)
    })
  }

  const statusToTitle = (status: number) => {
    let response: string = '';
    Object.keys(statusGeneral).map((titleTmp: any, statusTmp: any) => {
      if (statusTmp.toString() === status.toString()) {
        response = titleTmp;
      }
      return true;
    });

    return response;
  }

  const titleToStatus = (title: string) => {
    let response: any;
    Object.keys(statusGeneral).map((titleTmp: any) => {
      if (titleTmp === title) {
        response = statusGeneral[titleTmp] || '';
      }

      return true;
    });

    return response;
  }

  const likeQuestion = (questionId: string, projId: string) => {
    HWFS.questions(oid, projId)
      .doc(questionId)
      .set({ likes: SDK.arrayUnion(uid) }, { merge: true });
  }

  const dislikeQuestion = (questionId: string, projId: string) => {
    HWFS.questions(oid, projId)
      .doc(questionId)
      .set({ likes: SDK.arrayRemove(uid) }, { merge: true });
  }

  const userHaveSomeAccessInQuestion = (questionData: any) => {

    if (
      questionData.createdBy === uid
      || questionData.users.includes(uid)
      || isPartOfSomeTeam(questionData.teams, uid)
    ) {
      return true;
    }

    return false
  }

  const getLexorank = (questionsInGroup: Array<any>, newIndex: number, oldIndex: number, questionInfo: any) => {

    let newLexo: any;

    if (questionsInGroup[newIndex - 1] && questionsInGroup[newIndex + 1]) {
      const lexoPrev: string = questionsInGroup[newIndex - 1].lexorank;
      const lexoNext: string = questionsInGroup[newIndex + 1].lexorank;
      const lexoPrevObj = LexoRank.parse(lexoPrev);
      const lexoNextObj = LexoRank.parse(lexoNext);

      if (lexoPrevObj.toString() === lexoNextObj.toString()) return questionInfo.lexorank
      newLexo = lexoPrevObj.between(lexoNextObj).toString();

    } else if (questionsInGroup[newIndex - 1]) {
      const lexoPrev: string = questionsInGroup[newIndex - 1].lexorank;
      const lexoPrevObj = LexoRank.parse(lexoPrev);

      newLexo = lexoPrevObj.genNext();

    } else if (questionsInGroup[newIndex + 1]) {
      const lexoNext: string = questionsInGroup[newIndex + 1].lexorank;
      const lexoNextObj = LexoRank.parse(lexoNext);

      newLexo = lexoNextObj.genPrev();
    } else {
      newLexo = LexoRank.middle();
    }

    if (newIndex === oldIndex) return questionInfo.lexorank;
    return newLexo.toString();
  }

  const allQuestionsInOrg = () => {
    return new Promise((resolve, reject) => {
      let questions: Array<any> = [];

      if (projectsDocs.length === 0) resolve(questions)

      projectsDocs.map(async (project: any, index: number) => {

        if (project.id) {
          await HWFS.questions(oid, project.id).get().then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
              let info = doc.data();
              if (info && doc.id) {
                let payload = { id: doc.id, ...info }
                try {
                  questions.push(payload)
                } catch (err) {
                  console.error(err)
                }
              }
            });
          })
        }

        if (index + 1 === projectsDocs.length) {
          resolve(questions)
        }

        return null
      })
    })
  }

  const openQuestionFloated = (question: any) => {

    if (question.status === 201) {
      openModal('editQuestion', { question: question });
      return null
    }

    if (searchCtt.searchResultsQuestionsOpen.includes(question.id)) {
      searchCtt.setSearchData((draft: any) => {
        draft.searchResultsQuestionsOpen = draft.searchResultsQuestionsOpen.filter((questId: string) => {
          return questId !== question.id;
        });
      });
    } else {
      searchCtt.setSearchData((draft: any) => {
        draft.searchResultsQuestionsOpen = [
          ...draft.searchResultsQuestionsOpen,
          question.id
        ]
        draft.searchResultsQuestionsOpenExt = {
          ...draft.searchResultsQuestionsOpenExt,
          [question.id]: question.projId,
        }
      });
    }
  }

  const closeQuestionsFloated = () => {
    searchCtt.setSearchData((draft: any) => {
      draft.searchResultsQuestionsOpen = draft.searchResultsQuestionsOpen = [];
    });
  }

  return {
    info: {
      statusString,
      statusGeneral
    },
    collections: {
    },
    functions: {
      openQuestionFloated,
      closeQuestionsFloated,

      allQuestionsInOrg,

      createQuestion,
      updateQuestion,

      defineAnswer,
      removeAnswer,

      titleToStatus,

      likeQuestion,
      dislikeQuestion,

      statusToTitle,

      archiveQuestion,
      unarchiveQuestion,
      confirmArchiveQuestion,

      userHaveSomeAccessInQuestion,

      getLexorank
    }
  }
}