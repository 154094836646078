import React, { useState, useEffect } from 'react';
import GridCenter from 'pages/App/components/GridCenter'
import { Form, Button, Dropdown, Icon, Input } from 'semantic-ui-react'
import { useForm } from "react-hook-form";
import { AlertInput } from 'pages/App/components/AlertInput'
import { SuccessModal } from 'pages/App/components/Success'
import { useImmer } from 'use-immer'
import capitalize from 'capitalize'
import { TextUnderline, TextGrey, LinkTextUnderline } from './styles'
import { useQuestionsHook } from 'services/hooks/useQuestionsHook'
import SelectorSubjects from 'pages/App/components/Dropdowns/SelectorSubjects'
import SelectorTeams from 'pages/App/components/Dropdowns/SelectorTeams'
import SelectorUsers from 'pages/App/components/Dropdowns/SelectorUsers'
import { useModalHook } from 'services/hooks/useModalHook'
import { toast } from 'react-toastify';
import { LexoRank } from "lexorank";
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';
import { useFirestoreCollectionData, SuspenseWithPerf } from 'reactfire';
import SelectorProject from 'pages/App/components/Dropdowns/SelectorProject';
import { useProjectHook } from 'services/hooks/useProjectHook';
import { Loading } from 'pages/App/components/Loading';
import { orgId, projectId, questionId, subjectId, teamId, userId } from 'typings/generic';
import { questionsArrayType, questionPrefixEnum } from 'typings/questions';
import { projectType } from 'typings/projects';

interface Props {
  path: string;
  oid: typeof orgId;
  projId: typeof projectId;
  isModal?: boolean;
  setOpenModal: (val: boolean) => void;
  editQuestion?: boolean;
  questions: questionsArrayType;
}

function EditQuestionComponent({
  path,
  oid,
  projId,
  isModal,
  setOpenModal,
  editQuestion = false,
  questions }: Props
) {

  // UI States
  const [loading, setLoading] = useState<boolean>(false);
  // --- Only in case of not modal
  const [completed, setCompleted] = useState<boolean>(false);
  // --- Only in case of edit scenario
  const [editQuestionId, setEditQuestionId] = useState<typeof questionId>('');

  // UI Triggers
  const [showSelectorSubjects, setShowSelectorSubjects] = useState<boolean>(false);
  const [showSelectorTeams, setShowSelectorTeams] = useState<boolean>(false);
  const [showSelectorUsers, setShowSelectorUsers] = useState<boolean>(false);

  // UI Buttons
  const [disableCommit, setDisableCommit] = useState<boolean>(true);
  const [disableSave, setDisableSave] = useState<boolean>(true);

  // Hooks
  const { register, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' });
  const { functions: { createQuestion, updateQuestion, archiveQuestion } } = useQuestionsHook(oid);
  const { functions: { getModalData, closeModal } } = useModalHook()
  const { functions: { addDoc } } = useFirestoreHook();
  const { functions: { projectById } } = useProjectHook(oid);

  // Dropdown States
  const [usersForQ, setUsersForQ] = useState<Array<typeof userId>>([]);
  const [teamsForQ, setTeamsForQ] = useState<Array<typeof teamId>>([]);
  const [subjectsForQ, setSubjectsForQ] = useState<Array<typeof subjectId>>([]);
  const [projectForQ, setProjectForQ] = useState<typeof projectId | null>(projId || null)
  const [projectData] = useState<projectType | null>(projectById(projId))
  const [questionPrefix, setQuestionPrefix] = useState<keyof typeof questionPrefixEnum>('how_do_wee');
  const [questionTitle, setQuestionTitle] = useState<string>('');

  const { collections: { HWFS } } = useFirestoreHook();
  const subjectsRef = HWFS.subjects(oid);
  const subjectsDocs = useFirestoreCollectionData(subjectsRef, { idField: 'id' });


  // Types
  const optionsBase: Array<string> = ['high', 'medium', 'low']
  const optionsBaseIcons: Array<string> = ['arrow up', 'arrow right', 'arrow down'];

  // Immer
  const [formHelper, setFormHelper] = useImmer({ priority: 'medium' });

  // =======
  // Effects
  // >>>>>>>
  useEffect(() => {
    // Check if modal is for edit or for create
    // In the case of edit, always get the question data from Modal Context
    if (editQuestion) {
      setLoading(true);
      const modalData: any = getModalData();

      if (modalData.question) {
        const openQuestion = modalData.question;

        setProjectForQ(openQuestion.projId);
        setEditQuestionId(openQuestion.id);

        setShowSelectorSubjects(true);
        setShowSelectorTeams(true);
        setShowSelectorUsers(true);

        setQuestionPrefix(openQuestion.questionPrefix)

        setTeamsForQ(openQuestion.teams)
        setUsersForQ(openQuestion.users)
        setSubjectsForQ(openQuestion.subjects)
        setQuestionTitle(openQuestion.question)
        setValue('description', openQuestion.description)
        setFormHelper((draft: any) => { draft.priority = openQuestion.priority })

        setLoading(false);
        setDisableSave(false);
        if (openQuestion.users && openQuestion.users.length > 0) setDisableCommit(false)
        if (openQuestion.teams && openQuestion.teams.length > 0) setDisableCommit(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!editQuestionId) {
      setDisableSave(!formState.isValid)
      setDisableCommit(!formState.isValid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    if (
      (teamsForQ.length > 0 && showSelectorTeams === true) ||
      (usersForQ.length > 0 && showSelectorUsers === true)
    ) {
      setDisableCommit(false)
    } else {
      setDisableCommit(false) // old : true
    }
  }, [usersForQ, teamsForQ, showSelectorUsers, showSelectorTeams]);
  // <<<<<<<
  // Effects
  // =======

  // =========
  // Functions
  // >>>>>>>>>
  const getIconPriority = (priority: string) => {
    switch (priority) {
      case 'high': return optionsBaseIcons[0]
      case 'medium': return optionsBaseIcons[1]
      case 'low': return optionsBaseIcons[2]
    }
  }

  const createNewSubjects = () => {
    return new Promise((resolve, reject) => {
      const result: Array<any> = [];
      if (subjectsForQ.length === 0) resolve(result);

      subjectsForQ.map((subjectValue: any) => {
        if (subjectsDocs.filter((subject: any) => subject.id === subjectValue).length === 0) {
          const payload = {
            name: subjectValue.replace(/ /g, "").toLowerCase(),
            text: subjectValue.replace(/ /g, "")
          }
          addDoc(subjectsRef, payload).then((res: any) => {
            result.push(res.id);
            if (result.length === subjectsForQ.length) resolve(result);
          })
        } else {
          result.push(subjectValue)
          if (result.length === subjectsForQ.length) resolve(result);
        }

        return null;
      });
    })
  }

  const submitQuestion = (data: any, event?: any, status?: number) => {

    if (!projectForQ || projectForQ.length === 0) {
      toast.warning('Please select a discussion first')
      return null;
    }

    setLoading(true);

    if (status !== 201 && status !== 204) {
      status = 201;
    }

    createNewSubjects().then((subjectsParsed) => {
      const payload: any = {
        ...data,
        ...formHelper,
        usersWithComments: [],
        question: questionTitle,
        questionPrefix: questionPrefix,
        status: status,
        subjects: subjectsParsed,
        teams: teamsForQ,
        users: usersForQ,
        projId: projectForQ,
      };

      if (editQuestion) {
        updateQuestion(projectForQ, editQuestionId, payload).then(() => {
          setLoading(false);
          setCompleted(true);
          closeModal();
          toast.success(<><Icon name='check' /> Question edited correctly </>);
        }).catch((err: any) => {
          console.error(err)
        })
      } else {

        // Lexorank ==>
        let lexo = LexoRank.middle();
        if (questions.length > 0) {
          let lastLexo = questions[0].lexorank;
          lexo = LexoRank.parse(lastLexo).genNext();
        }
        // <== Lexorank

        let test = { lexorank: lexo.toString(), ...payload }

        createQuestion(projectForQ, test).then((res: any) => {
          setLoading(false);
          setCompleted(true);

          if (setOpenModal) {
            setOpenModal(false);
            toast.success(<><Icon name='check' /> Question created correctly </>);
          }
        }).catch((err) => {
          console.error(err)
        })
      }

    })
  }

  const optionsPriority = () => {
    const result: Array<any> = [];
    let count = 0;
    optionsBase.forEach((col: string, key: number) => {
      count++;
      result.push({
        key: count,
        text: col,
        value: col,
        content: (
          <>
            <Icon name={optionsBaseIcons[key]} />
            {col.charAt(0).toUpperCase() + col.slice(1)}
          </>
        ),
      })
    });

    return result;
  }
  // <<<<<<<<<
  // Functions
  // =========

  // ==========
  // Components
  // >>>>>>>>>>
  const ButtonsEdit = () => {
    if (editQuestionId) {
      return (
        <>
          <Button
            icon='archive'
            onClick={() => {
              const modalData: any = getModalData();
              archiveQuestion(editQuestionId, modalData.question)
              closeModal();
            }}
            style={{ float: 'left' }}
          />
        </>
      )
    }
    return (<></>)
  }

  const Buttons = () => {
    return (
      <>
        <Button
          disabled={disableCommit}
          onClick={handleSubmit((data) => submitQuestion(data, null, 204))}
          style={{ float: 'right' }}
          primary
        >
          Ask Question
          </Button>
        <Button
          disabled={disableSave}
          onClick={handleSubmit((data) => submitQuestion(data, null, 201))}
          style={{ float: 'right' }}
          primary
        >
          Save Changes
        </Button>
      </>
    )
  }
  // <<<<<<<<<
  // Components
  // ==========

  if (completed) {
    return (<SuccessModal description='Question created correctly' />)
  }

  return (
    <>
      <GridCenter fullWidth={isModal}>
        <Form loading={loading}>
          <Form.Group>
            <Form.Field width={16}>
              <Input
                label={
                  <Dropdown
                    onChange={(e, { value }: any) => setQuestionPrefix(value)}
                    value={questionPrefix}
                    defaultValue='how_do_wee'
                    options={[
                      { key: 'how_do_wee', value: 'how_do_wee', text: 'How do wee' },
                      { key: 'how', value: 'how', text: 'How' },
                      { key: 'why', value: 'why', text: 'Why' },
                      { key: 'what', value: 'what', text: 'What' },
                      { key: 'when', value: 'when', text: 'When' },
                      { key: 'where', value: 'where', text: 'Where' },
                    ]} />}
                labelPosition='left'
                placeholder=''
                value={questionTitle}
                onChange={(e, { value }: any) => setQuestionTitle(value)}
              >
                {/* <input
                  name="question"
                  type="text"
                  autoComplete="off"
                  ref={register({ required: true })}
                /> */}
              </Input>
              {/* {errors.question && <AlertInput message="Please write your question" />} */}
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={16}>
              <label>Discussion</label>
              <SelectorProject
                oid={oid}
                initial={projectForQ}
                setResult={setProjectForQ}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ display: 'none' }}>
            <Form.Field width={1} onClick={() => setShowSelectorUsers(!showSelectorUsers)}>
              <Icon name='user' />
            </Form.Field>
            <Form.Field width={15}>
              {showSelectorUsers ?
                <SelectorUsers
                  oid={oid}
                  setResult={setUsersForQ}
                  initial={usersForQ}
                  filter={projectData}
                />
                :
                <LinkTextUnderline onClick={() => setShowSelectorUsers(!showSelectorUsers)}>
                  <TextUnderline>Which individuals should answer? </TextUnderline>
                  <TextGrey> + </TextGrey>
                </LinkTextUnderline>
              }
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ display: 'none' }}>
            <Form.Field width={1} onClick={() => setShowSelectorTeams(!showSelectorTeams)}>
              <Icon name='users' />
            </Form.Field>
            <Form.Field width={15}>
              {showSelectorTeams ?
                <SelectorTeams
                  oid={oid}
                  setResult={setTeamsForQ}
                  initial={teamsForQ}
                  filter={projectData}
                />
                :
                <LinkTextUnderline onClick={() => setShowSelectorTeams(!showSelectorTeams)} >
                  <TextUnderline>Which teams should answer? </TextUnderline>
                  <TextGrey> + </TextGrey>
                </LinkTextUnderline>
              }
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ display: 'none' }}>
            <Form.Field width={1} onClick={() => setShowSelectorSubjects(!showSelectorSubjects)}>
              <Icon name='tag' />
            </Form.Field>
            <Form.Field width={15}>
              {showSelectorSubjects ?
                <SelectorSubjects
                  oid={oid}
                  initial={subjectsForQ}
                  setResult={setSubjectsForQ}
                />
                :
                <LinkTextUnderline onClick={() => setShowSelectorSubjects(!showSelectorSubjects)}>
                  <TextUnderline>Which subjects does this cover? </TextUnderline>
                  <TextGrey> + </TextGrey>
                </LinkTextUnderline>
              }
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Subjects</label>
            <SelectorSubjects
              oid={oid}
              initial={subjectsForQ}
              setResult={setSubjectsForQ}
            />
          </Form.Field>
          <Form.Field>
            <label>Priority</label>
            <Dropdown
              className='ui basic button'
              options={optionsPriority()}
              trigger={
                <>
                  <span style={{ marginRight: '60px' }} >
                    <Icon name={getIconPriority(formHelper.priority)} style={{ marginLeft: '-10px' }} />
                    {
                      formHelper.priority ?
                        capitalize(formHelper.priority) :
                        ''
                    }
                  </span>
                </>
              }
              onChange={(e, { name, value }: any) => {
                setFormHelper((draft) => {
                  draft.priority = value;
                })
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Additonal context</label>
            <textarea
              placeholder="Give your audience some context"
              rows={5}
              name="description"
              ref={register()}
            >
            </textarea>
            {errors.description && <AlertInput message="Please write a brief description" />}
          </Form.Field>
          <ButtonsEdit />
          <Buttons />
        </Form>
      </GridCenter >
    </>
  );
}

function EditQuestion(props: any) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId="howwee-edit-question">
      <EditQuestionComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default EditQuestion;