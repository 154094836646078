import React from 'react';
import BadgeTeam from './BadgeTeam'

interface Props {
  teams: Array<any>;
  oid: string;
}

function BadgeMultipleTeam({ teams, oid }: Props) {
  return (
    <>
      {
        teams.map((team: any, index: number) => {
          return <BadgeTeam key={index} teamId={team} oid={oid} />
        })
      }
    </>
  )
}

export default BadgeMultipleTeam;