import React, { useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { useModalHook } from 'services/hooks/useModalHook'
import { useProjectHook } from 'services/hooks/useProjectHook';

function ArchiveDiscussion({ oid }: any) {

  const { functions: { closeModal, getModalData } } = useModalHook()
  const { functions: { confirmArchiveDiscussion } } = useProjectHook(oid);
  const { discussion, id } = getModalData()
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <div style={{ marginBottom: '3em' }}>
        <p>Are you sure you want to archive this Discussion?</p>
        <span> "{discussion.name}" </span>
      </div>
      <Grid centered>
        <Button
          color='blue'
          disabled={loading}
          onClick={closeModal}
        >
          No
          </Button>
        <Button
          color='red'
          loading={loading}
          onClick={() => {
            setLoading(true)
            confirmArchiveDiscussion(id).then(() => {
              closeModal()
            })
          }}
        >
          Yes, archive
          </Button>
      </Grid>
    </div>
  );
}

export default ArchiveDiscussion;