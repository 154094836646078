import React, { useEffect, useState } from 'react';
import { Input, Grid, Dropdown, Table, Button, Image, Popup, Form, Dimmer, Loader, Icon } from 'semantic-ui-react'
import SelectorUsers from 'pages/App/components/Dropdowns/SelectorUsers'
import GridCenter from 'pages/App/components/GridCenter'
import { Link, navigate } from '@reach/router';
import { useFirestoreCollectionData, SuspenseWithPerf } from 'reactfire'
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'
import { Loading } from 'pages/App/components/Loading';
import { useReportsHook } from 'services/hooks/useReportsHook';
import { toast } from 'react-toastify';
import { useModalHook } from 'services/hooks/useModalHook';
import { LinkCustom } from './styles';
import { useImmer } from 'use-immer';
import Header from './Header'
import { useProjectHook } from 'services/hooks/useProjectHook';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';

function DashboardReportsComponent({ path, oid, projId = null }: any) {

  const [authorFilter, setAuthorFilter] = useState([]);
  const [reportFilter, setReportFilter] = useState('')
  const [loading, setLoading] = useState(true);
  const [questionDocs, setQuestionDocs] = useImmer({ data: [] });

  const { collections: { HWFS } } = useFirestoreHook();
  const { functions: { openModal } } = useModalHook()
  const { functions: { photoUserByID, usersById } } = useOrganisationHook(oid);
  const { functions: { isOwner, allReportsInOrg, deleteReport, userHaveSomeAccessInReport, reportAccessExtended } } = useReportsHook(oid)
  const { functions: { projectById } } = useProjectHook(oid);

  let reportsRef: any;

  if (projId) {
    reportsRef = HWFS.reports(oid, projId).where('status', '==', 200);
  } else {
    reportsRef = HWFS.reportsOld(oid).where('status', '==', 200);
  }

  const reportsDoc = useFirestoreCollectionData(reportsRef, { idField: 'id' });

  useEffect(() => {
    setLoading(true);
    if (projId) {
      setQuestionDocs((draft: any) => {
        draft.data = reportsDoc;
      })
      setLoading(false);
    }

    if (!projId) {
      allReportsInOrg().then((res: any) => {
        setQuestionDocs((draft: any) => {
          draft.data = res;
        });
        setLoading(false);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsDoc])

  const OptionsReports = ({ report }: any) => {

    const access = reportAccessExtended(report);

    return (
      <Dropdown
        icon='ellipsis vertical'
        style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text='View'
            onClick={() => navigate(`/app/org/${oid}/discussions/${report.projId}/reports/view/${report.id}`)}
          />
          <Dropdown.Item
            text='Edit'
            disabled={!access.editor && !access.owner}
            onClick={() => navigate(`/app/org/${oid}/discussions/${report.projId}/reports/edit/${report.id}`)}
          />
          <Dropdown.Item
            //disabled={!access.editor && !access.owner}
            disabled={true}
            text='Duplicate (Coming Soon)' />
          <Dropdown.Divider />
          <Dropdown.Item
            disabled={!isOwner(report.createdBy)}
            text='Delete'
            onClick={() => deleteReport(report.projId, report.id).then(() => toast.info('Report removed successfully'))}
          />
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const ProjectById = ({ projId }: any) => {
    const proj = projectById(projId)
    const name = proj ? proj.name || 'ERROR!' : '?'
    return (<Link to={`/app/org/${oid}/discussions/${projId}`}> {name} </Link>)
  }

  return (
    <div>
      <Header oid={oid} projId={projId} />
      <GridCenter fullWidth>
        <Grid columns={2}>
          <Grid.Row stretched style={{ marginBottom: '-1em' }}>
            <Grid.Column>
              <p style={{ fontSize: '18px', fontWeight: 500 }}>{!projId && 'All'} Reports</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Field inline>
                  <label>Owned by</label>
                  <SelectorUsers
                    oid={oid}
                    setResult={setAuthorFilter}
                    initial={authorFilter}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Form>
                <Form.Field inline>
                  <label>Filter by title</label>
                  <Input
                    icon='search'
                    placeholder='Search Reports'
                    style={{ width: '100%' }}
                    value={reportFilter}
                    onChange={(e: any) => setReportFilter(e.target.value)}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid style={{ marginTop: '2em' }}>
          <Dimmer inverted active={loading}>
            <Loader inverted>Loading Reports...</Loader>
          </Dimmer>
          <Table basic='very' sortable selectable={false}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Owner</Table.HeaderCell>
                {!projId &&
                  <Table.HeaderCell>Discussion</Table.HeaderCell>
                }
                <Table.HeaderCell>History</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                questionDocs.data.map((report: any, index: number) => {
                  const { name, createdBy } = report;

                  if (report && report.name) {
                    if (name.toLowerCase().includes(reportFilter.toLowerCase()) || reportFilter.length === 0) {
                      if (authorFilter.includes(createdBy) || authorFilter.length === 0) {
                        if (userHaveSomeAccessInReport(report)) {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell
                                style={{ cursor: 'pointer' }} onClick={() => navigate(`/app/org/${oid}/discussions/${report.projId}/reports/view/${report.id}`)}
                              >
                                <LinkCustom>
                                  {report.name}
                                </LinkCustom>
                              </Table.Cell>
                              <Table.Cell>
                                <Popup
                                  basic
                                  content={usersById(report.createdBy).displayName}
                                  trigger={
                                    <Image src={photoUserByID(report.createdBy)} avatar />
                                  }
                                />
                                {usersById(report.createdBy).displayName}
                              </Table.Cell>
                              {!projId &&
                                <Table.Cell>
                                  <ProjectById projId={report.projId} />
                                </Table.Cell>
                              }
                              <Table.Cell textAlign='center'>
                                <LinkCustom onClick={() => openModal('historyReport', { report: report })}>
                                  <Icon
                                    size='large'
                                    name='history'
                                    style={{ color: '#6F7A80', cursor: 'pointer' }}
                                  />
                                </LinkCustom>
                              </Table.Cell>
                              <Table.Cell>
                                <OptionsReports report={report} />
                              </Table.Cell>
                            </Table.Row>
                          )
                        }
                      }
                    }
                  }

                  return null
                })
              }
            </Table.Body>
          </Table>
        </Grid>
        {projId &&
          <Grid style={{ marginTop: '5em' }}>
            <Grid.Row columns={2}>
              <Grid.Column>
              </Grid.Column>
              <Grid.Column>
                <Link to={'new'}>
                  <Button primary content='Add New' style={{ float: 'right' }} />
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      </GridCenter>
    </div >
  );
}

function DashboardReports(props: any) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId={`howwee-organisation-reports-dashboard-${props.oid}`} >
      <DashboardReportsComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default DashboardReports;