import React from 'react';

interface AlertInputProps {
  message?: string
}

export function AlertInput({ message }: AlertInputProps) {
  return (
    <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
      { message ? message : 'Please complete this field'}
    </div>
  );
}