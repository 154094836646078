import React, { createContext, useEffect } from "react";
import { useImmer } from 'use-immer';

const initial = {
  data: {
    sideMenu: false,
    moduleOpen: 'home'
  },
  setData: {},
}

const UIContext = createContext(initial);
const { Consumer } = UIContext;

function UIProvider(props: any): any {

  const [UIData, setUIData]: any = useImmer(initial)

  useEffect(() => {
    setUIData((draft: any) => {
      draft.setData = setUIData;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UIContext.Provider value={UIData} >
      {props.children}
    </UIContext.Provider >
  )

}

export default UIContext;
export {
  UIProvider,
  Consumer as ModalConsumer,
};