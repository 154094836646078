import React, { useContext } from 'react';
import UIContext from 'services/contexts/ContextUI';
import { Menu, Dropdown, } from "semantic-ui-react"
import { MenuItemHW } from '../styles'
import { navigate, } from '@reach/router'

function MenuHW({ oid }: any) {

  const uiCtt: any = useContext(UIContext);

  const getDropdownStyle = (option: string) => {
    if (uiCtt.data.moduleOpen === option) return { color: '#38B1F9', fontWeight: '500' }
    if (uiCtt.data.moduleOpen.split('/')[0] === option) return { color: '#38B1F9', fontWeight: '500' }
    return {}
  }

  const getActiveItem = (path: string) => {
    if (path === uiCtt.data.moduleOpen) return true;
    if (uiCtt.data.moduleOpen.split('/')[0] === path) return true
    return false
  }

  const goTo = (path: string) => {
    uiCtt.setData((draft: any) => { draft.data.moduleOpen = path })

    switch (path) {
      case 'insights':
        navigate(`/app/org/${oid}/insights`)
        break;
      case 'questions':
        navigate(`/app/org/${oid}/questions`)
        break;
      case 'reports':
        navigate(`/app/org/${oid}/reports`)
        break;
      case 'discussions':
        navigate(`/app/org/${oid}/discussions`)
        break;
      case 'discussions/edit':
        navigate(`/app/org/${oid}/discussions/edit`)
        break;
      case 'organisation/manage':
        navigate('/secure/settings/personal?tab=organisation')
        break;
      case 'organisation/profile':
        navigate(`/secure/settings/organisation/${oid}?tab=0`)
        break;
      case 'organisation/new':
        navigate('/secure/new/organisation')
        break;
      case 'organisation/members':
        navigate(`/secure/settings/organisation/${oid}?tab=1`)
        break;
      case 'organisation/teams':
        navigate(`/secure/settings/organisation/${oid}?tab=2`)
        break;
      case 'organisation/my-profile-org':
        navigate(`/app/org/${oid}/settings/profile`)
        break;
    }
  }

  const MenuContent = ({ mobile = false }) =>
    <>
      <MenuItemHW
        position='left'
        active={getActiveItem('questions')}
        onClick={() => { goTo('questions') }}
      >
        <p>Home</p>
      </MenuItemHW>
      <MenuItemHW
        position='left'
        active={getActiveItem('organisation')}
      >
        <Dropdown
          floating={!mobile}
          style={getDropdownStyle('organisation')}
          text={mobile ? 'Organisation' : 'Organisation'}
        >
          <Dropdown.Menu>

            <Dropdown.Item
              onClick={() => goTo('organisation/profile')}
            >
              Profile
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => goTo('organisation/members')}
            >
              Members
                  </Dropdown.Item>
            <Dropdown.Item
              onClick={() => goTo('organisation/teams')}
            >
              Teams
                  </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => goTo('organisation/new')}
            >
              New Organisation
                  </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </MenuItemHW>
      <MenuItemHW
        position='left'
        active={getActiveItem('discussions')}
      >
        <Dropdown
          floating={!mobile}
          style={getDropdownStyle('discussions')}
          text={mobile ? 'Discussions' : 'Discussions'}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => goTo('discussions')}>View</Dropdown.Item>
            <Dropdown.Item onClick={() => goTo('discussions/edit')}>Add</Dropdown.Item>
            <Dropdown.Item disabled>View Subjects</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </MenuItemHW>
      <MenuItemHW
        position='left'
        active={getActiveItem('reports')}
        onClick={() => { goTo('reports') }}
      >
        <p>Reports</p>
      </MenuItemHW>
      <MenuItemHW
        position='left'
        active={getActiveItem('insights')}
        onClick={() => { goTo('insights') }}

      >
        <p>Insights</p>
      </MenuItemHW>
    </>

  return (
    <>
      <Menu className="computer widescreen large screen only" style={{ backgroundColor: "#F8F9FA", border: 'none', boxShadow: 'none' }}>
        <MenuContent />
      </Menu>

      <Menu.Item className="mobile tablet only">
        <Dropdown
          pointing='top left'
          icon={'th'}
          button
          style={{ paddingRight: '1em' }}
        >
          <Dropdown.Menu style={{ minWidth: '135px' }}>
            <MenuContent mobile />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  );
}

export default MenuHW;