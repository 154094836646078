import React, { useEffect, useContext } from 'react';
import { navigate, RouteComponentProps, Router, useLocation } from '@reach/router'

import { DraggeableQuestions } from 'pages/App/Modules/Org/Questions/DraggeableQuestions'
import { GenericModal } from 'pages/App/Modules/Org/ModalsContext'
import { SearchGlobal } from 'pages/App/Modules/Org/SearchGlobal'
import NotFound from 'pages/App/Modules/Org/NotFound'
import Playground from 'pages/App/Modules/Org/Playground'

import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'

import { useOrganisationHook } from 'services/hooks/useOrganisationHook'

import EditQuestion from './Questions/Forms/EditQuestion'
import QuestionInfo from './Questions/Info'
import QuestionsBoard from './Questions/Board'
import ArchivedQuestions from './Questions/Archived'

import Insights from './Insights'
import InsightsGlobal from './Insights/Global'
import DashboardReports from './Reports/Dashboard'
import EditReport from './Reports/Forms/EditReport'
import ViewReport from './Reports/ViewReport'
import Discussions from './Discussions';
import { Loading } from 'pages/App/components/Loading';
import EditDiscussion from './Discussions/Forms/EditDiscussion';
import { toast } from 'react-toastify';
import { SuspenseWithPerf } from 'reactfire';
import { useProjectHook } from 'services/hooks/useProjectHook';

interface Props extends RouteComponentProps {
  path: string,
  oid: string,
}

interface Props2 extends RouteComponentProps {
  path: string,
  oid: string,
  projId: string,
}

function DiscussionGroupCore({ oid, projId, ...props }: Props2) {


  const { functions: { validateAccessHelper }, data: { accessLive } } = useProjectHook(oid);

  if (projId && accessLive) {
    if (validateAccessHelper(projId)) {
      return (
        <Router style={{ height: '100%' }}>
          <DashboardReports exact path={'/reports'} projId={projId} oid={oid} />
          <EditReport exact path={'/reports/edit/:reportId'} projId={projId} oid={oid} />
          <ViewReport exact path={'/reports/view/:reportId'} projId={projId} oid={oid} />
          <EditReport exact path={'/reports/new'} oid={oid} />
          <EditDiscussion path={'/edit'} projId={projId} oid={oid} />
          <QuestionInfo exact path={'/questions/:questionId'} projId={projId} oid={oid} />
          <ArchivedQuestions exact path={'/archived'} projId={projId} oid={oid} />
          <QuestionsBoard exact path={'/'} projId={projId} oid={oid} />
          <Insights exact path={'/insights'} projId={projId} oid={oid} />
        </Router>
      )
    } else {
      return (
        <Router style={{ height: '100%' }}>
          <NotFound
            title={'You do not have access to this discussion'}
            description={'Ask the author to give you access to see the content of this Discussion'}
            reportIssue={false}
            path={'/*'}
          />
        </Router>
      )
    }
  } else {
    return (
      <Loading />
    )
  }

}

function DiscussionGroup(props: Props2) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId={`howwee-discussions-group-${props.oid}`} >
      <DiscussionGroupCore {...props} />
    </SuspenseWithPerf>
  )
}

function Org({ oid, ...props }: Props) {

  const searchCtt: any = useContext(SearchGlobalContext);
  const { data: { usersDocs }, functions: { getMyRole, myUser } } = useOrganisationHook(oid);
  const location = useLocation();

  useEffect(() => {
    if (myUser()) {
      if (
        getMyRole() === 'guest' &&
        location.pathname !== `/app/org/${oid}/settings/profile` &&
        !myUser().representsOrg
      ) {
        toast.info('Please define which organization you represent')
        navigate(`/secure/settings/personal?tab=profileInOrg`);
      }
    } else {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oid, location, usersDocs])

  return (
    <>
      {
        searchCtt.searchData.isOpen && <SearchGlobal oid={oid} />
      }
      <GenericModal oid={oid} />
      <DraggeableQuestions oid={oid} />

      <Router style={{ height: '100%', paddingTop: '10px' }}>

        {/* Playground */}
        <Playground path={'/playground-dev'} oid={oid} />

        {/* Insights */}
        <InsightsGlobal exact path={'/insights'} oid={oid} />


        {/* Discussions */}
        <Discussions path={'/discussions'} oid={oid} showArchived={false} />
        <Discussions path={'/discussions-archived'} oid={oid} showArchived={true} />
        <EditDiscussion path={'/discussions/edit'} oid={oid} />
        <DiscussionGroup path={'/discussions/:projId/*'} oid={oid} projId={''} />

        {/* Questions */}
        <QuestionsBoard path={'/questions'} oid={oid} />
        <EditQuestion path={'/questions/new'} oid={oid} />

        {/* Reports */}
        <DashboardReports path={'/reports'} oid={oid} />

      </Router>
    </>
  );
}

export default Org;