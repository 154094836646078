import React, { useState, useContext, useEffect } from 'react';
import { Card, Button, Segment, Header, Table, Dropdown, Divider, Label } from 'semantic-ui-react';
import EditTeam from 'pages/App/components/Modals/EditTeam'
import { Loading } from 'pages/App/components/Loading'
import HowWeeContext from 'services/contexts/ContextHowWee'
import { ModalConfirm } from 'pages/App/components/Modals/Confirm';
import { toast } from 'react-toastify';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import { BadgeMultipleUser } from 'pages/App/components/Badges';

interface TeamsProps {
  organisation: any;
  orgId: string;
}

function Teams(props: TeamsProps) {

  const { orgId } = props;

  const { functions: { getMyRole } } = useOrganisationHook(orgId);

  const [isAdmin] = useState(getMyRole() === 'admin');
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const HWCtt: any = useContext(HowWeeContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   if (HWCtt.data.HW_ORG_listById[orgId]) setLoading(HWCtt.data.HW_ORG_listById[orgId].loading);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [HWCtt.data.HW_ORG_listById])

  const getTeamById = (id: string) => {
    const res = HWCtt.data.HW_ORG_listById[orgId].teams.filter((team: any) => team.id === id);
    if (res[0]) {
      setModalData(res[0])
      setModal(true)
    }
  }

  const DropdownUser = ({ teamId }: any) => {
    return (
      <Dropdown
        icon='ellipsis vertical'
        style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text='Edit'
            onClick={() => {
              getTeamById(teamId)
            }}
          />
          <ModalConfirm
            primary
            confirmText={'Delete Team'}
            title={'Are you sure?'}
            description={'If you delete a team members will still have access to the organizatione'}
            callback={() => {
              HWCtt.data.HW_FUNCTIONS.delete.team(orgId, teamId).then(() => {
                toast.success('Team successfully removed')
              });
            }}
          >
            <Dropdown.Item text='Delete' />
          </ModalConfirm>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const TableTeams = () => {
    return (
      <Table unstackable singleLine basic='very' collapsing style={{ margin: '20px', width: '100%', marginBottom: '50px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Team</Table.HeaderCell>
            <Table.HeaderCell>Users</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            HWCtt.data.HW_ORG_listById[orgId].teams.map((team: any) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <Label color={team.color}> {team.name} </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <BadgeMultipleUser users={team.users} oid={orgId} />
                  </Table.Cell>
                  <Table.Cell> {team.description} </Table.Cell>
                  <Table.Cell>
                    {isAdmin &&
                      <DropdownUser teamId={team.id} />
                    }
                  </Table.Cell>
                </Table.Row>)
            })
          }
        </Table.Body>
      </Table>
    )
  }

  const getTeamQty = () => {
    if (HWCtt.data?.HW_ORG_listById[orgId]?.teams?.length) {
      return HWCtt.data.HW_ORG_listById[orgId].teams.length
    }
    return '0'
  }

  return (
    <Card.Group itemsPerRow={1} style={{ marginTop: '15px' }} >
      <EditTeam
        open={modal}
        setOpen={setModal}
        orgId={orgId}
        setData={setModalData}
        data={modalData}
      />
      <Card style={{ paddingTop: '10px' }}>
        <Header as='h3' attached='top'>
          Teams
        </Header>
        <Segment attached>
          {!loading &&
            <>
              {getTeamQty()}  Teams <br /><br />
            </>
          }
          <Button disabled={!isAdmin} primary onClick={() => setModal(true)}>New Team</Button>
          <Divider />
          {!loading &&
            <TableTeams />
          }
          {loading &&
            <Loading />
          }
        </Segment>
      </Card>
    </Card.Group >
  );
}

export default Teams;