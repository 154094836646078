import React, { useEffect, useState } from 'react';
import { CardContentQuestion } from 'pages/App/Modules/Org/Questions/Board/Card/Content'
import GridCenter from 'pages/App/components/GridCenter'
import { Divider, Grid, Label } from 'semantic-ui-react'
import NoResults from 'pages/App/components/NoResults';
import { useProjectHook } from 'services/hooks/useProjectHook';
import { questionsArrayType, questionType } from 'typings/questions';
import { orgId, projectId, questionId } from 'typings/generic';

interface Props {
  searchCtt: any;
  oid: typeof orgId;
  projId: typeof projectId;
  questionsDocs: questionsArrayType;
  columns: any;
  independent: boolean;
  onlyAnswerSelected: boolean;
  onClickQuestion: (question: questionType) => void;
  avoidQuestions: Array<typeof questionId>;
}

function Results({
  searchCtt,
  oid,
  projId,
  questionsDocs,
  columns,
  independent,
  onlyAnswerSelected,
  onClickQuestion,
  avoidQuestions
}: Props) {

  const { functions: { userHaveSomeAccessInProject, validateAccessHelper } } = useProjectHook(oid)

  const [results, setResults] = useState<Array<any>>([])

  const [searchTermIndependent] = useState<string>(searchCtt.searchDataIndependent.searchTerm)
  const [searchTerm] = useState<string>(searchCtt.searchData.searchTerm);

  const validateAccess = (question: questionType) => {
    if (projId) return true // questionsDocs already filtered by project from father
    if (userHaveSomeAccessInProject(question.projId)) return true
    if (validateAccessHelper(question.projId)) return true
    return false
  }

  const analyzeFilters = () => {

    let index = independent ? 'searchDataIndependent' : 'searchData'
    const { searchFilters } = searchCtt[index];

    let tempQuestions: Array<any> = [];

    const searchTerm = searchCtt[index].searchTerm.toLowerCase();

    // #1 Main Filter
    questionsDocs.map((question: any) => {
      const questionPresent: string = question.question.toLowerCase() || '';
      const descriptionPresent: string = question.description.toLowerCase() || '';

      // #1.1 Filter by basic access like main board
      if (validateAccess(question)) {

        // #1.2 Filter by "question" title
        if (questionPresent.includes(searchTerm) || searchTerm === '') {
          tempQuestions.push(question);
        } else if (searchFilters.extra && searchFilters.extra.length > 0) {
          // #1.2 Filter by "question" description (aka 'context')
          if (
            searchFilters.extra.includes('description') &&
            descriptionPresent.includes(searchTerm)) {
            tempQuestions.push(question);
          }
        }

      }

      return null
    });


    // #1 check if only answer selected
    if (onlyAnswerSelected) {
      tempQuestions = tempQuestions.filter(
        (question) => question.status === 203
      );
    }

    // #2 Filter by all the rest of selector (always like array)
    if (searchFilters) {
      if (searchFilters.author && searchFilters.author.length > 0) {
        tempQuestions = tempQuestions.filter(
          (question) => searchFilters.author.includes(question.createdBy)
        );
      }
      if (searchFilters.users && searchFilters.users.length > 0) {
        tempQuestions = tempQuestions.filter(
          (question) => {

            let res = question.users.filter((userId: string) => {
              if (searchFilters.users.includes(userId)) {
                return true
              }
              return false
            })

            if (res.length > 0) return true
            return false
          }
        );
      }
      if (searchFilters.teams && searchFilters.teams.length > 0) {
        tempQuestions = tempQuestions.filter(
          (question) => {

            let res = question.teams.filter((teamId: string) => {
              if (searchFilters.teams.includes(teamId)) {
                return true
              }
              return false
            })

            if (res.length > 0) return true
            return false
          }
        );
      }
      if (searchFilters.subjects && searchFilters.subjects.length > 0) {
        tempQuestions = tempQuestions.filter(
          (question) => {

            let res = question.subjects.filter((subjectId: string) => {
              if (searchFilters.subjects.includes(subjectId)) {
                return true
              }
              return false
            })

            if (res.length > 0) return true
            return false
          }
        );
      }
      if (searchFilters.status && searchFilters.status.length > 0) {
        tempQuestions = tempQuestions.filter(
          (question) => searchFilters.status.includes(question.status)
        );
      } else {
        tempQuestions = tempQuestions.filter(
          (question) => question.status !== 410
        );
      }
      if (searchFilters.priority && searchFilters.priority.length > 0) {
        tempQuestions = tempQuestions.filter(
          (question) => searchFilters.priority.includes(question.priority)
        );
      }
      if (searchFilters.dateTo) {
        tempQuestions = tempQuestions.filter(
          (question) => {
            if (question.createdAt.seconds) {
              let { dateTo } = searchFilters;
              let parts = dateTo.split('-');
              let filter: any = new Date(parts[2], parts[1] - 1, parts[0]);

              let questDate: any = new Date(question.createdAt.seconds * 1000);
              if (questDate <= filter) return question
            }
            return false
          }
        );
      }
      if (searchFilters.dateFrom) {
        tempQuestions = tempQuestions.filter(
          (question) => {
            if (question.createdAt.seconds) {
              let { dateFrom } = searchFilters;
              let parts = dateFrom.split('-');
              let filter: any = new Date(parts[2], parts[1] - 1, parts[0]);

              let questDate: any = new Date(question.createdAt.seconds * 1000);
              if (questDate >= filter) return question
            }
            return false
          }
        );
      }
    }

    return tempQuestions
  }

  useEffect(() => {
    if (independent) {
      const data = analyzeFilters();
      if (JSON.stringify(data) !== JSON.stringify(results)) setResults(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermIndependent]);

  useEffect(() => {
    if (!independent) {
      const data = analyzeFilters();
      if (JSON.stringify(data) !== JSON.stringify(results)) setResults(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      <GridCenter fullWidth style={{ marginBottom: '-10px' }}>
        <Label.Group circular >
          <Label as='a'>{results.length} Questions</Label>
        </Label.Group>
      </GridCenter>
      <Divider style={{ marginTop: '8px' }} />
      <Grid style={{ marginTop: '0px', paddingRight: '1em', paddingLeft: '1em' }}>
        <Grid.Row columns={columns}>
          {
            results && results.map((question: any, index: number) => {

              return (
                <Grid.Column key={index} onClick={() => onClickQuestion && onClickQuestion(question)}>
                  <CardContentQuestion
                    oid={oid}
                    key={index}
                    question={question}
                    independent
                    disabled={avoidQuestions && avoidQuestions.includes(question.id)}
                    disableDetailFloated={independent}
                  />
                </Grid.Column>
              )

            })
          }
        </Grid.Row>
      </Grid>
      {results.length === 0 && !independent &&
        <NoResults />
      }
    </>
  );
}

export default Results;