import styled from 'styled-components'

export const QuestionFloated = styled.div`
position: absolute;
border-width: 1px;
border-style: solid;
border-color: #6C757D;
min-width: 200px;
max-width: ${(props: any) => props.width ? props.width : "40%"};
height: 100vh;
z-index: 999;
padding: 0px;
max-height: 90%;
background-color: white;
border-radius: 10px;
-webkit-box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.45);
-moz-box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.45);
box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.45);
`