import React from 'react';
import GridCenter from 'pages/App/components/GridCenter'
import { Divider } from 'semantic-ui-react';
import HistoryContentOrganisation from './Content/HistoryContentOrganisation'
import { TitleQuestion, PrefixTitleQuestion } from './styles'
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';

export function HistoryOrganisation({ oid, isModal }: any) {

  const { functions: { orgData } } = useOrganisationHook(oid);

  return (
    <>
      <GridCenter fullWidth={isModal}>
        <PrefixTitleQuestion> {orgData?.name || 'Organisation'}</PrefixTitleQuestion>
        <TitleQuestion> All the activity </TitleQuestion>
        <Divider />
        <HistoryContentOrganisation oid={oid} />
      </GridCenter>
    </>
  );
}

