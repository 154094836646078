import React, { useState } from 'react'
import {
  Menu,
  Item,
  Separator,
  useContextMenu
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useUser } from 'reactfire'
import { Icon } from 'semantic-ui-react';
import { navigate } from '@reach/router'
import { useModalHook } from 'services/hooks/useModalHook';
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';

export function Contexify({ question, oid, ...props }: any) {

  const MENU_ID = "menu-contexify-question-card-" + question.id;

  const { uid }: any = useUser()
  const { functions: { openModal } } = useModalHook()
  const { show } = useContextMenu({ id: MENU_ID })
  const { functions: { archiveQuestion } } = useQuestionsHook(oid);

  const [qstn] = useState(question);

  const handleItemClick = ({ event, props, triggerEvent, data }: any) => {
    switch (event.currentTarget.id) {
      case 'archive':
        if (qstn.id) {
          archiveQuestion(qstn.id, qstn)
        }
        break;
      case 'openDetail':
        if (qstn.status === 201) {
          openModal('editQuestion', { question: qstn });
        } else {
          navigate(`/app/org/${oid}/discussions/${question.projId}/questions/${qstn.id}`)
        }
        break;
      default:
        return null;
    }
  }

  const handleContextMenu = (event: any, question: any) => {
    event.preventDefault();
    show(event, {
      props: {
        // questionStatus: question.status,
        // questionId: question.id
      },
      position: {
        // This hacks prevent the bug of portal and DnD library
        x: event.clientX - 150,
        y: event.clientY - 40,
      },
    })
  }

  return (
    <>
      {/* Run custom logic then display the menu */}
      <div onContextMenu={(event: any) => handleContextMenu(event, qstn)}>
        {props.children}
      </div>

      <Menu id={MENU_ID}>
        <Item id='openDetail' onClick={handleItemClick}>
          {qstn.status === 201 ?
            <span> <Icon name='pencil' /> Edit Draft </span> :
            <span> <Icon name='play' /> Open detail </span>
          }
        </Item>
        <Separator />
        <Item disabled={qstn.createdBy !== uid} id='archive' onClick={handleItemClick}>
          <span>
            <Icon name='trash' /> Archive
          </span>
        </Item>
      </Menu>
    </>
  );
}