/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from 'react';
import { Sidebar, Grid, Menu, Icon } from 'semantic-ui-react';
import { Link } from '@reach/router';
import HowWeeContext from 'services/contexts/ContextHowWee'
import { useModalHook } from 'services/hooks/useModalHook';

function SideMenuModules() {

  const [openOID, setOpenOID]: any = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openOrg, setOpenOrg]: any = useState(null);

  const HWCtt: any = useContext(HowWeeContext);

  const { functions: { openModal } } = useModalHook()

  useEffect(() => {
    if (HWCtt.data.HW_DEFAULT.organisation) {
      if (HWCtt.data.HW_DEFAULT.organisation.id !== openOID) {
        setOpenOrg(HWCtt.data.HW_DEFAULT.organisation);
        setOpenOID(HWCtt.data.HW_DEFAULT.organisation.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HWCtt.data.HW_DEFAULT.organisation]);


  return (
    <Sidebar
      as={Menu}
      icon='labeled'
      vertical
      animation='push'
      visible={false}
      width='thin'
    >
      {openOID &&

        <div style={{ width: '100%', paddingTop: '2em' }} className="ui vertical labeled icon ui push left visible menu secondary">
          {/* <h4 style={{ marginTop: '15px' }}> {openOrg.name || 'Organisation'}</h4> */}
          {/* <Divider style={{ marginBottom: '10px' }} /> */}
          <Link className="item" to={`/app/org/${openOID}/questions`}>
            <Grid>
              <Grid.Column>
                <Icon disabled name="home" size='small' />
              </Grid.Column>
              <Grid.Column>
                <span style={{ textAlign: 'left' }}>Home</span>
              </Grid.Column>
            </Grid>
          </Link>
          <Link className="item" to={`/app/org/${openOID}/discussions`}>
            <Grid>
              <Grid.Column>
                <Icon disabled name="box" size='small' />
              </Grid.Column>
              <Grid.Column>
                <span style={{ textAlign: 'left' }}>Discussions</span>
              </Grid.Column>
            </Grid>
          </Link>
          <Link className="item" to={`/app/org/${openOID}/reports`}>
            <Grid>
              <Grid.Column>
                <Icon disabled name="file alternate outline" size='small' />
              </Grid.Column>
              <Grid.Column>
                <span style={{ textAlign: 'left' }}>Reports</span>
              </Grid.Column>
            </Grid>
          </Link>
          <Link className="item" to={``}>
            <Grid onClick={() => openModal('historyOrganisation', {})}>
              <Grid.Column>
                <Icon disabled name="history" size='small' />
              </Grid.Column>
              <Grid.Column>
                <span style={{ textAlign: 'left' }}>History</span>
              </Grid.Column>
            </Grid>
          </Link>

          {
            openOID &&
            HWCtt.data &&
            HWCtt.data.HW_ORG_myAccessAdmin &&
            HWCtt.data.HW_ORG_myAccessAdmin.includes(openOID) &&

            <Link className="item" to={`/secure/settings/organisation/${openOID}`}>
              <Grid>
                <Grid.Column>
                  <Icon disabled name="setting" size='small' />
                </Grid.Column>
                <Grid.Column>
                  <span style={{ textAlign: 'left' }}>Organisation</span>
                </Grid.Column>
              </Grid>
            </Link>
          }

        </div>
      }
    </Sidebar>
  );
}

export default SideMenuModules;