import { useContext } from 'react';
import { localSt, LOCAL_ST } from 'helpers/local-session-storage'
import HowWeeContext from 'services/contexts/ContextHowWee'
import { useUser } from 'reactfire';
import { onlyUniqueArray } from 'helpers/mini'
import { useFirestoreHook } from './useFirestoreHook';

export function useContextOrganisations() {

  const HWCtt: any = useContext(HowWeeContext);
  const { collections: { HWFS } } = useFirestoreHook();
  const { uid }: any = useUser();

  const orgByOid = (oid: string, organisations?: any) => {
    let res: any;
    if (organisations) {
      organisations.map((organisation: any) => {
        if (organisation.id === oid) {
          res = organisation;
        }

        return true;
      })
    } else {
      HWCtt.data.HW_ORG_listDetailed.map((organisation: any) => {
        if (organisation.id === oid) {
          res = organisation;
        }

        return true;
      })
    }
    return res;
  }

  const changeDefaultOrg = (oid: string | null) => {
    if (!oid) {
      HWCtt.setMain_Data((draft: any) => {
        draft.data.HW_DEFAULT.organisation = {};
      })
    } else {
      const orgData = orgByOid(oid);
      if (orgData?.id) {
        localSt.defineNow(LOCAL_ST.defaultOrganisation, orgData.id)
        HWCtt.setMain_Data((draft: any) => {
          draft.data.HW_DEFAULT.organisation = orgData;
        })
      }
    }
  }

  const projectAccessFromContextAdd = (oid: string, projectsId: Array<string>, isAuthor: boolean) => {
    if (Array.isArray(projectsId)) {

      let res: Array<string> = [];
      if (
        HWCtt.data.HW_PROJECT_myAccessPlain[oid] &&
        Array.isArray(HWCtt.data.HW_PROJECT_myAccessPlain[oid])) {
        res = [...HWCtt.data.HW_PROJECT_myAccessPlain[oid]];
      }

      res.push(...projectsId);

      HWCtt.setMain_Data((draft: any) => {
        draft.data.HW_PROJECT_myAccessPlain[oid] = res;
      })

      if (isAuthor) {

        let res2: Array<string> = [];
        if (HWCtt.data?.HW_PROJECT_myAccess[oid]?.projectsAuthor) {
          res2 = HWCtt.data?.HW_PROJECT_myAccess[oid]?.projectsAuthor
        }
        res2 = [...projectsId, ...res2]

        HWCtt.setMain_Data((draft: any) => {
          if (HWCtt.data.HW_PROJECT_myAccess[oid]) {
            draft.data.HW_PROJECT_myAccess[oid].projectsAuthor = res2;
          } else {
            draft.data.HW_PROJECT_myAccess[oid] = {
              projectsAuthor: res2
            }
          }
        })
      }
    }
  }

  const projectAccessFromContext = (oid: string, force: boolean = false) => {
    return new Promise((resolve, reject) => {
      try {

        if (force === false && HWCtt.data.HW_PROJECT_myAccessPlain[oid]) {
          // console.xx("[Proj] Project Access from Context")
          resolve(HWCtt.data.HW_PROJECT_myAccessPlain[oid]);
        } else {
          // console.xx("[Proj] Project Access from Firestore")
          // Force Way

          let usersObj: any = {};
          let myAccessPlain: any = [];
          let count = 0;

          HWFS.projects_access(oid).get().then((snapshot: any) => {
            snapshot.forEach((doc: any) => {
              // We get the acces of all users in the organisation
              let data = { id: doc.id, ...doc.data() }
              if (data) usersObj[doc.id] = data;

              if (doc.id === uid) {
                Object.keys(data).map((key: any) => {
                  if (Array.isArray(data[key])) {
                    myAccessPlain.push(...data[key]);
                  }
                  return null
                })
              }

              count++

              if (snapshot.size === count) {
                HWCtt.setMain_Data((draft: any) => {
                  draft.data.HW_PROJECT_myAccess[oid] = usersObj[uid];
                  draft.data.HW_PROJECT_myAccessPlain[oid] = onlyUniqueArray(myAccessPlain);
                  draft.data.HW_PROJECT_usersAccess[oid] = usersObj;
                })

                resolve(onlyUniqueArray(myAccessPlain))
              }
            })
          });
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  return {
    data: {},
    helpers: {
      orgByOid
    },
    functions: {
      changeDefaultOrg,
      projectAccessFromContext,
      projectAccessFromContextAdd
    }
  }
}