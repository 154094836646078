import { useRef, useEffect } from "react";

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el: any = elRef.current;
    if (el) {
      const onWheel = e => {
        e.preventDefault();

        if (Math.abs(e.deltaX)) {
          el.scrollTo({
            left: el.scrollLeft + (e.deltaX * 5),
            behavior: "smooth"
          });
        } else {
          el.scrollTo({
            left: el.scrollLeft + (e.deltaY * 5),
            behavior: "smooth"
          });
        }

      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}