import moment from 'moment';
import { useEffect, useState } from 'react'
import { useImmer } from 'use-immer';
import { useFirestoreHook } from './useFirestoreHook'
import { useOrganisationHook } from './useOrganisationHook';
import { daysExtString, daysString } from 'helpers/mini';
import { useQuestionsHook } from './useQuestionsHook';
import { useProjectHook } from './useProjectHook';

export function useInsightsHook(
  oid: string,
  projId: string | null = null
) {

  const { collections: { HWFS } } = useFirestoreHook();
  const { functions: { allQuestionsInOrg } } = useQuestionsHook(oid)
  const { functions: { userDisplayNameById, subjectById } } = useOrganisationHook(oid);
  const { functions: { validateAccessHelper } } = useProjectHook(oid)

  const [mainData, setMainData] = useImmer({
    timeToAnswer: null,
    timeToComment: null,
    totalQuestions: 0,
    questionSource: [],
    answerSource: [],
    subjects: [],
    commentsSource: [],
    daysOfQuestions: [],
  })

  const [questions, setQuestions]: any = useState([]);
  const [loadingHook, setLoading]: any = useState(true);

  const initHook = (questionsGroup: Array<any> = []) => {
    return new Promise(async (resolve, reject) => {
      let questionsTmp: Array<any> = [];

      if (projId) {
        await HWFS.questions(oid, projId).get().then((querySnapshot: any) => {
          querySnapshot.forEach((doc: any) => {
            let info = doc.data();
            if (info && doc.id) {
              let payload = { id: doc.id, ...info }
              questionsTmp.push(payload);
            }
          });
        });
      } else {
        await allQuestionsInOrg().then((allQuestions: any) => {
          questionsTmp = allQuestions.filter((question: any) => validateAccessHelper(question.projId));
        })
      }

      setQuestions(questionsTmp);
      resolve(questionsTmp);
    })
  }

  useEffect(() => {
    initHook().then((questionsTmp: any) => {
      setMainData((draft: any) => {
        draft.timeToAnswer = getTimeToAnswer(questionsTmp);
        draft.timeToComment = getTimeToComment(questionsTmp);
        draft.totalQuestions = getTotalQuestions(questionsTmp);
        draft.questionSource = getQuestionSource(questionsTmp);
        draft.answerSource = getAnswerSource(questionsTmp);
        draft.subjects = getSubjects(questionsTmp);
        draft.commentsSource = getCommentsSource(questionsTmp);
        draft.daysOfQuestions = getDaysOfQuestions(questionsTmp);
      })

      setLoading(false);
    }).catch((err: any) => {
      console.error(err);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTimeToAnswer = (questionsTmp: any) => {
    const diffPerQuestion: Array<any> = [];
    questionsTmp.map((question: any) => {
      if (
        question.createdAt?.seconds &&
        question.createdAtAnswer?.seconds
      ) {
        const date = moment.utc(question.createdAt.seconds * 1000);
        const daysDiff = Math.abs(moment(date).startOf('day').diff(moment(Date.now()).startOf('day'), 'days'));

        if (daysDiff < 31) {
          const diffHours = (question.createdAtAnswer.seconds - question.createdAt.seconds)
          diffPerQuestion.push(diffHours)
        }
      }
      return null;
    })

    if (diffPerQuestion.length === 0) return 'No Data'

    const avgSeconds = diffPerQuestion.reduce((a: number, b: number) => a + b) / diffPerQuestion.length;
    const duration = moment.duration(avgSeconds, 'seconds');
    return duration.humanize();
  }

  const getTimeToComment = (questionsTmp: any) => {
    const diffPerQuestion: Array<any> = [];
    questionsTmp.map((question: any) => {
      if (
        question.createdAt?.seconds &&
        question.createdAtFirstComment?.seconds
      ) {
        const date = moment.utc(question.createdAt.seconds * 1000);
        const daysDiff = Math.abs(moment(date).startOf('day').diff(moment(Date.now()).startOf('day'), 'days'));

        if (daysDiff < 31) {
          const diffHours = (question.createdAtFirstComment.seconds - question.createdAt.seconds)
          diffPerQuestion.push(diffHours)
        }

      }
      return null;
    })

    if (diffPerQuestion.length === 0) return 'No Data'

    const avgSeconds = diffPerQuestion.reduce((a: number, b: number) => a + b) / diffPerQuestion.length;
    const duration = moment.duration(avgSeconds, 'seconds');
    return duration.humanize();
  }

  const getTotalQuestions = (questionsTmp: any) => {
    return questionsTmp.length;
  }

  const getQuestionSource = (questionsTmp: any) => {
    const results: any = [
      // {
      //   "id": "uid",
      //   "label": "user display name",
      //   "value": qty of questions,
      // }
    ]

    const res: any = [];

    questionsTmp.map((question: any, index: number) => {
      if (question.createdBy) {
        if (!results[question.createdBy]) {
          results[question.createdBy] = {
            id: question.createdBy,
            value: 0,
          }
        }
        results[question.createdBy].value = results[question.createdBy].value + 1;
      }

      if (index === questionsTmp.length - 1) {
        Object.keys(results).map((key: any) => {
          res.push({
            ...results[key],
            label: userDisplayNameById(results[key].id),
          })
          return false;
        });
      }
      return null
    })

    return res;
  }

  const getAnswerSource = (questionsTmp: any) => {
    const results: any = [
      // {
      //   "id": "uid",
      //   "label": "user display name",
      //   "value": qty of answers,
      // }
    ]

    const res: any = [];

    questionsTmp.map((question: any, index: number) => {
      if (question.answer && question.answer.createdBy) {
        const createdBy = question.answer.createdBy;
        if (!results[createdBy]) {
          results[createdBy] = {
            id: createdBy,
            value: 0,
          }
        }
        results[createdBy].value = results[createdBy].value + 1;
      }

      if (index === questionsTmp.length - 1) {
        Object.keys(results).map((key: any) => {
          res.push({
            ...results[key],
            label: userDisplayNameById(results[key].id),
          })
          return false;
        });
      }
      return null
    })

    return res;
  }

  const getSubjects = (questionsTmp: any) => {
    const results: any = [
      // {
      //   "id": "subject id",
      //   "label": "subject name",
      //   "value": qty of subjects,
      // }
    ]

    const res: any = [];

    questionsTmp.map((question: any, index: number) => {
      if (question.subjects) {
        question.subjects.map((subjectId: string) => {
          if (!results[subjectId]) {
            results[subjectId] = {
              id: subjectId,
              value: 0,
            }
          }
          results[subjectId].value = results[subjectId].value + 1;
          return null
        })
      }

      if (index === questionsTmp.length - 1) {
        Object.keys(results).map((key: any) => {
          res.push({
            ...results[key],
            label: subjectById(results[key].id),
          })
          return false;
        });
      }
      return null
    })

    return res;
  }

  const getCommentsSource = (questionsTmp: any) => {
    const results: any = [
      // {
      //   "id": "subject id",
      //   "label": "subject name",
      //   "value": qty of subjects,
      // }
    ]

    const res: any = [];

    questionsTmp.map((question: any, index: number) => {
      if (question.usersWithComments) {
        question.usersWithComments.map((userId: string) => {
          if (!results[userId]) {
            results[userId] = {
              id: userId,
              value: 0,
            }
          }
          results[userId].value = results[userId].value + 1;
          return null
        })
      }

      if (index === questionsTmp.length - 1) {
        Object.keys(results).map((key: any) => {
          res.push({
            ...results[key],
            label: userDisplayNameById(results[key].id),
          })
          return false;
        });
      }
      return null
    })

    return res;
  }

  const getDaysOfQuestions = (questionsTmp: any) => {
    const results: any = []

    const res: any = [];

    daysString.map((day: string, index: number) => {
      results[index] = {
        id: index,
        qtyQuestions: 0,
        day: day,
        dayExt: daysExtString[index],
      }
      return null;
    })

    questionsTmp.map((question: any, index: number) => {
      if (question.createdAt && question.createdAt.seconds) {
        const day = moment.utc(question.createdAt.seconds * 1000).isoWeekday();
        results[day].qtyQuestions = results[day].qtyQuestions + 1;
      }

      if (index === questionsTmp.length - 1) {
        Object.keys(results).map((key: any) => {
          if (results[key].day !== '') {
            res.push({
              ...results[key]
            })
          }
          return false;
        });
      }
      return null
    })

    return res;
  }

  return {
    data: {
      loadingHook,
      mainData,
      questions
    },
  }
}