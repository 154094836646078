// Import FirebaseAuth and firebase.
import React, { useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebase from 'firebase/app'
import 'firebase/auth';
import { useAuth } from 'reactfire';
import { navigate } from '@reach/router'
import { Form, Grid, Header, Message, Segment, Image } from 'semantic-ui-react'
import { LOCAL_ST, localSt } from 'helpers/local-session-storage'
import logo from 'assets/hw/logofull.png'
import GridCenter from 'pages/App/components/GridCenter';


interface LoginProps {
  path?: any;
}

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: true,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    },
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      clientId: '721396587607-hkqqhs7cdddue0h4oub1n7am2bla8mo5.apps.googleusercontent.com',
      // customParameters: {
      //   hd: 'kitspace.com'
      // }
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult: any) => {
      const defaultOrg = localSt.obtainNow(LOCAL_ST.defaultOrganisation);
      const lastUrl = localSt.obtainNow(LOCAL_ST.firstUrlOpen);

      if (lastUrl) {
        navigate(lastUrl)
      } else if (defaultOrg) {
        navigate('/app/org/' + defaultOrg + '/questions');
      } else {
        navigate('/');
      }

      return false;
    },
    signInFailure: (error: any) => {

    },
    uiShown: () => {

    },
  },
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: 'platerocm.com',
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign('wikipedia.org');
  }
};

export function LoginScreen(props: LoginProps) {

  const authFirebase = useAuth();

  useEffect((): any => {
    if (window.location.pathname !== '/login') navigate('/login');
  }, []);

  return (
    <>
      <GridCenter>
        <Grid
          textAlign='center'
          style={{ maxWidth: '450px', margin: '0 auto', marginTop: '10%' }}
          verticalAlign='middle'
          columns={1}
        >
          <Grid.Column style={{ marginBottom: '0px' }}>
            <Image
              src={logo}
              style={{ maxHeight: '60px', margin: '0 auto', cursor: 'pointer' }}
            />
          </Grid.Column>
          <Grid.Column>
            <Header as='h3' color='teal' textAlign='center'>
              Your way of working, documented.
            </Header>
            <Form size='large'>
              <Segment stacked>
                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={authFirebase}
                />
              </Segment>
            </Form>
            <Message>
              For all businesses and teams, from small to large, document what you do and how you do it in a living, actionable ledger of processes and know-how, all in a web interface.
            </Message>
          </Grid.Column>
        </Grid>
      </GridCenter>
    </>
  );
};