/* Core | Components | Hooks | Helpers | Third-party */

import React, { useEffect, useState } from 'react';
import { useFirestoreCollectionData, SuspenseWithPerf } from 'reactfire'
import Header from './Header'

import GridCenter from 'pages/App/components/GridCenter'
import { CardContentQuestion } from 'pages/App/Modules/Org/Questions/Board/Card/Content'
import SelectorSubjects from 'pages/App/components/Dropdowns/SelectorSubjects'
import SelectorTeams from 'pages/App/components/Dropdowns/SelectorTeams'
import SelectorUsers from 'pages/App/components/Dropdowns/SelectorUsers'
import { Loading } from 'pages/App/components/Loading';
import { SearchGlobal } from 'pages/App/Modules/Org/SearchGlobal';

import { useQuestionsHook } from 'services/hooks/useQuestionsHook';
import { useReportsHook } from 'services/hooks/useReportsHook'

import { avoidEmpty } from 'helpers/mini'

import { Loader, Form, Button, Input, Icon, Divider, Grid, Dimmer, Popup } from 'semantic-ui-react'
import { useImmer } from 'use-immer'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'
import { LexoRank } from 'lexorank'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SelectorProject from 'pages/App/components/Dropdowns/SelectorProject';
import { useProjectHook } from 'services/hooks/useProjectHook';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';

function EditReportComponent({ path, reportId, oid, projId }: any) {

  // UI ===>
  const [loading, setLoading] = useState(true)

  // In case of EDIT ===>
  const [reportOpen, setReportOpen]: any = useState(null)
  const [reportOrder, setReportOrder]: any = useImmer({ data: {} })
  const [reportAccess, setReportAccess]: any = useState(null);
  const [reportName, setReportName] = useState('');
  const [projectForQ, setProjectForQ] = useState(projId || null)
  // <=== In case of EDIT


  /* Custom Hooks */
  const { collections: { HWFS } } = useFirestoreHook();
  const { functions: { projectById } } = useProjectHook(oid);
  const { functions: { getLexorank } } = useQuestionsHook(oid);
  const { functions: { createReport, getReport, updateReport, reportAccessExtended } } = useReportsHook(oid);


  // Form ===>
  const [editorUsersForQ, setEditorUsersForQ] = useState([]);
  const [teamsForQ, setTeamsForQ] = useState([]);
  const [usersForQ, setUsersForQ] = useState([]);
  const [subjectsForQ, setSubjectsForQ] = useState([]);
  const [projectData] = useState(projectById(projId))
  // <=== Form

  /* Array of Questions ID */
  const [questionsSelected, setQuestionsSelected]: any = useState([])
  /* Array of Questions Data (Object) */
  const [questionsFullSelected, setQuestionsFullSelected]: any = useImmer({ selected: [] })

  /* Firestore */
  const questionsRef = HWFS.questions(oid, projId);
  const questionsDocs = useFirestoreCollectionData(questionsRef, { idField: 'id' });

  /* Index of Functions */
  // questionsWithLexo : List availables questions and merge the lexorank value
  // sortQuestions: Sort array by .lexorank value
  // getInitialLexoRank: Obtain default lexorank value of new question added (prev, middle, next)
  // toggleQuestion: Add/Remove question from Report
  // validReport: Check the neccesary fields to validate the report
  // saveReport: Save changes in Firestore
  // questionById: Util - Get question object by ID
  // onDragEnd: Capture event from DnD

  const questionsWithLexo = (data: any = null) => {
    if (reportOpen) {
      const dataArray: Array<any> = data || questionsDocs;
      const res: any = dataArray.map((question: any) => {
        if (reportOpen.questionsIncluded && reportOpen.questionsIncluded.includes(question.id)) {
          let tmp = { ...question };
          tmp.lexorank = reportOpen.questionsIncludedLexorank[question.id];
          return tmp;
        }
        return null
      })
      return res;
    }

    return null
  }

  const sortQuestions = (questions: any) => {
    if (questions.length === 0) return [];
    let tmp = avoidEmpty([...questions])
    const res = tmp.sort((a: any, b: any) => (a.lexorank > b.lexorank) ? 1 : -1);
    return res;
  }

  const getInitialLexoRank = (questionId: string) => {
    const keys = Object.keys(reportOrder.data)

    if (keys.length === 0) return LexoRank.middle().toString();
    const res = LexoRank.parse(reportOrder.data[keys[keys.length - 1]]).genNext().toString();

    return res;
  }

  const toggleQuestion = (question: any) => {
    if (!questionsSelected.includes(question.id)) {
      const lexoQuestion = getInitialLexoRank(question.id);

      // ADD Question
      setQuestionsSelected([...questionsSelected, question.id]);

      // ADD Question Order based on LexoRank
      setReportOrder((draft: any) => {
        draft.data[question.id] = lexoQuestion;
      })

      // ADD Question Data
      let tmp: Array<any> = [];
      if (questionsFullSelected.selected) tmp = questionsFullSelected.selected;
      const newValue = [{ lexorank: lexoQuestion, ...question }, ...tmp];
      setQuestionsFullSelected((draft: any) => {
        draft.selected = sortQuestions(newValue);
      })

    } else {
      // REMOVE Question
      let res = questionsSelected.filter((quest: any) => quest !== question.id)
      setQuestionsSelected(res)

      let newReportOrder = { ...reportOrder.data }
      delete newReportOrder[question.id];

      setReportOrder((draft: any) => {
        draft.data = newReportOrder
      })

      let newValue: Array<any> = [];
      if (questionsFullSelected.selected) {
        newValue = questionsFullSelected.selected.filter((quest: any) => quest.id !== question.id);
      }
      setQuestionsFullSelected((draft: any) => {
        draft.selected = sortQuestions([...newValue])
      })

    }
  }

  const validReport = () => {
    if (reportName.length === 0) return false
    return true
  }

  const saveReport = () => {
    if (validReport()) {

      const orderSimplified: any = {};
      questionsFullSelected.selected.map((question: any) => {
        orderSimplified[question.id] = question.lexorank;
        return null
      });

      const payload = {
        name: reportName,
        questionsIncludedLexorank: orderSimplified,
        editorUsers: editorUsersForQ,
        viewerUsers: usersForQ,
        viewerTeams: teamsForQ,
        subjects: subjectsForQ,
        questionsIncluded: questionsSelected,
        projId: projectForQ
      }

      if (reportOpen) {
        updateReport(projectForQ, reportOpen.id, payload, reportOpen).then(() => {
          toast.success(<><Icon name='check' /> Report saved successfully </>)
          navigate('../../reports');
        })
      } else {
        createReport(projectForQ, payload).then(() => {
          toast.success(<><Icon name='check' /> Report created successfully </>)
          navigate('../reports')
        })
      }
    }
  }

  const questionById = (questionId: string) => {
    const res = questionsFullSelected.selected.filter((question: any) => question.id === questionId);
    return res[0] || null;
  }

  const onDragEnd = (data: any) => {
    const questionId = data.draggableId;
    const questionInfo = questionById(questionId);

    // Lexorank ===>
    const questionsInGroup: any = questionsFullSelected.selected;
    const newIndex = data.destination.index;
    const oldIndex = data.source.index;
    const lexorankUpdate = getLexorank(questionsInGroup, newIndex, oldIndex, questionInfo);

    /* Update UI to prevent firestore delay */
    questionsFullSelected.selected.map((question: any, index: number) => {
      if (question.id === questionId) {
        const questData: any = questionsFullSelected.selected[index] || {};
        const tmp = [...questionsFullSelected.selected]
        tmp[index] = { ...questData, lexorank: lexorankUpdate };
        setQuestionsFullSelected((draft: any) => {
          draft.selected = sortQuestions(tmp);
        });
      }
      return null
    })
    // <=== Lexorank
  }

  useEffect(() => {
    if (reportId) {
      getReport(reportId, projId).then((res: any) => {
        setReportOpen({ ...res.data(), id: reportId });
        setReportAccess(reportAccessExtended(res.data()))
      })
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  useEffect(() => {
    if (reportOpen) {
      setReportName(reportOpen.name);
      setReportOrder((draft: any) => {
        draft.data = reportOpen.questionsIncludedLexorank || {}
      });

      setEditorUsersForQ(reportOpen.editorUsers)
      setUsersForQ(reportOpen.viewerUsers)
      setTeamsForQ(reportOpen.viewerTeams)
      setSubjectsForQ(reportOpen.subjects)
      setQuestionsSelected(reportOpen.questionsIncluded)
      setQuestionsFullSelected((draft: any) => {
        draft.selected = sortQuestions(questionsWithLexo());
      });

      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportOpen]);

  return (
    <div style={{ paddingTop: '0.4em' }}>
      <Header projId={projId} oid={oid} reportOpen={reportOpen} reportName={reportName} />

      <GridCenter>
        <Form loading={loading} style={{ marginTop: '50px' }}>
          <Form.Group widths='equal'>
            <Form.Field>
              <label>Report Name</label>
              <Input labelPosition='left' type='text' placeholder=''>
                <input
                  name="question"
                  type="text"
                  autoComplete="off"
                  placeholder="Define a concise report name"
                  value={reportName}
                  disabled={reportAccess && !reportAccess.owner}
                  onChange={((event: any) => setReportName(event.target.value))}
                />
              </Input>
            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              <label>Discussion</label>
              <SelectorProject
                oid={oid}
                initial={projectForQ}
                setResult={setProjectForQ}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={1}>
              <Icon name='pencil alternate' style={{ marginTop: '2em' }} />
            </Form.Field>
            <Form.Field width={15} disabled={reportAccess && !reportAccess.owner}>
              <label> Editors </label>
              {!loading &&
                <SelectorUsers
                  oid={oid}
                  setResult={setEditorUsersForQ}
                  initial={editorUsersForQ}
                  filter={projectData}
                />
              }
            </Form.Field>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Form.Field width={1}>
              <Icon name='user' style={{ marginTop: '0.7em' }} />
            </Form.Field>
            <Form.Field width={15}>
              {!loading &&
                <SelectorUsers
                  oid={oid}
                  setResult={setUsersForQ}
                  initial={usersForQ}
                  filter={projectData}
                />
              }
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={1} >
              <Icon name='users' style={{ marginTop: '0.7em' }} />
            </Form.Field>
            <Form.Field width={15}>
              {!loading &&
                <SelectorTeams
                  oid={oid}
                  setResult={setTeamsForQ}
                  initial={teamsForQ}
                  filter={projectData}
                />
              }
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={1}>
              <Icon name='tag' style={{ marginTop: '0.7em' }} />
            </Form.Field>
            <Form.Field width={15}>
              {!loading &&
                <SelectorSubjects
                  oid={oid}
                  initial={subjectsForQ}
                  setResult={setSubjectsForQ}
                />
              }
            </Form.Field>
          </Form.Group>
        </Form>
        <Divider />
        <div>
          <h3 style={{ display: 'inline' }}>Included Questions</h3>
          <p style={{ display: 'inline', color: 'grey', marginLeft: '10px' }}>(Drag to reorder)</p>
          <br />
          <p style={{ display: 'inline', color: 'grey' }}>
            Click on a question card to add to the report. Click a question card in the report to remove it.
        </p>
        </div>
        <Grid>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'report'}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ minHeight: '150%', width: '100%', padding: '4em 1em 4em 1em' }}
                >
                  <Grid>
                    {
                      questionsFullSelected && questionsFullSelected.selected.map((question: any, index: number) => {
                        if (question) {
                          return (
                            <Grid.Column key={question.id} width={16}>
                              <Draggable
                                index={index}
                                key={question.id}
                                draggableId={question.id}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{ width: '100%' }}
                                  >
                                    <Grid divided>
                                      <Grid.Column width={14}>
                                        <CardContentQuestion
                                          oid={oid}
                                          key={index}
                                          question={question}
                                          independent
                                          disableDetailFloated
                                          style={{ width: '100%' }}
                                        />
                                      </Grid.Column>
                                      <Grid.Column verticalAlign='middle'>
                                        <Button color='red' icon onClick={() => toggleQuestion(question)}>
                                          <Icon name='remove' />
                                        </Button>
                                      </Grid.Column>
                                    </Grid>
                                  </div>
                                )}
                              </Draggable>
                            </Grid.Column>)
                        }
                        return null
                      })
                    }
                  </Grid>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid >
        <Grid style={{ marginTop: '4em' }}>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Popup
                trigger={
                  <Button color='teal' onClick={() => navigate(`../view/${reportId}`)}> View </Button>
                }
                content="If you don' t want to lose your changes, remember to save first"
                position='right center'
              />

            </Grid.Column>
            <Grid.Column>

            </Grid.Column>
            <Grid.Column>
              <div style={{ float: 'right' }}>
                <Button
                  primary
                  disabled={loading || !validReport()}
                  onClick={saveReport}
                >
                  {reportOpen ? "Save Changes" : "Create Report"}
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <div>
          <h3 style={{ display: 'inline' }}>Available Questions</h3>
          <p style={{ display: 'inline', color: 'grey', marginLeft: '10px' }}>
            ({questionsDocs.filter((quest: any) => quest.status === 203).length - questionsSelected.length || 0} Questions)
        </p>
        </div>
        <Grid columns={1} style={{ marginTop: '1em' }}>
          <Grid.Column>
            <SearchGlobal
              independent
              projId={projId}
              oid={oid}
              avoidQuestions={questionsSelected}
              onClickQuestion={(question: any) => {
                toggleQuestion(question)
              }}
              onlyAnswerSelected
            />
          </Grid.Column>
        </Grid>
      </GridCenter >
    </div >
  );
}

function EditReport(props: any) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId={`howwee-organisation-reports-${props.oid}`} >
      <EditReportComponent {...props} />
    </ SuspenseWithPerf>
  )
}

export default EditReport;