import React from 'react';
import { Label, Popup } from 'semantic-ui-react';
import BadgeSubject from './BadgeSubject'

function BadgeOtherSubjects({ subjects, maxItems, oid }: any) {

  return (
    <div style={{ marginRight: '-10px', display: 'inline' }}>
      <Popup
        basic
        content={<>
          {
            subjects.map((subjectId: string, index: number) => {
              if (index + 1 > maxItems) {
                return <BadgeSubject key={index} subjectId={subjectId} oid={oid} />
              }
              return null
            })
          }
        </>}
        trigger={
          <Label size='tiny' color='grey' style={{ marginTop: '2px' }}>
            +{subjects.length - maxItems}
          </Label>
        }
      />
    </div>
  );
}

export default BadgeOtherSubjects;