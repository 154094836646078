import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useFirestoreCollectionData, SuspenseWithPerf, useUser } from 'reactfire'
import capitalize from 'capitalize';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';

function SelectorUsersComponent({ oid, setResult, initial, excludeSelfUser, filter }: any) {

  const { uid }: any = useUser();
  const { collections: { HWFS } } = useFirestoreHook();

  const usersRef = HWFS.users(oid);
  const optionsInit = useFirestoreCollectionData(usersRef, { idField: 'id' });

  const [options, setOptions]: any = useState([]);
  const [users, setusers]: any = useState([]);
  const [initialLoaded, setInitialLoaded] = useState(false);

  useEffect(() => {
    if (initial && initialLoaded === false) {
      setResult(initial);
      setInitialLoaded(true);
    } else {
      setResult(users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    let result: Array<any> = [];

    optionsInit.map((opt: any) => {
      if (
        !filter ||
        (filter && filter.createdBy === opt.id) ||
        (filter && filter.users && filter.users.includes(opt.id)) ||
        (filter && filter.editors && filter.editors.includes(opt.id))) {

        if (excludeSelfUser && opt.id === uid) return null

        result.push({
          key: opt.id,
          value: opt.id,
          text: opt.displayName || opt.email,
          image: { avatar: true, src: opt.photoURL || '' },
        });
      }

      return null;
    });

    setOptions(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsInit]);

  const getPhotoById = (uid: any) => {
    let photo: string = '';

    optionsInit.map((user: any) => {
      if (user.id === uid) photo = user.photoURL;
      return true;
    })

    return photo;
  }

  return (
    <>
      <Dropdown
        style={{ width: '100%' }}
        placeholder='Press enter to add multiple users'
        fluid
        search
        selection
        multiple
        defaultValue={initial}
        onLabelClick={(event, data: any) => {
          setusers(
            users.filter((item: any) => item !== data.value)
          );
        }}
        onChange={(event, data: any) => {
          setusers(data.value);
        }}
        renderLabel={(data) => ({
          content: `${capitalize(data.text)}`,
          image: { avatar: true, src: getPhotoById(data.value) }
        })
        }
        options={options}
      />
    </>
  );
}

function SelectorUsers(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Dropdown style={{ width: '100%' }} text='Loading users...' loading></Dropdown>}
      traceId={`howwee-users-${props.oid || ''}`}
    >
      <SelectorUsersComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default SelectorUsers;