import React, { useEffect } from 'react'
import { FirebaseAppProvider, SuspenseWithPerf, AuthCheck } from 'reactfire'
import { LoginScreen } from 'pages/App/public/Login'
import { firebaseConfig } from 'config/firebase.config'
import { Main } from 'pages/App/Main'

import { ModalProvider } from 'services/contexts/ContextModal'
import { UIProvider } from 'services/contexts/ContextUI'
import { HowWeeProvider } from 'services/contexts/ContextHowWee'
import { SearchGlobalProvider } from 'services/contexts/ContextSearchGlobal'
import { RemoteConfProvider } from 'services/contexts/ContextRemoteConfig'
import { EmailVerifiedValidator } from './EmailVerifiedValidator'
import { localSt, LOCAL_ST } from 'helpers/local-session-storage'
import CookieBanner from 'react-cookie-banner';

// eslint-disable-next-line
import cookieImg from 'assets/hw/cookie.png'

function App(props: any) {

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      localSt.defineNow(LOCAL_ST.firstUrlOpen, window.location.pathname)
    }
  }, []);

  const styleCookie = {
    banner: {
      bottom: 0,
      height: 57,
      background: `rgba(52, 64, 81, 0.88) url(${cookieImg}) 20px 50% no-repeat`,
      backgroundSize: '30px 30px',
      backgroundColor: '',
      fontSize: '15px',
      fontWeight: 600,
      position: 'absolute',
    },
    button: {
      border: '1px solid white',
      borderRadius: 4,
      width: 66,
      height: 32,
      lineHeight: '32px',
      background: 'transparent',
      color: 'white',
      fontSize: '14px',
      fontWeight: 600,
      opacity: 1,
      right: 20,
      marginTop: -18,
    },
    message: {
      display: 'block',
      padding: '18px 67px',
      lineHeight: 1.3,
      textAlign: 'left',
      marginRight: 40,
      color: 'white'
    },
    link: {
      textDecoration: 'none',
      fontWeight: 'bold'
    }
  }

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <SuspenseWithPerf fallback={<p>Loading firebase...</p>} traceId="howwee-workspace-main">
        <AuthCheck fallback={<LoginScreen />}>
          <RemoteConfProvider>

            <EmailVerifiedValidator>

              <HowWeeProvider>
                <UIProvider>
                  <ModalProvider>
                    <SearchGlobalProvider>

                      <Main />

                    </SearchGlobalProvider>
                  </ModalProvider>
                </UIProvider>
              </HowWeeProvider>

            </EmailVerifiedValidator>

          </RemoteConfProvider>
        </AuthCheck>

        <CookieBanner
          styles={styleCookie}
          dismissOnClick={false}
          dismissOnScroll={false}
          buttonMessage='Accept'
          message="We use cookies to improve your experience. By using our site, you agree to our use of cookies."
          onAccept={() => { }}
          cookie="hw-user-has-accepted-cookies" />
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  );
}

export default App;