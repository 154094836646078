import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'semantic-ui-react'
import { Printable } from './styles'

export const PrintLauncher = ({ Content, captureClick }: any) => {

  const componentRef = useRef();
  const [loading, setLoading] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setLoading(false)
    }
  });

  return (
    <>
      <Printable ref={componentRef}>
        <Content printMode={true} />
      </Printable>
      <Button
        loading={loading}
        onClick={() => {
          setLoading(true)
          try {
            handlePrint()
          } catch (err: any) {

          }
          captureClick()
        }}
        primary>Export</Button>
    </>
  );
};