import React, { useState, useEffect, useContext } from 'react';
import HowWeeContext from 'services/contexts/ContextHowWee'
import { Breadcrumb, Grid, Icon, Input, Popup } from 'semantic-ui-react';
import { useProjectHook } from 'services/hooks/useProjectHook';
import pluralize from 'pluralize';
import { navigate } from '@reach/router';
import { useModalHook } from 'services/hooks/useModalHook';

function BoardHeader({ projId, oid, setSearch, search, questionsFilter }: any) {

  const HWCtt: any = useContext(HowWeeContext);
  const { functions: { openModal } } = useModalHook()
  const { functions: { projectById } } = useProjectHook(oid)
  const [project, setProject] = useState({ name: '', description: '' })
  const [withoutProject] = useState(projId ? false : true)

  useEffect(() => {
    if (projId) {
      setProject(projectById(projId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {projId &&
        <div style={{ paddingTop: '0.3em' }}>
          <Breadcrumb size='small' style={{ marginLeft: '1em' }}>
            <Breadcrumb.Section link onClick={() => navigate(`/app/org/${oid}/discussions`)}>
              Discussions
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section style={{ color: 'grey' }}>
              {withoutProject ?
                `All Activity` :
                project?.name
              }
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>
      }
      <div style={{ padding: '1em', paddingLeft: '28px' }}>
        <Grid doubling columns={2}>
          <Grid.Column>
            <p style={{ fontSize: '20px', display: 'block', fontWeight: 500, marginBottom: '0.4em' }}>
              {withoutProject ?
                `${HWCtt?.data?.HW_DEFAULT?.organisation?.name}: All Activity` :
                project?.name
              }
              {!withoutProject &&
                <>
                  <i style={{ marginLeft: '0.5em' }} />
                  <Icon onClick={() => navigate(`${projId}/edit`)} size='small' name='setting' style={{ color: '#6F7A80', cursor: 'pointer' }} />
                  <Icon size='small' name='users' style={{ color: '#6F7A80', cursor: 'pointer' }} />
                </>
              }
            </p>
            <span style={{ color: '#6F7A80' }}>
              {withoutProject ?
                'Read only mode. You view questions here, but they cannot be reordered or moved.' :
                project?.description
              }
            </span>
          </Grid.Column>
          <Grid.Column>
            <div style={{ display: 'flex', float: 'right' }}>
              <div style={{ justifyContent: 'right', paddingTop: '0.5em' }}>
                <Popup
                  content='Reports'
                  position='bottom center'
                  trigger={
                    <Icon
                      onClick={() => {
                        if (projId) navigate(`${projId}/reports`)
                        if (!projId) navigate(`reports`)
                      }}
                      size='large'
                      name='file alternate outline'
                      style={{ color: '#6F7A80', cursor: 'pointer' }}
                    />
                  }
                />
                <Popup
                  content='Insights'
                  position='bottom center'
                  trigger={
                    <Icon
                      size='large'
                      name='chart bar'
                      style={{ color: '#6F7A80', cursor: 'pointer' }}
                      onClick={() => {
                        if (projId) navigate(`${projId}/insights`)
                        if (!projId) navigate(`insights`)
                      }}
                    />
                  }
                />
                {projId &&
                  <Popup
                    content='Archived'
                    position='bottom center'
                    trigger={
                      <Icon
                        size='large'
                        name='archive'
                        style={{ color: '#6F7A80', cursor: 'pointer' }}
                        onClick={() => {
                          if (projId) navigate(`${projId}/archived`)
                        }}
                      />
                    }
                  />
                }
                <Popup
                  content='History'
                  position='bottom center'
                  trigger={
                    <Icon
                      size='large'
                      name='history'
                      style={{ color: '#6F7A80', cursor: 'pointer' }}
                      onClick={() => {
                        projId ?
                          openModal('historyDiscussion', { project: project }) :
                          openModal('historyOrganisation', {})
                      }
                      }
                    />
                  }
                />
              </div>
              <Input
                style={{ marginLeft: '1em' }}
                icon='search'
                placeholder='Search discussions...'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </Grid.Column>
          {search !== '' &&
            <Grid.Column>
              <h3>
                {questionsFilter.length} {pluralize('result', questionsFilter.length)} based on '{search}'
              </h3>
            </Grid.Column>
          }
        </Grid>
      </div>
    </>
  );
}

export default BoardHeader;