import React, { useContext } from 'react'
import { Sidebar, Menu, Segment } from 'semantic-ui-react'
import UIContext from 'services/contexts/ContextUI';
import HowWeeContext from 'services/contexts/ContextHowWee';
import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'
import noCompanyLogo from 'assets/nocompany.jpg'
import { navigate } from '@reach/router';


const SideMenu = ({ children, ...props }: any) => {

  const uiCtt: any = useContext(UIContext);
  const HWCtt: any = useContext(HowWeeContext);
  const searchCtt: any = useContext(SearchGlobalContext);


  return (
    <Sidebar.Pushable as={Segment} style={{ marginTop: '0px', borderRadius: '0px', marginRigth: '0px', }}>

      <Sidebar
        as={Menu}
        animation='push'
        style={{ backgroundColor: "#F8F9FA" }}
        onHide={() => { }}
        vertical
        visible={uiCtt.sideMenu}
        width='very thin'
      >
        <Menu.Item as='a' style={{ height: '8vh' }}></Menu.Item>
        {
          HWCtt.data.HW_ORG_listDetailed.map((org: any) => {
            return (
              <Menu.Item
                as='a'
                key={org.id}
                style={{ textAlign: 'center' }}
                onClick={() => {
                  searchCtt.setSearchData((draft: any) => {
                    draft.searchResultsQuestionsOpen = draft.searchResultsQuestionsOpen = [];
                  });
                  navigate('/app/org/' + org.id + '/questions')
                }}
              >
                <img src={org.IMAGE_URL || noCompanyLogo} alt="company" style={{ borderRadius: '6px', maxHeight: '30px' }} />
              </Menu.Item>
            )
          })
        }
      </Sidebar>

      <Sidebar.Pusher>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable >
  )
}

export default SideMenu;