import React, { createContext, useEffect } from "react";
import { useUser } from 'reactfire'
import { useImmer } from 'use-immer';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook'
import { onlyUniqueArray } from "helpers/mini";
import { useOrganisationsHook } from "services/hooks/useOrganisationsHook";
import { localSt, LOCAL_ST } from 'helpers/local-session-storage'

const initialOrg: any = {
  teams: [],
  users: [],
  projects: [],
  projectsId: [],
  loading: true
}

const initial = {
  setMain_Data: () => { }, // Immer self reference
  loading: true,
  data: {
    HW_DEFAULT: {
      organisation: null
    },
    HW_ORG_myAccessAdmin: [], // List of org's ID with Admin
    HW_ORG_myAccessGuest: [], // List of org's ID with Guest
    HW_ORG_myAccess: [], // List of org's ID with access (any role)
    HW_ORG_listDetailed: [],
    HW_ORG_listById: {
      /*
      CHECK => "initialOrg"
      {id} : {
        teams: [],
        users: [],
        projects: [],
        projectsId: [],
        loading: false
      }
      */
    },
    HW_PROJECT_usersAccess: {  // /organisations/{oid}/projects_access
      /*
      {oid} : {
        {uid-A} : /organisations/{oid}/projects_access/{uid}
        {uid-B} : /organisations/{oid}/projects_access/{uid}
      }
      */
    },
    HW_PROJECT_myAccess: {  // /organisations/{oid}/projects_access/{uid}
      /*
      {oid} : /organisations/{oid}/projects_access/{my-uid}
      */
    },
    HW_PROJECT_myAccessPlain: {  // /organisations/{oid}/projects_access/{uid}
      /*
      {oid} : [{projId-A}, {projId-B}, {projId-C}]
      */
    },
    HW_FUNCTIONS: {} // Javascript Functions
  }
}

const HowWeeContext = createContext(initial);
const { Consumer } = HowWeeContext;

function HowWeeProvider(props: any): any {

  const user: any = useUser();
  const { uid } = user || { uid: null };

  const { functions: { organisationByIds, addImageByIds } } = useOrganisationsHook();
  const {
    collections: { HWFS, FSorganisations },
    functions: { addDoc, getDoc, softDeleteDoc, updateDoc, getSubCollection }
  } = useFirestoreHook();
  const [mainData, setMain_Data]: any = useImmer(initial)


  ///////////////
  // METHODS ==>
  ///////////////

  const resetOrg = (oid: string) => {
    return new Promise((resolve, reject) => {
      try {
        setMain_Data((draft: any) => {
          if (!draft.data.HW_ORG_listById) draft.data.HW_ORG_listById = {}
          if (!draft.data.HW_ORG_listById[oid]) draft.data.HW_ORG_listById[oid] = {};
          draft.data.HW_ORG_listById[oid] = initialOrg;
        })
        resolve(true)
      } catch (err) {
        console.error(err)
        reject()
      }
    });
  }

  const loadUsers_fn = async (oid: string) => {
    await getSubCollection(oid, 'users').then((res) => {
      setMain_Data((draft: any) => {
        draft.data.HW_ORG_listById[oid].users = res;
      });
    }).catch((err) => {
      console.error(err);
    })
  }

  const loadTeams_fn = async (oid: string) => {
    await getSubCollection(oid, 'teams').then((res) => {
      setMain_Data((draft: any) => {
        draft.data.HW_ORG_listById[oid].teams = res;
      });
    }).catch((err) => {
      console.error(err);
    })
  }

  const loadProjects_fn = async (oid: string) => {
    await getSubCollection(oid, 'projects').then((res: any) => {
      let list: Array<string> = [];

      res.map((project: any) => {
        return list.push(project.id)
      });

      setMain_Data((draft: any) => {
        draft.data.HW_ORG_listById[oid].projectsId = list;
        draft.data.HW_ORG_listById[oid].projects = res;
      });
    }).catch((err) => {
      console.error(err);
    })
  }

  const orgByOid = (oid: string, organisations?: any) => {
    let res: any;
    if (organisations) {
      organisations.map((organisation: any) => {
        if (organisation.id === oid) {
          res = organisation;
        }

        return true;
      })
    } else {
      mainData.data.HW_ORG_listDetailed.map((organisation: any) => {
        if (organisation.id === oid) {
          res = organisation;
        }

        return true;
      })
    }

    if (res) return res;
    return undefined;
  }

  const MAIN_getUserAccess_fn = async () => {
    const ref: any = HWFS.users_access.doc(uid)
    return new Promise((resolve, reject) => {
      getDoc(ref).then(async (userAccessSplit: any) => {
        if (userAccessSplit) {
          const mergeAccess: Array<any> = [
            ...userAccessSplit.organisation || [],
            ...userAccessSplit.organisationAdmin || [],
            ...userAccessSplit.organisationMember || [],
            ...userAccessSplit.organisationGuest || [],
          ];

          const HW_ORG_myAccess = onlyUniqueArray(mergeAccess);
          let organisations: any = [];
          let defaultOrg: string = '';

          if (HW_ORG_myAccess.length > 0) {
            let tmp_organisations = await organisationByIds(HW_ORG_myAccess);
            organisations = await addImageByIds(tmp_organisations);

            defaultOrg = localSt.obtainNow(LOCAL_ST.defaultOrganisation);
            if (!defaultOrg && HW_ORG_myAccess[0]) {
              defaultOrg = HW_ORG_myAccess[0];
              localSt.defineNow(LOCAL_ST.defaultOrganisation, HW_ORG_myAccess[0].id || '')
            }
          }

          resolve({
            HW_ORG_myAccessAdmin: [
              ...userAccessSplit.organisationAdmin || []
            ],
            HW_ORG_myAccessGuest: [
              ...userAccessSplit.organisationGuest || []
            ],
            HW_ORG_myAccessMember: [
              ...userAccessSplit.organisationMember || []
            ],
            HW_ORG_myAccess: HW_ORG_myAccess,
            HW_ORG_listDetailed: organisations,
            defaultOrganisation: orgByOid(defaultOrg, organisations),
          })
        } else {
          resolve({
            HW_ORG_myAccessAdmin: [],
            HW_ORG_listDetailed: [],
            HW_ORG_myAccess: [],
            defaultOrganisation: {}
          })
        }
      });
    });
  }

  ///////////////
  // <== METHODS
  ///////////////

  const refreshUserAccess_fn = () => {
    MAIN_getUserAccess_fn()
      .then(({
        HW_ORG_myAccessAdmin,
        HW_ORG_myAccessGuest,
        HW_ORG_myAccessMember,
        HW_ORG_myAccess,
        HW_ORG_listDetailed,
        defaultOrganisation
      }: any) => {
        setMain_Data((draft: any) => {
          draft.data.HW_ORG_myAccessAdmin = HW_ORG_myAccessAdmin;
          draft.data.HW_ORG_myAccessMember = HW_ORG_myAccessMember;
          draft.data.HW_ORG_myAccessGuest = HW_ORG_myAccessGuest;
          draft.data.HW_ORG_myAccess = HW_ORG_myAccess;
          draft.data.HW_ORG_listDetailed = HW_ORG_listDetailed;
          draft.data.HW_DEFAULT.organisation = defaultOrganisation;
        })

        setTimeout(() => {
          setMain_Data((draft: any) => {
            draft.loading = false;
          });
        }, 2000);
      })
  }

  const initContext = () => {

    const DATA_FUNCTIONS = {
      loadCoreOrg: (oid: string) => {
        return resetOrg(oid).then(async () => {
          await loadUsers_fn(oid)
          await loadTeams_fn(oid)
          await loadProjects_fn(oid)
        })
      },
      refreshUserAccess: () => {
        refreshUserAccess_fn();
      },
      update: {
        team: (oid: string, teamId: string, payload: any) => {
          return new Promise(async (resolve, reject) => {
            updateDoc(FSorganisations.doc(oid).collection('teams').doc(teamId), payload).then((res) => {
              loadTeams_fn(oid);
              resolve(true);
            })
          });
        }
      },
      delete: {
        team: (oid: string, teamId: string) => {
          return new Promise(async (resolve, reject) => {
            softDeleteDoc(FSorganisations.doc(oid).collection('teams').doc(teamId)).then((res) => {
              loadTeams_fn(oid);
              resolve(true);
            }).catch((err: any) => {
              console.error(err);
              reject();
            });
          });
        }
      },
      create: {
        team: (oid: string, payload: any) => {
          return new Promise(async (resolve, reject) => {
            addDoc(FSorganisations.doc(oid).collection('teams'), payload).then((res) => {
              loadTeams_fn(oid);
              resolve(true);
            }).catch((err: any) => {
              reject();
            });
          });
        },
      },
      refresh: {
        org_allProfileImg: () => {
          if (mainData.data.HW_ORG_listDetailed) {
            addImageByIds(mainData.data.HW_ORG_listDetailed, true).then((res: any) => {
              setMain_Data((draft: any) => { draft.data.HW_ORG_listDetailed = res });
            }).catch((err: any) => {
              console.error(err);
            })
          } else {
            console.error("HW_ORG_listDetailed not exist in context");
          }
        },
        org_profileImg: (oid: string, downloadURL: string) => {
          if (mainData.data.HW_ORG_listDetailed) {
            mainData.data.HW_ORG_listDetailed.map((org: any, index: number) => {

              if (org.id === oid) {
                // "Update image : " + oid + ", image: " + downloadURL
                // mainData.data.HW_ORG_listDetailed[index]
                setMain_Data((draft: any) => {
                  draft.data.HW_ORG_listDetailed[index].IMAGE_URL = downloadURL
                });
              }

              return true;
            })
          }
        }
      },
      get: {
        org: (oid: string) => {
          let org: Array<any> = [];
          if (mainData.data.HW_ORG_listDetailed) {
            mainData.data.HW_ORG_listDetailed.map((organisation: any) => {
              if (organisation.id === oid) org = organisation;
              return true
            })
          }
          return org;
        }
      }
    }

    setMain_Data((draft: any) => {
      draft.data.HW_FUNCTIONS = DATA_FUNCTIONS;
      draft.setMain_Data = setMain_Data;
    });

    refreshUserAccess_fn();
  }

  useEffect(() => {
    console.log("Init Context")
    initContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HowWeeContext.Provider value={mainData} >
      {props.children}
    </HowWeeContext.Provider>
  )

}

export default HowWeeContext;

export {
  HowWeeProvider,
  Consumer as HowWeeConsumer,
};