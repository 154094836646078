import React, { useState, useEffect } from 'react';
import GridCenter from 'pages/App/components/GridCenter'
import { Button, Checkbox, Form, Input } from 'semantic-ui-react'
import SelectorSubjects from 'pages/App/components/Dropdowns/SelectorSubjects'
import SelectorTeams from 'pages/App/components/Dropdowns/SelectorTeams'
import SelectorUsers from 'pages/App/components/Dropdowns/SelectorUsers'
import { useQuestionsHook } from 'services/hooks/useQuestionsHook'
import { TitleField } from './styles'
import { useImmer } from 'use-immer';
import { DateInput } from 'semantic-ui-calendar-react'


function Fields({ initialSearch, searchCtt, oid, independent, onlyAnswerSelected }: any) {

  const { functions: { titleToStatus } } = useQuestionsHook(oid);

  const [usersForQ, setUsersForQ] = useState([]);
  const [authorsForQ, setAuthorsForQ] = useState([]);
  const [teamsForQ, setTeamsForQ] = useState([]);
  const [subjectsForQ, setSubjectsForQ] = useState([]);
  const [toggleUIFilters, setToggleUIFilters] = useState(false);

  const [priority, setPriority]: any = useImmer({
    low: false,
    medium: false,
    high: false
  });

  const [status, setStatus]: any = useImmer({
    open: false,
    review: false,
    selected: false,
    archived: false
  });

  const [extra, setExtra]: any = useImmer({
    description: false
  });

  const [dates, setDates]: any = useImmer({
    dateTo: null,
    dateFrom: null,
  })

  const [searchTermUser, setSearchTermUser] = useState(independent ? searchCtt.searchDataIndependent.searchTerm : searchCtt.searchData.searchTerm);

  useEffect(() => {
    if (!independent) {
      if (searchCtt.searchData.searchTerm !== searchTermUser) {
        setSearchTermUser(searchCtt.searchData.searchTerm)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCtt.searchData.searchTerm]);

  useEffect(() => {
    if (independent) {
      if (searchCtt.searchDataIndependent.searchTerm !== searchTermUser) {
        setSearchTermUser(searchCtt.searchDataIndependent.searchTerm)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCtt.searchDataIndependent.searchTerm]);

  const btnSearch = () => {
    const statusSimplified: Array<string> = [];
    const prioritySimplified: Array<string> = [];
    const extraSimplified: Array<string> = [];

    Object.entries(status).forEach(([key, value]) => {
      if (value === true) {
        statusSimplified.push(titleToStatus(key))
      }
    });

    Object.entries(priority).forEach(([key, value]) => {
      if (value === true) {
        prioritySimplified.push(key)
      }
    });

    Object.entries(extra).forEach(([key, value]) => {
      if (value === true) {
        extraSimplified.push(key)
      }
    });

    searchCtt.setSearchData((draft: any) => {
      let index = independent ? 'searchDataIndependent' : 'searchData'
      draft[index].searchTerm = searchTermUser;
      draft[index].searchFilters = {
        author: authorsForQ,
        users: usersForQ,
        teams: teamsForQ,
        subjects: subjectsForQ,
        status: statusSimplified,
        priority: prioritySimplified,
        dateTo: dates.dateTo,
        dateFrom: dates.dateFrom,
        extra: extraSimplified
      }
    })
  }

  const capturePriority = (e, { name, checked }: any) => {
    setPriority((draft: any) => {
      draft[name] = checked;
    })
  }

  const captureStatus = (e, { name, checked }: any) => {
    setStatus((draft: any) => {
      draft[name] = checked;
    })
  }

  const captureExtraCheckbox = (e, { name, checked }: any) => {
    setExtra((draft: any) => {
      draft[name] = checked;
    })
  }

  const captureDate = (e, { name, value }: any) => {
    let res = value === '' ? null : value;
    setDates((draft: any) => {
      draft[name] = res;
    })
  }

  const toggleFilters = () => {
    setToggleUIFilters(!toggleUIFilters);
  }


  return (
    <div style={{ marginBottom: '-25px' }}>
      <GridCenter fullWidth>
        <Form>
          <Form.Group>
            <Form.Field width={16}>
              <Input
                action={
                  <Button
                    primary={toggleUIFilters}
                    icon='filter'
                    onClick={toggleFilters}
                  />
                }
                icon='search'
                iconPosition='left'
                value={searchTermUser}
                onChange={(e: any) => setSearchTermUser(e.target.value)}
                placeholder='Search for question...'
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    let index = independent ? 'searchDataIndependent' : 'searchData'
                    searchCtt.setSearchData((draft: any) => {
                      draft[index].searchTerm = e.target.value;
                    })
                  }
                }}
              />
            </Form.Field>
          </Form.Group>
          {toggleUIFilters &&
            <>
              <Form.Group>
                <Form.Field width={2}>
                  <TitleField> Subject </TitleField>
                </Form.Field>
                <Form.Field width={14}>
                  <SelectorSubjects
                    oid={oid}
                    setResult={setSubjectsForQ}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={2}>
                  <TitleField> Author </TitleField>
                </Form.Field>
                <Form.Field width={14}>

                  <SelectorUsers
                    oid={oid}
                    setResult={setAuthorsForQ}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={2}>
                  <TitleField> Person </TitleField>
                </Form.Field>
                <Form.Field width={14}>

                  <SelectorUsers
                    oid={oid}
                    setResult={setUsersForQ}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={2}>
                  <TitleField> Team </TitleField>
                </Form.Field>
                <Form.Field width={14}>
                  <SelectorTeams
                    oid={oid}
                    setResult={setTeamsForQ}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group style={{ marginTop: '-20px' }}>
                <Form.Field width={8}>
                  <TitleField> From </TitleField>
                </Form.Field>
                <Form.Field width={8}>
                  <TitleField> To </TitleField>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={8}>
                  <DateInput
                    name="dateFrom"
                    clearable
                    placeholder="From"
                    value={dates.dateFrom}
                    iconPosition="left"
                    onChange={captureDate}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <DateInput
                    name="dateTo"
                    clearable
                    placeholder="To"
                    value={dates.dateTo}
                    iconPosition="left"
                    onChange={captureDate}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={16}>
                  <span> Status </span>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={4}>
                  <Checkbox toggle disabled={onlyAnswerSelected} onChange={captureStatus} name='open' label='Open' />
                </Form.Field>
                <Form.Field width={4}>
                  <Checkbox toggle disabled={onlyAnswerSelected} onChange={captureStatus} name='answered' label='Review' />
                </Form.Field>
                <Form.Field width={4}>
                  <Checkbox toggle disabled={onlyAnswerSelected} onChange={captureStatus} name='selected' label='Selected' />
                </Form.Field>
                <Form.Field width={4}>
                  <Checkbox toggle disabled={onlyAnswerSelected} onChange={captureStatus} name='archived' label='Archived' />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={16}>
                  <span> Priority </span>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={4}>
                  <Checkbox
                    onChange={capturePriority}
                    toggle
                    name='high'
                    label='High'
                  />
                </Form.Field>
                <Form.Field width={4}>
                  <Checkbox
                    onChange={capturePriority}
                    toggle
                    name='medium'
                    label='Medium'
                  />            </Form.Field>
                <Form.Field width={4}>
                  <Checkbox
                    onChange={capturePriority}
                    toggle
                    name='low'
                    label='Low'
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={16}>
                  <span> Deeper Search </span>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={16}>
                  <Checkbox
                    onChange={captureExtraCheckbox}
                    toggle
                    name='description'
                    label='Search in Question Context text'
                  />
                </Form.Field>
              </Form.Group>
            </>
          }
          <Form.Group>
            <Form.Field width={16}>
              <Button onClick={btnSearch} primary style={{ float: 'right' }}>
                Search
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </GridCenter >
    </div>
  );
}

export default Fields;