import { toast } from 'react-toastify';
import { useFirestoreCollectionData, useFirestoreDocData, useUser } from 'reactfire'
import { projectType } from 'typings/projects';
import { useFirestoreHook } from './useFirestoreHook';
import { useHistoryHook } from './useHistoryHook';

export function useProjectHook(oid: string) {

  const { uid }: any = useUser()
  const { collections: { HWFS }, functions: { addDoc, updateDoc } } = useFirestoreHook();
  const { functions: { nhr_discussion_archive, nhr_discussion_restored } } = useHistoryHook(oid);

  const projectsRef = HWFS.projects(oid)
  const projectsDocs = useFirestoreCollectionData(projectsRef, { idField: 'id' });

  const teamsRef = HWFS.teams(oid);
  const teamsDocs = useFirestoreCollectionData(teamsRef, { idField: 'id' });

  const accessLiveRef = HWFS.projects_access_user(oid);
  const accessLive: any = useFirestoreDocData(accessLiveRef, { idField: 'id' });

  const projectById = (projId: string) => {
    const project: projectType | null = projectsDocs.filter((proj: any) => proj.id === projId)[0] || null;
    return project;
  }

  const newProject = (payload: any) => {
    return addDoc(projectsRef, payload)
  }

  const editProject = (payload: any, projId: string) => {
    return updateDoc(projectsRef.doc(projId), payload);
  }

  const userHaveSomeAccessInProject = (projId: string) => {
    const project: any = projectById(projId);

    if (
      project?.createdBy === uid ||
      project?.editors?.includes(uid) ||
      project?.users?.includes(uid)
    ) return true

    return false
  }

  const userIsAdmin = (projId: string) => {
    const project: any = projectById(projId);

    if (
      project?.createdBy === uid ||
      project?.editors?.includes(uid)
    ) return true

    return false
  }

  const confirmArchiveDiscussion = (projId: string) => {
    return HWFS.projects(oid).doc(projId).update({ status: 410 })
      .then((res: any) => {
        nhr_discussion_archive(projId, {}, projId)
        toast.info('Discussion archived correctly');
      }).catch((err: any) => {
        console.error(err)
      })
  }

  const restoreProject = (projId: string) => {
    return HWFS.projects(oid).doc(projId).update({ status: 200 })
      .then((res: any) => {
        nhr_discussion_restored(projId, {}, projId)
        toast.info('Discussion restored correctly');
      }).catch((err: any) => {
        console.error(err)
      })
  }

  const validateTeamInProject = (projId: string) => {
    let response: boolean = false;
    const proj: any = projectById(projId);
    if (proj && proj.teams) {
      teamsDocs.map((team: any) => {
        if (team.users && proj.teams) {
          if (team.users.includes(uid) && proj.teams.includes(team.id)) {
            response = true;
          }
        }
        return null
      })
    }
    return response;
  }

  const validateAccessHelper = (projId: string) => {
    if (
      validateTeamInProject(projId) ||
      accessLive?.projectsAuthor?.includes(projId) ||
      accessLive?.projectsEditor?.includes(projId) ||
      accessLive?.projectsIndividual?.includes(projId)
    ) {
      return true;
    } else {
      return false
    }
  }

  return {
    data: {
      projectsDocs,
      accessLive
    },
    functions: {
      validateAccessHelper,
      projectById,
      newProject,
      editProject,
      userHaveSomeAccessInProject,
      userIsAdmin,
      confirmArchiveDiscussion,
      restoreProject,
    }
  }
}