import React from 'react';
import { Label } from 'semantic-ui-react';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'

interface Props {
  teamId: string;
  oid: string;
}

function BadgeTeam({ teamId, oid }: Props) {

  const { functions: { teamById } } = useOrganisationHook(oid);
  const team = teamById(teamId)

  return (
    <Label color={team ? team.color : 'teal'} size='tiny' style={{ float: 'left', margin: '2px' }}>
      {team ? team.name : '???'}
    </Label>
  )
}

export default BadgeTeam;