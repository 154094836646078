import { useUser } from 'reactfire'
import { useFirestoreHook } from './useFirestoreHook'
import { useOrganisationHook } from './useOrganisationHook';
import { useHistoryHook } from './useHistoryHook';
import { useProjectHook } from './useProjectHook';

export function useReportsHook(oid: string) {

  const { functions: {
    nhr_report_new,
    nhr_report_newWithEditors,
    nhr_report_delete,
    nhr_report_edit,
    nhr_report_editQuestionsOrder,
    nhr_report_editQuestions,
    nhr_report_add_editor,
    nhr_report_add_viewer,
    nhr_report_add_teams,
    nhr_report_remove_viewer,
    nhr_report_remove_editor,
    nhr_report_remove_teams,
  } } = useHistoryHook(oid);
  const { functions: { isPartOfSomeTeam } } = useOrganisationHook(oid)

  const { uid }: any = useUser();

  const { functions: { addDoc }, collections: { HWFS } } = useFirestoreHook();
  const { data: { projectsDocs } } = useProjectHook(oid)

  const equals = (a: Array<any>, b: Array<any>) =>
    a.length === b.length &&
    a.every((x: any, z: any) => x === b[z]);

  const updateReport_historyEvents = (projId: string, id: string, data: any, prevData: any) => {
    try {
      if (
        (data.questionsIncluded.length !==
          prevData.questionsIncluded.length)
        ||
        !equals(data.questionsIncluded, prevData.questionsIncluded)
      ) {
        nhr_report_editQuestions(id, projId)
      } else if (
        JSON.stringify(data.questionsIncludedLexorank) !==
        JSON.stringify(prevData.questionsIncludedLexorank)) {
        nhr_report_editQuestionsOrder(id, projId)
      } else {
        nhr_report_edit(id, projId)
      }
    } catch (err) {
      console.error(err)
      nhr_report_edit(id, projId)
    }

    try {
      if (JSON.stringify(data.editorUsers) !==
        JSON.stringify(prevData.editorUsers)) {
        let added = data.editorUsers.filter((x: any) => !prevData.editorUsers.includes(x))
        let removed = prevData.editorUsers.filter((x: any) => !data.editorUsers.includes(x))

        if (added.length > 0) {
          nhr_report_add_editor(id, { users: added }, projId)
        }

        if (removed.length > 0) {
          nhr_report_remove_editor(id, { users: removed }, projId)
        }
      }

      if (JSON.stringify(data.viewerUsers) !==
        JSON.stringify(prevData.viewerUsers)) {
        let added = data.viewerUsers.filter((x: any) => !prevData.viewerUsers.includes(x))
        let removed = prevData.viewerUsers.filter((x: any) => !data.viewerUsers.includes(x))

        if (added.length > 0) {
          nhr_report_add_viewer(id, { users: added }, projId)
        }

        if (removed.length > 0) {
          nhr_report_remove_viewer(id, { users: removed }, projId)
        }
      }

      if (JSON.stringify(data.viewerTeams) !==
        JSON.stringify(prevData.viewerTeams)) {
        let added = data.viewerTeams.filter((x: any) => !prevData.viewerTeams.includes(x))
        let removed = prevData.viewerTeams.filter((x: any) => !data.viewerTeams.includes(x))

        if (added.length > 0) {
          nhr_report_add_teams(id, { teams: added }, projId)
        }

        if (removed.length > 0) {
          nhr_report_remove_teams(id, { teams: removed }, projId)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const updateReport = (projId: string, id: string, data: any, prevData?: any) => {
    return HWFS.reports(oid, projId).doc(id).update({ ...data }).then((res: any) => {
      updateReport_historyEvents(projId, id, data, prevData)
    }).catch((err) => {
      console.error(err)
    })
  }

  const createReport = (projId: string, data: any) => {
    return addDoc(HWFS.reports(oid, projId), data).then((res) => {
      if (res.id) {
        data.editorUsers && data.editorUsers.length > 0 ?
          nhr_report_newWithEditors(res.id, projId) :
          nhr_report_new(res.id, projId)
      }
    })
  }

  const allReportsInOrg = () => {
    return new Promise((resolve, reject) => {
      let reports: Array<any> = [];

      projectsDocs.map(async (project: any, index: number) => {

        if (project.id) {
          await HWFS.reports(oid, project.id).get().then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
              let info = doc.data();
              if (info && doc.id) {
                let payload = { id: doc.id, ...info }
                try {
                  reports.push(payload)
                } catch (err) {
                  console.error(err)
                }
              }
            });
          })
        }

        if (index + 1 === projectsDocs.length) {
          resolve(reports)
        }

        return null
      })
    })
  }

  const getReport = (id: string, projId: string) => {
    return HWFS.reports(oid, projId).doc(id).get()
  }

  const deleteReport = (projId: string, id: string) => {
    return updateReport(projId, id, { status: 404 }).then((res: any) => {
      if (res.id) {
        nhr_report_delete(res.id, projId)
      }
    })
  }

  const isOwner = (createdBy: any) => {
    if (createdBy === uid) return true
    return false
  }

  const userHaveSomeAccessInReport = (reportData: any) => {
    const { viewerTeams, editorUsers, viewerUsers, createdBy } = reportData;

    if (
      (viewerTeams && isPartOfSomeTeam(viewerTeams, uid)) ||
      (editorUsers && editorUsers.includes(uid)) ||
      (viewerUsers && viewerUsers.includes(uid)) ||
      (createdBy && createdBy === uid)) {
      return true;
    }

    return false
  }

  const reportAccessExtended = (reportData: any) => {

    let result = { viewer: false, viewerFromTeam: false, editor: false, owner: false }
    const { viewerTeams, editorUsers, viewerUsers, createdBy } = reportData;

    if (!userHaveSomeAccessInReport(reportData)) return result;

    if (viewerUsers && viewerUsers.includes(uid)) result.viewer = true;
    if (editorUsers && editorUsers.includes(uid)) result.editor = true;
    if (viewerTeams && viewerTeams.includes(uid)) result.viewerFromTeam = true;
    if (createdBy && createdBy === uid) result.owner = true;

    return result;
  }

  return {
    functions: {
      createReport,
      updateReport,
      deleteReport,
      getReport,
      isOwner,
      userHaveSomeAccessInReport,
      reportAccessExtended,
      allReportsInOrg
    }
  }
}