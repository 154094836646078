import React from 'react';
import { Contexify } from '../Contexify'
import { Card, Image, Label, Icon, Popup, Dimmer } from 'semantic-ui-react';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';
import { onlyUniqueArray } from 'helpers/mini'
import { BadgeMultipleUser } from 'pages/App/components/Badges';
import capitalize from 'capitalize';
import { questionType } from 'typings/questions'
import { orgId } from 'typings/generic';

interface Props {
  oid: typeof orgId;
  question: questionType;
  disabled?: boolean;
  disableDetailFloated?: boolean;
  independent: boolean;
  style?: React.CSSProperties;
}

export function CardContentQuestion({ oid, question, disabled, independent = false, disableDetailFloated = false }: Props) {


  const { functions: { photoUserByID, usersById, subjectById } } = useOrganisationHook(oid);
  const { functions: { openQuestionFloated } } = useQuestionsHook(oid)

  const getStyleForSubjects = (subjects: Array<string>) => {
    if (!subjects || subjects.length === 0) return { padding: '0.3em', paddingLeft: '0em', paddingRight: '1em' }
    return { padding: '0.4em', paddingLeft: '0.5em', paddingRight: '1em' }
  }

  return (
    <Contexify question={question} oid={oid}>
      <Card
        color='blue'
        style={{
          minHeight: 'auto',
          marginTop: '10px',
          marginBottom: '10px',
          position: 'static',
          width: independent ? '100%' : 'auto',
        }}
        onClick={() => {
          if (!disableDetailFloated) {
            openQuestionFloated(question)
          }
        }}
      >
        <Dimmer active={disabled} inverted />
        <div
          style={{ cursor: 'pointer', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', padding: '5px 10px' }}>
          <p style={{ color: 'grey', marginBottom: '0px' }}>
            {question.questionPrefix ?
              `${capitalize(question.questionPrefix)}...` :
              'How do we...'
            }
          </p>
          <p style={{ marginTop: '0px', fontWeight: 500, color: 'black', fontSize: '14px' }}>{question.question}</p>
        </div>
        {question.subjects?.length > 0 &&
          <Card.Content style={getStyleForSubjects(question.subjects)}>
            {question.subjects && question.subjects.map((subjetId: string, index: number) => {
              return (
                <Label
                  color='grey'
                  size='tiny'
                  style={{ float: 'left', margin: '2px' }}
                  key={index}
                >
                  #{subjectById(subjetId)}
                </Label>
              )
            })}
          </Card.Content>
        }
        <Card.Content>
          <div>
            <Popup
              basic
              content={usersById(question.createdBy).displayName}
              trigger={
                <Image src={photoUserByID(question.createdBy) || 'http://www.gravatar.com/avatar/?d=identicon'} avatar />
              }
            />

            {
              question.priority === 'high' &&
              <Icon name="arrow up" style={{ color: '#FF0012' }} />
            }
            {
              question.priority === 'medium' &&
              <Icon name="arrow right" style={{ color: '#FFBA37' }} />
            }
            {
              question.priority === 'low' &&
              <Icon name="arrow down" style={{ color: '#00A54F' }} />
            }

            {
              (question && question.usersWithComments && question.usersWithComments.length > 0) &&
              <BadgeMultipleUser users={onlyUniqueArray(question.usersWithComments)} oid={oid} />
            }

            {/* {(question && question.users && question.users.length > 0) &&
              <Icon name="arrow right" />
            }
            {(question && question.users) &&
              question.users.map((uid: string, index: number) => {
                if (index > 1) return (null)
                return (
                  <Popup
                    basic
                    key={index}
                    content={usersById(uid).displayName}
                    trigger={
                      <Image src={photoUserByID(uid)} key={index} avatar />
                    }
                  />
                )
              })
            }
            {question && question.users && question.users.length > 2 &&
              <Button circular size='mini' style={{ paddingLeft: '10px', paddingRight: '10px' }}> +{question.users.length - 2} </Button>
            } */}

            {/* {question.teams && question.teams.map((teamId: string, index: number) => {
            let team = teamById(teamId);
            if (team) {
              return (
                <Label key={index} color='teal' size='tiny' style={{ float: 'left', margin: '2px' }}>
                {team.name || '???'}
                </Label>
                )
              }
              return null
            })} */}
          </div>
        </Card.Content>
      </Card>
    </Contexify >
  );
}