import styled from 'styled-components'

export const Printable = styled.div`
  @media screen {
    display: none;
  }

  @media print {
      display: block;
      padding: 3em;
  }

  @page { margin-bottom: 4em }
`