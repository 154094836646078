import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, Progress, Dimmer, Icon, Loader, Divider } from 'semantic-ui-react'
import { SuspenseWithPerf } from 'reactfire'
import InputPack from '../../InputPack'
import { useImmer } from 'use-immer'
import { countriesSelect } from 'helpers/countries'
import DropdownPack from 'pages/App/components/DropdownPack'
import TextareaPack from 'pages/App/components/TextareaPack'
import { useOrganisationsHook } from 'services/hooks/useOrganisationsHook'
import { toast } from 'react-toastify'
import * as firebase from 'firebase/app'
import 'firebase/storage';
import ProfilePicture, { handleUploadImage } from 'pages/App/components/ProfilePicture';
import HowWeeContext from 'services/contexts/ContextHowWee';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';

interface ProfileProps {
  organisation: any;
  organisationPublic: any;
  orgId: string;
}

function ProfileElement({ organisation, organisationPublic, orgId }: ProfileProps) {

  const storage = firebase.storage();
  const { ref: { organisationRef, organisation_publicRef } } = useOrganisationsHook();
  const { functions: { getMyRole } } = useOrganisationHook(orgId);

  const HWCtt: any = useContext(HowWeeContext);

  const [profilePic, setProfilePicPreview] = useState()
  const [file, setFile] = useState(null);
  const [profilePicLoading, setProfilePicPreviewLoading] = useState(true)
  const [percentProgressProfile, setPrecentProgressProfile] = useState(0);
  const [disabledForm, setDisabledForm] = useState(false);
  const [isAdmin] = useState(getMyRole() === 'admin');
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useImmer({
    name: organisation.name || '',
    description: organisation.description || '',
    domain: organisation.domain || '',
    username: organisation.username || '',
    address: {
      lineOne: organisation.address ? organisation.address.lineOne : '',
      lineTwo: organisation.address ? organisation.address.lineTwo : '',
      city: organisation.address ? organisation.address.city : '',
      region: organisation.address ? organisation.address.region : '',
      postalCode: organisation.address ? organisation.address.postalCode : '',
      country: organisation.address ? organisation.address.country : '',
    }
  });

  const [publicField, setPublicField] = useImmer({
    name: organisationPublic.name ? true : false,
    description: organisationPublic.description ? true : false,
  });

  useEffect(() => {
    if (organisation.imageProfile) {
      const pathImageReference = storage.ref(organisation.imageProfile);
      pathImageReference.getDownloadURL().then(function (url) {
        setProfilePicPreview(url);
        setProfilePicPreviewLoading(false);
      });
    } else {
      setProfilePicPreviewLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // percentage bar
    let total = 0, notEmpty = 0;
    Object.entries(form).forEach((val) => {
      total++;
      if (val[1] !== '' && val[1] !== undefined) {
        notEmpty++
      } else if (val[1] && val[1].length > 0) {
        notEmpty++
      }
    });

    const result = notEmpty / total * 100;
    setPrecentProgressProfile(result);
    ////

    if (form.name === '') {
      setDisabledForm(true);
    } else {
      if (isAdmin) {
        setDisabledForm(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (!isAdmin) setDisabledForm(true)
  }, [isAdmin]);

  const handleOnChange = (evn: any, data: any) => {

    let name: string, value: string;

    if (data) {
      name = data.name;
      value = data.value;
    } else {
      // textarea case
      name = evn.target.name;
      value = evn.target.value;
    }

    // Fix special inputs
    if (name === 'username') {
      value = value.replace(/ /g, "_"); // space to underscore
      value = value.replace(/[^a-zA-Z0-9]/g, ''); // special cha. to nothing
    }

    if (name === 'domain') {
      value = value.replace(/ /g, "_"); // space to underscore
    }
    //

    // max 2, i.e : adress.lineOne, adress.lineTwo
    const nameSplit = name.split(".");

    if (nameSplit.length === 1) {
      setForm((draft: any) => {
        draft[name] = value;
      });
    } else {
      setForm((draft: any) => {
        draft[nameSplit[0]][nameSplit[1]] = value;
      });
    }
  }

  const onChangePublicToggle = (e: any, { name, checked, ...checkbox }: any) => {
    setPublicField((draft: any) => {
      draft[name] = checked;
    });
  }

  const savePublicData = (pathImage?: string) => {

    let payloadPublic: any = { status: 200 };
    payloadPublic.imageProfile = organisation.imageProfile || '';
    if (pathImage) payloadPublic.imageProfile = pathImage;

    Object.entries(publicField).forEach((val) => {
      const key: string = val[0];
      if (val[1] === true) {
        payloadPublic[key] = form[key];
      }
    });

    organisation_publicRef.doc(orgId).set(payloadPublic).then((res: any) => {
      toast.success(<><Icon name='check' /> Profile edited successfully </>)
    }).catch((e: any) => {

    });
  }

  const saveAllChanges = () => {
    setLoading(true);
    const refStorage = `/organisations_public/${orgId}/profile_${Math.floor(Date.now() / 1000)}`;
    handleUploadImage({ file, setProfilePicPreview, refStorage }).then(async ({ pathImage, downloadURL }: any) => {

      if (downloadURL) {
        // "update preview in side menu...", orgId, downloadURL
        HWCtt.data.HW_FUNCTIONS.refresh.org_profileImg(orgId, downloadURL);
        setProfilePicPreview(downloadURL);
      }

      let payload;

      if (pathImage) {
        payload = { imageProfile: pathImage, ...form };
      } else {
        payload = { ...form };
      }

      organisationRef.doc(orgId).update(payload).then((res: any) => {
        savePublicData(pathImage);
        setLoading(false);
      }).catch((error: any) => {

        setLoading(false);
      });

    })
  }

  return (
    <Grid columns='equal'>
      <Grid.Column></Grid.Column>
      <Grid.Column width={12}>
        <Dimmer active={loading} inverted>
          <Loader size='medium'>Loading</Loader>
        </Dimmer>
        <Grid>
          <Grid.Row columns={1}>
            {isAdmin && percentProgressProfile !== 100 &&
              <Grid.Column>
                <b>
                  {percentProgressProfile === 100 ? 'Everything Done!' : 'Progress on your organisation'}
                </b>
                <Progress percent={percentProgressProfile} indicating>
                </Progress>
              </Grid.Column>
            }
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <ProfilePicture
                file={file}
                setFile={setFile}
                profilePic={profilePic || ''}
                setProfilePicPreview={setProfilePicPreview}
                profilePicLoading={profilePicLoading || false}
                disabled={disabledForm}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <InputPack
                publicToggle
                value={form.name}
                publicField={publicField.name}
                setValue={handleOnChange}
                setPublicField={onChangePublicToggle}
                name='name'
                disabled={disabledForm}
                description="This is the name of your Organisation. This is typically the name of your company."
                label='Organisation Display Name'
                placeHolder='Name'
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                value={form.username}
                setValue={handleOnChange}
                name='username'
                label='Organisation Identifier'
                placeHolder='This name must be unique and can not contain special characters or spaces'
                icon='at'
                disabled={disabledForm}
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                value={form.domain}
                setValue={handleOnChange}
                name='domain'
                description="Please enter the domain you use for employees that work at your Organisation"
                label='Domain'
                placeHolder='@yourdomain.com'
                disabled={disabledForm}
              />
            </Grid.Column>
            <Grid.Column>
              <TextareaPack
                label="Bio"
                placeholder="What does your Organisation do?"
                row={3}
                name='description'
                value={form.description}
                setValue={handleOnChange}
                disabled={disabledForm}
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                value={form.address.lineOne}
                setValue={handleOnChange}
                name='address.lineOne'
                label='Line 1'
                placeHolder='Street and Number'
                disabled={disabledForm}
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                value={form.address.lineTwo}
                setValue={handleOnChange}
                name='address.lineTwo'
                label='Line 2'
                placeHolder='Line 2'
                disabled={disabledForm}
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                value={form.address.city}
                setValue={handleOnChange}
                name='address.city'
                label='City'
                placeHolder='City'
                disabled={disabledForm}
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                value={form.address.region}
                setValue={handleOnChange}
                name='address.region'
                label='Region'
                placeHolder='Region'
                disabled={disabledForm}
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                value={form.address.postalCode}
                setValue={handleOnChange}
                name='address.postalCode'
                label='Postal Code'
                typeInput='number'
                placeHolder='ZIP Code'
                disabled={disabledForm}
              />
            </Grid.Column>
            <Grid.Column>
              <DropdownPack
                label="Country"
                disabled={disabledForm}
                placeholder="Select Country"
                options={countriesSelect()}
                defaultOption={organisation.address ? organisation.address.country : ''}
                setOption={(value: string) => {
                  setForm((draft) => {
                    draft.address.country = value;
                  })
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Divider style={{ marginTop: '30px', marginBottom: '30px' }} />
            </Grid.Column>
            <Grid.Column>
              <Button primary disabled={disabledForm} onClick={saveAllChanges} style={{ float: 'right' }}>Save Changes</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column></Grid.Column>
    </Grid>
  );
}

function Profile(props: any) {

  return (
    <SuspenseWithPerf fallback={<p>Cargando datos...</p>} traceId='secure_settings_organisation_profile'>
      <ProfileElement {...props} />
    </SuspenseWithPerf>
  )

}

export default Profile;