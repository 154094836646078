import React, { useEffect, useState } from 'react';
import { Loading } from 'pages/App/components/Loading';
import { SuspenseWithPerf } from 'reactfire';
import { Grid } from 'semantic-ui-react';
import { useHistoryHook } from 'services/hooks/useHistoryHook'
import HistoryItems from '../Items'


function HistoryContentDiscussionCore({ projId, oid }: any) {

  const { collections: { historyByDiscussion } } = useHistoryHook(oid);

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    historyByDiscussion(projId).then((historyItems: any) => {
      setData(historyItems)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid.Row>
        {loading ?
          <Loading /> :
          <HistoryItems oid={oid} historyData={data} />
        }
      </Grid.Row>
    </>
  );
}

function HistoryContentDiscussion(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Loading />}
      traceId={`howwee-history-${props.oid || ''}-${props.uid || ''}`}
    >
      <HistoryContentDiscussionCore {...props} />
    </SuspenseWithPerf>
  )
}

export default HistoryContentDiscussion;