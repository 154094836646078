import React from 'react';
import { Dropdown, Button, Label } from 'semantic-ui-react';

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
]

const options = () => {
  const result = [];
  let count = 0;
  colors.forEach((col: string) => {
    count++;
    result.push({
      key: count,
      text: col,
      value: col,
      content: (
        <Label color={col} key={col}>
          {col.charAt(0).toUpperCase() + col.slice(1)}
        </Label>
      ),
    })
  });

  return result;
}

interface Props {
  color: string,
  setColor: any;
}

function DropdownColour({ color, setColor }: Props) {
  return (
    <Button.Group color={color.toLowerCase()}>
      <Button>{color.charAt(0).toUpperCase() + color.slice(1)}</Button>
      <Dropdown
        className='button icon'
        floating
        scrolling
        options={options()}
        trigger={<></>}
        onChange={(e, { name, value }: any) => {
          setColor(value)
        }}
      />
    </Button.Group >
  );
}

export default DropdownColour;