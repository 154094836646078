import React, { useContext, useEffect } from 'react'
import TopMenu from './Layout/TopMenu'
import Primary from './Primary'
import { Secondary } from './Secondary'
import { navigate, Router } from "@reach/router"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HowWeeContext from 'services/contexts/ContextHowWee'
import { useGoogleAnalyticsHook } from 'services/hooks/useGoogleAnalyticsHook'
import "./index.css"
import "./responsive-semantic-ui.css"

export const Main = () => {

    const HWCtt: any = useContext(HowWeeContext);
    const { functions: { newEventGA } } = useGoogleAnalyticsHook();

    useEffect(() => {
        newEventGA('general', 'view', 'hw_init_app',);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (window.location.pathname === '/') {
            if (HWCtt.data.HW_DEFAULT?.organisation?.id) {
                navigate(`app/org/${HWCtt.data.HW_DEFAULT.organisation.id}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);



    return (
        <>
            {
                HWCtt.loading === true ?
                    <div>
                        <p>Loading Organisations...</p>
                    </div>
                    :
                    <>
                        <ToastContainer style={{ marginTop: '6vh' }} />
                        <Router>
                            <TopMenu path="/*" />
                        </Router>
                        <Router>
                            <Primary path='/app/*' default />
                            <Secondary path='/secure/*' />
                        </Router>
                    </>
            }
        </>
    )
}