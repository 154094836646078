import styled from 'styled-components'
import { Dropdown } from 'semantic-ui-react'

const { Menu } = Dropdown;

export const DropdownNotifications = styled(Menu)`
  min-width: 300px !important;
  width: 400px !important;
  max-width: 450px !important;
  max-height: 80vh;
  overflow-y: scroll;
`

export const CloseIcon = styled.div`
  float: right;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  border-radius: 10px;
  :hover {
    background-color:#e2e2e2;
  }
`

export const LinkNotif = styled.p`
  margin-bottom: 1em;
  display: block;
  color: #008EF9;
`