import React from 'react';
import { Router } from '@reach/router';
import { Grid } from 'semantic-ui-react';
import NewOrganisation from 'pages/App/Modules/Creator/NewOrganisation';

function Creator(props: any) {
  return (
    <Grid columns='equal'>
      <Grid.Column></Grid.Column>
      <Grid.Column width={10}>
        <Router>
          <NewOrganisation path={'organisation'} />
        </Router>
      </Grid.Column>
      <Grid.Column></Grid.Column>
    </Grid>
  );
}

export default Creator;