import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useFirestoreCollectionData, SuspenseWithPerf } from 'reactfire'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';
import capitalize from 'capitalize';

function SelectorTeamsComponent({ oid, setResult, initial, filter }: any) {

  const { collections: { HWFS } } = useFirestoreHook();

  const teamsRef = HWFS.teams(oid);
  const optionsInit = useFirestoreCollectionData(teamsRef, { idField: 'id' });

  const [options, setOptions]: any = useState([]);
  const [teams, setteams]: any = useState([]);
  const [initialLoaded, setInitialLoaded] = useState(false);

  useEffect(() => {
    if (initial && initialLoaded === false) {
      setResult(initial);
      setInitialLoaded(true);
    } else {
      setResult(teams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  useEffect(() => {
    let result: Array<any> = [];

    optionsInit.map((opt: any) => {
      const payload: any = {
        key: opt.id,
        value: opt.id,
        text: opt.name,
        disabled: false,
      };

      if (filter && filter.teams && !filter.teams.includes(opt.id)) {
        payload.disabled = true;
      }

      result.push(payload);

      return true;
    });

    setOptions(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsInit]);

  const colorByText = (text: string) => {
    let color = 'teal';
    optionsInit.map((opt: any) => {
      if (opt.name === text) color = opt.color;
      return null
    })
    return color;
  }

  return (
    <>
      <Dropdown
        style={{ width: '100%' }}
        placeholder='Press enter to add multiple teams'
        fluid
        search
        selection
        multiple
        defaultValue={initial}
        onLabelClick={(event, data: any) => {
          setteams(
            teams.filter((item: any) => item !== data.value)
          );
        }}
        onChange={(event, data: any) => {
          setteams(data.value);
        }}
        renderLabel={(data) => ({
          color: colorByText(data.text),
          content: capitalize(data.text),
        })
        }
        options={options}
      />
    </>
  );
}

function SelectorTeams(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Dropdown style={{ width: '100%' }} text='Loading teams...' loading></Dropdown>}
      traceId={`howwee-teams-${props.oid || ''}`}
    >
      <SelectorTeamsComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default SelectorTeams;