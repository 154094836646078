import React, { createContext, useEffect } from "react";
import { useImmer } from 'use-immer';

const searchFields = {
  floatSize: 'small',
  isOpen: false,
  searchTerm: '',
  searchFilters: {
    author: [],
    users: [],
    teams: [],
    subjects: [],
    status: [],
    priority: [],
    dateTo: null,
    dateFrom: null,
    extra: []
  }
}

const initial = {
  searchData: searchFields,
  searchDataIndependent: searchFields,
  searchResultsQuestionsOpen: [],
  setSearchData: {}
}

const SearchGlobalContext = createContext(initial);
const { Consumer } = SearchGlobalContext;

function SearchGlobalProvider(props: any): any {

  const [searchData, setSearchData]: any = useImmer(initial)

  useEffect(() => {
    setSearchData((draft: any) => {
      draft.setSearchData = setSearchData;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchGlobalContext.Provider value={searchData} >
      {props.children}
    </SearchGlobalContext.Provider >
  )

}

export default SearchGlobalContext;
export {
  SearchGlobalProvider,
  Consumer as ModalConsumer,
};