import styled from 'styled-components'
import { Card } from 'semantic-ui-react'

export const BackgroundImage = styled.img`
  object-fit: cover;
  object-position: 100% 0;
  border-radius: 0.25em;
  height: 200px;
  width: 100%;
  position: absolute;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`
export const ProjectCard = styled(Card)`
  min-height: 200px !important;
  margin: 0.5em 0.25em !important;
  border-radius: 0.25em !important;
  transition: transform .1s;
  :hover{
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.75) !important;
    box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.75) !important;
    transform: scale(1.05);
  }
`
export const ProjectVisibility = styled.div`
  width: 30px;
  height: 30px;
  background-color: rgba(216,216,216, 0.8) !important;
  position: absolute;
  border-radius: 50%;
  margin-left: 0.5em;
  margin-top: 0.5em;
  text-align: center;
  padding-left: 2px;
  padding-top: 5px;
`

export const GradientBackground = styled.div`
  height: 200px;
  width: 100%;
  background: #4AAFFE; /* fallback for old browsers */
 // background: -webkit-linear-gradient(to right, #4AAFFE, #514a9d); /* Chrome 10-25, Safari 5.1-6 */
 // background: linear-gradient(to right, #4AAFFE, #514a9d);
`

export const ProjectSettings = styled.div`
  width: 30px;
  height: 30px;
  background-color: rgba(216,216,216, 0.8) !important;
  border-radius: 50%;
  margin-top: 0.5em;
  margin-right: 0.5em;
  text-align: center;
  padding-left: 3px;
  padding-top: 5px;
  position: absolute;
  right: 0;
`

export const ProjectFoot = styled.div`
  background-color: rgba(216,216,216, 0.8) !important;
  position: absolute;
  z-index: 20;
  padding: 10px 10px 10px 10px;
  border-radius: 10px 10px 0px 0px !important;
  width: 100%;
  color: black;
  height: auto;
  bottom: 0;
`