import React, { useEffect, useState } from 'react';
import GridCenter from 'pages/App/components/GridCenter';
import { Dimmer, Grid, Label, Loader, } from 'semantic-ui-react';
import HeaderInsights from './Header'
import { useInsightsHook } from 'services/hooks/useInsightsHook'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { BadgeSubject, BadgeUser } from 'pages/App/components/Badges';
import { useProjectHook } from 'services/hooks/useProjectHook';
import { CardChart, CardChartDescription, AvgTitle, } from './Helpers'

function Insights({ oid, projId }: any) {

  const { data: { loadingHook, mainData } } = useInsightsHook(oid, projId);
  const { functions: { projectById } } = useProjectHook(oid)

  const [project]: any = useState(projectById(projId))
  const [loading, setLoad]: any = useState(true)

  useEffect(() => {
    if (loadingHook === false) setLoad(loadingHook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingHook, mainData]);

  const ResponsivePieExtended = (props: any) =>
    <ResponsivePie
      {...props}
      arcLinkLabel={function (e) { return "" + e.label + "" }}
      margin={{ top: 25, right: 50, bottom: 80, left: 50 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      itemWidth={100}
      activeOuterRadiusOffset={10}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 25,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ]}
    />


  return (
    <>
      <HeaderInsights oid={oid} project={project} />
      <GridCenter fullWidth marginTop='2em'>
        {/* FIRST ROW */}
        <Grid centered columns={3} doubling>
          <Grid.Column>
            <CardChart title='Time to answer'>
              <CardChartDescription >
                Average time for a question to be have a selected answers (last 30 days)
            </CardChartDescription>
              <AvgTitle>
                {
                  loading ?
                    '----' :
                    mainData.timeToAnswer
                }
              </AvgTitle>
              {
                loading &&
                <Dimmer active inverted>
                  <Loader></Loader>
                </Dimmer>
              }
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart title='Time to comment'>
              <CardChartDescription>
                Average time for a question to have a first comment (last 30 days)
            </CardChartDescription>
              <AvgTitle>
                {
                  loading ?
                    '----' :
                    mainData.timeToComment
                }
              </AvgTitle>
              {
                loading &&
                <Dimmer active inverted>
                  <Loader></Loader>
                </Dimmer>
              }
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart title='Total Questions'>
              <CardChartDescription>
                Total number of questions in this discussion
            </CardChartDescription>

              <AvgTitle>
                {
                  loading ?
                    '----' :
                    mainData.totalQuestions
                }
              </AvgTitle>
              {
                loading &&
                <Dimmer active inverted>
                  <Loader></Loader>
                </Dimmer>
              }
            </CardChart>
          </Grid.Column>
        </Grid>
        {/* SECOND ROW */}
        <Grid doubling columns={2}>
          <Grid.Column>
            <CardChart height='400px' title='Question source'>
              <CardChartDescription>
                Who&#39;s asking questions
            </CardChartDescription>
              {
                loading ?
                  <Dimmer active inverted>
                    <Loader></Loader>
                  </Dimmer> :
                  <ResponsivePieExtended
                    data={mainData.questionSource}
                    tooltip={(eve: any) => {
                      return <Label basic>
                        <BadgeUser oid={oid} uid={eve.datum.id} />
                        <span style={{ marginLeft: '1em' }}>
                          {eve.datum.label} : {eve.datum.value}
                        </span>
                      </Label>
                    }} />
              }
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart height='400px' title='Answered by'>
              <CardChartDescription>
                Who&#39;s comments are being selected as answers
            </CardChartDescription>
              {
                loading ?
                  <Dimmer active inverted>
                    <Loader></Loader>
                  </Dimmer> :
                  <ResponsivePieExtended
                    data={mainData.answerSource}
                    tooltip={(eve: any) => {
                      return <Label basic>
                        <BadgeUser oid={oid} uid={eve.datum.id} />
                        <span style={{ marginLeft: '1em' }}>
                          {eve.datum.label} : {eve.datum.value}
                        </span>
                      </Label>
                    }} />
              }
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart height='400px' title=' Comments by'>
              <CardChartDescription>
                Who&#39;s commenting
            </CardChartDescription>
              {
                loading ?
                  <Dimmer active inverted>
                    <Loader></Loader>
                  </Dimmer> :
                  <ResponsivePieExtended
                    data={mainData.commentsSource}
                    tooltip={(eve: any) => {
                      return <Label basic>
                        <BadgeUser oid={oid} uid={eve.datum.id} />
                        <span style={{ marginLeft: '1em' }}>
                          {eve.datum.label} : {eve.datum.value}
                        </span>
                      </Label>
                    }} />
              }
            </CardChart>
          </Grid.Column>

          {/* THRID ROW */}
          <Grid.Column>
            <CardChart height='400px' title=' Subjects'>
              <CardChartDescription>
                What&#39;s being talked about
            </CardChartDescription>
              {
                loading ?
                  <Dimmer active inverted>
                    <Loader></Loader>
                  </Dimmer> :
                  <ResponsivePieExtended
                    data={mainData.subjects}
                    tooltip={(eve: any) => {
                      return <Label basic>
                        <Grid columns={2}>
                          <Grid.Column>
                            <BadgeSubject oid={oid} subjectId={eve.datum.id} />
                          </Grid.Column>
                          <Grid.Column>
                            <p style={{ marginTop: '0.5em', marginLeft: '2px' }}>
                              : {eve.datum.value}
                            </p>
                          </Grid.Column>
                        </Grid>
                      </Label>
                    }} />
              }
            </CardChart>
          </Grid.Column>
        </Grid>
        {/* THRID ROW */}
        <Grid centered columns={1}>
          <Grid.Column>
            <CardChart height='400px' title='Active Days'>
              <CardChartDescription>
                The most active days for asking questions
               {/* (line shows average over last 30 days) */}
              </CardChartDescription>
              <ResponsiveBar
                data={mainData.daysOfQuestions}
                keys={['qtyQuestions']}
                indexBy="day"
                tooltip={(eve: any) => {
                  return <span>
                    {eve.data.dayExt} : {eve.data.qtyQuestions}
                  </span>
                }}
                margin={{ top: 50, right: 60, bottom: 50, left: 80 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                  }
                ]}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'day',
                  legendPosition: 'middle',
                  legendOffset: 32
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'questions',
                  legendPosition: 'middle',
                  legendOffset: -40,
                  format: e => Math.floor(e) === e && e
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
              />
            </CardChart>
          </Grid.Column>
        </Grid>
      </GridCenter >
    </>
  );
}

export default Insights;