/* Core | Components | Hooks | Styles | Third-party */

import React, { useContext, useEffect, useState } from 'react';
import { useFirestoreDocData, SuspenseWithPerf, useFirestoreCollectionData } from 'reactfire'
import 'firebase/firestore';

import { Loading } from 'pages/App/components/Loading';
import HowWeeContext from 'services/contexts/ContextHowWee'
import GridCenter from 'pages/App/components/GridCenter';
import { PrintLauncher } from 'pages/App/components/PrintLauncher'

import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import { useReportsHook } from 'services/hooks/useReportsHook';
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';

import { QuestionExplained, QuestionContainer } from './styles';

import { Grid, Divider, Image, Message, Button, } from 'semantic-ui-react';
import moment from 'moment'
import capitalize from 'capitalize';
import { navigate } from '@reach/router';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useImmer } from 'use-immer';
import { toast } from 'react-toastify';
import Output from 'editorjs-react-renderer';
import { useHistoryHook } from 'services/hooks/useHistoryHook';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';


function ViewReportComponent({ path, reportId, projId, oid }: any) {

  const HWCtt: any = useContext(HowWeeContext);
  const { collections: { HWFS }, data: { SDK } } = useFirestoreHook();

  /* Firestore */
  const reportRef = HWFS.reports(oid, projId).doc(reportId);
  const reportDoc: any = useFirestoreDocData(reportRef, { idField: 'id' });

  const questionsIncluded = reportDoc.questionsIncluded.length > 0 ? reportDoc.questionsIncluded : ['_']

  /* Firestore */
  const questionsRef = HWFS.questions(oid, projId).where(SDK.documentId(), 'in', questionsIncluded);
  const questionsRefDoc: any = useFirestoreCollectionData(questionsRef, { idField: 'id' });

  /* States base on Immer */
  const [reportOrder, setReportOrder]: any = useImmer({ data: {} })
  const [questionsDocs, setQuestionDocs] = useImmer({ data: [] });
  const [historyEventSended, setHistoryEventSended] = useState(false)

  /* Custom Hooks */
  const { functions: { nhr_report_export, nhr_report_view } } = useHistoryHook(oid);
  const { functions: { photoOrgOpen, photoUserByID, usersById } } = useOrganisationHook(oid);
  const { functions: { getLexorank } } = useQuestionsHook(oid);
  const { functions: { updateReport, userHaveSomeAccessInReport, reportAccessExtended } } = useReportsHook(oid);


  const [reportAccess] = useState(reportAccessExtended(reportDoc))
  /* Index of Functions */
  // sortQuestions: Sort array by .lexorank value
  // questionsWithLexo : List availables questions and merge the lexorank value
  // questionById: Util - Get question object by ID
  // onDragEnd: Capture event from DnD

  const sortQuestions = (questions: any) => {
    let tmp = [...questions]
    if (tmp.length === 0) return []
    const res = tmp.sort((a: any, b: any) => (a.lexorank > b.lexorank) ? 1 : -1)

    return res;
  }

  const questionsWithLexo = () => {
    return questionsRefDoc.map((question: any) => {
      if (reportDoc.questionsIncluded && reportDoc.questionsIncluded.includes(question.id)) {
        let tmp = { ...question };
        tmp.lexorank = reportDoc.questionsIncludedLexorank[question.id];
        return tmp;
      }
      return null
    })
  }

  const questionById = (questionId: string) => {
    const res = questionsDocs.data.filter((question: any) => question.id === questionId);
    return res[0] || null;
  }

  const onDragEnd = (data: any) => {
    const questionId = data.draggableId;
    const questionInfo = questionById(questionId);

    // Lexorank ===>
    const questionsInGroup: any = questionsDocs.data;
    const newIndex = data.destination.index;
    const oldIndex = data.source.index;
    const lexorankUpdate = getLexorank(questionsInGroup, newIndex, oldIndex, questionInfo);

    /* Update UI to prevent firestore delay */
    questionsDocs.data.map((question: any, index: number) => {
      if (question.id === questionId) {
        const questData: any = questionsDocs.data[index] || {};
        const tmp = [...questionsDocs.data]
        tmp[index] = { ...questData, lexorank: lexorankUpdate };

        setQuestionDocs((draft) => {
          draft.data = sortQuestions(tmp);
        });
      }
      return null
    })
    // <=== Lexorank

    updateReport(projId, reportId, {
      questionsIncludedLexorank: {
        ...reportOrder.data,
        [questionId]: lexorankUpdate
      }
    }).catch(() => {
      toast.warning('Error occurred while trying to save changes')
    })
  }

  useEffect(() => {
    if (historyEventSended === false) {
      nhr_report_view(reportId, projId)
      setHistoryEventSended(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setQuestionDocs((draft: any) => {
      draft.data = sortQuestions(questionsWithLexo());
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsRefDoc])

  useEffect(() => {
    setReportOrder((draft: any) => {
      draft.data = reportDoc.questionsIncludedLexorank || {}
    });

    setQuestionDocs((draft: any) => {
      draft.data = sortQuestions(questionsWithLexo());
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDoc])

  /* Component */
  const QuestionFull = ({ question }: any) => {
    return (
      <QuestionContainer>
        <Grid style={{ marginBottom: '2em' }}>
          <Grid.Column width={16}>
            <QuestionExplained onClick={() => navigate(`../../questions/${question.id}`)}>
              <h2>{capitalize(question.question)}</h2>
            </QuestionExplained>
          </Grid.Column>
          <Grid.Column width={8}>
            <div>
              <span style={{ color: 'grey' }}>
                Asked:
              </span>
              <Image
                src={photoUserByID(question.createdBy) || 'http://www.gravatar.com/avatar/?d=identicon'}
                avatar
                style={{ marginLeft: '1em' }}
              />
              {usersById(question.createdBy)?.displayName || ''}
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div>
              <span style={{ color: 'grey' }}>
                Answered:
              </span>
              {question.answer &&
                <>
                  <Image
                    src={photoUserByID(question.answer.createdBy) || 'http://www.gravatar.com/avatar/?d=identicon'}
                    avatar
                    style={{ marginLeft: '1em' }}
                  />
                  {usersById(question.answer.createdBy)?.displayName || ''}
                </>
              }
            </div>
          </Grid.Column>
          <Grid.Column width={16}>
            <span style={{ color: 'grey' }}>
              {moment.utc(question.createdAt.seconds * 1000).format("DD/MM/YYYY")}
            </span>
          </Grid.Column>
          <Grid.Column width={16}>
            {(question.answer && (question.answer.comment || question.answer.commentRich)) ?
              <>
                {question.answer.comment ?
                  question.answer.comment :
                  <Output data={question.answer.commentRich} />
                }
              </>
              :
              <Message
                icon='eye slash outline'
                header='Oh! This question has no answer selected'
                content='You should remove it from the report or select an answer'
              />
            }
          </Grid.Column>
        </Grid>
      </QuestionContainer>
    )
  }

  /* Component */
  const PreviewReport = ({ printMode }: any) => (
    <>
      <Grid>
        <Grid.Column width={16} style={{ marginTop: '2em' }}>
          <img src={photoOrgOpen() || ''} alt='organisation profile' style={{ float: 'left', borderRadius: '6px', maxHeight: '50px' }} />
          <h3 style={{ float: 'left', marginTop: '15px' }}> {HWCtt.data.HW_DEFAULT.organisation.name}</h3>
        </Grid.Column>
        <Grid.Column width={16}>
          <Divider style={{ marginTop: 0 }} />
          <h2>{reportDoc.name}</h2>
        </Grid.Column>
      </Grid>
      <Grid columns='equal' style={{ paddingLeft: '1em', }}>
        <Grid.Column style={{ padding: '0.2em' }}>
          <span style={{ color: 'grey' }}>Created:</span>
        </Grid.Column>
        <Grid.Column style={{ padding: '0.2em' }} width={12}>
          <span style={{ color: 'grey' }}>{moment.utc(reportDoc.createdAt.seconds * 1000).format("DD MMMM YYYY")}</span>
        </Grid.Column>
      </Grid>
      <Grid columns='equal' style={{ paddingLeft: '1em' }}>
        <Grid.Column style={{ padding: '0.2em' }}>
          <span style={{ color: 'grey' }}>Last Modified: </span>
        </Grid.Column>
        <Grid.Column style={{ padding: '0.2em' }} width={12}>
          <span style={{ color: 'grey' }}>{reportDoc.modificatedAt ? moment.utc(reportDoc.modificatedAt.seconds * 1000).fromNow() : '-'}</span>
        </Grid.Column>
      </Grid>
      <Grid columns='equal' style={{ paddingLeft: '1em' }}>
        <Grid.Column style={{ padding: '0.2em' }}>
          <span style={{ color: 'grey' }}>Created By:  </span>
        </Grid.Column>
        <Grid.Column style={{ padding: '0.2em' }} width={12}>
          <span style={{ color: 'grey' }}>
            {usersById(reportDoc.createdBy)?.displayName || ''}
          </span>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={16}>
          <Divider style={{ marginTop: 0 }} />
        </Grid.Column>
      </Grid>
      { printMode ?
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'report'} style={{ minHeight: '100vh', height: '100%' }}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ height: '100%' }}
              >
                {
                  questionsDocs.data.map((question: any, index: number) => {
                    return (
                      <Draggable
                        index={index}
                        key={question.id}
                        draggableId={question.id}
                        isDragDisabled={!(reportAccess && (reportAccess.owner || reportAccess.editor))}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <QuestionFull
                              question={question}
                              provided={provided}
                              snapshot={snapshot}
                            />
                          </div>
                        )
                        }
                      </Draggable >
                    )
                  })
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        :
        <>
          {
            questionsDocs.data.map((question: any, key: number) => {
              return (
                <QuestionFull question={question} key={key} />
              )
            })
          }
        </>
      }
    </>)

  return (
    <GridCenter>
      {userHaveSomeAccessInReport(reportDoc) ?
        <>
          <PreviewReport printMode={true} />
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column>
                <Button onClick={() => navigate(-1)} > Exit </Button>
              </Grid.Column>
              <Grid.Column textAlign="right">
                {reportAccess && (reportAccess.owner || reportAccess.editor) &&
                  <Button color='teal' onClick={() => navigate(`../edit/${reportId}`)}> Edit </Button>
                }

                <PrintLauncher captureClick={() => nhr_report_export(reportId, projId)} Content={PreviewReport} />
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </>
        :
        <>
          <Message style={{ marginTop: '2em' }}>
            <Message.Header>
              Sorry, yo do not have permission to the details of this Report
            </Message.Header>
            <Message.Content>
              Here information that can help you:
            </Message.Content>
            <Message.List>
              <Message.Item>Contact the owner or administrator of this Report</Message.Item>
              <Message.Item>Request access to the editors of this Report</Message.Item>
              <Message.Item><p style={{ cursor: 'pointer' }}>Report a issue</p></Message.Item>
            </Message.List>
          </Message>
        </>
      }
    </GridCenter >
  );
}

function ViewReport(props: any) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId={`howwee-report-${props.reportId}`} >
      <ViewReportComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default ViewReport;