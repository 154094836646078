import React, { useEffect, useState } from 'react'
import { Board } from './styles'
import List from './List'
import { useFirestoreCollectionData, SuspenseWithPerf, useUser } from 'reactfire';
import { DragDropContext } from 'react-beautiful-dnd';
import { Loading } from 'pages/App/components/Loading';
import { useHorizontalScroll } from 'services/hooks/useHorizontalScroll'
import { useQuestionsHook } from 'services/hooks/useQuestionsHook'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook'
import { useProjectHook } from 'services/hooks/useProjectHook'
import { useHistoryHook } from 'services/hooks/useHistoryHook'
import { toast } from 'react-toastify'
import { useImmer } from 'use-immer';
import BoardHeader from './Header'
import { Dimmer, Loader } from 'semantic-ui-react';

function QuestionsBoardComponent({ oid, projId = null }: any) {

  const { uid }: any = useUser();

  const { collections: { HWFS } } = useFirestoreHook();
  const { functions: { userHaveSomeAccessInProject } } = useProjectHook(oid);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollRef = useHorizontalScroll();

  let questionsRef: any;
  if (projId) {
    questionsRef = HWFS.questions(oid, projId)
  } else {
    questionsRef = HWFS.questionsOld(oid);
  }

  const questionsRefDoc = useFirestoreCollectionData(questionsRef, { idField: 'id' });
  const [questionsDocs, setQuestionDocs] = useImmer({ data: [] });
  const [questionsFilter, setQuestionFilter]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const teamsRef = HWFS.teams(oid);
  const teamsDoc = useFirestoreCollectionData(teamsRef, { idField: 'id' });

  const { functions: { nhr_questions_open } } = useHistoryHook(oid);
  const { functions: { titleToStatus, updateQuestion, getLexorank, allQuestionsInOrg } } = useQuestionsHook(oid);

  useEffect(() => {
    if (search && search !== '') {
      let tmp: any = [];
      questionsDocs.data.map((question: any) => {
        if (question.question.toLowerCase().includes(search.toLowerCase())) {
          tmp.push(question);
        }
        return null;
      })
      setQuestionFilter(tmp)
    } else {
      setQuestionFilter(questionsDocs.data)
    }
  }, [questionsDocs, search])

  useEffect(() => {
    setLoading(true);

    if (projId) {
      setQuestionDocs((draft: any) => {
        draft.data = questionsRefDoc;
      })
      setLoading(false);
    }

    if (!projId) {
      allQuestionsInOrg().then((res: any) => {

        const quest = res.filter((question: any) => {
          if (question.projId && userHaveSomeAccessInProject(question.projId)) {
            return true
          }
          return false;
        });

        setQuestionDocs((draft: any) => {
          draft.data = quest;
        });

        setLoading(false);
      }).catch((err: any) => {
        console.error(err)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsRefDoc])

  const validateAlerts = (data: any) => {
    switch (data.source.droppableId) {
      case 'draft':
        switch (data.destination.droppableId) {
          case 'answered':
            toast.info('This question need to be Open before it can be moved to Ready for Review')
            break;
        }
        break;

      case 'open':
        switch (data.destination.droppableId) {
          case 'draft':
            toast.info('This question is open, it cannot go back to Draft')
            break;
          case 'answered':
            toast.info('This question needs a comment before it can be moved to Ready for Review')
            break;
        }
        break;

      case 'answered':
        switch (data.destination.droppableId) {
          case 'draft':
            toast.info('This question is answered, it cannot go back to Draft, try to move to Open')
            break;
          case 'selected':
            toast.info('Please select a comment as an answer to change the status')
            break;
        }
        break;
    }
  }

  const questionById = (questionId: string) => {
    const res = questionsDocs.data.filter((question: any) => question.id === questionId);
    return res[0] || null;
  }

  const validator = {
    // Question Draft -> Open
    Draft_to_Open: (data: any) => {
      if (data.source.droppableId === 'draft' &&
        data.destination.droppableId === 'open') return true
    },
    // Question Open -> Ready for Review (with comments)
    Open_to_ReadyForReview: (data: any, questionInfo: any) => {
      if (data.source.droppableId === 'open' &&
        data.destination.droppableId === 'answered' &&
        questionInfo.commentsLength &&
        questionInfo.commentsLength > 0) return true;
    },
    // Question Ready for Review (with comments) -> Open 
    ReadyForReview_to_Open: (data: any) => {
      if (data.source.droppableId === 'answered' &&
        data.destination.droppableId === 'open') return true
    },
    // Question Answered selected -> Ready for Review
    AnsweredSelected_to_ReadyForReview: (data: any) => {
      if (
        data.source.droppableId === 'selected' &&
        data.destination.droppableId === 'answered'
      ) return true
    }
  }

  const validateMinRequirementsQuestion = (questionInfo: any) => {
    return true;
    // if (
    //   (questionInfo.teams && questionInfo.teams.length > 0) ||
    //   (questionInfo.users && questionInfo.users.length > 0)
    // ) return true
    // return false
  }

  const getQuestionByGroup = (status: number) => {
    return questionsDocs.data.filter((que: any) => que.status === status)
  }

  const onDragEnd = (data: any) => {
    if (data && data.destination && data.destination.droppableId) {

      // Base Data
      const questionId = data.draggableId;
      const questionInfo: any = questionById(questionId);
      const statusCode = titleToStatus(data.destination.droppableId);


      // Lexorank ===>
      const questionsInGroup: any = getQuestionByGroup(statusCode);
      const newIndex = data.destination.index;
      const oldIndex = data.source.index;
      const lexorankUpdate = getLexorank(questionsInGroup, newIndex, oldIndex, questionInfo);

      /* Update UI to prevent firestore delay */
      questionsDocs.data.map((question: any, index: number) => {
        if (question.id === questionId) {
          setQuestionDocs((draft: any) => {
            draft.data[index].lexorank = lexorankUpdate;
          });
        }
        return null
      })
      // <=== Lexorank

      if (questionInfo.createdBy === uid) { // Only owner can move

        if (data.source.droppableId !== data.destination.droppableId) { // Only if destination is diff of source

          if (validator.Draft_to_Open(data)) {
            if (validateMinRequirementsQuestion(questionInfo)) {
              nhr_questions_open(questionId, {}, questionInfo.projId)
              updateQuestion(questionInfo.projId, questionId, { lexorank: lexorankUpdate, status: statusCode }, questionsInGroup);
            } else {
              toast.warning('To change the status of this Question it is necessary to assign someone');
            }
          } else if (
            validator.Open_to_ReadyForReview(data, questionInfo) ||
            validator.ReadyForReview_to_Open(data)
          ) {
            updateQuestion(questionInfo.projId, questionId, { lexorank: lexorankUpdate, status: statusCode }, questionsInGroup);
          } else if (
            validator.AnsweredSelected_to_ReadyForReview(data)
          ) {
            updateQuestion(questionInfo.projId, questionId, { lexorank: lexorankUpdate, status: statusCode, answer: {} }, questionsInGroup);
          } else {
            validateAlerts(data);
          }

        } else {
          if (questionInfo.lexorank !== lexorankUpdate) {
            updateQuestion(questionInfo.projId, questionId, { lexorank: lexorankUpdate });
          }
        }

      } else {
        toast.warning('Only the author can change the status of the Question');
      }

    }
  }

  return (
    <>
      <BoardHeader questionsFilter={questionsFilter} setSearch={setSearch} search={search} projId={projId} oid={oid} />
      <Board  /* ref={scrollRef} */ style={{ maxWidth: '100%' }} >
        <Dimmer active={loading} inverted>
          <Loader inverted>Loading Questions</Loader>
        </Dimmer>
        <DragDropContext onDragEnd={onDragEnd}>
          <List search={search} teams={teamsDoc} projId={projId} title='draft' code={201} oid={oid} questions={questionsFilter.filter((que: any) => que.status === 201)} />
          <List search={search} teams={teamsDoc} projId={projId} title='open' code={204} oid={oid} questions={questionsFilter.filter((que: any) => que.status === 204)} />
          <List search={search} teams={teamsDoc} projId={projId} title='answered' code={200} oid={oid} questions={questionsFilter.filter((que: any) => que.status === 200)} />
          <List search={search} teams={teamsDoc} projId={projId} title='selected' code={203} oid={oid} questions={questionsFilter.filter((que: any) => que.status === 203)} />
        </DragDropContext>
      </ Board>
    </>
  );
}

function QuestionsBoard(props: any) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId="howwee-settings-organisation-questions">
      <QuestionsBoardComponent {...props} />
    </SuspenseWithPerf>
  )
}



export default QuestionsBoard;
