import React from 'react';
import GridCenter from 'pages/App/components/GridCenter'
import { useModalHook } from 'services/hooks/useModalHook'
import { Divider } from 'semantic-ui-react';
import HistoryContentQuestion from './Content/HistoryContentQuestion'
import { TitleQuestion, PrefixTitleQuestion } from './styles'

export function HistoryQuestion({ oid, isModal }: any) {

  const { functions: { getModalData } } = useModalHook()
  const { question } = getModalData()

  return (
    <>
      <GridCenter fullWidth={isModal}>
        <PrefixTitleQuestion> How do we..</PrefixTitleQuestion>
        <TitleQuestion> {question.question}</TitleQuestion>
        <Divider />
        <HistoryContentQuestion questionId={question.id} projId={question.projId} oid={oid} />
      </GridCenter>
    </>
  );
}