import React, { useContext } from 'react';
import Draggable from 'react-draggable'
import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'
import QuestionInfo from 'pages/App/Modules/Org/Questions/Info'
import { QuestionFloated } from './../../SearchGlobal/styles'
import { useImmer } from 'use-immer'
import { Icon } from 'semantic-ui-react'
import { orgId } from 'typings/generic';

interface Props {
  oid: typeof orgId;
}

export function DraggeableQuestions({ oid }: Props) {
  const searchCtt: any = useContext(SearchGlobalContext);

  const [hoverTopBar, setHoverTopBar]: any = useImmer({ data: {} });

  const getStyles = (questionId: string) => {
    const baseStyle = { zIndex: 10, position: 'fixed', width: 'calc(100% - 10px)', borderTopLeftRadius: '10px', marginBottom: '-10px', height: '2em', marginRight: '1em' };
    if (hoverTopBar.data[questionId] && hoverTopBar.data[questionId] === true) {
      return { ...baseStyle, backgroundColor: '#f2f2f2', transition: '0.2s', boxShadow: '0 2px 2px -2px gray' }
    }
    return baseStyle
  }

  return (
    <>
      {/* Questions Open Window */}
      {oid && searchCtt.searchResultsQuestionsOpen.map((questionId: string) => {

        let projId = searchCtt.searchResultsQuestionsOpenExt[questionId];

        if (questionId) {
          return (
            <Draggable handle="strong" key={questionId} defaultPosition={{ x: 15 * searchCtt.searchResultsQuestionsOpen.length, y: 15 * searchCtt.searchResultsQuestionsOpen.length }}>
              <QuestionFloated className="no-cursor" width='30%'>
                <div style={{
                  // maxHeight: window.screen.height - 200 + 'px',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                  height: '100%'
                }}>
                  <strong className="cursor">
                    <div
                      onMouseEnter={() => setHoverTopBar((draft: any) => { draft.data[questionId] = true })}
                      onMouseLeave={() => setHoverTopBar((draft: any) => { draft.data[questionId] = false })}
                      style={getStyles(questionId)}
                    >
                      <div
                        onClick={() => {
                          searchCtt.setSearchData((draft: any) => {
                            draft.searchResultsQuestionsOpen = draft.searchResultsQuestionsOpen.filter((questId: string) => {
                              return questId !== questionId;
                            });
                          });
                        }}
                        style={{ float: 'right', cursor: 'pointer', height: '100%', width: '40px', textAlign: 'center' }}
                      >
                        <Icon
                          name="close"
                          style={{ margin: '6px 5px 0px 0px' }}
                        />
                      </div>
                    </div>
                  </strong>
                  <div style={{ whiteSpace: 'pre-wrap', marginTop: '1em' }} >
                    <QuestionInfo fullWidth projId={projId} questionId={questionId} oid={oid} />
                  </div>
                </div>
              </QuestionFloated>
            </Draggable>
          )
        } else {
          return null
        }
      })
      }
    </>
  )
}