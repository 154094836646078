import React, { useState, useEffect } from 'react';
import { useUser } from 'reactfire'
import { Button, Grid, Header, Icon, Label, Message, Segment } from 'semantic-ui-react'
import * as firebase from 'firebase/app'
import 'firebase/auth';

export function EmailVerifiedValidator({ children }: any) {

  const user: any = useUser();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(user);
  const [sended, setSended] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setUserData(user)
  }, [user]);

  const sendValidation = () => {
    setLoading(true)
    setError(false);

    userData.sendEmailVerification().then(() => {

      setSended(true)
      setError(false)
      setLoading(false)

    }).catch((err: any) => {

      console.error(err);
      setError(true)
      setLoading(false)

    });
  }

  const handleSignout = () => {
    firebase.auth().signOut().then(function () {
      // Sign-out successful.
    }).catch(function (error) {
      // An error happened.
    });
  }

  const ValidateAccount = () => (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h1' color='teal' textAlign='center'>
          How-Wee
          </Header>
        <Header as='h3' color='teal' textAlign='center' style={{ marginTop: '-10px' }}>
          Your way of working, documented.
          </Header>
        <Segment stacked>
          <h3>Welcome {userData?.displayName || ''}</h3>
          <p> We need validate your email address before continuing</p>
          <p>You will receive a link to validate your email</p>
          <Button.Group>
            <Button
              loading={loading}
              primary
              onClick={sendValidation}
              disabled={sended}>
              Send to {userData?.email}
            </Button>
            <Button onClick={handleSignout}>
              Logout
            </Button>
          </Button.Group>
          {sended &&
            <Label color='green' style={{ marginTop: '1em' }}>
              <Icon name='check' />
              Done, we have sent an email with a validation link
            </Label>
          }
          {error &&
            <Label color='red' style={{ marginTop: '1em' }}>
              <Icon name='warning' />
              An error occurred, please try again shortly
            </Label>
          }
        </Segment>
        <Message>
          For all businesses and teams, from small to large, document what you do and how you do it in a living, actionable ledger of processes and know-how, all in a web interface.
          </Message>
      </Grid.Column>
    </Grid >
  );

  return (
    <>
      {
        userData?.emailVerified ?
          <> { children} </> :
          <ValidateAccount />
      }
    </>
  )
}