import React, { useState } from 'react'
import { Dropdown, Image, Grid, Button, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'
import { useNotificationsHook } from 'services/hooks/useNotificationsHook'
import { CloseIcon, LinkNotif } from './styles'
import { navigate } from '@reach/router'

const WrapperNotif = (props: any) => {

  const [hover, setHover] = useState(false);
  const { functions: { markReaded, marReadedGeneral } } = useNotificationsHook(props.oid)

  return (

    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Grid style={{ height: '3em' }} >
        <Grid.Column width={8}>
          <p style={{ color: 'grey', fontSize: '12px' }} >
            {moment.utc(props.createdAt.seconds * 1000).fromNow()}
          </p>
        </Grid.Column>
        <Grid.Column width={8}>
          <CloseIcon
            onClick={() =>
              props.isGeneral ?
                marReadedGeneral(props.id) :
                markReaded(props.id)
            }
            style={{ marginRight: '-5px' }}
          >
            {hover &&
              <Icon
                name='x'
                style={{ margin: '0' }}
              />
            }
          </CloseIcon>
        </Grid.Column>
      </Grid>
      { props.children}
    </div >
  )
}

export const ImagesNotif = ({ uid, oid }: any) => {

  const { functions: { photoUserByID } } = useOrganisationHook(oid);

  return <Image bordered src={photoUserByID(uid)} avatar />
}

/*
  TYPES NOTIF :
  1.InvitedOrg
  2.InvitedDiscussion
  3.newCommentQuestionAsked
  4.newQuestionInProject
*/

const titleNotif = {
  marginBottom: '0.5em',
  display: 'block',
  color: '#212529',
  fontWeight: 400,
}

const subTitleNotif = {
  display: 'block',
  color: '#6C757D',
  lineHeight: '24px',
  marginBottom: '0em',
  fontWeight: 400,
}

export const NotifxxInvitedOrg = (props: any) => {
  return (
    <>
      <Dropdown.Item>
        <WrapperNotif  {...props}>
          <Grid>
            <Grid.Column width={2} style={{ paddingRight: '0px', paddingTop: '0em', textAlign: 'center' }}>
              <ImagesNotif uid={props.user} oid={props.oid} />
            </Grid.Column>
            <Grid.Column width={14} style={{ paddingLeft: '1em', paddingTop: '0em' }}>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis'
                }}>
                <p style={titleNotif}>
                  {props.userDisplayName} invited you to an Organisation
                </p>
                <p style={subTitleNotif}>
                  {props.userDisplayName} invited you to the {props.extra.organisationName} Organisation as a {props.extra.role}
                </p>
              </div>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={14} style={{ paddingLeft: '1em', paddingTop: '0em' }}>
              <Button
                size='mini'
                style={{ marginTop: '0px' }}
                onClick={() => navigate('/secure/settings/personal?tab=organisation')}
                color='blue'
              >
                View Invitation
              </Button>
            </Grid.Column>
          </Grid>
        </WrapperNotif>
      </Dropdown.Item>
    </>
  )
}

export const NotifxxInvitedDiscussion = (props: any) => {
  return (
    <>
      <Dropdown.Item>
        <WrapperNotif  {...props}>
          <Grid>
            <Grid.Column width={2} style={{ paddingRight: '0px', paddingTop: '0em', textAlign: 'center' }}>
              <ImagesNotif uid={props.user} oid={props.oid} />
            </Grid.Column>
            <Grid.Column width={14} style={{ paddingLeft: '1em', paddingTop: '0em' }}>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis'
                }}>
                <p style={titleNotif}>
                  {props.userDisplayName} added you to a Discussion
                </p>
                <LinkNotif
                  onClick={() => navigate(`/app/org/${props.oid}/discussions/${props.project}`)}
                >
                  {props.projectName}
                </LinkNotif>
              </div>
            </Grid.Column>
          </Grid>
        </WrapperNotif>
      </Dropdown.Item>
    </>
  )
}

export const NotifxxNewCommentQuestionAsked = (props: any) => {
  return (
    <>
      <Dropdown.Item>
        <WrapperNotif  {...props}>
          <Grid>
            <Grid.Column width={2} style={{ paddingRight: '0px', paddingTop: '0em', textAlign: 'center' }}>
              <ImagesNotif uid={props.user} oid={props.oid} />
            </Grid.Column>
            <Grid.Column width={14} style={{ paddingLeft: '1em', paddingTop: '0em' }}>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis'
                }}>

                <p style={titleNotif}>
                  {props.userDisplayName} commented on a Question you asked:
                </p>
                <div style={subTitleNotif}>
                  commented on the Question:
                  <LinkNotif
                    onClick={() => navigate(`/app/org/${props.oid}/discussions/${props.project}/${props.itemType}/${props.itemId}`)}
                  >
                    {props.extra.questionTitle}
                  </LinkNotif>
                </div>

                <div style={subTitleNotif}>
                  In this discussion :
                  <LinkNotif
                    onClick={() => navigate(`/app/org/${props.oid}/discussions/${props.project}`)}
                  >
                    {props.projectName}
                  </LinkNotif>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </WrapperNotif>
      </Dropdown.Item>
    </>
  )
}

export const NotifxxNewQuestionInProject = (props: any) => {

  return (
    <>
      <Dropdown.Item>
        <WrapperNotif  {...props}>
          <Grid>
            <Grid.Column width={2} style={{ paddingRight: '0px', paddingTop: '0em', textAlign: 'center' }}>
              <ImagesNotif uid={props.user} oid={props.oid} />
            </Grid.Column>
            <Grid.Column width={14} style={{ paddingLeft: '1em', paddingTop: '0em' }}>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis'
                }}>

                <p style={titleNotif}>
                  {props.userDisplayName} added a Question to a Discussion you are in
                </p>
                <div style={subTitleNotif}>
                  {props.userDisplayName} {props.userOrgRepresentName && ` representing ${props.userOrgRepresentName}`} added a Question:
                  <LinkNotif
                    onClick={() => navigate(`/app/org/${props.oid}/discussions/${props.project}/${props.itemType}/${props.itemId}`)}
                  >
                    {props.extra.questionTitle}
                  </LinkNotif>
                </div>

                <div style={subTitleNotif}>
                  In this discussion :
                  <LinkNotif
                    onClick={() => navigate(`/app/org/${props.oid}/discussions/${props.project}`)}
                  >
                    {props.projectName}
                  </LinkNotif>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </WrapperNotif>
      </Dropdown.Item>
    </>
  )
}
