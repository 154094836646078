import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useFirestoreCollectionData, SuspenseWithPerf } from 'reactfire'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';

function SelectorProjectComponent({ oid, initial, setResult }: any) {

  const { collections: { HWFS } } = useFirestoreHook();
  const subjectsRef = HWFS.projects(oid);
  const optionsInit = useFirestoreCollectionData(subjectsRef, { idField: 'id' });

  const [options, setOptions]: any = useState([]);
  const [project, setProject]: any = useState([]);
  const [initialLoaded, setInitialLoaded] = useState(false);

  useEffect(() => {
    setInitialLoaded(false);
    if (initial !== project) {
      setProject(initial)
      setResult(initial)
    }
    setInitialLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);

  useEffect(() => {
    let result: Array<any> = [];

    optionsInit.map((opt: any) => {
      result.push({
        key: opt.id,
        value: opt.id,
        text: opt.name
      });

      return true;
    });

    setOptions(result)
  }, [optionsInit]);

  return (
    <>
      { !initialLoaded ?
        <Dropdown text='Loading discussions' loading />
        :
        <Dropdown
          style={{ width: '100%' }}
          placeholder='Select a Discussion'
          fluid
          search
          selection
          value={project}
          disabled={initial ? true : false}
          onChange={(event, data: any) => {
            setProject(data.value);
            setResult(data.value);
          }}
          options={options}
        />
      }
    </>
  );
}

function SelectorProject(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Dropdown style={{ width: '100%' }} text='Loading Discussions...' loading></Dropdown>}
      traceId={`howwee-project-${props.oid || ''}`}
    >
      <SelectorProjectComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default SelectorProject;