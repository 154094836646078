import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from '@reach/router'
import { Button, Input, } from "semantic-ui-react"
import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'

function Search() {

  const location = useLocation();

  const searchCtt: any = useContext(SearchGlobalContext);
  const [searchTermUser, setSearchTermUser] = useState('');
  const [disableSearch, setDisableSearh] = useState(true);


  useEffect(() => {
    if (searchCtt.searchData.searchTerm !== searchTermUser) {
      setSearchTermUser(searchCtt.searchData.searchTerm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCtt.searchData.searchTerm]);

  useEffect(() => {
    if (location.pathname && location.pathname.includes('app/org')) {
      setDisableSearh(false)
    } else {
      setDisableSearh(true)
    }
  }, [location]);

  return (
    <>
      <Input
        className="computer widescreen large screen only"
        disabled={disableSearch}
        style={{ maxWidth: '200px', marginRight: '1em' }}
        icon='search'
        value={searchTermUser}
        onChange={(e: any) => setSearchTermUser(e.target.value)}
        placeholder='Search for question...'
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            searchCtt.setSearchData((draft: any) => {
              if (!searchCtt.searchData.isOpen) {
                draft.searchData.isOpen = true;
              }
              draft.searchData.searchTerm = searchTermUser;
            })
          }
        }}
      />
      { !disableSearch &&
        <Button
          icon='search'
          className="mobile tablet only"
          onClick={() => {
            searchCtt.setSearchData((draft: any) => {
              if (!searchCtt.searchData.isOpen) {
                draft.searchData.isOpen = true;
              }
            })
          }}
        />
      }
    </>
  );
}

export default Search;