import React from 'react';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'
import { LinkCustom, HistoryItem } from '../styles'
import moment from 'moment'
import { navigate } from '@reach/router';
import { useModalHook } from 'services/hooks/useModalHook';
import { Image, Divider, Grid } from 'semantic-ui-react';
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';

function HistoryItems({ historyData, oid }: any) {

  const { functions: { photoUserByID, usersById, teamById } } = useOrganisationHook(oid);
  const { functions: { closeModal } } = useModalHook();
  const { functions: { closeQuestionsFloated } } = useQuestionsHook(oid);

  const openLink = (historyItem: any, focus: string | null = null, focusValue: string | null = null) => {

    closeQuestionsFloated();

    let url: string = '';

    switch (historyItem.itemType) {
      case 'questions':
        url = `/app/org/${oid}/discussions/${historyItem.projId}/questions/${historyItem.itemId}`
        closeModal();
        break;

      case 'reports':
        url = `/app/org/${oid}/discussions/${historyItem.projId}/reports/view/${historyItem.itemId}`
        closeModal();
        break;

      case 'discussions':
        url = `/app/org/${oid}/discussions/${historyItem.projId}`
        closeModal();
        break;
    }

    if (url !== '' && focus && focusValue) {
      navigate(`${url}?${focus}=${focusValue}`)
    } else if (url !== '') navigate(url)
  }

  const getUsers = (extra: any) => {
    if (extra.users) {
      let res: string = ''
      extra.users.map((uid: string, index: number) => {
        res = `${res} ${usersById(uid).displayName}`
        if (index + 1 !== extra.users.length) res = `${res},`
        return null
      })

      if (extra.users.length === 0) return 'user'
      return res;
    } else {
      return 'Error getUsers';
    }
  }

  const getTeams = (extra: any) => {
    if (extra.teams) {
      let res: string = ''
      extra.teams.map((uid: string, index: number) => {
        res = ` ${res} ${teamById(uid).name}`
        if (index + 1 !== extra.teams.length) res = `${res},`
        return null
      })
      return res;
    } else {
      return 'Error getTeams';
    }
  }

  const parseHistoryEvent = (historyItem: any) => {

    switch (historyItem.actionType) {

      case 'discussion_archive':
        return <HistoryItem>
          Moved this <LinkCustom onClick={() => openLink(historyItem)}>discussion</LinkCustom> to Archived
        </HistoryItem>

      case 'question_draft_moves_to_open':
        return <HistoryItem>
          Draft <LinkCustom onClick={() => openLink(historyItem)}>Question</LinkCustom> moves to <b>Open</b>
        </HistoryItem>

      case 'question_ready_for_review':
        return <HistoryItem>
          <LinkCustom onClick={() => openLink(historyItem)}>Question</LinkCustom> <b>Ready for Review</b>
        </HistoryItem>

      case 'question_unarchived':
        return <HistoryItem>
          <LinkCustom onClick={() => openLink(historyItem)}>Question</LinkCustom> restored from Draft
        </HistoryItem>

      case 'question_archived_from_draft':
        return <HistoryItem>
          <LinkCustom onClick={() => openLink(historyItem)}>Question</LinkCustom> archived from Draft
        </HistoryItem>

      case 'question_archived_from_answer_selected':
        return <HistoryItem>
          <LinkCustom onClick={() => openLink(historyItem)}>Question</LinkCustom> archived from Answer Selected
        </HistoryItem>

      case 'question_archived_from_ready_for_review':
        return <HistoryItem>
          <LinkCustom onClick={() => openLink(historyItem)}>Question</LinkCustom> archived from Ready for Review
        </HistoryItem>

      case 'question_new_comment':
        return <HistoryItem>
          Added this <LinkCustom
            onClick={() =>
              openLink(historyItem, 'comment', historyItem.extra.commentId)
            }
          >comment </LinkCustom>
           on this <LinkCustom onClick={() => openLink(historyItem)}>question</LinkCustom>
        </HistoryItem>


      case 'question_first_comment':
        return <HistoryItem>
          Added this first <LinkCustom
            onClick={() =>
              openLink(historyItem, 'comment', historyItem.extra.commentId)
            }>comment </LinkCustom>
           on this <LinkCustom>question</LinkCustom>
        </HistoryItem>


      case 'question_answer_selected':
        return <HistoryItem>
          Selected this  <LinkCustom
            onClick={() =>
              openLink(historyItem, 'comment', historyItem.extra.commentId)
            }>comment </LinkCustom>
          by <LinkCustom> {historyItem.extra.ownerAnswer ? usersById(historyItem.extra.ownerAnswer).displayName : 'a user'} </LinkCustom> as the Answer
        </HistoryItem>


      case 'question_open':
        return <HistoryItem>
          Moved this <LinkCustom onClick={() => openLink(historyItem)}>question</LinkCustom> from Draft to Open
        </HistoryItem>


      case 'question_new':
        return <HistoryItem>
          Created draft <LinkCustom onClick={() => openLink(historyItem)}>question</LinkCustom>
        </HistoryItem>

      case 'report_newWithEditors':
        return <HistoryItem>
          Created <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_new':
        return <HistoryItem>
          Created <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom> without editors
        </HistoryItem>

      case 'report_edit':
        return <HistoryItem>
          Edited the <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_editQuestionsOrder':
        return <HistoryItem>
          Edited the order of the questions in the <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_editQuestions':
        return <HistoryItem>
          Added questions to the <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_view':
        return <HistoryItem>
          Viewed this <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_delete':
        return <HistoryItem>
          Deleted the <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_export':
        return <HistoryItem>
          Exported the <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_add_viewer':
        return <HistoryItem>
          Added <b>{getUsers(historyItem.extra)}</b> as a viewer in this <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_add_editor':
        return <HistoryItem>
          Added <b>{getUsers(historyItem.extra)}</b> as a editor in this <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_add_teams':
        return <HistoryItem>
          Added <b>{getTeams(historyItem.extra)}</b> as a viewer in this <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_remove_viewer':
        return <HistoryItem>
          Removed <b>{getUsers(historyItem.extra)}</b> as a viewer in this <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_remove_editor':
        return <HistoryItem>
          Removed <b>{getUsers(historyItem.extra)}</b> as a editor in this <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      case 'report_remove_teams':
        return <HistoryItem>
          Removed <b>{getTeams(historyItem.extra)}</b> as a viewer in this <LinkCustom onClick={() => openLink(historyItem)}>report</LinkCustom>
        </HistoryItem>

      default:
        return `ERROR! on ${historyItem.actionType}`
    }
  }

  return (
    <>
      {
        historyData && historyData.length > 0 && historyData.sort((a: any, b: any) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : ((b.createdAt.seconds < a.createdAt.seconds) ? -1 : 0))
          .map((item: any, key: number) => {
            let uid = item.createdBy;

            return (
              <div key={key}>
                <Grid.Column width='16'>
                  <p style={{ marginBottom: '0.6em', color: '#6C757D' }}>
                    {moment.utc(item.createdAt.seconds * 1000).fromNow()}
                  </p>
                  <Image src={photoUserByID(uid)} avatar />
                  <b>{usersById(uid).displayName}</b>
                  <div style={{ marginTop: '1em' }}>
                    {parseHistoryEvent(item)}
                  </div>
                  <Divider />
                </Grid.Column>
              </div>
            )
          })
      }
    </>
  );
}

export default HistoryItems;