import React, { useContext, useState, useEffect } from 'react';
import { Menu, Image, } from "semantic-ui-react"
import { TopMenuStyled } from './styles'
import logo from 'assets/hw/logofull.png'
import isologo from 'assets/hw/logo.png'
import HowWeeContext from 'services/contexts/ContextHowWee'
import DropdownUser from './DropdownUser'
import DropdownOrg from './DropdownOrg'
import Search from './Search'
import NotificationsHW from './Notifications'
import MenuHW from './MenuHW'
import MenuHWMin from './MenuHWMin'
import { navigate, useLocation } from '@reach/router';
import { useContextOrganisations } from 'services/hooks/useContextOrganisations'

function TopMenu(props: any) {

  const location = useLocation();
  const HWCtt: any = useContext(HowWeeContext);
  const { functions: { changeDefaultOrg } } = useContextOrganisations()

  const [openOID, setOpenOID]: any = useState(HWCtt.data.HW_DEFAULT?.organisation?.id);

  useEffect(() => {
    console.log("CHANGE DEFAULT ORG")
    if (window.location.pathname !== '/') {
      if (HWCtt.data.HW_DEFAULT.organisation) {
        if (HWCtt.data.HW_DEFAULT.organisation.id !== openOID) {
          setOpenOID(HWCtt.data.HW_DEFAULT.organisation.id)
        }
      }
    } else {
      setOpenOID(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HWCtt.data.HW_DEFAULT.organisation]);

  useEffect(() => {
    const routeParts = location.pathname.split('/')

    if (location.pathname === '/') {
      changeDefaultOrg(null);
    } else {
      if (routeParts[0] === '' && routeParts[1] === 'app' && routeParts[2] === 'org') {
        const oidNow = routeParts[3];
        if (oidNow !== openOID) {
          console.log(openOID, oidNow)
          setOpenOID(oidNow)
          changeDefaultOrg(oidNow);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <TopMenuStyled>
        <Menu style={{ backgroundColor: "#F8F9FA", marginBottom: '0px' }} secondary>

          {/* 
          Side Menu for Organisations
          <Menu.Item position="left" style={{ justifyContent: 'left' }}>
            <div style={{ paddingLeft: '1em', cursor: 'pointer' }} onClick={() => { handleLogoClick() }}>
              <Icon name='sidebar' />
              {openOrg ? openOrg.name : 'Menu'}
            </div>
          </Menu.Item> 
          */}

          {!openOID &&
            <Menu.Item
              className="mobile tablet only"
              style={{ marginBottom: '0px', float: 'left', justifyContent: 'left' }}>
              <Image
                src={isologo}
                style={{ maxHeight: '40px', marginLeft: '1em', cursor: 'pointer' }}
              />
            </Menu.Item>
          }

          <Menu.Item
            className="computer widescreen large screen only"
            style={{ marginBottom: '0px', float: 'left', justifyContent: 'left' }}
          >
            <Image
              onClick={() => navigate(openOID ? `/app/org/${openOID}/questions` : '/')}
              src={logo}
              style={{ maxHeight: '40px', marginLeft: '1em', cursor: 'pointer' }}
            />
          </Menu.Item>

          {openOID ?
            <MenuHW oid={openOID} /> :
            <MenuHWMin />
          }

          <Menu.Item position='right'>
            <div style={{ width: '100%', float: 'right' }}>
              <Search />
              <NotificationsHW oid={openOID} />
              <DropdownOrg />
              <DropdownUser />
            </div>
          </Menu.Item>
        </Menu>
      </TopMenuStyled >
    </>
  );
}

export default TopMenu;