import React, { createContext, useEffect } from "react";
import { useImmer } from 'use-immer';

const initial = {
  modalData: {
    modalType: '',
    modalSize: 'small',
    isOpen: false,
  },
  setModalData: {}
}

const ModalContext = createContext(initial);
const { Consumer } = ModalContext;

function ModalProvider(props: any): any {

  const [modalData, setModalData]: any = useImmer(initial)

  useEffect(() => {
    setModalData((draft: any) => {
      draft.setModalData = setModalData;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalContext.Provider value={modalData} >
      {props.children}
    </ModalContext.Provider >
  )

}

export default ModalContext;
export {
  ModalProvider,
  Consumer as ModalConsumer,
};