import { useFirestoreHook } from './useFirestoreHook'

export function useNotificationsHook(oid: string) {

  const { collections: { HWFS } } = useFirestoreHook();

  const notifRef = HWFS.userNotification(oid);
  const notiGeneralRef = HWFS.users_notifications;

  const markReaded = (notifId: string) => {
    notifRef.doc(notifId).update({ readed: true })
  }

  const marReadedGeneral = (notifId: string) => {
    try {
      notiGeneralRef.doc(notifId).update({ readed: true })
    } catch (err: any) {
      console.error(err)
    }
  }

  const markCapturedFirstTime = (notifId: string) => {
    // captured = the frontend read this notification in some moment.
    try {
      notiGeneralRef.doc(notifId).update({ captured: true })
    } catch (err: any) {
      console.error(err)
    }
  }


  return {
    functions: {
      markReaded,
      marReadedGeneral,
      markCapturedFirstTime
    }
  }
}