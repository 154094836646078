import React, { useState, useEffect } from 'react';
import { ListBoard, Title, ContainerCards } from './styles'
import CardQuestion from '../Card'
import { Button } from 'semantic-ui-react';
import { useVerticalScroll } from 'services/hooks/useVerticalScroll'
import capitalize from 'capitalize'
import { Droppable } from 'react-beautiful-dnd'
import EditQuestionModal from 'pages/App/components/Modals/EditQuestion'
import { useUser } from 'reactfire'
// import { useQuestionsHook } from 'services/hooks/useQuestionsHook';

function List({ title, questions, oid, projId, code, search }: any) {

  // const { functions: { userHaveSomeAccessInQuestion } } = useQuestionsHook(oid);
  const scrollRef: any = useVerticalScroll();
  const [modalOpen, setModalOpen] = useState(false);
  const [titleFormatted, setTitleFormatted] = useState(title);
  const { uid }: any = useUser();
  const limitForAnwsered = 9;

  useEffect(() => {

    switch (title) {
      case 'answered': setTitleFormatted('Ready for Review'); break;
      case 'selected': setTitleFormatted('Answer Selected'); break;
      default:
        return setTitleFormatted(capitalize(title));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const EmptyCard = (props: any) => {
    return (<></>)
  }

  return (
    <ListBoard>
      <EditQuestionModal
        oid={oid}
        projId={projId || null}
        questions={questions}
        open={modalOpen}
        setOpen={setModalOpen}
      />
      <Title>

        {titleFormatted}

        {(title === 'draft' && projId) &&

          <Button
            onClick={() => setModalOpen(!modalOpen)}
            basic
            icon
            size='mini'
            style={{ float: 'right', paddingTop: '4px', paddingBottom: '4px', marginRight: '10px' }}
          >
            +
          </Button>
        }
      </Title>
      <ContainerCards ref={scrollRef}>
        <Droppable droppableId={title}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ height: '100%', position: 'static' }}
            >
              {questions && questions.sort((a: any, b: any) => (a.lexorank > b.lexorank) ? 1 : -1).map((question: any, index: number) => {

                if (code === 203 && index > limitForAnwsered) return <EmptyCard key={index} />

                if (title === 'draft') {

                  if (question.createdBy === uid) {
                    return (
                      <CardQuestion projId={projId} index={index} key={question.id} question={question} oid={oid} />
                    )
                  }

                } else {

                  // if (userHaveSomeAccessInQuestion(question)) {
                  return (
                    <CardQuestion projId={projId} index={index} key={question.id} question={question} oid={oid} />
                  )
                  // }

                }

                return (<EmptyCard key={index} />)
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {
          code === 203 && questions.length > limitForAnwsered &&
          <div style={{ position: 'static', marginTop: '80px', paddingRight: '18px' }}>
            <Button style={{ minWidth: '100%' }}>
              See more selected answers (+ {questions.length - limitForAnwsered})
            </Button>
          </div>
        }
      </ContainerCards>
    </ListBoard >
  );
}

export default List;