import React, { useEffect, useState } from 'react';
import { Grid, Image, Button, Progress, Divider, Icon, Dimmer, Loader } from 'semantic-ui-react'
import { useUser, useFirestoreDocData, SuspenseWithPerf } from 'reactfire'
import InputPack from '../../InputPack'
import { useImmer } from 'use-immer'
import { avoidEmptyObject } from 'helpers/mini'
import { toast } from 'react-toastify'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';

function ProfileElement({ path }: any) {

  const user: any = useUser();

  const [percentProgressProfile, setPrecentProgressProfile] = useState(0);
  const [disabledForm, setDisabledForm] = useState(false)
  const [loading, setLoading] = useState(false);
  const { collections: { HWFS } } = useFirestoreHook();

  const userPublicRef = HWFS.users_public.doc(user.uid);
  const userPublic: any = useFirestoreDocData(userPublicRef, { idField: 'id' });

  const userSettingsRef = HWFS.users_settings.doc(user.uid);
  const userSettings: any = useFirestoreDocData(userSettingsRef, { idField: 'id' });

  const [form, setForm] = useImmer({
    bio: userSettings.bio,
    email: userSettings.email,
    displayName: userSettings.displayName,
    role: userSettings.role,
    status: userSettings.status,
    username: userSettings.username,
  });

  useEffect(() => {
    let total = 0, notEmpty = 0;
    Object.entries(form).forEach((val) => {
      total++;
      if (val[1] !== '' && val[1] !== undefined) {
        notEmpty++
      } else if (val[1] && val[1].length > 0) {
        notEmpty++
      }
    });

    const result = notEmpty / total * 100;
    setPrecentProgressProfile(result);

    // 

    if (form.username === '' || form.displayName === '') {
      setDisabledForm(true);
    } else {
      setDisabledForm(false);
    }

  }, [form]);

  const [publicField, setPublicField] = useImmer({
    bio: userPublic.bio ? true : false,
    email: userPublic.email ? true : false,
    displayName: userPublic.displayName ? true : false,
    role: userPublic.role ? true : false,
    status: userPublic.status ? true : false,
    username: userPublic.username ? true : false,
  });

  const handleOnChange = (e: any, { name, value }: any) => {
    setForm((draft: any) => {
      draft[name] = value;
    });
  }

  const handleOnChangePublicCheckbox = (e: any, { name, checked, ...checkbox }: any) => {
    setPublicField((draft: any) => {
      draft[name] = checked;
    });
  }

  const updatePublicData = () => {
    const payloadPublic: any = { status: 200 };
    Object.entries(publicField).forEach((val) => {
      const key: string = val[0];
      if (val[1] === true) {
        payloadPublic[key] = form[key];
      }
    });

    userPublicRef.set(payloadPublic).then((res: any) => {
      toast.success(<><Icon name='check' /> Profile edited successfully </>)
    }).catch((err: any) => {
      console.error(err);
    });
  }

  const handleSaveChanges = () => {
    setLoading(true);
    userSettingsRef.update(avoidEmptyObject(form)).then((res: any) => {
      updatePublicData();
      setLoading(false)
    }).catch((err: any) => {
      console.error(err)
    })
  }

  return (
    <Grid columns='equal'>
      <Grid.Column></Grid.Column>
      <Grid.Column width={12}>
        <Dimmer active={loading} inverted>
          <Loader size='medium'>Saving Changes...</Loader>
        </Dimmer>
        <Grid>
          <Grid.Row columns={1}>
            {percentProgressProfile !== 100 &&
              <Grid.Column>
                <b>
                  {percentProgressProfile === 100 ? 'Everything Done!' : 'Progress on your profile'}
                </b>
                <Progress percent={percentProgressProfile} indicating>
                </Progress>
              </Grid.Column>
            }
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Image src={user.photoURL || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='tiny' circular />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            {/* <Grid.Column style={{ marginBottom: '50px' }}>
              <Divider style={{ width: '100%' }} />
            </Grid.Column> */}
            <Grid.Column>
              <InputPack
                publicToggle
                mandatory
                value={form.username}
                publicField={publicField.username}
                setValue={handleOnChange}
                setPublicField={handleOnChangePublicCheckbox}
                name='username'
                description="This is your unique name in the How-wee system. It's one of the ways that people can find you to invite you to their project."
                label='Username'
                placeHolder='Your username'
                icon='at'
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                publicToggle
                mandatory
                value={form.displayName}
                publicField={publicField.displayName}
                setValue={handleOnChange}
                setPublicField={handleOnChangePublicCheckbox}
                name='displayName'
                description="This is the name that your team mates will see when you interact with How-Wee"
                label='Display Name'
                placeHolder='Your Display Name'
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                publicToggle
                value={form.email}
                publicField={publicField.email}
                setValue={handleOnChange}
                setPublicField={handleOnChangePublicCheckbox}
                name='email'
                description="Contact support to change your email, learn more about sign-in methods"
                label='Email'
                disabled
                placeHolder='Your Email'
              />
            </Grid.Column>
            <Grid.Column>
              <Button fluid>Change Password</Button>
              <Divider />
            </Grid.Column>
            <Grid.Column style={{ marginTop: '50px' }}>
              <InputPack
                publicToggle
                value={form.role}
                publicField={publicField.role}
                setValue={handleOnChange}
                setPublicField={handleOnChangePublicCheckbox}
                name='role'
                description="Let people know what your job title is."
                label='Job title'
                placeHolder='Your job title'
              />
            </Grid.Column>
            <Grid.Column>
              <InputPack
                publicToggle
                value={form.bio}
                publicField={publicField.bio}
                setValue={handleOnChange}
                setPublicField={handleOnChangePublicCheckbox}
                name='bio'
                label='Bio'
                placeHolder='Let people know a little about you'
              />
            </Grid.Column>
            <Grid.Column>
              <Button primary disabled={disabledForm} onClick={handleSaveChanges} style={{ float: 'right' }}>Save Changes</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column></Grid.Column>
    </Grid>
  );
}

function Profile(props: any) {

  return (
    <SuspenseWithPerf fallback={<p>Cargando datos...</p>} traceId='secure_settings_personal'>
      <ProfileElement {...props} />
    </SuspenseWithPerf>
  )

}

export default Profile;