import React, { useState, useEffect } from 'react';
import { Card, Button, Segment, Header, Table, Image, Dropdown, Divider, Grid } from 'semantic-ui-react';
import InvitePeople from 'pages/App/components/Modals/InvitePeople'
import { useFirestoreCollectionData, SuspenseWithPerf, useFirestoreDocData } from 'reactfire'
import { Loading } from 'pages/App/components/Loading'
import Invitations from './Invitations'
import useFunctionsHook from 'services/hooks/useFunctionsHook';
import { onlyUniqueArray } from 'helpers/mini'
import noUserImage from 'assets/nouser.png'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import capitalize from 'capitalize';

interface MembersProps {
  organisation: any;
  orgId: string;
}

function MembersElement(props: MembersProps) {

  const { functions: { runCloudFunction } } = useFunctionsHook('COREHW-removeUser');
  const { collections: { HWFS } } = useFirestoreHook();
  const { functions: { getMyRole } } = useOrganisationHook(props.orgId);

  const [isAdmin] = useState(getMyRole() === 'admin');
  const [listAccess, setListAccess]: Array<any> = useState([]);
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);


  const orgAccessRef = HWFS.organisations_access.doc(props.orgId);
  const orgAccess: any = useFirestoreDocData(orgAccessRef, { idField: 'id' });

  const usersOrgRef = HWFS.users(props.orgId);
  const usersOrg: any = useFirestoreCollectionData(usersOrgRef, { idField: 'id' });

  useEffect(() => {
    const allUsers: Array<any> = [
      ...orgAccess.usersAdmin || null,
      ...orgAccess.usersMember || null,
      ...orgAccess.usersGuest || null,
      ...orgAccess.users || null
    ];

    const result: Array<any> = [];
    const usersUniques = onlyUniqueArray(allUsers);

    usersUniques.map((uid: string) => {
      const user = usersOrg.filter((user: any) => user.id === uid);
      return result.push(user[0]);
    });

    orgAccess.usersInvited.forEach((email: string) => {
      return result.push({ invitedEmail: email });
    })

    setMetadata(orgAccess);
    setListAccess(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgAccess]);

  const deleteUser = (uid: string) => {
    setLoading(true);
    runCloudFunction({ uid, oid: props.orgId }).then(() => {
      setLoading(false);
    });
  }

  const getRoleById = (uid: string) => {
    if (metadata.usersAdmin && metadata.usersAdmin.includes(uid)) return "admin"
    if (metadata.usersMember && metadata.usersMember.includes(uid)) return "member"
    if (metadata.usersGuest && metadata.usersGuest.includes(uid)) return "guest"
    if (metadata.users.includes(uid)) return "user"
    return "ERROR"
  }

  const DropdownUser = ({ user }: any) => {
    return (
      <Dropdown
        icon='ellipsis vertical'
        style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text='View'
          />
          <Dropdown.Item
            disabled={getRoleById(user) === 'admin'}
            onClick={() => deleteUser(user)}
            text='Delete'
          />
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const TableInvitations = () => {
    return (
      <Table unstackable singleLine basic='very' collapsing style={{ margin: '20px', width: '100%', marginBottom: '50px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Member</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            listAccess.map((item: any) => {
              if (!item) return (<></>)
              let image;
              if (item.invitedEmail) {
                image = noUserImage;
              } else {
                image = item.photoURL || noUserImage
              }

              return (
                <Table.Row>
                  <Table.Cell>
                    <Header as='h4' image>
                      <Image src={image} rounded size='mini' />
                      <Header.Content>
                        {item.displayName || item.invitedEmail}
                        <Header.Subheader>{item.username}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      item.invitedEmail ?
                        <p style={{ color: 'blue' }}>Pending...</p>
                        :
                        capitalize(getRoleById(item.id))
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {
                      !item.invitedEmail && isAdmin &&
                      <DropdownUser user={item.id} />
                    }
                  </Table.Cell>
                </Table.Row>)
            })
          }
          {/* {
            orgAccess.usersInvited.map((item: any) => {
              if (!item) return (<></>)
              return (
                <Table.Row>
                  <Table.Cell>
                    <Header as='h4' image>
                      <Image src={''} rounded size='mini' />
                      <Header.Content>
                        {item}
                        <Header.Subheader>Invited</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell> Pending </Table.Cell>
                  <Table.Cell>

                  </Table.Cell>
                </Table.Row>)
            })
          } */}
        </Table.Body>
      </Table>
    )
  }

  return (
    <Grid columns='equal'>
      <Grid.Column width={16}>
        <Card.Group itemsPerRow={1} style={{ marginTop: '15px' }} >
          <InvitePeople
            open={modal}
            setOpen={setModal}
            orgId={props.orgId}
          />
          <Card style={{ paddingTop: '10px' }}>
            <Header as='h3' attached='top'>
              Members
            </Header>
            <Segment attached>
              {!loading &&
                <>
                  {listAccess.length || 0} Members <br /><br />
                </>
              }
              <Button disabled={!isAdmin} primary onClick={() => setModal(true)}>Invite People</Button>
              <Divider />
              {!loading &&
                <TableInvitations />
              }
              {loading &&
                <Loading />
              }
            </Segment>
          </Card>
        </Card.Group>
      </Grid.Column>
      {
        isAdmin &&
        <Grid.Column width={16}>
          <Invitations oid={props.orgId} showInvites={true} />
        </Grid.Column>
      }
    </Grid >

  );
}

function Members(props: MembersProps) {
  return (
    <SuspenseWithPerf fallback={<Loading />} traceId="howwee-settings-organisation-member">
      <MembersElement {...props} />
    </SuspenseWithPerf>
  )

}

export default Members;