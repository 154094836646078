import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { navigate } from '@reach/router';
import { useProjectHook } from 'services/hooks/useProjectHook';

function Header({ oid, projId, reportOpen, reportName }: any) {

  const { functions: { projectById } } = useProjectHook(oid)
  const [project, setProject] = useState({ name: '', description: '' })

  useEffect(() => {
    if (projId) {
      setProject(projectById(projId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginLeft: '1em' }}>
      <Breadcrumb size='small'>
        <Breadcrumb.Section link onClick={() => navigate(`/app/org/${oid}/discussions`)}>
          Discussions
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section onClick={() => {
          navigate(`/app/org/${oid}/discussions/${projId}`)
        }} link>
          {project?.name}
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section onClick={() => {
          navigate(`/app/org/${oid}/discussions/${projId}/reports`)
        }} link>
          Reports
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section style={{ color: 'grey' }}>{reportOpen ? (reportName || 'Report without name') : 'New Report'}</Breadcrumb.Section>
      </Breadcrumb>
    </div>
  );
}

export default Header;