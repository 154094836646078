import React, { useContext, useState, useEffect } from 'react'
import ModalContext from 'services/contexts/ContextModal'
import EditQuestion from 'pages/App/Modules/Org/Questions/Forms/EditQuestion'
import ArchiveQuestion from 'pages/App/components/Modals/ArchiveQuestion'
import ArchiveDiscussion from 'pages/App/components/Modals/ArchiveDiscussion'
import { Loading } from 'pages/App/components/Loading'
import { useModalHook } from 'services/hooks/useModalHook'
import { Modal } from 'semantic-ui-react'
import { SuspenseWithPerf } from 'reactfire'
import { HistoryQuestion } from 'pages/App/Modules/Org/History/HistoryQuestion';
import { HistoryUser } from 'pages/App/Modules/Org/History/HistoryUser';
import { HistoryReport } from 'pages/App/Modules/Org/History/HistoryReport';
import { HistoryOrganisation } from 'pages/App/Modules/Org/History/HistoryOrganisation'
import { HistoryDiscussion } from 'pages/App/Modules/Org/History/HistoryDiscussion'
import { ProjectWithoutAccess } from 'pages/App/components/Modals/ProjectWithoutAccess'

export const modalTypes: any = {
  'archiveQuestion': {
    type: 'archiveQuestion',
    size: 'mini',
    title: 'Archive Question'
  },
  'archiveDiscussion': {
    type: 'archiveDiscussion',
    size: 'mini',
    title: 'Archive Discussion'
  },
  'editQuestion': {
    type: 'editQuestion',
    size: 'small',
    title: 'Edit Question'
  },
  'historyQuestion': {
    type: 'historyQuestion',
    size: 'small',
    title: 'Question History'
  },
  'historyUser': {
    type: 'historyUser',
    size: 'small',
    title: 'My Activity'
  },
  'historyReport': {
    type: 'historyReport',
    size: 'small',
    title: 'Report History'
  },
  'historyOrganisation': {
    type: 'historyOrganisation',
    size: 'small',
    title: 'Organisation History'
  },
  'historyDiscussion': {
    type: 'historyDiscussion',
    size: 'small',
    title: 'Discussion History'
  },
  'projectWithoutAccess': {
    type: 'projectWithoutAccess',
    size: 'small',
    title: 'Discussion access'
  },
}

export function GenericModal({ oid }: any) {
  const modalCtt: any = useContext(ModalContext);
  const { functions: { closeModal } } = useModalHook();
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (modalCtt.isOpen === true || modalCtt.isOpen === false) {
      setShow(modalCtt.isOpen)
    }
  }, [modalCtt.isOpen]);

  const ModalTypeRender = () => {
    if (oid) {
      switch (modalCtt.modalType) {

        case 'archiveQuestion':
          return <ArchiveQuestion oid={oid} />

        case 'archiveDiscussion':
          return <ArchiveDiscussion oid={oid} />

        case 'editQuestion':
          return <EditQuestion oid={oid} isModal editQuestion />

        case 'historyQuestion':
          return <HistoryQuestion oid={oid} isModal />

        case 'historyUser':
          return <HistoryUser oid={oid} isModal />

        case 'historyReport':
          return <HistoryReport oid={oid} isModal />

        case 'historyOrganisation':
          return <HistoryOrganisation oid={oid} isModal />

        case 'historyDiscussion':
          return <HistoryDiscussion oid={oid} isModal />

        case 'projectWithoutAccess':
          return <ProjectWithoutAccess oid={oid} isModal />

        default: return <></>
      }
    } else {
      return <></>
    }
  }

  return (
    <Modal
      closeOnDimmerClick={false}
      onClose={() => closeModal()}
      onOpen={() => { }}
      size={modalCtt.modalSize}
      open={show}
      closeIcon
    >
      <SuspenseWithPerf fallback={<Loading />} traceId='modal-generic'>
        <Modal.Header style={{ fontWeight: 400 }}>{modalCtt.modalTitle}</Modal.Header>
        <Modal.Content>
          <ModalTypeRender />
        </Modal.Content>
      </SuspenseWithPerf>
    </Modal>
  )
}
