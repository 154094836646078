import React, { useEffect, useState, useMemo } from 'react';
import { navigate } from '@reach/router';
import { Icon, Table, Dropdown, Card, Grid, Input } from 'semantic-ui-react'
import { BadgeUser, BadgeMultipleUser, BadgeMultipleSubject } from 'pages/App/components/Badges'
import { useTable, useSortBy } from 'react-table'
import { CenterAlign, LeftAlign } from './styles'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';
import { useFirestoreCollectionData, SuspenseWithPerf, useUser } from 'reactfire';
import GridCenter from 'pages/App/components/GridCenter';
import { CardShadow } from 'pages/App/components/CardShadow';
import { onlyUniqueArray } from 'helpers/mini';
import Header from './Header';
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';
import { Loading } from 'pages/App/components/Loading';
import { orgId, projectId } from 'typings/generic';
import { questionsArrayType, questionType } from 'typings/questions';

interface Props {
  oid: typeof orgId;
  projId: typeof projectId;
}

function ArchivedQuestionsCore({ oid, projId }: Props) {

  const { collections: { HWFS } } = useFirestoreHook();
  const { functions: { unarchiveQuestion } } = useQuestionsHook(oid);
  const { uid }: any = useUser();

  const [search, setSearch] = useState<string>('');
  const [questions, setQuestions] = useState<questionsArrayType>([]);

  const questionsRef = HWFS.questions(oid, projId)
  const questionsDocs: questionsArrayType = useFirestoreCollectionData(questionsRef, { idField: 'id' });

  useEffect(() => {
    if (search && search !== '') {
      let tmp: any = [];
      questionsDocs.map((question: questionType) => {
        if (question?.question?.toLowerCase().includes(search.toLowerCase())) {
          tmp.push(question);
        }
        return null;
      })
      setQuestions(tmp.filter((question: any) => question.status === 410))
    } else {
      setQuestions(questionsDocs.filter((question: any) => question.status === 410))
    }
  }, [search, questionsDocs]);

  const columns = useMemo(
    () => [
      {
        Header: 'Author',
        accessor: 'createdBy',
        headerStyle: { textAlign: 'right' },
        Cell: (props: any) => {
          const uid = props.cell.value;
          return (
            <LeftAlign onClick={props.column.onClick}>
              <BadgeUser uid={uid} oid={oid} />
            </LeftAlign>
          );
        },
      },
      {
        Header: 'Users Involved',
        accessor: 'usersWithComments',
        Cell: (props: any) => {
          return (
            <BadgeMultipleUser
              users={onlyUniqueArray(props.cell.value)}
              oid={oid}
              maxItems={3}
            />
          )
        }
      },
      {
        Header: 'Question',
        accessor: 'question',
        Cell: (props: any) => {
          return (
            <p>
              {props.cell.value}
            </p>
          );
        },
      },
      {
        Header: 'Subjects',
        accessor: 'subjects',
        Cell: (props: any) => {
          const subjects = props.cell.value;
          return (
            <div onClick={props.column.onClick}>
              <BadgeMultipleSubject subjects={subjects} maxItems={3} oid={oid} />
            </div>
          );
        },
      },
      {
        Header: 'Options',
        id: 'options',
        Cell: (props: any) => {
          const id = props.row.original.id;

          return (
            <CenterAlign>
              <Dropdown
                icon='ellipsis vertical'
                pointing='right'
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text='Open'
                    onClick={() => navigate(`../${projId}/questions/${id}`)}
                  />
                  <Dropdown.Item
                    text='Restore'
                    disabled={props.row.original?.createdBy !== uid}
                    onClick={() => unarchiveQuestion(id, props.row.original)
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>
            </CenterAlign>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const initialStateTable: any = {
    columns: columns,
    data: questions,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(initialStateTable, useSortBy)

  return (
    <div style={{ paddingTop: '0.3em' }}>
      <Header oid={oid} projId={projId} />
      <GridCenter fullWidth>
        <CardShadow>
          <Card.Content>
            <p style={{ fontSize: '18px', fontWeight: 500 }}> Archived Questions </p>
            <span> You can return these questions to the board or see the detail of each one </span>
            <Grid columns={1} style={{ marginTop: '0.1em' }}>
              <Grid.Column>
                <Input
                  icon='search'
                  iconPosition='left'
                  placeholder='Search...'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid.Column>
            </Grid>
          </Card.Content>
          <Card.Content>
            <Card style={{ width: '100%', padding: '1em' }}>
              <Table selectable basic='very' {...getTableProps()}>
                <Table.Header>
                  {headerGroups.map(headerGroup => (
                    <Table.Row {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any, index: number) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <Table.HeaderCell key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                          <span style={{ marginLeft: '5px' }}>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? <Icon name='arrow up' />
                                : <Icon name='arrow down' />
                              : ''}
                          </span>
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                  {rows.map(
                    (row: any, i: number) => {
                      prepareRow(row);
                      return (
                        <Table.Row
                          key={i}
                          style={{ cursor: 'pointer' }}
                          onClick={() => console.log(row.original, row.original.id)}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell: any, index: number) => {
                            return (
                              <Table.Cell key={index} {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </Table.Cell>
                            )
                          })}
                        </Table.Row >
                      )
                    }
                  )}
                </Table.Body>
              </Table>
            </Card>
          </Card.Content>
        </CardShadow>
      </GridCenter>
    </div>
  );
}

function ArchivedQuestions(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Loading />}
      traceId={`howwee-archived-questions-${props.projId || ''}`}
    >
      <ArchivedQuestionsCore {...props} />
    </SuspenseWithPerf>
  )
}

export default ArchivedQuestions;