import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useFirestoreCollectionData, SuspenseWithPerf } from 'reactfire'
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';

function SelectorSubjectsComponent({ oid, initial, setResult }: any) {

  const { collections: { HWFS } } = useFirestoreHook();
  const subjectsRef = HWFS.subjects(oid);
  const optionsInit = useFirestoreCollectionData(subjectsRef, { idField: 'id' });
  const { functions: { addDoc } } = useFirestoreHook();

  const [options, setOptions]: any = useState([]);
  const [subjects, setSubjects]: any = useState([]);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const subjectById = (subjectId: string) => {
    let res = { value: false, text: '' }
    optionsInit.map((subject: any) => {
      if (subject.id === subjectId) {
        res = { value: subject.id, text: subject.text }
      }
      return null
    })
    return res;
  }

  useEffect(() => {
    if (initial && initialLoaded === false) {
      setResult(initial);
      setInitialLoaded(true);
    } else {
      let result = subjects.filter((subjectId: any) => {
        const subject: any = subjectById(subjectId)
        if (subject.value === false) return false
        if (subject.text !== subject.value) return true

        return false
      })
      setResult(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjects]);

  useEffect(() => {
    let result: Array<any> = [];

    optionsInit.map((opt: any) => {

      if (opt.id !== opt.text) {
        result.push({
          key: opt.id,
          value: opt.id,
          text: opt.text
        });
      }

      return true;
    });

    setOptions(result)
  }, [optionsInit]);

  const createSubject = (subject: string) => {
    return new Promise(async (resolve, reject) => {
      setLoading(true)
      const payload = {
        name: subject.replace(/ /g, "").toLowerCase(),
        text: subject.replace(/ /g, "")
      }
      await addDoc(subjectsRef, payload).then((res: any) => {
        resolve(res.id)
      }).catch((err: any) => {
        console.error(err)
        reject(err)
      })
    })
  }

  return (
    <>
      <Dropdown
        style={{ width: '100%' }}
        placeholder='Press enter to add multiple subjects'
        fluid
        search
        selection
        multiple
        disabled={loading}
        loading={loading}
        allowAdditions
        additionLabel={'Create subject '}
        defaultValue={initial}
        options={options}
        onLabelClick={(event, data: any) => {
          setSubjects(
            subjects.filter((item: any) => item !== data.value)
          );
        }}
        onAddItem={(event, data: any) => {
          createSubject(data.value).then((subjectId: any) => {
            setOptions([
              { key: subjectId, text: data.value, value: subjectId },
              ...options]);
            setLoading(false)
          })
        }
        }
        onChange={(event, data: any) => {
          setSubjects(data.value);
        }}
        renderLabel={(data) => ({
          color: 'grey',
          content: data.text,
          // image: { avatar: true, src: getPhotoById(data.value) }
        })
        }
      />
    </>
  );
}

function SelectorSubjects(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Dropdown style={{ width: '100%' }} text='Loading subjects...' loading></Dropdown>}
      traceId={`howwee-subjects-${props.oid || ''}`}
    >
      <SelectorSubjectsComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default SelectorSubjects;