import React, { useState } from 'react';
import { Header, Grid, Dropdown, Form, Radio } from 'semantic-ui-react'
import { validateEmail } from 'helpers/mini'

interface Props {
  emailInvite: Array<string>;
  setEmailInvite: any;
  typeUser: string;
  setTypeUser: any;
}

function FormInvitePeople(props: Props) {

  const { emailInvite, setEmailInvite, typeUser, setTypeUser } = props;

  const [emailInviteOptions, setEmailInviteOptions] = useState([]);

  return (
    <Grid columns='equal'>
      <Grid.Column width={16}>
        <Header style={{ marginBottom: '0px' }}>Email Addresses</Header>
        <p>
          New people to add to your Organisation
        </p>
        <Dropdown
          placeholder='Press enter to add multiple emails'
          fluid
          search
          selection
          multiple
          allowAdditions
          onLabelClick={(event, data: any) => {

            setEmailInvite(
              emailInvite.filter((item: any) => item !== data.value)
            );
          }}
          onAddItem={(event, data: any) => {
            if (validateEmail(data.value)) {
              setEmailInviteOptions([
                { key: data.value, text: data.value, value: data.value },
                ...emailInviteOptions]);
            }
          }
          }
          onChange={(event, data: any) => {
            setEmailInvite(data.value);
          }}
          renderLabel={(label) => ({
            color: 'blue',
            content: `${label.text}`,
            icon: 'mail',
          })
          }
          options={emailInviteOptions}
        />
      </Grid.Column>
      <Grid.Column width={16}>
        <Header style={{ marginBottom: '0px' }}>Add as</Header>
        <p>
          Invited people will be added to your Organisation as <b> {typeUser} </b>
        </p>
        <Form>
          <Form.Group inline>
            <Form.Field>
              <Radio
                label='Member'
                name='radioGroup'
                value='member'
                checked={typeUser === 'member'}
                onChange={(e: any, val: string) => {
                  setTypeUser(val.value)
                }}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Admin'
                name='radioGroup'
                value='admin'
                checked={typeUser === 'admin'}
                onChange={(e: any, val: string) => {
                  setTypeUser(val.value)
                }}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Guest'
                name='radioGroup'
                value='guest'
                checked={typeUser === 'guest'}
                onChange={(e: any, val: string) => {
                  setTypeUser(val.value)
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Grid.Column>
      <Grid.Column width={16}>
        <Header style={{ marginBottom: '0px' }}>Add to Teams</Header>
        <p>
          Add to the following teams
              </p>
        <Dropdown
          placeholder='Search based on your current teams'
          fluid
          search
          selection
          multiple
          options={[]}
        />
      </Grid.Column>
    </Grid>
  );
}

export default FormInvitePeople;