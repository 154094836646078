import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Form, Header, Divider, Button, Icon, Image, Grid } from 'semantic-ui-react'
import { AlertInput } from 'pages/App/components/AlertInput'
import HowWeeContext from 'services/contexts/ContextHowWee';
import successIlust from 'assets/illustrations/watermelon-pack-illustration-14.svg'
import { navigate } from "@reach/router";
import useFunctionsHook from "services/hooks/useFunctionsHook";

const options = [
  { key: '2_5', text: '2-5 people', value: '2_5' },
  { key: '5_20', text: '5-20 people', value: '5_20' },
  { key: '20_100', text: '20-100 people', value: '20_100' },
  { key: '100_', text: '100+ people', value: '100_' },
]

function NewOrganisation(props: any) {

  const HWCtt: any = useContext(HowWeeContext);
  const { register, errors, handleSubmit, setValue } = useForm();
  const { functions: { runCloudFunction } } = useFunctionsHook('COREHW-createOrganisation');


  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState('');
  // const [planSelected, setPlanSelected] = useState('pro');
  const [termsConditions, setTermsConditions] = useState(false);

  useEffect(() => {
    register({ name: "sizeTeamInfo" });
    register({ name: "plan" });

    setValue('plan', 'free');
    setValue('sizeTeamInfo', options[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    setLoading(true);
    runCloudFunction(data).then((res: any) => {
      const orgID: string = res.data.oid;
      if (orgID) {
        setOrgId(orgID);
        setLoading(false);
        setSuccess(true);
        HWCtt.data.HW_FUNCTIONS.refreshUserAccess();
      } else {

      }
    });
  }

  // const handleGenericChange = (e, { name, value }: any) => {
  //   setValue(name, value);
  //   if (name === 'plan') {
  //     setPlanSelected(value);
  //   }
  // }

  if (success === true) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Header as='h2' icon style={{ margin: '0 auto' }} >
          <Icon name='check' color='green' />
          Success
          <Header.Subheader>
            Your organisation it's ready! Now create amazing teams to start with How-Wee
          </Header.Subheader>
          <Grid centered textAlign='center' columns={1}>
            <Grid.Column style={{ marginTop: '50px' }}>
              <Image src={successIlust} size='small' style={{ margin: '0 auto' }} />
            </Grid.Column>
          </Grid>
        </Header>
        <Grid centered textAlign='center' columns={1}>
          <Grid.Column style={{ marginTop: '10px', textAlign: 'center' }}>
            {orgId &&
              <Button onClick={() => {
                navigate(`/secure/settings/organisation/${orgId}`)
              }} icon labelPosition='right'>
                Go to Settings
                <Icon name='right arrow' />
              </Button>
            }
          </Grid.Column>
        </Grid>
      </div >
    )
  }

  return (
    <>
      <Header as='h1'>
        New Organisation
        <Header.Subheader>Create your organization with the free or pro plan</Header.Subheader>
      </Header>
      <Divider style={{ marginBottom: '30px' }} />
      <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>
        <Form.Group widths='equal'>
          <Form.Field>
            <label htmlFor="name">Name</label>
            <input
              name="name"
              placeholder='Your organisation name'
              ref={register({ required: true })}
            />
            {errors.name && <AlertInput message="We need the name of your organisation to proceed" />}
          </Form.Field>
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Select
            fluid
            label='Company Size'
            options={options}
            name="sizeTeamInfo"
            placeholder='Select Company Size'
            defaultValue={options[0].value}
            onChange={(e, { name, value }) => {

              setValue(name, value);
            }}
          />
        </Form.Group>
        <Form.Field>
          <label>Description</label>
          <textarea
            placeholder="Tell your team a little about your organization..."
            rows={3}
            name="description"
            ref={register({ required: true })}
          >
          </textarea>
          {errors.description && <AlertInput message="Please write a brief description" />}
        </Form.Field>
        {/* <Form.Group inline>
          <label>Plan</label>
          <Form.Radio
            label='Free'
            value='free'
            name='plan'
            checked={planSelected === 'free'}
            onChange={handleGenericChange}
          />
          <Form.Radio
            label='Pro'
            value='pro'
            name='plan'
            checked={planSelected === 'pro'}
            onChange={handleGenericChange}
          />
        </Form.Group> */}
        {/* <Form.Field>
          <div className="ui checked checkbox" data-children-count="1">
            <input className="hidden" name="terms" readonly="" tabindex="0" type="checkbox" value="">
              <label>I agree to the Terms and Conditions</label>
          </div>
        </Form.Field> */}
        <Form.Checkbox
          name='terms'
          label='I agree to the Terms and Conditions'
          value={termsConditions}
          onChange={(e, { name, checked }) => {
            const res: boolean = checked || false;
            setTermsConditions(res);
          }}
        />
        <Button type="submit" style={{ float: 'right' }} disabled={!termsConditions} primary>Submit</Button>
      </Form>
    </>
  );
}

export default NewOrganisation;