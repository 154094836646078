import styled from 'styled-components'

export const QuestionExplained = styled.div`
  width: 100%;
  cursor: pointer;
  :hover {}
`
export const QuestionContainer = styled.div`
  width: 100%;
  margin-bottom: 2em;
  transition-duration: 0.3s;
  :hover{
    padding: 0.5em;
    transition-duration: 0.3s;
    -webkit-box-shadow: 0px 0px 50px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 50px -20px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 50px -20px rgba(0,0,0,0.75);
    border-radius: 20px !important;
  }
`