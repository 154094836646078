import React from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react'

function MaintenanceMode() {
  return (
    <Segment placeholder style={{ height: '100vh' }}>
      <Header icon>
        <Icon name='ship' />
        <h2>
          How-wee is currently down for maintenance
        </h2>
        <h3 style={{ marginTop: '1em' }}>
          Sorry, we expect to be back soon.
        </h3>
      </Header>
      <Segment.Inline>
        <Button primary onClick={() => window.location.reload()}>Refresh App</Button>
        <Button href="mailto:support@how-wee.com" target="_blank">
          support@how-wee.com
        </Button>
      </Segment.Inline>
    </Segment>
  );
}

export default MaintenanceMode;