import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react'

interface DropdownPackProps {
  options: Array<any>;
  placeholder: string;
  label: string;
  setOption: any;
  defaultOption?: string;
  disabled?: boolean;
}

function DropdownPack(props: DropdownPackProps) {

  return (
    <Form.Field>
      <label><b>{props.label}</b></label>
      <Dropdown
        placeholder={props.placeholder}
        fluid
        search
        selection
        disabled={props.disabled}
        defaultValue={props.defaultOption}
        noResultsMessage='No results found.'
        options={props.options}
        onChange={(e, option) => {
          props.setOption(option.value)
        }}
      />
    </Form.Field>
  );
}

export default DropdownPack;