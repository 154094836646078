/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Segment, Loader, Dimmer, Image } from 'semantic-ui-react'

export function Loading(props) {
  return (
    <Segment>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Image
        alt="example"
        src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png'
      />
    </Segment>
  );
}