import React, { useRef, useState, useEffect } from 'react';
import EditorJS from '@editorjs/editorjs';
import { Image, Grid, Card, Button, Icon, Dimmer } from 'semantic-ui-react';
import { useUser } from 'reactfire'
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'
import { useCommentsHook } from 'services/hooks/useCommentsHook'
import { useQuestionsHook } from 'services/hooks/useQuestionsHook'
import Header from '@editorjs/header';
import Marker from '@editorjs/marker';
import ImageTool from '@editorjs/image';
import * as firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/storage';
import randomstring from 'randomstring';
import { questionType } from 'typings/questions';
import { commentArrayType, commentType } from 'typings/comments';
import { orgId } from 'typings/generic';

const DEFAULT_INITIAL_DATA = () => {
  return {
    "time": new Date().getTime(),
    "blocks": []
  }
}

interface Props {
  question: questionType;
  comments?: commentArrayType;
  oid: typeof orgId;
  commentParent: commentType | null;
}

const QuestionNewCommentEditorjs = ({ question, comments, oid, commentParent = null }: Props) => {

  let EDITTOR_HOLDER_ID: string;

  const { uid }: any = useUser();
  const [loading, setLoading] = useState(false);
  const { functions: { photoUserByID, usersById } } = useOrganisationHook(oid);
  const { functions: { newComment } } = useCommentsHook(oid, question.projId);
  const { functions: { updateQuestion } } = useQuestionsHook(oid)

  if (commentParent) {
    EDITTOR_HOLDER_ID = `editorjs-${question.id}-${commentParent.id}`;
  } else {
    EDITTOR_HOLDER_ID = `editorjs-${question.id}`;
  }

  const ejInstance: any = useRef();
  const [editorData] = useState<any>(DEFAULT_INITIAL_DATA);
  const [editor, setEditor] = useState<any>();

  // This will run only once
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      if (ejInstance && ejInstance.current) {
        ejInstance.current.destroy();
        ejInstance.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initEditor = () => {
    setEditor(new EditorJS({
      minHeight: 0,
      holder: EDITTOR_HOLDER_ID,
      data: editorData,
      onReady: () => {
        ejInstance.current = editor;
      },
      autofocus: true,
      tools: {
        header: Header,
        marker: Marker,
        image: {
          class: ImageTool,
          config: {
            uploader: {
              async uploadByFile(file: any) {
                const path = `/organisations/${oid}/projects/${question.projId}/questions/${question.id}`;
                let storageRef = firebase.storage().ref(path);

                let imagesRef = storageRef.child(randomstring.generate(20) + "__T__" + Date.now());
                let metadata = {
                  contentType: file.type,
                };

                let uploadTask = await imagesRef.put(file, metadata);
                const downloadURL = await uploadTask.ref.getDownloadURL();

                // console.xx("Uploaded successfully!", uploadTask);
                // console.xx(downloadURL);
                return {
                  success: 1,
                  file: {
                    url: downloadURL
                  }
                }
              }
            }
          }
        },
        paragraph: {
          config: {
            placeholder: 'Add a new comment...'
          }
        }
      },
    }));
  };

  const postComment = (data: any) => {
    let commentsData = comments ? comments : [];

    if (data.blocks.length > 0) {
      let payload: any = { commentRich: data };
      if (commentParent && commentParent.id) {
        payload.parent = commentParent.id;
      }

      newComment(payload, question.id, commentsData.length).then(() => {
        setLoading(false);
        editor.blocks.clear();

        let payload = {};

        if (commentsData.length === 0) {
          payload = {
            ...payload,
            status: 200,
            createdAtFirstComment: firebase.firestore.FieldValue.serverTimestamp()
          }
        }

        payload = {
          ...payload,
          commentsLength: commentsData.length + 1,
          usersWithComments: question.usersWithComments ?
            [...question.usersWithComments, uid] :
            [uid]
        }
        updateQuestion(question.projId, question.id, payload);
      })
    }
  }

  const saveComment = () => {
    setLoading(true);
    editor.save().then((outputData: any) => {
      postComment(outputData)
    }).catch((error: any) => {
      console.error('Saving failed: ', error)
    });
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width='16' style={{ marginBottom: '1em' }}>
          <Image src={photoUserByID(uid)} avatar />
          <b> {usersById(uid).displayName}</b>
        </Grid.Column>
        <Grid.Column width={16}>
          <Dimmer active={loading} inverted />
          <Card fluid style={{ padding: '1em 2em' }}>
            <div id={EDITTOR_HOLDER_ID}></div>
          </Card>
        </Grid.Column>
        <Grid.Column width='4'>
          <Button
            onClick={saveComment}
            disabled={loading}
            icon
            style={{ float: 'left', marginTop: '0.5em', display: 'none' }}
          >
            <Icon name='exchange' />
          </Button>
        </Grid.Column>
        <Grid.Column width='8'>

        </Grid.Column>
        <Grid.Column width='4'>
          <Button
            onClick={saveComment}
            disabled={loading}
            primary
            style={{ float: 'right', marginTop: '0.5em' }}
          >
            Post
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default QuestionNewCommentEditorjs;