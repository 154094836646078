import GridCenter from 'pages/App/components/GridCenter';
import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import { TextLine } from './styles'

function WelcomeMainBox(props: any) {
  return (
    <div style={{ marginTop: '2em' }}>
      <GridCenter>
        <Card fluid>
          <Card.Content header='Welcome to How-Wee!' />
          <Card.Content>
            <TextLine>
              Select an organisation from the <Icon name='users' /> icon in the top right of the screen to continue.
            </TextLine>
            <TextLine>
              If you do not yet have access to an organisation please follow these instructions:
            </TextLine>
            <TextLine>
              To use your How-wee account you need to create or join an organisation.
            </TextLine>
            <TextLine>
              To create an organisation click on the “Organisation” menu option and select “New Organisation”.
            </TextLine>
            <TextLine>
              If you have been invited to an organisation you will have received a notification via email and the notifications icon in the top right of this screen will show a red circle. Click on the notifications icon to open the notification.
            </TextLine>
            <TextLine>
              You can also click on your avatar icon and select "Organisation membership" to accept any invites you may have.
            </TextLine>
            <TextLine>
              This message will appear until you have created or joined an organisation.
            </TextLine>
            <span>
              Team,<br />
              How-wee
            </span>
          </Card.Content>
          <Card.Content extra>

          </Card.Content>
        </Card>
      </GridCenter >
    </div >
  );
}

export default WelcomeMainBox;