import React from 'react'
import Settings from 'pages/App/Modules/Settings';
import Creator from 'pages/App/Modules/Creator';
import { Router } from "@reach/router"
import SideMenu from './Layout/SideMenu'
import Container from './Layout/Container'


interface SecondaryProps {
  path: string
}

export const Secondary = ({ path }: SecondaryProps) => {
  return (<>
    {/* <div style={{ zIndex: 10, height: '98vh', overflowY: 'scroll', width: '100%', paddingLeft: '2%', paddingRight: '2%', paddingTop: '10vh' }}> */}
    <SideMenu>
      <Container withoutModules>
        <Router style={{ margin: '50px 5%' }}>
          <Settings path='/settings/*' />
          <Creator path='/new/*' />
        </Router>
      </Container>
    </SideMenu>
    {/* </div> */}
  </>)
}