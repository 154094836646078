import React from 'react';
import { Header, Icon, Grid, Image } from 'semantic-ui-react'
import successIlust from 'assets/illustrations/watermelon-pack-illustration-14.svg'

export function SuccessModal(props: any) {

  const { title, description } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      <Header as='h2' icon style={{ margin: '0 auto' }} >
        <Icon name='check' color='green' />
        {title ? title : 'Success'}
        <Header.Subheader>
          {description ? description : 'Changes applied correctly, amazing!'}
        </Header.Subheader>
        <Grid centered textAlign='center' columns={1}>
          <Grid.Column style={{ marginTop: '50px' }}>
            <Image src={successIlust} size='small' style={{ margin: '0 auto' }} />
          </Grid.Column>
        </Grid>
      </Header>
      <Grid centered textAlign='center' columns={1}>
        <Grid.Column style={{ marginTop: '10px', textAlign: 'center' }}>
          {/* {orgId &&
            <Button onClick={() => {
              navigate(`/secure/settings/organisation/${orgId}`)
            }} icon labelPosition='right'>
              Go to Settings
                <Icon name='right arrow' />
            </Button>
          } */}
        </Grid.Column>
      </Grid>
    </div >
  );
}