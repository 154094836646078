import React, { useEffect, useState } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/storage';
import { navigate } from '@reach/router'
import { useUser, useFirestoreCollectionData, SuspenseWithPerf, } from 'reactfire'
import { Breadcrumb, Button, Card, Dimmer, Dropdown, Grid, Icon, Input, Loader, Segment } from 'semantic-ui-react'
import GridCenter from 'pages/App/components/GridCenter'
import { CardShadow } from 'pages/App/components/CardShadow'
import { Loading } from 'pages/App/components/Loading';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';
import { useContextOrganisations } from 'services/hooks/useContextOrganisations'
import { useModalHook } from 'services/hooks/useModalHook';
import { useProjectHook } from 'services/hooks/useProjectHook';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import DiscussionsGrid from './Grid'
import DiscussionsTable from './Table'
import { projectArrayType, projectType } from 'typings/projects'
import { projectId } from 'typings/generic'

function DiscussionsCore({ oid, showArchived }: any) {

  const { uid }: any = useUser();
  const { functions: { projectAccessFromContext } } = useContextOrganisations();
  const { functions: { openModal } } = useModalHook();
  const { functions: { validateAccessHelper }, data: { accessLive } } = useProjectHook(oid);
  const { functions: { myTeams } } = useOrganisationHook(oid)
  const { collections: { HWFS } } = useFirestoreHook();

  const storage = firebase.storage();

  const discussionsRef = HWFS.projects(oid);
  const discussionsDocs: any = useFirestoreCollectionData(discussionsRef, { idField: 'id' });

  const [typeView, setTypeView] = useState<string>('list')
  const [loading, setLoading] = useState<boolean>(true)
  const [onlyArchived] = useState<boolean>(showArchived);
  const [search, setSearch] = useState<string>('');
  const [newData, setNewData]: any = useState([])
  const [teamAccess]: any = useState(myTeams())
  const [userProjAccess, setUserProjAccess]: any = useState([])
  const [dataDiscussions, setDataDiscussions] = useState<projectArrayType>([]);

  const openDiscussion = (discussionData: any, id: typeof projectId) => {
    if (validateAccessHelper(id)) {
      navigate(`discussions/${id}`)
    } else {
      openModal('projectWithoutAccess', {
        projectId: id,
        projectData: discussionData,
        accessLive: accessLive,
      }
      )
    }
  }

  const myTeamInProject = (discussion: projectType) => {
    let response: boolean = false;
    if (!discussion.teams) return false;
    discussion.teams.map((teamId: string) => {
      if (teamAccess.includes(teamId)) response = true;
      return null
    });
    return response;
  }

  const discussionById = (projId: typeof projectId) => {
    const res: projectType = dataDiscussions.filter((discussion: any) => discussion.id === projId)[0];
    return res || { coverImageURL: null };
  }

  const validateProjectAccess = (discussion: projectType, projId: typeof projectId) => {

    if (
      userProjAccess.includes(projId) &&
      !validateAccessHelper(projId)
    ) return true

    if (
      validateAccessHelper(projId) ||
      myTeamInProject(discussion) ||
      discussion.visibility === 'public' ||
      discussion.createdBy === uid) return false

    return true
  }

  const loadCoverImages = () => {
    return new Promise((resolve) => {
      const tmpDiscussions: any = [];
      let count: number = 1;

      // await don't work correctly in case of no-promise
      const validateFinish = () => {
        if (count === discussionsDocs.length) {
          resolve(tmpDiscussions);
        }
        count = count + 1;
      }

      discussionsDocs.map(async (discussion: projectType) => {
        // In the second condition, we check if the coverImageURL is already part of the state (to prevent getDownloadURL duplicated)
        if (discussion.coverImage && !discussionById(discussion.id)?.coverImageURL) {
          const pathImageReference = await storage.ref(discussion.coverImage);
          await pathImageReference.getDownloadURL().then((url: string) => {
            tmpDiscussions.push({
              ...discussion,
              coverImageURL: url,
            })

            validateFinish()

          }).catch((err: any) => {
            console.error(err)
            tmpDiscussions.push(discussion);
            validateFinish()
          })
        } else {
          tmpDiscussions.push(discussion);
          validateFinish()
        }
      })
    })
  }

  useEffect(() => {
    if (search && search !== '') {
      let tmp: any = [];
      dataDiscussions.map((discussion: any) => {
        if (discussion?.name?.toLowerCase().includes(search.toLowerCase())) {
          tmp.push(discussion);
        }
        return null;
      })
      setNewData(tmp)
    } else {
      setNewData(dataDiscussions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    projectAccessFromContext(oid).then((res: any) => {
      setUserProjAccess(res)

      loadCoverImages().then((discussionParsed: any) => {
        setDataDiscussions(discussionParsed)
        setLoading(false)
      }).catch((err: any) => {
        console.error(err);
      })
    }).catch((err: any) => {
      console.error(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussionsDocs]);

  useEffect(() => {
    if (Array.isArray(userProjAccess)) {
      // Filter by access)
      let filtered = dataDiscussions.filter((discussion: projectType) =>
        discussion.visibility === 'public' ||
        myTeamInProject(discussion) ||
        userProjAccess.includes(discussion?.id) ||
        accessLive?.projectsAuthor?.includes(discussion.id) ||
        accessLive?.projectsEditor?.includes(discussion.id) ||
        accessLive?.projectsIndividual?.includes(discussion.id)
      );

      if (onlyArchived) {
        filtered = filtered.filter((discussion: projectType) => discussion.status === 410)
      } else {
        filtered = filtered.filter((discussion: projectType) => discussion.status !== 410)
      }

      // Replace date for seconds
      filtered = filtered.map((project: projectType) => {
        let tmp: any = project;

        try {
          if (project.latestActivity?.seconds) {
            tmp.dateSeconds = project.latestActivity.seconds
          } else {
            tmp.dateSeconds = 0
          }
        } catch (err: any) {
          console.error(err);
        }

        return tmp;
      })

      setNewData(filtered || []);
    } else {
      setNewData(dataDiscussions.filter((discussion: any) => discussion.status !== 410));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDiscussions, userProjAccess, onlyArchived]);

  return (
    <div style={{ paddingTop: '0.3em' }}>

      <Breadcrumb size='small' style={{ marginLeft: '1em' }}>
        <Breadcrumb.Section style={{ color: 'grey' }}>
          Discussions
        </Breadcrumb.Section>
      </Breadcrumb>

      <div style={{ marginTop: '3em' }}>

      </div>

      <GridCenter fullWidth>
        <CardShadow>
          <Card.Content>
            <p style={{ fontSize: '18px', fontWeight: 500 }}>
              {onlyArchived && 'Archived'}  Discussions
            </p>
            <span> All the topics being discussed in this organisation </span>
            <Grid columns={1} style={{ marginTop: '0.1em' }}>
              <Grid.Column>
                <Input
                  icon='search'
                  iconPosition='left'
                  placeholder='Search...'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid.Column>
            </Grid>
            <Grid columns={2} style={{ marginTop: '0.1em' }}>
              <Grid.Column>
                {!onlyArchived &&
                  <>
                    <Button
                      onClick={() => navigate(`discussions/edit`)}
                      color='blue'
                    >Add new Discussion</Button>
                    <Dropdown
                      color='blue'
                      button
                      floating
                      style={{ paddingLeft: '10px', paddingRight: '0px' }}
                      icon='ellipsis vertical'
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                          navigate('./discussions-archived')
                        }}>
                          Archived Discussions
                    </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                }
                {onlyArchived &&
                  <Button onClick={() => {
                    navigate('./discussions')
                  }}
                  >
                    <Icon name='arrow left' /> Back to open Discussions
                  </Button>
                }
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Button disabled={typeView === 'list'} basic icon onClick={() =>
                  setTypeView('list')
                }>
                  <Icon name={'list'} />
                </Button>

                <Button disabled={typeView === 'grid'} basic icon onClick={() =>
                  setTypeView('grid')
                }>
                  <Icon name={'grid layout'} />
                </Button>
              </Grid.Column>
            </Grid>
          </Card.Content>
          <Card.Content style={{ overflow: 'auto' }}>
            <Dimmer.Dimmable as={Segment} blurring dimmed={loading}>

              <Dimmer active={loading} inverted>
                <Loader size='medium'>Loading Discussions...</Loader>
              </Dimmer>
              <div
                style={{ display: typeView === 'list' ? '' : 'none' }}>
                <DiscussionsTable
                  oid={oid}
                  loading={loading}
                  openDiscussion={openDiscussion}
                  discussions={newData}
                  validateProjectAccess={validateProjectAccess}
                />
              </div>
              <div
                style={{ display: typeView === 'grid' ? '' : 'none' }}>
                <DiscussionsGrid
                  oid={oid}
                  loading={loading}
                  openDiscussion={openDiscussion}
                  discussions={newData}
                  validateProjectAccess={validateProjectAccess}
                />
              </div>
            </Dimmer.Dimmable>
          </Card.Content>
        </CardShadow>

      </GridCenter>
    </div >
  );
}

function Discussions(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Loading />}
      traceId={`howwee-discussions-${props.oid || ''}`}
    >
      <DiscussionsCore {...props} />
    </SuspenseWithPerf>
  )
}

export default Discussions;