/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import SideMenuModules from '../SideMenuModules'
import { Segment, Sidebar } from 'semantic-ui-react'
import { ModuleContainer } from './styles'

function Container(props: any) {

  return (
    <div style={{ borderRadius: '0px', height: '100%', marginLeft: '-2px', marginTop: '7vh' }}>
      <Sidebar.Pushable as={Segment}>
        {props.withoutModules !== true &&
          <SideMenuModules />
        }
        <Sidebar.Pusher>
          <ModuleContainer>
            {props.children}
          </ModuleContainer>
        </Sidebar.Pusher>
      </Sidebar.Pushable>

    </div >
  );
}

export default Container;