import React from 'react';
import { Label } from 'semantic-ui-react'

function StatusInvitation({ code }: any) {
  let statusCode = parseInt(code);

  switch (statusCode) {
    case 201:
      return (<Label as='a' color='black'>Rejected</Label>);
    case 404:
      return (<Label as='a' color='red'>Cancelled</Label>);
    case 203:
      return (<Label as='a' color='blue'>Pending</Label>);
    case 200:
      return (<Label as='a' color='green'>Accepted</Label>);
    default:
      return (<Label as='a' color='grey'>Error ({code})</Label>);
  }
}

export default StatusInvitation;