import React, { useState, useEffect, useContext } from 'react';
import capitalize from 'capitalize';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'
import { Icon, Label, Grid, Divider, Button } from 'semantic-ui-react'
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';
import SearchGlobalContext from 'services/contexts/ContextSearchGlobal'
import { useModalHook } from 'services/hooks/useModalHook';
import { useUser } from 'reactfire'
import moment from 'moment'
import { useProjectHook } from 'services/hooks/useProjectHook';
import { navigate } from '@reach/router';
import { BadgeUser } from 'pages/App/components/Badges';
import { orgId } from 'typings/generic';
import { questionType } from 'typings/questions';

interface Props {
  question: questionType;
  oid: typeof orgId;
  fullWidth: boolean;
}

function QuestionData({ question, oid, fullWidth }: Props) {

  const searchCtt: any = useContext(SearchGlobalContext);

  const { functions: { openModal } } = useModalHook()
  const { functions: { projectById } } = useProjectHook(oid)
  const { functions: { teamById, subjectById } } = useOrganisationHook(oid);
  const { functions: { likeQuestion, dislikeQuestion, archiveQuestion, unarchiveQuestion }, info: { statusString } } = useQuestionsHook(oid);

  const [commentIconHover, setCommentIconHover] = useState<boolean>(false);
  const [likeIconHover, setLikeIconHover] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(false);
  const { uid }: any = useUser();

  useEffect(() => {
    if (question.likes) {
      if (question.likes.includes(uid)) {
        setLiked(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const likeQuestionAction = () => {
    if (liked === false) {
      likeQuestion(question.id, question.projId);
      setLiked(true);
    } else {
      setLiked(false)
      dislikeQuestion(question.id, question.projId);
    }
  }

  const getPriorityIcon = (priority: string) => {
    if (!priority) return <Icon name="arrow circle right" size='large' color='yellow' />
    switch (priority.toLowerCase()) {
      case 'high': return <Icon name="arrow circle up" size='large' color='red' />
      case 'medium': return <Icon name="arrow circle right" size='large' color='yellow' />
      case 'low': return <Icon name="arrow circle down" size='large' color='green' />
      default: return <Icon name="arrow circle right" size='large' color='yellow' />
    }
  }

  const styleRow: any = { paddingBottom: '0em' };

  const getStyleLike = () => {
    if (question.likes && question.likes.length) {
      if (question.likes.length > 9) return { marginRight: '2em' }
      if (question.likes.length > 0) return { marginRight: '1em' }
      return { marginRight: '2em' }
    }
  }

  return (
    <div>
      <Label
        color='blue'
        size='medium'
        style={{ float: 'left', margin: '2px', display: 'none' }}
      >
        {projectById(question.projId)?.name}
      </Label>
      <Label
        size='medium'
        style={{ float: 'left', margin: '2px' }}
      >
        {statusString[question.status]}
      </Label>
      <br />
      <h1 style={{ color: 'grey', marginBottom: '0px' }}>
        {question.questionPrefix ?
          `${capitalize(question.questionPrefix)}...` :
          'How do we...'
        }
      </h1>
      <h3 style={{ marginTop: '0px' }}>{capitalize(question.question || '')}</h3>
      <Divider />
      <Grid verticalAlign='middle' columns={2}>
        <Grid.Row style={styleRow}>
          <Grid.Column width='1'>
            <Icon name='user' />
          </Grid.Column>
          <Grid.Column>
            <BadgeUser uid={question.createdBy} oid={oid} />
            {/* {(question && question.users && question.users.length > 0) &&
              <Icon name="arrow right" />
            }
            {(question && question.users) &&
              question.users.map((uid: string, index: number) => {
                if (index > 1) return (null)
                return (
                  <Popup
                    basic
                    key={index}
                    content={usersById(uid).displayName}
                    trigger={
                      <Image src={photoUserByID(uid)} avatar />
                    }
                  />
                )
              })
            }
            {question && question.users && question.users.length > 2 &&
              <Button circular size='mini' style={{ paddingLeft: '10px', paddingRight: '10px' }}> +{question.users.length - 2} </Button>
            } */}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ ...styleRow, display: 'none' }}>
          <Grid.Column width='1'>
            <Icon name='users' />
          </Grid.Column>
          <Grid.Column>
            {question.teams && question.teams.map((teamId: string, index: number) => {
              return (
                <Label key={index} color='teal' size='medium' style={{ float: 'left', margin: '2px' }}>
                  {teamById(teamId).name || '???'}
                </Label>
              )
            })}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={styleRow}>
          <Grid.Column width='1'>
            <Icon name='tag' />
          </Grid.Column>
          <Grid.Column>
            {question.subjects && question.subjects.map((subjetId: string, index: number) => {
              return (
                <Label
                  color='grey'
                  size='medium'
                  style={{ float: 'left', margin: '2px' }}
                  key={index}
                >
                  #{subjectById(subjetId)}
                </Label>
              )
            })}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styleRow}>
          <Grid.Column width='16'>
            <span>
              <b style={{ color: 'grey', marginRight: '10px' }}>Priority</b>
              {getPriorityIcon(question.priority)}
            </span>
          </Grid.Column>
        </Grid.Row>
        {question.description &&
          <Grid.Row style={styleRow}>
            <Grid.Column width='16'>
              <b style={{ color: 'grey', marginRight: '10px' }}>Additional context</b>
              <p>
                {question.description}
              </p>
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row style={styleRow}>
          <Grid.Column width='16'>
            <span style={{ color: 'grey' }}>
              Created: {moment.utc(question.createdAt.seconds * 1000).format("DD MMM YYYY")}
            </span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width='16' textAlign='right'>
            {fullWidth &&
              <Button
                icon='expand arrows alternate'
                content='Expand View'
                color='blue'
                style={{ marginTop: '1em' }}
                onClick={() => {
                  searchCtt.setSearchData((draft: any) => {
                    draft.searchResultsQuestionsOpen = draft.searchResultsQuestionsOpen.filter((questId: string) => {
                      return questId !== question.id;
                    });
                  });
                  navigate(`
                    /app/org/${oid}/discussions/${question.projId}/questions/${question.id}
                    `)
                }}
              />
            }
            {
              question.createdBy === uid && question.status !== 410 &&
              <Button
                icon='archive'
                content='Archive'
                color='red'
                style={{ marginTop: '1em' }}
                onClick={() => {
                  archiveQuestion(question.id, question, true)
                }}
              />
            }
            {
              question.createdBy === uid && question.status === 410 &&
              <Button
                icon='arrow up'
                content='Restore and move to the board'
                color='violet'
                style={{ marginTop: '1em' }}
                onClick={() => {
                  unarchiveQuestion(question.id, question)
                }}
              />
            }
          </Grid.Column>
        </Grid.Row>

        <Grid.Row >
          <Grid.Column width='6'>
            <Icon
              size='large'
              link
              onClick={() => likeQuestionAction()}
              onMouseEnter={() => { setLikeIconHover(true) }}
              onMouseLeave={() => { setLikeIconHover(false) }}
              name={(likeIconHover || liked) ? 'thumbs up' : 'thumbs up outline'}
              style={getStyleLike()}
            >
              <span style={{ fontFamily: 'Roboto' }}>
                {
                  question.likes &&
                    question.likes.length &&
                    question.likes.length > 0 ?
                    question.likes.length : ''
                }
              </span>
            </Icon>
            <Icon
              size='large'
              link
              onClick={() => {
                const elem: any = document.getElementById('newCommentContainer')
                if (elem) elem.scrollIntoView()
              }}
              onMouseEnter={() => { setCommentIconHover(true) }}
              onMouseLeave={() => { setCommentIconHover(false) }}
              name={(commentIconHover) ? 'comment' : 'comment outline'}
              style={{ marginRight: '10px' }}
            />
          </Grid.Column>
          <Grid.Column width='4'></Grid.Column>
          <Grid.Column width='6'>
            <Icon name='bookmark outline' size='large' style={{ float: 'right' }} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider style={{ marginTop: '1em' }} />
      <Grid>
        <Grid.Row>
          <Grid.Column width='16'>
            <div style={{ float: 'right' }}>
              <span
                onClick={() => openModal('historyQuestion', { question: question })}
                style={{ color: 'blue', cursor: 'pointer', margin: '0 3px 0 3px' }}>
                History
              </span>
              <span> | </span>
              <span style={{ cursor: 'pointer', margin: '0 3px 0 3px' }}>
                Enquiry Line
              </span>
              <span> | </span>
              <span style={{ cursor: 'pointer', margin: '0 3px 0 3px' }}>
                Create new Question from this
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider style={{ marginTop: '1em' }} />
    </div >
  );
}

export default QuestionData;