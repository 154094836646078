import React, { useState, useRef, useEffect } from 'react';
import { SuspenseWithPerf, useUser } from 'reactfire';
import { Loading } from 'pages/App/components/Loading';
import { useOrganisationHook } from 'services/hooks/useOrganisationHook'
import { Grid, Icon, Divider, Popup } from 'semantic-ui-react'
import { useCommentsHook } from 'services/hooks/useCommentsHook';
import { useQuestionsHook } from 'services/hooks/useQuestionsHook';
import moment from 'moment';
import Output from 'editorjs-react-renderer';
import QuestionNewCommentEditorjs from '../QuestionNewCommentEditorjs';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { BadgeUser } from 'pages/App/components/Badges';
import { commentType, commentArrayType } from 'typings/comments';
import { questionType } from 'typings/questions';
import { orgId } from 'typings/generic';

interface Props {
  comment: commentType;
  comments?: commentArrayType;
  question: questionType;
  oid: typeof orgId;
  isSubcomment?: boolean;
  focusComment: string;
}

function Comment({ comment, comments, question, oid, isSubcomment, focusComment }: Props) {

  const { functions: { usersById, userRepresentOrg } } = useOrganisationHook(oid);
  const { functions: { defineAnswer, removeAnswer } } = useQuestionsHook(oid);
  const { functions: { likeCommentQuestion, cancelLikeCommentQuestion, dislikeCommentQuestion, cancelDislikeCommentQuestion } } = useCommentsHook(oid, question.projId);

  const [commentIconHover, setCommentIconHover] = useState<boolean>(false);
  const [likeIconHover, setLikeIconHover] = useState<boolean>(false);
  const [dislikeIconHover, setDislikeIconHover] = useState<boolean>(false);

  const [disliked, setDisliked] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(false);
  const [newSubCommentOpen, setNewSubCommentOpen] = useState<boolean>(false);
  const { uid }: any = useUser();

  useEffect(() => {
    if (comment.likes) {
      if (comment.likes.includes(uid)) {
        setLiked(true)
      }
    }

    if (comment.dislikes) {
      if (comment.dislikes.includes(uid)) {
        setDisliked(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const likeComment = () => {
    if (liked === false) {
      likeCommentQuestion(comment.id, question.id);
      setLiked(true);
      setDisliked(false);
    } else {
      setLiked(false)
      cancelLikeCommentQuestion(comment.id, question.id);
    }
  }

  const dislikeComment = () => {
    if (disliked === false) {
      dislikeCommentQuestion(comment.id, question.id);
      setDisliked(true);
      setLiked(false);
    } else {
      setDisliked(false)
      cancelDislikeCommentQuestion(comment.id, question.id);
    }
  }

  const selectAnswer = (comment: commentType) => {
    defineAnswer(question.id, comment, question.projId);
  }

  const unselectAnswer = () => {
    removeAnswer(question.id, question.projId);
  }

  const haveAnswer = () => {
    if (question.answer && question.answer.id) {
      return true
    }
    return false
  }

  const isAnswer = (comment: commentType) => {
    if (haveAnswer()) {
      if (comment.id === question.answer.id) {
        return true
      }
    }

    return false;
  }

  const getStyle = (comment: commentType) => {
    if (isAnswer(comment)) {
      return {
        backgroundColor: '#FEF9D8',
        borderRadius: '10px',
        padding: '1em',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
      }
    }
    if (comment.id === focusComment) {
      return {
        borderRadius: '10px',
        borderColor: 'grey',
        padding: '1em',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.30)',
      }
    }
  }

  const commentRef: any = useRef(null);

  useEffect(() => {
    if (focusComment === comment.id) {
      if (commentRef?.current?.scrollIntoView) commentRef.current.scrollIntoView()
    }
  })

  return (
    <Grid.Row style={{ marginTop: '2em' }}>
      <div ref={commentRef} ></div>
      <Grid.Column width='16' style={getStyle(comment)}>

        {isAnswer(comment) &&
          <Grid columns={2} style={{ marginBottom: '1em' }}>
            <Grid.Column width='8'>
              <Icon name='star' />
              <b> Selected as Answer </b>
            </Grid.Column>
            <Grid.Column width='8'>
              {question.createdBy === uid &&
                <Popup
                  basic
                  content='Unselect comment as answer'
                  trigger={
                    <Icon
                      name='x'
                      style={{ float: 'right' }}
                      link
                      onClick={() => unselectAnswer()}
                    />
                  }
                />
              }
            </Grid.Column>
          </Grid>
        }

        <BadgeUser uid={comment.createdBy} oid={oid} />
        <b style={{ marginLeft: '0.5em' }}> {usersById(comment.createdBy).displayName} </b>
        <p style={{ marginLeft: '3em', color: 'grey', marginTop: '-0.5em', fontSize: '12px' }}>
          {userRepresentOrg(comment.createdBy)}
        </p>

        {/* {focusComment === comment.id &&
          <Label color='olive' style={{ float: 'right' }}> Comment open from History </Label>
        } */}

        {comment.comment ?

          <p style={{ marginTop: '1em', whiteSpace: 'pre-line' }}>
            {comment.comment}
          </p>
          :

          <div style={{ marginTop: '1em', whiteSpace: 'pre-line' }}>
            <Output
              data={comment.commentRich}
              style={{
                image: {
                  img: {
                    /* CCS */
                  }
                }
              }}
              config={{
                image: {
                  disableDefaultStyle: false,
                }
              }}
              classNames={{}}
            />
          </div>
        }
        <span style={{ color: 'grey' }}>
          - {comment.createdAt && moment.utc(comment.createdAt.seconds * 1000).fromNow()}
        </span>
        <Grid>
          <Grid.Row style={{ marginTop: '1em' }}>
            <Grid.Column width='6'>

              <Icon
                onClick={likeComment}
                onMouseEnter={() => { setLikeIconHover(true) }}
                onMouseLeave={() => { setLikeIconHover(false) }}
                name={(likeIconHover || liked) ? 'thumbs up' : 'thumbs up outline'}
                style={{ marginRight: '25px', cursor: 'pointer' }}
              >
                <span style={{ fontFamily: 'Roboto' }}>
                  {
                    comment.likes ?
                      comment.likes.length > 0 ?
                        comment.likes.length :
                        ''
                      :
                      ''
                  }
                </span>
              </Icon>

              <Icon
                onClick={dislikeComment}
                onMouseEnter={() => { setDislikeIconHover(true) }}
                onMouseLeave={() => { setDislikeIconHover(false) }}
                name={(dislikeIconHover || disliked) ? 'thumbs down' : 'thumbs down outline'}
                style={{ marginRight: '25px', cursor: 'pointer' }}
              >
                <span style={{ fontFamily: 'Roboto' }}>
                  {
                    comment.dislikes ?
                      comment.dislikes.length > 0 ?
                        comment.dislikes.length :
                        ''
                      :
                      ''
                  }
                </span>
              </Icon>
              {!isSubcomment &&
                <Icon
                  onMouseEnter={() => { setCommentIconHover(true) }}
                  onMouseLeave={() => { setCommentIconHover(false) }}
                  onClick={() => { setNewSubCommentOpen(!newSubCommentOpen); }}
                  name={(commentIconHover || newSubCommentOpen) ? 'comment' : 'comment outline'}
                  style={{ cursor: 'pointer' }}
                />
              }
            </Grid.Column>
            <Grid.Column width='4'></Grid.Column>
            <Grid.Column width='6'>
              {!haveAnswer() && question.createdBy === uid &&
                <span onClick={() => selectAnswer(comment)} style={{ color: 'blue', cursor: 'pointer', float: 'right' }}>
                  Select as answer
                </span>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>

      {comments && comments.map((subcomment: any, key: number) => {
        if (subcomment.parent !== comment.id) return null
        return (
          <>
            <Grid.Column key={key} width='16' style={{ paddingLeft: '10%' }}>
              <Comment
                focusComment={focusComment}
                comment={subcomment}
                question={question}
                isSubcomment
                oid={oid} />
            </Grid.Column>
          </>
        )
      })}

      {
        newSubCommentOpen &&
        <>
          <Grid.Column width='16' style={{ paddingLeft: '10%' }}>
            <Divider />
            <QuestionNewCommentEditorjs
              question={question}
              oid={oid}
              commentParent={comment}
              comments={comments}
            />
          </Grid.Column>
        </>
      }

    </Grid.Row >
  )
}

function QuestionCommentsComponent({ question, comments, oid }: any) {

  const [focusComment, setFocusComment]: any = useState('');

  const location = useLocation()

  useEffect(() => {
    const searchParams = parse(location.search)
    if (searchParams.comment) {
      setFocusComment(searchParams.comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Grid>
      {comments && comments.map((comment: any, key: string) => {

        if (comment.parent) return null
        if (comment.comment || comment.commentRich) {
          return (
            <Comment
              focusComment={focusComment}
              comment={comment}
              comments={comments}
              question={question}
              oid={oid}
              key={key}
            />
          )
        } else {
          return null
        }

      })}
      {comments.length > 0 &&
        <Grid.Row>
          <Grid.Column width='16'>
            <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />
          </Grid.Column>
        </Grid.Row>
      }
    </Grid >
  );
}

function QuestionComments(props: any) {
  return (
    <SuspenseWithPerf
      fallback={<Loading />}
      traceId={`howwee-question-${props.questionId || ''}`}
    >
      <QuestionCommentsComponent {...props} />
    </SuspenseWithPerf>
  )
}

export default QuestionComments;