import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Draggable as DraggableCard } from 'react-beautiful-dnd'
import { CardContentQuestion } from './Content'
import { questionType } from 'typings/questions';
import { orgId, projectId } from 'typings/generic';

const portal: HTMLElement = document.createElement('div');
document.body.appendChild(portal);

class CardQuestionPortal extends Component<any> {
  render() {
    const provided: any = this.props.provided;
    const snapshot: any = this.props.snapshot;

    const usePortal: boolean = snapshot.isDragging;

    const child: any = (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <CardContentQuestion
          oid={this.props.oid}
          independent
          question={this.props.question}
        />
      </div >
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
  }
}

interface Props {
  question: questionType;
  index: number;
  projId: typeof projectId;
  oid: typeof orgId;
}
  
function CardQuestion({ question, index, projId, oid }: Props) {

  return (
    <>
      <DraggableCard
        index={index}
        key={question.id}
        draggableId={question.id}
        isDragDisabled={projId ? false : true}
      >
        {(provided, snapshot) => (
          <CardQuestionPortal
            oid={oid}
            question={question}
            provided={provided}
            snapshot={snapshot}
          />
        )
        }
      </DraggableCard >
    </>
  );
}

export default CardQuestion;