import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';

function UserSelect({ usersAvailables, users, setUsers, currentUsers, usersDeleted }: any) {

  const [options, setOptions]: any = useState([]);

  useEffect(() => {
    const res: Array<any> = [];
    usersAvailables.map((user: any) => {
      if (currentUsers && currentUsers.includes(user.id)) {
        if (usersDeleted && !usersDeleted.includes(user.id)) {
          return true; // exclude current users
        }
      }
      return res.push({
        key: user.id,
        value: user.id,
        text: user.displayName,
        image: { avatar: true, src: user.photoURL || '' },
      })
    });
    setOptions(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAvailables, usersDeleted]);

  const getPhotoById = (uid: any) => {
    let photo: string = '';

    usersAvailables.map((user: any) => {
      if (user.id === uid) photo = user.photoURL;
      return true;
    })

    return photo;
  }

  return (
    <>
      <Dropdown
        placeholder='Press enter to add multiple users'
        fluid
        search
        selection
        multiple
        onLabelClick={(event, data: any) => {
          setUsers(
            users.filter((item: any) => item !== data.value)
          );
        }}
        onChange={(event, data: any) => {
          setUsers(data.value);
        }}
        renderLabel={(data) => ({
          content: `${data.text}`,
          image: { avatar: true, src: getPhotoById(data.value) }
        })
        }
        options={options}
      />
    </>
  );
}

export default UserSelect;