import { useContext } from 'react'
import ModalContext from 'services/contexts/ContextModal'
import { modalTypes } from 'pages/App/Modules/Org/ModalsContext'

type modalSize = 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';

export function useModalHook() {
  const modalCtt: any = useContext(ModalContext);

  const openModal = (type: string, data: any, size?: modalSize) => {
    if (modalTypes[type]) {
      modalCtt.setModalData((draft: any) => {
        draft.modalType = type;
        draft.modalData = data || {};
        draft.modalTitle = modalTypes[type].title;
        draft.modalSize = size || modalTypes[type].size;
        draft.isOpen = true;
      });
    } else {
      alert('This type modal not exist');
    }
  }

  const closeModal = () => {
    modalCtt.setModalData((draft: any) => {
      draft.modalType = null;
      draft.modalData = {};
      draft.isOpen = false;
    });
  }

  const getModalData = () => {
    return modalCtt.modalData;
  }

  return {
    functions: {
      openModal,
      closeModal,
      getModalData
    },
    data: {
      modalData: modalCtt.modalData,
    }
  }
}