import React, { useContext, useEffect, useState } from 'react';
import { Header, Image, Table, Card, Segment, Dropdown, Button, Label } from 'semantic-ui-react'
import { useHelperHook } from 'services/hooks/useHelperHook';
import { navigate } from '@reach/router';
import { useAccessHook } from 'services/hooks/useAccessHook'
import { ModalConfirm } from 'pages/App/components/Modals/Confirm';
import { toast } from 'react-toastify'
import useFunctionsHook from 'services/hooks/useFunctionsHook'
import { Loading } from 'pages/App/components/Loading';
import { useImmer } from 'use-immer';
import noCompanyLogo from 'assets/nocompany.jpg'
import HowWeeContext from 'services/contexts/ContextHowWee';

interface Props {
  setShowInvites: any;
  showInvites: boolean;
}

function Access({ setShowInvites, showInvites }: Props) {

  const HWCtt: any = useContext(HowWeeContext);

  const { functions: { listAccess } } = useAccessHook();
  const { functions: { runCloudFunction } } = useFunctionsHook('COREHW-acceptInvitation');
  const { functions: { orgLogoById } } = useHelperHook('')

  const [orgWithAccess, setOrgWithAccess]: any = useState([]);
  const [orgWorking, setOrgWorking] = useState([]);
  const [fullData, setFullData] = useState({})
  const [userAccess, setUserAccess] = useState({
    organisationAdmin: [],
    organisationMember: [],
    organisationGuest: [],
    organisation: [],
  });
  const [loading, setLoading] = useState(true);
  const [logosState, setLogosState] = useImmer({});

  useEffect(() => {
    listAccess().then((res: any) => {
      setFullData(res);
      setOrgWithAccess(res.organisations);
      setOrgWorking(res.organisationsWorking);
      setUserAccess(res.user_access);

      res.organisations.map((org: any) => {
        return orgLogoById(org.id).then((url: any) => {
          if (url === 'org_without_profile') url = noCompanyLogo;
          setLogosState((draft: any) => {
            draft[org.id] = url;
          })
        });
      });

      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkInvitations = (oid: string) => {
    if (fullData && fullData.invitations[oid] && fullData.invitations[oid].length > 0) {
      if (orgWorking && !orgWorking.includes(oid)) {
        if (fullData.invitations[oid][0].status === 203) {
          return fullData.invitations[oid][0]
        }
      }
    }

    return false
  }

  const joinOrganisation = (invitationOpen: any, oid: string, name: string) => {
    setLoading(true);
    const { id } = invitationOpen;

    if (id) {
      runCloudFunction({ invitationId: id }).then((res: any) => {
        if (res && res.data && res.data.success) {
          HWCtt.data.HW_FUNCTIONS.refreshUserAccess();
          setOrgWorking([oid, ...orgWorking])
          toast.success("Welcome to " + name || 'the organisation');
        }
        setLoading(false);
      });
    } else {
      toast.warning("Sorry, we have a problem with this invitation ID");
      setLoading(false);
    }
  }

  const getRoleByOrgId = (oid: string) => {
    const orgId: string = oid;
    if (userAccess.organisationAdmin && userAccess.organisationAdmin.includes(orgId)) {
      return 'Admin'
    } else if (userAccess.organisationMember && userAccess.organisationMember.includes(orgId)) {
      return 'Member'
    } else if (userAccess.organisationGuest && userAccess.organisationGuest.includes(orgId)) {
      return 'Guest'
    }

    if (fullData.invitations[oid] && fullData.invitations[oid][0]) {
      if (fullData.invitations[oid][0].role) {
        let roleTemp = fullData.invitations[oid][0].role;
        return roleTemp.charAt(0).toUpperCase() + roleTemp.slice(1)
      } else {
        return "Error";
      }
    }

    return 'Error'
  }

  const DropdownOrganisation = ({ id, name }: any) => {
    const invitationOpen = checkInvitations(id);

    return (
      <Dropdown
        icon='ellipsis vertical'
        style={{ marginTop: '0px', marginBottom: '0px', marginRight: '12px' }}
      >
        <Dropdown.Menu>
          {/* <Dropdown.Item disabled={invitationOpen ? true : false} text='View' /> */}
          {getRoleByOrgId(id) === 'Admin' &&
            <Dropdown.Item
              disabled={invitationOpen ? true : false}
              text='Edit'
              onClick={() => navigate(`/secure/settings/organisation/${id}`)}
            />
          }
          {invitationOpen &&
            <ModalConfirm
              primary
              confirmText={'Accept Invitation'}
              callback={() => joinOrganisation(invitationOpen, id, name)}
            >
              <Dropdown.Item text='Join' />
            </ModalConfirm>
          }
          {!invitationOpen &&
            <Dropdown.Item
              onClick={() => navigate(`/app/org/${id}`)}
              text='Open'
            />
          }
          {/* <Dropdown.Item disabled={invitationOpen ? true : false} text='Leave' /> */}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const TableAccess = () => {
    return (
      <Table unstackable singleLine basic='very' collapsing style={{ margin: '20px', width: '100%', marginBottom: '50px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Organisation</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Your Role</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {orgWithAccess.map(({ name, id, avatar }) => {
            return (
              <Table.Row key={id}>
                <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/app/org/${id}`)}>
                  <Table.Cell>
                    <Header as='h4' image>
                      <Image src={logosState[id]} rounded size='mini' />
                      <Header.Content>
                        {name}
                        <Header.Subheader>Organisation</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                </div>
                <Table.Cell>
                  {
                    checkInvitations(id) &&
                    <ModalConfirm
                      primary
                      confirmText={'Accept Invitation'}
                      callback={() => joinOrganisation(checkInvitations(id), id, name)}
                    >
                      <Label as='a' color='blue'>Invited</Label>
                    </ModalConfirm>
                  }
                </Table.Cell>
                <Table.Cell>{getRoleByOrgId(id)}</Table.Cell>
                <Table.Cell>
                  <DropdownOrganisation id={id} name={name} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  return (
    <Card.Group itemsPerRow={1} style={{ marginTop: '15px' }} >
      <Card style={{ paddingTop: '10px' }}>
        <Header as='h3' attached='top'>
          Organisations
        </Header>
        <Segment attached>
          {!loading &&
            <TableAccess />
          }
          {loading &&
            <Loading />
          }
          <Button
            primary style={{ float: 'right' }}
            onClick={() => {
              navigate('/secure/new/organisation')
            }}
          >Create New Organisation</Button>
          <Button
            style={{ float: 'left' }}
            onClick={() => setShowInvites(!showInvites)}
            icon={'info'}
          />
        </Segment>
      </Card>
    </Card.Group>
  );
}

export default Access;